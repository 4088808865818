/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from 'react'
import { LibraryItems } from '../../../../../../constant/mailer/libraryItem'
import {
  headingStyle,
  sidebarContainer,
  categoryLabelStyle,
  libraryCategoryStyle,
  categoryCountStyle,
  libraryStyle,
  libraryDropListStyle,
  quickAddWrapper
} from './style'
import QuickAddItem from './SidebarItems/QuickAddItem/quickAddItems'
import QuickAddLayout from './SidebarItems/QuickAddItem/quickAddLayout'
import Preheader from './SidebarItems/Preheader/preheader'
import Header from './SidebarItems/Header'
import HeroImage from './SidebarItems/HeroImage'
import ProductListing from './SidebarItems/ProductListing'
import ImageGallery from './SidebarItems/ImageGallery'
import Footer from './SidebarItems/Footer'
import EmailBody from './SidebarItems/Emailbody'

const Sidebar = ({ monitorDragging }) => {
  const [showDropList, setShowDropList] = useState(false)
  const [currentHoverItem, setCurrentHoverItem] = useState(null)

  const subMenu = useMemo(() => {
    return (LibraryItems.find(({ title }) => title === currentHoverItem) || {})
      .subMenus
  }, [currentHoverItem])
  const handleMouseEnter = title => {
    setShowDropList(true)
    setCurrentHoverItem(title)
  }

  const handleMouseLeave = () => {
    setShowDropList(false)
    setCurrentHoverItem(null)
  }

  const renderQuickAddItem = () => {
    return (
      <div css={quickAddWrapper}>
        {subMenu.map(item => {
          const { id } = item || {}
          return (
            <QuickAddItem
              key={id}
              data={item}
              monitorDragging={monitorDragging}
            />
          )
        })}
      </div>
    )
  }

  const renderPreHeader = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return (
        <Preheader key={id} data={item} monitorDragging={monitorDragging} />
      )
    })
  }

  const renderQuickAddLayout = () => {
    return (
      <div style={{ paddingTop: '24px' }}>
        {subMenu.map(item => {
          const { id } = item || {}
          return (
            <QuickAddLayout
              key={id}
              data={item}
              monitorDragging={monitorDragging}
            />
          )
        })}
      </div>
    )
  }

  const renderHeader = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return <Header key={id} data={item} monitorDragging={monitorDragging} />
    })
  }

  const renderHeroImage = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return (
        <HeroImage key={id} data={item} monitorDragging={monitorDragging} />
      )
    })
  }

  const renderProductListing = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return (
        <ProductListing
          key={id}
          data={item}
          monitorDragging={monitorDragging}
        />
      )
    })
  }

  const renderImageGallery = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return (
        <ImageGallery key={id} data={item} monitorDragging={monitorDragging} />
      )
    })
  }

  const renderFooter = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return <Footer key={id} data={item} monitorDragging={monitorDragging} />
    })
  }

  const renderEmailBody = () => {
    return subMenu.map(item => {
      const { id } = item || {}
      return (
        <EmailBody key={id} data={item} monitorDragging={monitorDragging} />
      )
    })
  }

  const renderMap = {
    'Quick add item': renderQuickAddItem,
    'Quick add layout': renderQuickAddLayout,
    Preheader: renderPreHeader,
    Header: renderHeader,
    'Hero image': renderHeroImage,
    'Product listing': renderProductListing,
    'Image gallery': renderImageGallery,
    Footer: renderFooter,
    'Email body': renderEmailBody
  }

  return (
    <div css={sidebarContainer}>
      <div css={libraryStyle}>
        <h3 css={headingStyle}>Library</h3>
        {LibraryItems.map(({ id, title, subMenus }) => {
          return (
            <div
              key={id}
              css={libraryCategoryStyle}
              onMouseEnter={() => handleMouseEnter(title)}
              onMouseLeave={handleMouseLeave}
            >
              <div css={categoryLabelStyle}>
                {title}
                <div css={categoryCountStyle}>{subMenus.length}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div
        onMouseEnter={() => {
          setShowDropList(true)
          setCurrentHoverItem(currentHoverItem)
        }}
        onMouseLeave={() => setShowDropList(false)}
        css={libraryDropListStyle}
        className={showDropList ? 'expanded' : ''}
      >
        {renderMap[currentHoverItem] ? renderMap[currentHoverItem]() : null}
      </div>
    </div>
  )
}

export default Sidebar
