/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import {
  dropdownMenu,
  blanket,
  dropdownSearch,
  optionsPanel,
  chevron,
  button
} from './style'
import Portal from './Portal'

function toTitleCase (str) {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const Button = ({ onClick, iconAfter, children }) => {
  return (
    <div onClick={onClick} css={button} className='button'>
      {children}
      {iconAfter ? iconAfter : null}
    </div>
  )
}

const ChevronDown = () => (
  <span css={chevron} className='chevron'>
    <img src={DropdownIcon} alt='dropdown-caret' />
  </span>
)

const Menu = ({ style, ...props }) => {
  return (
    <div
      css={dropdownMenu}
      style={style}
      {...props}
      className='dropdown-menu'
    />
  )
}

const Blanket = props => <div css={blanket} {...props} />

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
  style,
  menuPosition
}) => (
  <div css={[dropdownSearch, style]}>
    {target}
    {isOpen ? (
      <Portal>
        <Menu style={menuPosition}>{children}</Menu>
        <Blanket onClick={onClose} />
      </Portal>
    ) : null}
  </div>
)

const OptionsPanel = ({ options, onOptionSeleted, convertToTitleCase }) => {
  return (
    <div css={optionsPanel}>
      <ul>
        {options.map((option, index) => (
          <li key={index} onClick={() => onOptionSeleted(option)}>
            {convertToTitleCase ? toTitleCase(option) : option}
          </li>
        ))}
      </ul>
    </div>
  )
}

const DropdownWithSearch = ({
  value,
  options,
  onOptionSelected = () => {},
  selectPlaceholder = 'Select a value',
  style,
  convertToTitleCase = false
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)
  const [menuPosition, setMenuPosition] = useState({})
  const buttonRef = useRef(null)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect()
      setMenuPosition({
        position: 'absolute',
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width
      })
    }
  }, [isOpen])

  const onSelectChange = value => {
    toggleOpen()
    onOptionSelected(value)
    setSelectedValue(value)
  }

  return (
    <div ref={buttonRef} style={{ width: 'fit-content' }}>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        style={style}
        target={
          <Button
            iconAfter={<ChevronDown />}
            onClick={toggleOpen}
            isSelected={isOpen}
          >
            {selectedValue
              ? convertToTitleCase
                ? toTitleCase(selectedValue)
                : selectedValue
              : selectPlaceholder}
          </Button>
        }
        menuPosition={menuPosition}
      >
        <OptionsPanel
          options={options}
          onOptionSeleted={onSelectChange}
          convertToTitleCase={convertToTitleCase}
        />
      </Dropdown>
    </div>
  )
}

export default DropdownWithSearch
