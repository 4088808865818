/** @jsxImportSource @emotion/react */
import React, { useRef, useMemo, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import {
  grdStyle,
  tableSourceIconStyle,
  addConnectorBtnStyle,
  downloadBtnStyle,
  modalHeadingStyle,
  availableConnectorRowStyle,
  addButtonStyle,
  disabledStyle
} from './style'
import Salesforce from '../../assets/images/salesforce.png'
import downloadIcon from '../../assets/images/downloadIcon.png'
import LinkedIn from '../../assets/images/connectors/linkedin.png'
import Facebook from '../../assets/images/connectors/facebook.png'
import Twitter from '../../assets/images/connectors/twitter.png'
import GoogleAds from '../../assets/images/connectors/google_ads.png'
import Tradedesk from '../../assets/images/connectors/trade_desk.png'
import ZoomInfo from '../../assets/images/connectors/zoom_info.png'
import KickFire from '../../assets/images/connectors/kickfire.png'
import Bambora from '../../assets/images/dashboard/audience/bombora.png'
import Modal from '../ui/Modal'
import SwitchController from '../ui/SwitchController'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchConnectorHandler,
  fetchUserEnabledConnector,
  addConnectorHandler,
  updateConnectorStatus
} from '../../store/cdp/connectorsSlice'
import { v4 as uuidv4 } from 'uuid'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import FbAdAccountPopUp from '../../common/components/fbAdAccountPopup'
import { updateUserFbAdAccounts } from '../../store/onboardingDashboard'

const conectorIconMap = {
  salesforce: Salesforce,
  kickfire: KickFire,
  twitter: Twitter,
  facebook: Facebook,
  linkedin: LinkedIn,
  googleAds: GoogleAds,
  tradedesk: Tradedesk,
  zoominfo: ZoomInfo,
  bambora: Bambora
}

const ConnectorStatusMap = {
  connected: 'active',
  disconnected: 'pause',
  inactive: 'stop'
}

const RevConnectorStatusMap = {
  active: 'connected',
  pause: 'disconnected',
  stop: 'inactive'
}

const ConnectorList = [
  {
    id: uuidv4(),
    icon: Facebook,
    title: 'Facebook',
    desc: `Considering advertising on Facebook? There are ad solutions for every level of expertise. Your audience is
    waiting. Let them know you're here with Facebook ads. Try it now!`
  },
  {
    id: uuidv4(),
    icon: Twitter,
    title: 'Twitter',
    desc: `Advertise on Twitter. Reach potential customers. Get your messages in front
     of people not yet following you by promoting your Tweets. Gain more followers.`
  },
  {
    id: uuidv4(),
    icon: LinkedIn,
    title: 'LinkedIn',
    desc: `Generate leads, drive website traffic, and build brand awareness with Linkedin Ads.
     Target audiences by job title, function, industry, company, interests, and more.`
  },
  {
    id: uuidv4(),
    icon: GoogleAds,
    title: 'Google Ads',
    desc: `Get more customers on the phone, on your site, and in the door. Online ads on Google can help you
     reach the right customers and grow your business.`
  },
  {
    id: uuidv4(),
    icon: Tradedesk,
    title: 'The Trade Desk',
    desc: `The Trade Desk is the largest, independent programmatic advertising DSP for digital media buyers in the world.`
  },
  {
    id: uuidv4(),
    icon: ZoomInfo,
    title: 'ZoomInfo',
    desc: `It's our business to grow yours! Own your market with leading B2B contact data combined with sales intelligence
    , engagement software, and workflow tools.`
  }
]

const fb_permissions = [
  'public_profile',
  'email',
  'business_management',
  'ads_management',
  'leads_retrieval',
  'pages_read_engagement',
  'pages_manage_metadata',
  'pages_read_user_content',
  'pages_show_list',
  'pages_manage_ads'
]

const Connectors = () => {
  const [showAddConnectorModal, setShowAddConnectorModal] = useState(false)

  const [connectorData, setConnectorData] = useState(ConnectorList)
  const [userConnector, setUserConnector] = useState([
    {
      connector_name: 'salesforce',
      sync_type: 'two-way',
      icon: Salesforce,
      status: 'active',
      last_api_call: '11/23/2022 12:12',
      total_api_calls: '25,288',
      rejected_records: '98',
      last_error_date: '11/23/2022 12:12',
      id: uuidv4(),
      error_message: '“reason”:”user doesn’t match outgoing filter'
    },
    {
      connector_name: 'kickfire',
      sync_type: 'two-way',
      icon: KickFire,
      status: 'stop',
      id: uuidv4(),
      last_api_call: '11/23/2022 12:12',
      total_api_calls: '25,288',
      last_error_date: '11/23/2022 12:12',
      rejected_records: '98',
      error_message: `“reason”:”user doesn’t match outgoing filter “reason”:”user doesn’t`
    }
  ])
  const [facebookAccessToken, setFacebookAccessToken] = useState(null)
  const [selectedUserAdAccounts, setSelectedUserAdAccounts] = useState([])
  const [showadAccountPopUp, setShowAdAccountPopUp] = useState(null)
  const [adAccounts, setAdAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessOption, setShowSuccessOption] = useState(null)
  const dispatch = useDispatch()
  const connectors = useSelector(state => state.connectors.connectorsData)
  const [googleClient, setGoogleClient] = useState(null)
  const userEabledConnector = useSelector(
    state => state.connectors.userConnector
  )

  useEffect(() => {
    // Load the Google Identity Services library dynamically
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.onload = initializeGoogleSignIn
    document.body.appendChild(script)
  }, [])

  const initializeGoogleSignIn = () => {
    console.log(process.env)
    if (window.google) {
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        ux_mode: 'redirect',
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        state: 'connectors'
      })
      setGoogleClient(client)
    } else {
      console.error('Google Identity Services library not loaded')
    }
  }

  useEffect(() => {
    // if (connectors.length) {
    //   setConnectorData(connectors)
    // }
    // if (userEabledConnector.length) {
    //   setUserConnector(userEabledConnector)
    // }
  }, [connectors, userEabledConnector])

  const handleAddConnector = () => {
    setShowAddConnectorModal(true)
  }

  const handleConnectorState = (state, id) => {
    const data = { status: RevConnectorStatusMap[state], id }
    dispatch(updateConnectorStatus(data))
  }
  const columns = [
    {
      headerName: 'Data Source',
      field: 'connector_name',
      cellRenderer: props => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div css={tableSourceIconStyle}>
              <img
                src={conectorIconMap[props.value]}
                alt='connector-icons'
                style={{ objectFit: 'contain', width: 52, height: 36 }}
              />
              <SwitchController
                style={{ marginTop: '6px' }}
                state={ConnectorStatusMap[props.data.status]}
                toggleSwitchState={state =>
                  handleConnectorState(state, props.data.id)
                }
              />
            </div>
            <div style={{ marginLeft: 10 }}>{props.value}</div>
          </div>
        )
      },
      width: 234
    },
    {
      headerName: 'Type',
      field: 'sync_type',
      width: 120
    },
    {
      headerName: 'Last API Call',
      field: 'last_api_call',
      width: 160
    },
    {
      headerName: 'Total API Calls',
      field: 'total_api_calls',
      width: 160,
      cellRenderer: props => {
        return <div>{props.value ? `${props.value} Records` : ''}</div>
      }
    },
    {
      headerName: 'Rejected Records',
      field: 'rejected_records',
      width: 160,
      cellRenderer: props => {
        return <div>{props.value ? `${props.value} Records` : ''}</div>
      }
    },
    {
      headerName: 'Last Record Error Message',
      field: 'error_message',
      width: 320,
      autoHeight: true,
      wrapText: true,
      cellRenderer: props => {
        return (
          <div>
            <p>{props.value}</p>
            <p
              style={{
                fontFamily: 'BentonSans, sans-serif',
                fontWeight: 'normal',
                fontSize: '12px'
              }}
            >
              <span style={{ color: '#18a3e2' }}>Last Error Date: </span>
              {props.data.last_error_date}
            </p>
          </div>
        )
      },
      cellStyle: { lineHeight: '20px' }
    },
    {
      headerComponent: () => {
        return (
          <div
            onClick={handleAddConnector}
            css={addConnectorBtnStyle(!(userConnector && userConnector.length))}
          >
            Add Connector
          </div>
        )
      },
      cellRenderer: () => {
        return (
          <div css={downloadBtnStyle}>
            <img src={downloadIcon} style={{ width: 22, height: 22 }} />
          </div>
        )
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      width: 150
    }
  ]
  const gridRef = useRef()
  const [gridApi, setGridApi] = useState()
  const [columnDefs] = useState(columns)
  const gridStyle = useMemo(
    () => ({
      width: 'calc(100% - 122px)',
      height: '100%',
      marginLeft: '120px',
      marginTop: '27px',
      border: '1px solid #ccc',
      borderRadius: '10px'
    }),
    []
  )

  const onGridReady = params => {
    setGridApi(params.api)
  }

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      cellStyle: {
        outline: 'none'
      }
    }
  }, [])

  const handleFacebookConnection = async () => {
    let isLoggedIn = false
    const facebookLoginRes = await new Promise((resolve, reject) => {
      window.FB.getLoginStatus(function (response) {
        resolve(response)
      })
    })
    const { status } = facebookLoginRes || {}
    if (status == 'connected') {
      const { authResponse: { accessToken } = {} } = facebookLoginRes || {}
      isLoggedIn = true
      setFacebookAccessToken(accessToken)
    }
    if (status == 'unknown' || status == 'not_authorized') {
      const { success, access_token: accessToken } = await new Promise(
        (resolve, reject) => {
          window.FB.login(
            function (response) {
              if (response.authResponse) {
                const accessToken = response.authResponse.accessToken
                const message = {
                  success: true,
                  access_token: accessToken
                }
                resolve(message)
              } else {
                console.log('User cancelled login or did not fully authorize.')
              }
            },
            {
              scope: fb_permissions.join(',')
            }
          )
        }
      )
      if (success) {
        isLoggedIn = true
        setFacebookAccessToken(accessToken)
      }
    }
    if (isLoggedIn) {
      try {
        const { adaccounts: { data } = {} } = await new Promise(
          (resolve, reject) => {
            window.FB.api(
              '/me',
              'GET',
              { fields: 'id,name,adaccounts' },
              function (response) {
                const { error: { message } = {} } = response || {}
                if (!message) {
                  resolve(response)
                } else {
                  reject(message)
                }
              }
            )
          }
        )
        setShowAdAccountPopUp(true)
        setAdAccounts(data)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleGoogleConnection = async () => {
    try {
      googleClient.requestCode()
    } catch (err) {
      console.log(err)
    }
  }

  const handleLinkedInConnection = async () => {
    try {
      const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID
      const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_REDIRECT_URI

      const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
        LINKEDIN_CALLBACK_URL
      )}&scope=profile`
      window.location.href = authUrl
    } catch (err) {
      console.log(err)
    }
  }

  const onAddConnectorHadler = name => {
    switch (name) {
      case 'Facebook':
        handleFacebookConnection()
        break
      case 'Google Ads':
        handleGoogleConnection()
        break
      case 'LinkedIn':
        handleLinkedInConnection()
        break
      default:
        break
    }
  }

  const handleSubmitFbAccounts = () => {
    setIsLoading(true)
    const data = {
      fb: {
        app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
        fb_exchange_token: facebookAccessToken,
        account_id: selectedUserAdAccounts.map(({ account_id }) => account_id)
      }
    }
    dispatch(updateUserFbAdAccounts(data)).then(({ data, status }) => {
      setIsLoading(false)
      if (status === 200) {
        setShowSuccessOption(true)
      }
    })
  }

  const checkForAlreadyAddedConnectors = id => {
    const userConnectorsIds = userConnector.map(item => item.connector_id)
    return userConnectorsIds.includes(id)
  }
  return (
    <>
      {showAddConnectorModal && (
        <Modal
          onClose={() => setShowAddConnectorModal(false)}
          style={{
            alignItems: 'flex-start',
            width: '646px',
            maxWidth: 'unset',
            padding: 0
          }}
        >
          <div css={modalHeadingStyle}>Add Connector</div>
          <div
            style={{
              maxHeight: '75vh',
              width: '100%',
              overflow: 'auto',
              marginBottom: 20
            }}
          >
            {connectorData.map((item, index) => {
              const { id, title, desc, icon } = item || {}
              return (
                <div
                  key={id}
                  css={availableConnectorRowStyle}
                  style={{
                    backgroundColor: `${index % 2 === 0 ? 'white' : '#F5F5F5'}`
                  }}
                >
                  <div
                    css={tableSourceIconStyle}
                    style={{
                      boxShadow: ' 0 2px 6px 2px rgba(102,153,204,0.4)'
                    }}
                  >
                    <img
                      src={icon}
                      alt='connector-icons'
                      style={{ objectFit: 'contain', width: 52, height: 36 }}
                    />
                  </div>
                  <div style={{ maxWidth: '356px' }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: '18px',
                        fontWeight: '300',
                        color: '#333',
                        lineHeight: 1.5
                      }}
                    >
                      {title}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: '14px',
                        fontWeight: '300',
                        color: '#333',
                        lineHeight: 1.2
                      }}
                    >
                      {desc}
                    </p>
                  </div>
                  <button
                    disabled={checkForAlreadyAddedConnectors(id)}
                    onClick={() => onAddConnectorHadler(title)}
                    css={addButtonStyle(checkForAlreadyAddedConnectors(id))}
                  >
                    {checkForAlreadyAddedConnectors(id) ? 'Added' : '+Add'}
                  </button>
                </div>
              )
            })}
          </div>
        </Modal>
      )}
      {showadAccountPopUp && (
        <Modal
          style={{
            width: 'fit-content',
            maxWidth: 'fit-content',
            padding: 0,
            height: 'fit-content'
          }}
          onClose={() => setShowAdAccountPopUp(false)}
        >
          <FbAdAccountPopUp
            adAccounts={adAccounts}
            onClose={value => setShowAdAccountPopUp(value)}
            showSuccessOption={showSuccessOption}
            selectedUserAdAccounts={selectedUserAdAccounts}
            setSelectedUserAdAccounts={setSelectedUserAdAccounts}
            setShowSuccessOption={setShowSuccessOption}
            isLoading={isLoading}
            handleSubmitFbAccounts={handleSubmitFbAccounts}
          />
        </Modal>
      )}
      <div className='ag-theme-alpine' css={grdStyle()} style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          style={{ width: '100%;', height: '100%;' }}
          rowData={userConnector}
          rowHeight={96}
          isFullWidth={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          headerHeight={50}
          domLayout={'autoHeight'}
          suppressRowClickSelection='true'
          rowMultiSelectWithClick='false'
          rowClass='unifiedRow'
          rowStyle={{ width: '100%' }}
          getRowClass={params => {
            if (params.node.id % 2 === 0) return 'unifiedRowEven'
          }}
        />
      </div>
    </>
  )
}

export default Connectors
