import { css } from '@emotion/react/macro'

export const mailerContainerStyle = css`
  display: flex;
  flex-direction: column;
`

export const contentStyle = css`
  width: 100%;
  display: flex;
`

export const mainContentStyle = css`
  padding-top: 35px;
  padding-left: 12px;
  width: calc(100% - 117px);
`
