/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'

import {
  container,
  header,
  audienceAccountData,
  content,
  inputSection,
  companySection,
  contactsSection,
  contactsForm,
  companyForm,
  inputStyle,
  actionButtonSection,
  buttonStyle
} from './style'

import zoomInfoIcon from '../../../../../assets/images/dashboard/audience/zeta.png'

const ZoomInfoAudienceCard = ({
  zoomInfoAudienceId,
  zoomInfoAudienceData,
  deleteZoomInfoAudience,
  saveZoomInfoAudience
}) => {
  const [zoomInfoData, setZoomInfoData] = useState({
    ...JSON.parse(JSON.stringify(zoomInfoAudienceData))
  })

  const onChangeHandler = (event, subSection, keyName) => {
    const prevData = JSON.parse(JSON.stringify(zoomInfoData))
    prevData[subSection][keyName].value = event.target.value
    setZoomInfoData(prevData)
  }

  return (
    <div css={container}>
      <div css={header}>
        <span className='iconBox'>
          <img className='image' alt='zoomInfoIcon' src={zoomInfoIcon}></img>
        </span>
        <div css={audienceAccountData}>
          <label>{zoomInfoData.audienceName}</label>
          <hr></hr>
          <span>133 Targets</span>
        </div>
      </div>
      <div css={content}>
        <div css={inputSection}>
          <div css={companySection}>
            <span className='companySectionHeaderLabel'>
              Company Firmographics
            </span>
            <div css={companyForm}>
              {Object.keys(zoomInfoData.companyFirmographics).map(keyName => {
                return (
                  <div key={keyName}>
                    <label>
                      {zoomInfoData.companyFirmographics[keyName].key}
                    </label>
                    <input
                      css={inputStyle}
                      onChange={event =>
                        onChangeHandler(event, 'companyFirmographics', keyName)
                      }
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div css={contactsSection}>
            <span className='contactsSectionHeaderLabel'>Contacts</span>
            <div css={contactsForm}>
              {Object.keys(zoomInfoData.contacts).map(keyName => {
                return (
                  <div key={keyName}>
                    <label>{zoomInfoData.contacts[keyName].key}</label>
                    <input
                      css={inputStyle}
                      className={
                        keyName === 'limitsContactsPerAccount' &&
                        'limitContacts'
                      }
                      onChange={event =>
                        onChangeHandler(event, 'contacts', keyName)
                      }
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div css={actionButtonSection}>
          <button
            css={buttonStyle}
            onClick={() => deleteZoomInfoAudience(zoomInfoAudienceId)}
          >
            Delete
          </button>
          <button
            css={buttonStyle}
            className='saveAudience'
            onClick={() =>
              saveZoomInfoAudience(zoomInfoAudienceId, zoomInfoData)
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default ZoomInfoAudienceCard
