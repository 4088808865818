/** @jsxImportSource @emotion/react */
import React from 'react'

import SegmentForm from '../SegmentForm/SegmentForm'

import { segmentCard, contractModalIconStyle, contentCard } from './style'

import contractModalIcon from '../../../../assets/images/dashboard/audience/contractModal.png'

const SegmentModalForm = ({
  formValue,
  id,
  updateData = () => {},
  deleteSegment = () => {},
  segmentDropdown = false,
  fullHeight = false,
  toggleHeight = () => {},
  modalClickHandler = () => {}
}) => {
  return (
    <div css={segmentCard(formValue.isEditable)}>
      <button css={contractModalIconStyle} onClick={modalClickHandler}>
        <img alt='expand-modal-icon' src={contractModalIcon} />
      </button>
      <div css={contentCard(formValue.isEditable)}>
        <SegmentForm
          data={formValue}
          id={id}
          fullHeight={true}
          modalForm={true}
          updateData={updateData}
          deleteSegment={deleteSegment}
          segmentDropdown={segmentDropdown}
        />
      </div>
    </div>
  )
}

export default SegmentModalForm
