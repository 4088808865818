import { css } from '@emotion/react'

const isActiveStyle = css`
  border: 2px solid #18a3e2;
`

export const editableTextContainer = isActive => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isActive && isActiveStyle}
`
