import { createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../axiosConfig'

const initialConnectorsState = { connectorsData: '', userConnector: '' }

const ConnectorsSlice = createSlice({
  name: 'connector',
  initialState: initialConnectorsState,
  reducers: {
    updateConnectors (state, action) {
      state.connectorsData = action.payload
    },
    updateUserConnector (state, action) {
      state.userConnector = action.payload
    }
  }
})

export const fetchConnectorHandler = () => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get('/connectors')
    if (status === 200) {
      dispatch(ConnectorsSlice.actions.updateConnectors(data))
    } else {
      throw new Error('Something went wrong!')
    }
  }
}

export const fetchUserEnabledConnector = () => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get('/user_connector_maps')
    if (status === 200) {
      const processedData = data.map(item => {
        const {
          id,
          status,
          sync_type,
          last_api_call,
          total_api_calls,
          rejected_records,
          error_messsage,
          connector: { id: connector_id, name: connector_name }
        } = item
        return {
          id,
          status,
          sync_type,
          last_api_call,
          total_api_calls,
          rejected_records,
          error_messsage,
          connector_id,
          connector_name
        }
      })
      dispatch(ConnectorsSlice.actions.updateUserConnector(processedData))
    } else {
      throw new Error('Something went wrong!')
    }
  }
}

export const addConnectorHandler = id => {
  return async dispatch => {
    const payload = {
      user_connector_map: {
        connector_id: id,
        user_id: 1
      }
    }
    const { status } = await axiosInstance.post('/user_connector_maps', payload)
    if (status === 200 || status === 201) {
      dispatch(fetchUserEnabledConnector())
    }
  }
}

export const updateConnectorStatus = data => {
  return async dispatch => {
    const { id, status: connectorStatus } = data
    const { status } = await axiosInstance.put(`/user_connector_maps/${id}`, {
      status: connectorStatus
    })
    if (status === 200 || status === 201) {
      dispatch(fetchUserEnabledConnector())
    }
  }
}

export default ConnectorsSlice.reducer
