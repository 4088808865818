import { css } from '@emotion/react'

const baseStyle = css`
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  cursor: grab;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #555c66;
  padding-top: 8px;
`

export const container = css`
  ${baseStyle}
`

export const layoutContainer = css`
  ${baseStyle}
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  img {
    max-width: 100%;
  }
`

export const sectionTitle = css`
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  padding-top: 8px;
`
