/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  container,
  header,
  audienceAccountData,
  content,
  inputSection,
  companySection,
  contactsSection,
  contactsForm,
  companyForm,
  inputStyle,
  actionButtonSection,
  buttonStyle
} from './style'

import zoomInfoIcon from '../../../../assets/images/dashboard/audience/zeta.png'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'

const ZoomInfoAudienceCard = ({
  zoomInfoAudienceId,
  zoomInfoAudienceData,
  deleteZoomInfoAudience,
  saveZoomInfoAudience
}) => {
  const [fullViewFlag, setFullViewFLag] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [zoomInfoData, setZoomInfoData] = useState({
    ...JSON.parse(JSON.stringify(zoomInfoAudienceData))
  })

  const audienceViewHandler = () => {
    setFullViewFLag(prev => !prev)
  }

  const editButtonHandler = () => {
    setIsEditable(prev => !prev)
  }

  const saveButtonHandler = () => {
    setIsEditable(prev => !prev)
    saveZoomInfoAudience(zoomInfoAudienceId, zoomInfoData)
  }

  const onChangeHandler = (event, subSection, keyName) => {
    const prevData = JSON.parse(JSON.stringify(zoomInfoData))
    prevData[subSection][keyName].value = event.target.value
    setZoomInfoData(prevData)
  }

  return (
    <div css={container(fullViewFlag)}>
      <div css={header(fullViewFlag)}>
        <span className='toggleAudienceHeight' onClick={audienceViewHandler}>
          <img alt='expandIcon' src={fullViewFlag ? minusIcon : plusIcon} />
        </span>
        <span className='iconBox'>
          <img className='image' alt='zoomInfoIcon' src={zoomInfoIcon}></img>
        </span>
        <div css={audienceAccountData}>
          <label>{zoomInfoData.audienceName}</label>
          <hr></hr>
          <span>133 Targets</span>
        </div>
      </div>
      <div css={content(fullViewFlag)}>
        <div css={inputSection}>
          <div css={companySection}>
            <span className='companySectionHeaderLabel'>
              Company Firmographics
            </span>
            <div css={companyForm}>
              {Object.keys(zoomInfoData.companyFirmographics).map(keyName => {
                return (
                  <div key={keyName}>
                    <label>
                      {zoomInfoData.companyFirmographics[keyName].key}
                    </label>
                    <input
                      disabled={!isEditable}
                      css={inputStyle}
                      value={zoomInfoData.companyFirmographics[keyName].value}
                      onChange={event =>
                        onChangeHandler(event, 'companyFirmographics', keyName)
                      }
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div css={contactsSection}>
            <span className='contactsSectionHeaderLabel'>Contacts</span>
            <div css={contactsForm}>
              {Object.keys(zoomInfoData.contacts).map(keyName => {
                return (
                  <div key={keyName}>
                    <label>{zoomInfoData.contacts[keyName].key}</label>
                    <input
                      disabled={!isEditable}
                      css={inputStyle}
                      className={
                        keyName === 'limitsContactsPerAccount' &&
                        'limitContacts'
                      }
                      value={zoomInfoData.contacts[keyName].value}
                      onChange={event =>
                        onChangeHandler(event, 'contacts', keyName)
                      }
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div css={actionButtonSection}>
          {!isEditable ? (
            <>
              <button css={buttonStyle} onClick={editButtonHandler}>
                Edit
              </button>
              <button
                css={buttonStyle}
                onClick={() => deleteZoomInfoAudience(zoomInfoAudienceId)}
              >
                Archive
              </button>
              <Link
                to='/cdp/view-contacts'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <button css={buttonStyle} className='viewContacts'>
                  View Contacts
                </button>
              </Link>
            </>
          ) : (
            <>
              <button
                css={buttonStyle}
                className='saveContacts'
                onClick={saveButtonHandler}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ZoomInfoAudienceCard
