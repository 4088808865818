/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react'
import Icon from '@material-ui/core/Icon'
import AudienceCard from '../../../CreateAdsCompaign/Forms/AudienceCard'
import salesforceIcon from '../../../../../assets/images/dashboard/audience/salesforce.png'
import neyoColorIcon from '../../../../../assets/images/neyoMarkColor.svg'
import minusIcon from '../../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../../assets/images/dashboard/audience/plusIcon.png'
import addAudienceIcon from '../../../../../assets/images/dashboard/ads/addAudienceIcon.png'
import copySelectedIcon from '../../../../../assets/images/dashboard/ads/copySelectedIcon.png'
import copyUnselectedIcon from '../../../../../assets/images/dashboard/ads/copyUnselectedIcon.png'
import trashIcon from '../../../../../assets/images/trash-icon.png'
import { useSelector, useDispatch } from 'react-redux'
import { updateTemplateTree } from '../../../../../store/dashboard/mailerSlice'
import {
  cardContainerStyle,
  emailNameStyle,
  emailTypeStyle,
  addAudienceIconStyle,
  selectedAudienceIconList,
  audienceListActionButtonContainer,
  //
  audienceListActionButton,
  addedAudienceIconStyle,
  audienceListContainer,
  audienceListSection,
  mailIcon,
  minimizeFromLeft,
  minimizeFromRight,
  extensionButton,
  leftExtensionContainer,
  rightExtensionContainer,
  leftExtensionCard,
  rightExtensionCard,
  mailContentContainer,
  mailActionButton,
  copyActionIconContainer,
  emailDetails,
  previewStyle
} from './style'

const MailerCard = ({
  segmentId,
  mailIndex,
  copyIconClicked,
  setCopyIconClicked,
  setCopiedMailData,
  mailData,
  segmentLinkedMapper,
  mailCampaignData,
  updateMailDetails,
  handleMailDelete,
  handleMailExtensionMinimize = () => {},
  setCurrentEditingMail = () => {},
  emailName,
  emailType,
  selected,
  source = 'neyo',
  inEditMode,
  isMailDataValid = true
}) => {
  const dispatch = useDispatch()
  const [expandedView, setExpandedView] = useState(false)
  const [audienceIconClicked, setAddAudienceIconClicked] = useState(false)
  const [audienceList, setAudienceList] = useState([])

  useEffect(() => {
    if (mailData) {
      const { audienceData } = mailData || {}
      setAudienceList(audienceData)
    }
  }, [mailData])

  // expander and minimizer state
  const [rightExtensionHoverFlag, setRightExtensionHoverFlag] = useState(false)
  const [rightMinimizeHoverFlag, setRightMinimizeHoverFlag] = useState(false)
  const [leftExtensionHoverFlag, setLeftExtensionHoverFlag] = useState(false)
  const [leftMinimizeHoverFlag, setLeftMinimizeHoverFlag] = useState(false)
  const [isMailEditable, setMailEditable] = useState(false)

  const {
    currentEditedSegmentandAudience: {
      segmentId: currentEditingSegmentId,
      audienceId: currentEditingMailId
    },
    emailTemplates,
    templateTree
  } = useSelector(
    ({
      mailer: { currentEditedSegmentandAudience, emailTemplates, templateTree }
    }) => ({
      currentEditedSegmentandAudience,
      emailTemplates,
      templateTree
    })
  )

  useEffect(() => {
    if (isMailEditable) {
      setCurrentEditingMail()
      const { emailLayoutTree, id } = mailData || {}
      let alreadyExistingTemplateTree = null
      if (templateTree.hasOwnProperty(segmentId)) {
        if (templateTree[segmentId].hasOwnProperty(id)) {
          alreadyExistingTemplateTree = templateTree[segmentId][id]
        }
      }
      if (emailLayoutTree && Object.keys(emailLayoutTree).length) {
        const clonedTemplateTree = JSON.parse(JSON.stringify(templateTree))
        if (clonedTemplateTree.hasOwnProperty(segmentId)) {
          clonedTemplateTree[segmentId][id] =
            alreadyExistingTemplateTree || emailLayoutTree
        } else {
          clonedTemplateTree[segmentId] = {}
          clonedTemplateTree[segmentId][id] =
            alreadyExistingTemplateTree || emailLayoutTree
        }
        dispatch(updateTemplateTree(clonedTemplateTree))
      }
    }
  }, [isMailEditable])

  const templateString = useMemo(() => {
    const { id: mailId } = mailData
    const { emailString } =
      (emailTemplates[segmentId] || []).find(
        ({ audienceId }) => audienceId == mailId
      ) || {}
    return emailString
  }, [emailTemplates, segmentId, mailData])

  const [
    showExtensionMinimizeButtons,
    setShowExtensionMinimizeButtons
  ] = useState(true)

  const getMailIcon = () => {
    let imageSource = neyoColorIcon
    if (source === 'salesforce') imageSource = salesforceIcon
    return <img className={source} alt={source} src={imageSource} />
  }

  const getSourceIconBox = source => {
    let imageSource = neyoColorIcon
    if (source === 'salesforce') imageSource = salesforceIcon
    return (
      <img
        className={source}
        alt={source}
        src={imageSource}
        width={19}
        height={13}
      ></img>
    )
  }

  const toggleHeightHandler = () => {
    if (audienceIconClicked) {
      setAddAudienceIconClicked(prev => !prev)
    }
    setExpandedView(prev => !prev)
  }

  const onAudienceCardSelection = index => {
    const prevAudienceData = JSON.parse(JSON.stringify(audienceList))
    prevAudienceData[index].isSelected = !prevAudienceData[index].isSelected
    setAudienceList(prevAudienceData)
  }

  const saveAudienceSelectionHandler = () => {
    setAddAudienceIconClicked(prev => !prev)
    updateMailDetails(segmentId, mailIndex, {
      audienceData: audienceList
    })
  }

  const cancelAudienceSelectionHandler = () => {
    setAddAudienceIconClicked(prev => !prev)
    setAudienceList(JSON.parse(JSON.stringify(mailData.audienceData)))
  }

  const getThumbnailBoxesForSelectedAudiences = (selectedAudiences = []) => {
    if (!selectedAudiences.length) {
      return (
        <span
          css={addAudienceIconStyle}
          onClick={() => {
            if (expandedView) {
              setExpandedView(prev => !prev)
            }
            setAddAudienceIconClicked(prev => !prev)
          }}
        >
          <img
            alt='add-audience-icon'
            src={addAudienceIcon}
            width={18}
            height={19}
          />
        </span>
      )
    } else if (selectedAudiences.length > 4) {
      return (
        <div css={selectedAudienceIconList}>
          {[0, 1, 2, 3].map(id => (
            <span
              css={addedAudienceIconStyle}
              key={`${selectedAudiences[id].id}${id}`}
              onClick={() => {
                if (expandedView) {
                  setExpandedView(prev => !prev)
                }
                setAddAudienceIconClicked(prev => !prev)
              }}
            >
              {getSourceIconBox(selectedAudiences[id].source)}
            </span>
          ))}
          <span
            css={addedAudienceIconStyle}
            onClick={() => {
              setAddAudienceIconClicked(prev => !prev)
            }}
          >
            <label className='additionalSelectedAudiences'>{`+${selectedAudiences.length -
              4}`}</label>
          </span>
        </div>
      )
    } else {
      return (
        <div css={selectedAudienceIconList}>
          {selectedAudiences.map((audience, index) => (
            <span
              css={addedAudienceIconStyle}
              key={`${audience.id}${index}`}
              onClick={() => {
                setAddAudienceIconClicked(prev => !prev)
              }}
            >
              {getSourceIconBox(audience.source)}
            </span>
          ))}
        </div>
      )
    }
  }

  const showLeftExtensionButton = showExtensionMinimizeButtons => {
    const leftMostSegmentId = mailData.createdFromSegments[0]
    if (
      showExtensionMinimizeButtons &&
      segmentLinkedMapper[leftMostSegmentId] &&
      segmentLinkedMapper[leftMostSegmentId].prev &&
      (!mailCampaignData[segmentLinkedMapper[leftMostSegmentId].prev][
        mailIndex
      ] ||
        !mailCampaignData[segmentLinkedMapper[leftMostSegmentId].prev][
          mailIndex
        ].id)
    )
      return true
    return false
  }

  const showRightExtensionButton = showExtensionMinimizeButtons => {
    const rightMostSegmentId =
      mailData.createdFromSegments[mailData.createdFromSegments.length - 1]
    if (
      showExtensionMinimizeButtons &&
      segmentLinkedMapper[rightMostSegmentId] &&
      segmentLinkedMapper[rightMostSegmentId].next &&
      (!mailCampaignData[segmentLinkedMapper[rightMostSegmentId].next][
        mailIndex
      ] ||
        !mailCampaignData[segmentLinkedMapper[rightMostSegmentId].next][
          mailIndex
        ].id)
    )
      return true
    return false
  }

  const getSelectedAudiences = audienceData => {
    return audienceData.filter(audience => audience.isSelected)
  }

  const handleCopyAction = action => {
    if (action === 'copy') {
      setCopyIconClicked(true)
      setCopiedMailData(mailData)
    }
    if (action === 'close') {
      setCopyIconClicked(false)
      setCopiedMailData(null)
    }
    if (expandedView) {
      setExpandedView(prev => !prev)
    }
  }

  const { id: mailId } = mailData || {}
  const isSelected =
    mailId == currentEditingMailId && segmentId == currentEditingSegmentId

  const checkEmailDetailsFilled = () => {
    return (
      mailData['emailName'] || mailData['emailType'] || mailData['subjectLine']
    )
  }

  return (
    <div style={{ flex: 1, flexShrink: 0 }}>
      <div
        className={isSelected && 'active'}
        css={cardContainerStyle(
          expandedView,
          audienceIconClicked,
          rightMinimizeHoverFlag,
          leftMinimizeHoverFlag,
          mailData.createdFromSegments.length,
          inEditMode,
          isMailDataValid
        )}
        onClick={event => {
          setMailEditable(prev => !prev)
          event.stopPropagation()
        }}
        data-selected={selected}
        onMouseEnter={() => {
          !copyIconClicked && setShowExtensionMinimizeButtons(true)
        }}
        onMouseLeave={() => {
          setShowExtensionMinimizeButtons(false)
        }}
      >
        <div
          css={copyActionIconContainer(copyIconClicked)}
          onClick={() => handleCopyAction('close')}
        >
          <img
            src={copySelectedIcon}
            alt='copy-selected-icon'
            width={50}
            height={45}
          />
        </div>
        <span className='toggleHeight' onClick={toggleHeightHandler}>
          <img
            alt='expandIcon'
            src={expandedView ? minusIcon : plusIcon}
            height={24}
            width={24}
          />
        </span>
        {showExtensionMinimizeButtons &&
          mailData.createdFromSegments.length > 1 && (
            <div css={minimizeFromLeft(leftMinimizeHoverFlag)}>
              <Icon className='plusIcon'>remove</Icon>
            </div>
          )}
        {checkEmailDetailsFilled() && templateString ? (
          <div css={previewStyle}>
            <div
              style={{ transform: 'scale(0.1, 0.05' }}
              dangerouslySetInnerHTML={{ __html: templateString }}
            />
          </div>
        ) : (
          <div css={mailIcon}>{getMailIcon()}</div>
        )}

        <div
          style={{
            flex: 1,
            position: 'relative',
            minHeight: '45px',
            textAlign: 'center'
          }}
        >
          <div>
            {emailName && <span css={emailNameStyle}>{emailName}</span>}
            {emailType && <span css={emailTypeStyle}>{emailType}</span>}
          </div>
          {getThumbnailBoxesForSelectedAudiences(
            getSelectedAudiences(mailData.audienceData)
          )}
        </div>
        {showExtensionMinimizeButtons &&
          mailData.createdFromSegments.length > 1 && (
            <div css={minimizeFromRight(rightMinimizeHoverFlag)}>
              <Icon className='plusIcon'>remove</Icon>
            </div>
          )}

        <>
          {showLeftExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(mailData.createdFromSegments.length)}
              className='leftExtension'
              onMouseEnter={() => {
                setLeftExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setLeftExtensionHoverFlag(false)
              }}
              onClick={event => {
                event.stopPropagation()
                handleMailExtensionMinimize(
                  mailCampaignData,
                  segmentId,
                  mailIndex,
                  'leftExtension'
                )
              }}
            >
              <label>{'<'}</label>
            </div>
          )}

          {showExtensionMinimizeButtons &&
            mailData.createdFromSegments.length > 1 && (
              <div
                css={extensionButton(mailData.createdFromSegments.length)}
                className='leftMinimize'
                onMouseEnter={() => {
                  setLeftMinimizeHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setLeftMinimizeHoverFlag(false)
                }}
                onClick={event => {
                  event.stopPropagation()
                  handleMailExtensionMinimize(
                    mailCampaignData,
                    segmentId,
                    mailIndex,
                    'leftMinimize'
                  )
                }}
              >
                <label>{'>'}</label>
              </div>
            )}
          <div
            css={leftExtensionContainer(mailData.createdFromSegments.length)}
          >
            <div
              css={leftExtensionCard(
                leftExtensionHoverFlag,
                mailData.createdFromSegments.length
              )}
            >
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
        </>
        <>
          {showExtensionMinimizeButtons &&
            mailData.createdFromSegments.length > 1 && (
              <div
                css={extensionButton(mailData.createdFromSegments.length)}
                className='rightMinimize'
                onMouseEnter={() => {
                  setRightMinimizeHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setRightMinimizeHoverFlag(false)
                }}
                onClick={event => {
                  event.stopPropagation()
                  handleMailExtensionMinimize(
                    mailCampaignData,
                    segmentId,
                    mailIndex,
                    'rightMinimize'
                  )
                }}
              >
                <label>{'<'}</label>
              </div>
            )}
          {showRightExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(mailData.createdFromSegments.length)}
              className='rightExtension'
              onMouseEnter={() => {
                setRightExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setRightExtensionHoverFlag(false)
              }}
              onClick={event => {
                event.stopPropagation()
                handleMailExtensionMinimize(
                  mailCampaignData,
                  segmentId,
                  mailIndex,
                  'rightExtension'
                )
              }}
            >
              <label>{'>'}</label>
            </div>
          )}
          <div
            css={rightExtensionContainer(mailData.createdFromSegments.length)}
          >
            <div
              css={rightExtensionCard(
                rightExtensionHoverFlag,
                mailData.createdFromSegments.length
              )}
            >
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
        </>
      </div>

      <div
        css={audienceListContainer(
          mailData.createdFromSegments.length,
          audienceIconClicked,
          mailData.audienceData.length
        )}
      >
        <div
          css={audienceListSection(
            audienceIconClicked,
            mailData.audienceData.length
          )}
        >
          {audienceList.map((audience, index) => {
            return (
              <AudienceCard
                key={`${audience.id}${index}`}
                index={index}
                data={audience}
                onSelection={onAudienceCardSelection}
              />
            )
          })}
        </div>
        <div css={audienceListActionButtonContainer}>
          <button
            className='saveButton'
            css={audienceListActionButton}
            onClick={saveAudienceSelectionHandler}
          >
            Save
          </button>
          <button
            css={audienceListActionButton}
            onClick={cancelAudienceSelectionHandler}
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        css={mailContentContainer(
          mailData.createdFromSegments.length,
          expandedView
        )}
      >
        <div css={mailActionButton(isMailEditable)}>
          <span>
            <img
              src={copyUnselectedIcon}
              alt='copy-icon'
              width={19}
              height={21}
              onClick={() => handleCopyAction('copy')}
            />
          </span>
          <span onClick={() => handleMailDelete(segmentId, mailIndex)}>
            <img src={trashIcon} alt='trash-icon' />
          </span>
        </div>
        <div css={emailDetails}>
          <div className='row'>
            <div className='label'>
              <span>
                Email Send Date<sup>*</sup>
              </span>
              <span>
                Required<sup>*</sup>
              </span>
            </div>
            <div className='value'>4/3/21</div>
          </div>
          <div className='row'>
            <div className='label'>
              <span>
                Email Send Time<sup>*</sup>
              </span>
            </div>
            <div className='value'>11:00 AM PST</div>
          </div>
          <div className='row'>
            <div className='label'>
              <span>
                Subject Line<sup>*</sup>
              </span>
            </div>
            <div className='value'>{mailData.subjectLine}</div>
          </div>
          <div className='row'>
            <div className='label'>
              <span>Sender</span>
            </div>
            <div className='value'>{mailData.sender}</div>
          </div>
          <div className='row'>
            <div className='label'>
              <span>Backup Sender</span>
            </div>
            <div className='value'>{mailData.backupSender}</div>
          </div>
          <div className='row'>
            <div className='label'>
              <span>Additional Audience Filters</span>
            </div>
            <div className='value'>
              <div className='matchType'>Match Type: Match All</div>
              {(mailData.additionalAudienceFilter || []).map(
                ({ prospectTag, condition, value }, index) => {
                  return (
                    <div key={index}>
                      <div className='inputBox'>{prospectTag}</div>
                      <div className='inputBox'>{condition}</div>
                      <div className='inputBox'>{value}</div>
                      {index !==
                        mailData.additionalAudienceFilter.length - 1 && (
                        <div className='and'>and</div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailerCard
