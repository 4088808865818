/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Topfold from './TopFold/TopFold'
import SegmentHeader from './SegmentHeader/SegmentHeader'
import Segments from './Segments/Segments'
import MailerEditor from './MailerEditor'
import Alert from './Alert/index'
import Waypoint from '../ui/Waypoint/Waypoint'
import Audience from './Audience/Audience'
import CreateAdsCompaign from './CreateAdsCompaign'
import Ads from './Ads'
import MailModule from './MailModule'
import Footer from './Footer'

import { authActions } from '../../store/authSlice'
import {
  updateTemplateTree,
  updateEmails,
  updateEmailSettingsHandler,
  updateCurrentEditedSegmentAndAudience,
  updateCurrentEditingComponent,
  updateEdtingComponents,
  updateEmailTemplate,
  updateCampaignName
} from '../../store/dashboard/mailerSlice'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { dashboardContainer } from './styles'
import { processEmails, getAllAudiences } from '../../utils/mailerUtils'
import axiosInstance from '../../axiosConfig'

const Dashboard = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const search = useLocation().search
  const { closeCampaign } = useSelector(({ mailer: { closeCampaign } }) => ({
    closeCampaign
  }))

  const [campaignCreationType, setCampaignCreationType] = useState(null)
  const [stickyHeader, setStickyHeader] = useState(false)
  const [editSegment, setEditSegment] = useState(false)
  const [addAudienceFlag, setAddAudienceFlag] = useState(false)
  const [removeAudienceModule, setRemoveAudienceModule] = useState(false)
  const [campaignName, setCampaignName] = useState(null)
  const [editingCampaingData, setEditingCampaignData] = useState(null)
  const [editingMailCampaignId, setEditingMailCampaignId] = useState(null)
  const [editingAdsCampaignData, setEditingAdsCampaignData] = useState(null)

  const { segmentAudienceData, audienceDataFromApi } = useSelector(
    ({ audiences: { segmentAudienceData, audienceDataFromApi } = {} }) => ({
      segmentAudienceData,
      audienceDataFromApi
    })
  )

  const { id: productId } = JSON.parse(
    sessionStorage.getItem('productInfo')
  ) || { id: 2 }

  const logOutHandler = () => {
    dispatch(authActions.logout())
  }

  useEffect(() => {
    if (editingCampaingData) {
      document.body.style.overflow = 'unset'
    }
  }, [editingCampaingData])

  useEffect(() => {
    if (campaignCreationType) {
      switch (campaignCreationType) {
        case 'mail':
          setRemoveAudienceModule(true)
          setCampaignCreationType(campaignCreationType)
          break
        case 'ads':
          setRemoveAudienceModule(true)
          setCampaignCreationType(campaignCreationType)
          break
        default:
          setCampaignCreationType(null)
          setRemoveAudienceModule(false)
      }
    } else {
      setRemoveAudienceModule(false)
    }
  }, [campaignCreationType])

  const resetMailCampaignStore = () => {
    setCampaignCreationType(null)
    setRemoveAudienceModule(false)
    setEditingCampaignData(null)
    setCampaignName(null)
    setEditingMailCampaignId(null)
    dispatch(updateTemplateTree({}))
    dispatch(updateEmails({}))
    dispatch(updateEmailSettingsHandler({}))
    dispatch(updateCurrentEditedSegmentAndAudience({}))
    dispatch(updateCurrentEditingComponent({}))
    dispatch(updateEdtingComponents({}))
    dispatch(updateEmailTemplate({}))
  }

  useEffect(() => {
    if (closeCampaign) {
      resetMailCampaignStore()
    }
  }, [closeCampaign])

  const headerAudienceBtnClick = () => {
    setAddAudienceFlag(prev => !prev)
  }

  const editMailCampaign = campaign => {
    const { campaignName, emails, id } = campaign || {}

    setCampaignName(campaignName)
    setCampaignCreationType('mail')
    const audienceCollection = getAllAudiences(
      audienceDataFromApi,
      segmentAudienceData
    )
    let campaignData = processEmails(
      emails,
      segmentAudienceData,
      audienceCollection
    )
    setEditingMailCampaignId(id)
    setEditingCampaignData(campaignData)
  }

  const editAdsCampaign = (adCampaignData, campaignData) => {
    setEditingAdsCampaignData({ adCampaignData, campaignData })
    setCampaignCreationType('ads')
  }

  useEffect(() => {
    dispatch(updateCampaignName(campaignName))
  }, [campaignName])

  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(search)
      const code = urlParams.get('code')
      const state = urlParams.get('state')

      if (!code) {
        console.error('Authorization code not found')
        return
      }
      try {
        handleBackendConnect(
          {
            google_ad: {
              token: code
            }
          },
          state
        )
      } catch (err) {
        console.error('Error during token request', err)
      }
    }

    const handleBackendConnect = async (data, state) => {
      try {
        const { status: beCallStatus } = await axiosInstance.post(
          '/google_ads/connect',
          {
            ...data
          }
        )
        console.log(state)

        if (beCallStatus === 200 && state === 'connectors') {
          navigate('/cdp/connectors')
        } else {
          console.error('Failed to connect to backend')
        }
      } catch (err) {
        console.error('Error during backend call', err)
      }
    }

    fetchToken()
  }, [search, navigate])

  return (
    <>
      <div css={dashboardContainer}>
        <Waypoint
          fireOnceOnEnter={false}
          onLeave={() => setStickyHeader(true)}
          onEnter={() => setStickyHeader(false)}
        >
          <Topfold logOutHandler={logOutHandler} />
        </Waypoint>
        <Segments
          productId={productId}
          stickyHeader={stickyHeader}
          editSegments={editSegment}
          adsClicked={campaignCreationType === 'ads'}
          onSegmentClick={() => setEditSegment(!editSegment)}
        />
        <SegmentHeader
          stickyHeader={stickyHeader}
          adsClicked={campaignCreationType === 'ads'}
          mailClicked={campaignCreationType === 'mail'}
          campaignCreationType={campaignCreationType}
          onSegmentClick={() => setEditSegment(!editSegment)}
          headerAudienceBtnClick={headerAudienceBtnClick}
          setCampaignCreationType={setCampaignCreationType}
          editingMailCampaignData={editingCampaingData}
          editingMailCampaignId={editingMailCampaignId}
          emailCampaignName={campaignName}
        />
        {removeAudienceModule ? null : (
          <Audience
            productId={productId}
            addAudienceFlag={addAudienceFlag}
            addAudienceFormHandler={headerAudienceBtnClick}
          />
        )}
        {campaignCreationType === 'mail' && (
          <MailerEditor
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            editingCampaignData={editingCampaingData}
          />
        )}
        {campaignCreationType === 'ads' && (
          <CreateAdsCompaign
            productId={productId}
            adsClicked={campaignCreationType === 'ads'}
            editingAdsCampaignData={editingAdsCampaignData}
            setCampaignCreationType={setCampaignCreationType}
            setEditingAdsCampaignData={setEditingAdsCampaignData}
          />
        )}
        {!editingMailCampaignId && (
          <Ads productId={productId} editCampaign={editAdsCampaign} />
        )}
        {!editingMailCampaignId && (
          <MailModule editCampaign={editMailCampaign} />
        )}
        {!editingMailCampaignId && <Alert />}
      </div>
      <Footer />
    </>
  )
}

export default Dashboard
