/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import Dropdown from '../../ui/Dropdown/Dropdown'
import Icon from '@material-ui/core/Icon'
import ContactConditionForm from './NewCondition/NewContactsCondition'
import {
  matchTypeStyle,
  ruleGroupWrapper,
  conditionWrapper,
  extraRuleStyle,
  extraRuleContainer,
  ruleGroupContainer,
  conditionRowStyle,
  optionalStyle,
  ruleGroupsStyle,
  deleteGroupStyle,
  userMatchingContainerStyle,
  userMatchingDropdownStyle
} from './style'

const ViewContact = ({ data, updateData = () => {}, id }) => {
  const { matchType, ruleGroups } = data
  const [formValue, setFormValue] = useState({})
  useEffect(() => {
    setFormValue({
      matchType,
      ruleGroups
    })
  }, [matchType, ruleGroups])

  const handleOnChange = (key, value) => {
    setFormValue(prev => {
      return { ...JSON.parse(JSON.stringify(prev)), [key]: value }
    })
    updateData(id, key, value)
  }

  const updateRuleGroupData = (ruleGroupIndex, key, value) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    const newRuleGroupData = { ...ruleGroupData, [key]: value }
    formValue.ruleGroups.splice(ruleGroupIndex, 1, newRuleGroupData)
    handleOnChange('ruleGroups', formValue.ruleGroups)
  }

  const updateConditionData = ({ ruleGroupIndex, ruleIndex, key, value }) => {
    const ruleGroupData = JSON.parse(JSON.stringify(ruleGroups[ruleGroupIndex]))
    const rule = JSON.parse(
      JSON.stringify((ruleGroupData.rules || [])[ruleIndex])
    )
    const newRule = {
      ...rule,
      [key]: value,
      ...(key === 'prospectTag'
        ? { value: undefined, condition: undefined }
        : {})
    }
    ruleGroupData.rules.splice(ruleIndex, 1, newRule)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const addCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    if (!ruleIndex && ruleIndex !== 0) {
      ruleGroupData.rules.push({
        prospectTag: '',
        condition: '',
        value: ''
      })
    } else {
      ruleGroupData.rules.splice(ruleIndex, 0, {
        prospectTag: '',
        condition: '',
        value: ''
      })
    }
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const addRuleGroup = () => {
    formValue.ruleGroups.push({
      matchType: 'All',
      rules: [
        {
          prospectTag: '',
          condition: '',
          value: ''
        }
      ]
    })
    handleOnChange('ruleGroups', formValue.ruleGroups)
  }

  const deleteCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    ruleGroupData.rules.splice(ruleIndex, 1)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const onDeletingRuleGroup = ruleGroupIndex => {
    const ruleGroupData = JSON.parse(JSON.stringify(formValue.ruleGroups))
    ruleGroupData.splice(ruleGroupIndex, 1)
    handleOnChange('ruleGroups', ruleGroupData)
  }
  return (
    <>
      <div css={ruleGroupWrapper}>
        {(formValue.ruleGroups || []).map(({ rules, matchType }, index) => {
          const ruleGroupIndex = index
          return (
            <div key={ruleGroupIndex} css={ruleGroupsStyle}>
              {ruleGroupIndex > 0 && <div css={optionalStyle}>And</div>}
              <div css={ruleGroupContainer(ruleGroupIndex)}>
                <div css={matchTypeStyle}>
                  <div css={userMatchingContainerStyle}>
                    <span
                      style={{ display: 'inline-block', paddingTop: '2px' }}
                    >
                      User Matching
                    </span>
                    <Dropdown
                      options={['All', 'Any']}
                      style={userMatchingDropdownStyle}
                      value={matchType}
                      onOptionSelected={value =>
                        updateRuleGroupData(ruleGroupIndex, 'matchType', value)
                      }
                    />
                    <span
                      style={{ display: 'inline-block', paddingTop: '2px' }}
                    >
                      {' '}
                      of the following:
                    </span>
                  </div>
                  {ruleGroupIndex > 0 && (
                    <div
                      css={deleteGroupStyle}
                      onClick={() => onDeletingRuleGroup(ruleGroupIndex)}
                    >
                      Delete Group
                    </div>
                  )}
                </div>
                <div css={conditionWrapper}>
                  {(rules || []).map((conditionData, index) => {
                    const ruleIndex = index
                    return (
                      <div key={index} css={conditionRowStyle(ruleIndex)}>
                        {index > 0 && (
                          <div css={optionalStyle}>
                            {matchType === 'All' ? 'And' : 'or'}
                          </div>
                        )}
                        <ContactConditionForm
                          data={conditionData}
                          ruleIndex={ruleIndex}
                          ruleGroupIndex={ruleGroupIndex}
                          updateConditionsData={updateConditionData}
                          deleteCondition={(ruleGroupIndex, ruleIndex) =>
                            deleteCondition(ruleGroupIndex, ruleIndex)
                          }
                        />
                      </div>
                    )
                  })}
                  {ruleGroupIndex > 0 && (
                    <div css={extraRuleContainer(ruleGroupIndex)}>
                      <div
                        css={extraRuleStyle}
                        onClick={() => addCondition(ruleGroupIndex)}
                      >
                        <Icon>add</Icon>
                        New Criteria
                      </div>
                      <div css={extraRuleStyle} onClick={() => addRuleGroup()}>
                        <Icon>add</Icon>
                        New Criteria group
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div css={extraRuleContainer(0)}>
        <div css={extraRuleStyle} onClick={() => addCondition(0)}>
          <Icon>add</Icon>
          new Criteria
        </div>
        <div css={extraRuleStyle} onClick={() => addRuleGroup()}>
          <Icon>add</Icon>
          New Criteria group
        </div>
      </div>
    </>
  )
}

export default ViewContact
