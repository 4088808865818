import { css } from '@emotion/react'

export const emptySlotSection = css`
  display: flex;
`

export const emptySlotContent = sectionTopTo => css`
  pointer-events:none;
  ${sectionTopTo === 'welcome' && 'height: 206px;'}
  ${sectionTopTo === 'crm' && 'height: 148px;'}
  ${sectionTopTo === 'socialAndAnalytics' && 'height: 103px;'}
  ${sectionTopTo === 'businessType' && 'height: 103px;'}
  ${sectionTopTo === 'segment' && 'height: 103px;'}
  ${sectionTopTo === 'takeToDashboard' && 'height: 120px;'}
`
