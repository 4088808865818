import { css } from '@emotion/react/macro'

export const campaignBuilderContainer = css`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`

const toggleMenu = () => css`
  width: 282px;
  flex: 0 0 282px;
  padding: 8px 8px 19px 8px;
  border: 0.8px solid #cccccc;
  margin-right: 12px;
`

export const builderSideBarStyle = menuView => css`
  width: 100%;
  overflow: hidden;
  display: flex;
  border: 0.8px solid #ffffff;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px #0099ff;
  padding: 18px;
  .column {
    flex: 1;
  }
`

export const boxStyle = css`
  width: 95%;
  &:not(:first-of-type) {
    margin-top: 14px;
  }
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  flex: 1;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  padding-left: 5px;
  border-radius: 4px;
  &.not-valid {
    border: 1px solid #ee5050;
  }
  &[data-isInvalid='true'] {
    border: 1px solid red !important;
  }
  &[data-isinvalid='true'] {
    border: 1px solid red !important;
  }
`

export const inputWithErrorStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  .error-message {
    color: #ee5050;
    margin: 0;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background: #fff;
    &.show {
      bottom: -20px;
      opacity: 1;
      transition: all 0.1s ease;
    }
  }
`

export const emailTypeStyle = css`
  box-sizing: border-box;
  width: 80%;
  height: 34px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  font-size: 12px;
  color: #333333;
  font-family: BentonSans;
  cursor: pointer;
  &:first-of-type {
    margin-right: 10px;
  }
  &[data-selected='true'] {
    background: #ccc;
  }
`

export const builderContentStyle = menuView => css`
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  width: 100%;
  border-radius: 4px;
  padding: 23px;
  box-shadow: 0 2px 6px -2px rgba(51, 153, 255, 0.4);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 722px;
`

export const builderHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const labelStyle = css`
  font-size: 0.875em;
  color: #333333;
  font-family: BentonSans;
  font-weight: 600;
  min-width: 97px;
  display: inline-block;
  margin-bottom: 6px;
`

export const headerlabelStyle = css`
  margin-top: 7px;
  text-align: left;
`

export const senderContainerStyle = css`
  display: flex;
  flex-direction: column;
`

export const testEmailContainer = css`
  display: flex;
  width: 100%;
  padding-top: 4px;
  flex-direction: column;
  button {
    height: 34px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 7px;
    font-size: 12px;
    color: #333333;
    font-family: BentonSans;
  }
`

export const senderStyle = css`
  display: flex;
  flex-direction: column;
`

export const backupSenderStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
`

export const dropdownStyle = css`
  height: 34px;
  width: 156px;
  margin-right: 10px;
  flex-shrink: 0;
`

export const emailTypeContianerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .emailTypeWrapper {
    &[data-isInvalid='true'] {
      border: 1px solid red;
    }
  }
`

export const triggeredBoxStyle = showRecurringCriteriaForm => css`
  display: block;
  width: 305px;
  flex-grow: 1;
  flex-shrink: 0;
  background: #fff;

  border-radius: 0 0 4px 4px;

  border-top: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: none;
  .add-trigger-rule {
    font-size: 20px;
    color: #333;
    font-weight: 600;
    margin-top: 15px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  ${showRecurringCriteriaForm &&
    `
  border: 0.8px solid #ccc;
  padding: 20px 15px;
  overflow: unset;
  height: auto;
  z-index: 6;
  `}
`

export const emailTypeBoxStyle = triggered => css`
  display: block;
  width: 100%;
  margin-right: 21px;
`

export const triggerTitleContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  .recurring-text {
    color: #333;
    font-size: 0.8125em;
    font-weight: 300;
    margin-right: 5px;
  }
`

export const collapseButton = css`
  margin-bottom: 2px;
  text-align: end;
  img {
    cursor: pointer;
  }
`

export const audienceBox = css``

export const addIcon = css`
  cursor: pointer;
  font-size: 28px;
  font-weight: 600;
`

export const additionAudienceDropDownPopupStyle = showAdditionalDropdown => css`
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  background: #fff;
  border: 0;
  height: 0;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  z-index: 9;
  ${showAdditionalDropdown &&
    `
  border: 0.8px solid #ccc;
  padding: 20px 15px;
  overflow: unset;
  height: auto;
  `}
`

export const recurringDropdownStyle = showRecurringForm => css`
  border: 0.8px solid #ccc;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  cursor: pointer;
  ${showRecurringForm && 'border-bottom: 0; border-radius: 4px 4px 0 0;'}
  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-left: 20px;
    &.dropdown-caret {
      transform: rotate(180deg);
      &.rotate-up {
        transform: translateY(5px) rotate(0deg);
      }
    }
  }
  &[data-isInvalid='true'] {
    border: 1px solid red !important;
  }
  &[data-isinvalid='true'] {
    border: 1px solid red !important;
  }
`

export const sendDateStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 13px;
  input[type='date'] {
    padding: 8px;
    font-family: BentonSans;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    height: 34px;
    width: 100%;
    border: 0.8px solid #cccccc;
    background-color: #fff;
    outline: none;
    border-radius: 4px;
    margin-top: 2px;
    font-family: BentonSans;
    cursor: pointer;
    type {
      cursor: pointer;
    }
  }
`

export const dropdownContainer = isInvalid => css`
  width: 270px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: #fff;
  height: 34px;
  border: 0.8px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
  ${isInvalid && 'border: 1px solid red;'}
`

export const dropdownMenuStyle = isOpen => css`
  display: block;
  position: absolute;
  width: 152px;
  flex-grow: 1;
  flex-shrink: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: none;
  transform: translateY(3px);
  z-index: 10;
  ${isOpen &&
    `
  border: 0.8px solid #ccc;
  overflow: unset;
  height: auto;
  `}
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    li {
      padding: 10px;
      font-size: 12px;
      &:hover {
        background: #f2f3f5;
      }
    }
  }
`

export const displayValueStyle = css`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 12px;
  span {
    flex: 1;
    text-align: left;
    display: inline-block;
    padding-left: 10px;
  }

  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    transform: rotate(180deg);
    display: inline-block;
    margin-right: 8px;
  }
`
