/** @jsxImportSource @emotion/react */
import React from 'react'
import WithMockCellEditor from '../../../../HOC/WithMockCellEditor'
import { MockEditingContext } from '../../../../contexts/mockEditingContext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ListSubheader,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem
} from '@mui/material'

const dropdownOptions = [
  'accounts.Id',
  'accounts.Name',
  'accounts.AccountNumber',
  'accounts.AnnualRevenue',
  'accounts.Phone',
  'accounts.Industry',
  'accounts.Website',
  'contacts.Id',
  'contacts.AccountId',
  'contacts.FirstName',
  'contacts.LastName',
  'contacts.Name',
  'contacts.Birthdate',
  'contacts.Email',
  'contacts.HomePhone',
  'contacts.Phone,Fax',
  'contacts.MobilePhone'
]

class QueueRendere extends React.Component {
  state
  dropdownRef
  static contextType = MockEditingContext

  constructor (props) {
    super(props)
    this.state = {
      value: false,
      options: [],
      searchText: ''
    }
    this.dropdownRef = React.createRef()
  }

  componentDidMount () {
    this.setState({
      value: this.props.getValue(),
      options: [...dropdownOptions]
    })
  }

  getValue () {
    const field = this.props.column.getColId()
    return [field, this.state.value]
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  onSearchInput = event => {
    const { target: { value } = {} } = event
    this.setState({ searchText: value })
    if (!value) {
      this.setState({ options: [...dropdownOptions] })
      return
    }
    const menuItems = dropdownOptions.filter(option => option.includes(value))
    this.setState({ options: [...menuItems] })
  }

  render () {
    return (
      <>
        {this.props.isMockEditing ? (
          <Box
            sx={{ minWidth: '100%', height: '38px' }}
            className='customSelect'
          >
            <FormControl fullWidth>
              <Select
                displayEmpty
                IconComponent={ExpandMoreIcon}
                labelId='demo-simple-select-label'
                id='custom-dropdown'
                value={this.state.value}
                MenuProps={{
                  autoFocus: false,
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: '0.875rem',
                        fontFamily:
                          "'Roboto', 'Helvetica', 'Arial', sans-serif",
                        fontWeight: '300'
                      }
                    }
                  }
                }}
                onClose={() =>
                  this.setState({
                    searchText: '',
                    options: [...dropdownOptions]
                  })
                }
                renderValue={selected => {
                  if (!selected) {
                    return <em>Placeholder</em>
                  }

                  return selected
                }}
                onChange={this.handleChange}
              >
                <ListSubheader onClick={e => e.preventDefault()}>
                  <TextField
                    size='small'
                    // Autofocus on textfield
                    autoFocus
                    placeholder='Type to search...'
                    inputProps={{
                      style: {
                        fontSize: '0.875rem',
                        fontFamily:
                          "'Roboto', 'Helvetica', 'Arial', sans-serif",
                        fontWeight: '300'
                      }
                    }}
                    onClick={e => e.stopPropagation()}
                    onChange={this.onSearchInput}
                    onKeyDown={e => {
                      if (e.key !== 'Escape') {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation()
                      }
                    }}
                  />
                </ListSubheader>
                {this.state.options.map((el, index) => {
                  return (
                    <MenuItem value={el} key={index}>
                      {el}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        ) : this.state.value ? (
          <div>{this.state.value}</div>
        ) : null}
      </>
    )
  }
}

export default WithMockCellEditor(QueueRendere)
