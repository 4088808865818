import { css, keyframes } from '@emotion/react'

const bouncedelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

export const loaderWrapperStyle = css`
  width: 70px;
  text-align: center;
  &.spinner > div {
    width: 13px;
    height: 13px;
    background-color: #17a3e1;
    border-radius: 100%;
    display: inline-block;

    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  }

  &.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`
