import React, { useRef, useState, useEffect } from 'react'
import { createObserver, observeElement } from '../utils/intersectionObserver'
import debounce from '../utils/debounce'

/**
 * deps is only required when rootRef's current value may change based on some condition
 * an alternative of useOnScreen to observe one element only
 * @param {{onChange: (entry: IntersectionObserverEntry) => any, keepAlive?: boolean, delay?: number} & IntersectionObserverInit} options
 * @param {any[]} deps
 */
export default function useOnScreenCb (
  { onChange, delay, keepAlive = false, rootRef, ...options },
  deps = []
) {
  const memoRef = useRef({ onChange, observer: null })
  const [ref] = useState(() => {
    const ref = React.createRef()
    const memo = memoRef.current
    // unsubscribe to previous observer and subscribe using new observer
    memo.observe = () => {
      if (memo.unobserve) {
        memo.unobserve()
        memo.unobserve = null
      }
      if (ref.current && memo.observer) {
        let handler = entry => {
          if (entry.isIntersecting && !keepAlive) {
            memo.unobserve()
          }
          memo.onChange(entry)
        }
        handler = delay ? debounce(handler, delay) : handler
        const unobserve = observeElement(memo.observer, {
          target: ref.current,
          handleChange: handler
        })
        memo.unobserve = () => {
          handler.clear && handler.clear()
          unobserve()
        }
      }
    }
    return new Proxy(ref, {
      set (target, key, value) {
        if (key !== 'current') {
          target[key] = value
          return true
        }
        if (target[key] === value) return true
        target[key] = value
        memo.observe()
        return true
      }
    })
  })

  useEffect(() => {
    const memo = memoRef.current
    const observer = createObserver({
      root: rootRef && rootRef.current,
      ...options
    })
    memo.observer = observer
    // start observing using new observer
    memo.observe()
  }, [options.rootMargin, options.threshold, rootRef, ...deps])

  useEffect(() => {
    // update handler without re-rendering
    memoRef.current.onChange = onChange
  }, [onChange])

  return [ref]
}
