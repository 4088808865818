import { css } from '@emotion/react/macro'

export const grdStyle = () => css`
  &.ag-theme-alpine {
    font-family: BentonSans;
    .ag-header-group-cell {
      padding: 0;
    }
    .ag-header-group-cell-label {
      justify-content: center;
      align-items: center;
    }
    .ag-header {
      border: none;
      background-color: #fff;
      .ag-header-cell-text {
        font-size: 12px;
        color: #333;
        font-weight: 600;
      }
    }
    .ag-root-wrapper,
    .ag-header-row,
    .ag-header-row-column,
    .ag-header-cell {
      border: 0 !important;
    }
    .ag-pinned-left-header {
      border: 0;
    }
    .ag-pinned-left-cols-container {
      border: 0;
    }
    .ag-row {
      border: 0;
    }
    .ag-cell-focus {
      border: none !important;
    }
    .ag-cell {
      display: flex;
      align-items: center;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ag-center-cols-container,
    .ag-header-container {
      width: unset !important;
      display: flex;
    }
  }
`
