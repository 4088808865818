import {createTheme} from '@material-ui/core/styles';

// import BentonSansWoff from '../../assets/fonts/bentonsans_medium-webfont.woff';

// const bentonSans = {
//  fontFamily: 'BentonSans',
//  fontStyle: 'normal',
//  fontDisplay: 'swap',
//  src: `
//    local('BentonSans'),
//    local('BentonSans-Regular'),
//    url(${BentonSansWoff}) format('woff')
//  `,
//  unicodeRange:
//    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };


const white = '#ffffff';
const offWhite = '#e1e1e1';
const blue = '#2196f3';

const theme = createTheme({
    palette: {
        common: {
            white,
            offWhite
        },
        primary: {
            main: `${blue}`
        },
        secondary: {
            main: `${white}`
        }
    },
    // typography: {
    //     fontFamily: 'BentonSans',
    // },
    // overrides: {
    //     MuiCssBaseline: {
    //         '@global': {
    //             '@font-face': [bentonSans],
    //         },
    //     }
    // }
});

export default theme;