const checkForRequiredFields = (ad, requiredFields, campaignDetails) => {
  let validityObject = {}
  Object.keys(ad || {}).forEach(key => {
    if (requiredFields.includes(key)) {
      validityObject[key] = true
      if (key === 'audienceData') {
        const audienceSelected = ad[key].some(({ isSelected }) => isSelected)
        validityObject[key] = audienceSelected
      } else if (
        key == 'text' ||
        key == 'headline' ||
        key == 'newsFeedLinkDescription'
      ) {
        validityObject[key] = !!campaignDetails[key] || !!ad[key]
      } else {
        validityObject[key] = !!ad[key]
      }
    }
  })
  return validityObject
}

export const checkForValidAdCampaign = (adsData, campaignDetails) => {
  const segmentIds = Object.keys(adsData) || []
  const requiredFields = [
    'source',
    'url',
    'text',
    'headline',
    'newsFeedLinkDescription',
    'audienceData'
  ]
  let validationObject = {}
  segmentIds.forEach(segment => (validationObject[segment] = {}))
  segmentIds.forEach(segment => {
    const segmentAds = adsData[segment] || []
    segmentAds.forEach(ad => {
      if (ad && Object.values(ad).length) {
        const { adName } = ad || {}
        validationObject[segment][adName] = checkForRequiredFields(
          ad,
          requiredFields,
          campaignDetails
        )
      }
    })
  })
  return validationObject
}

export const convertToTitleCase = text => {
  return text.replace(/([A-Z])/g, ' $1')
}
