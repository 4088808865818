import { validate as uuidValidate } from 'uuid'

export const mapDataFromDataSourceForSegmentAudiences = (
  segmentsData,
  audienceData
) => {
  let segmantAudiences = {}
  segmentsData.forEach(segment => {
    segmantAudiences[segment.id] = { id: segment.id, status: segment.status }
  })
  audienceData
    .filter(audience => {
      if (audience.audience_type === 'segment' && audience.status === 'active')
        return true
      return false
    })
    .forEach(audience => {
      let segmentAudience = {
        id: audience.id,
        source: 'neyo',
        audienceName: audience.audience_name,
        status: audience.status,
        audienceType: 'Segment',
        isEditable: false,
        ruleGroups: [],
        matchedRecordsTotal: audience.matched_records_total,
        matchedRecordIds: audience.matched_record_ids
      }
      let rule = {}
      if (audience.match_type === 'and') rule.matchType = 'Match All'
      if (audience.match_type === 'or') rule.matchType = 'Match Any'
      rule.rules = audience.audience_queries.map(query => {
        return {
          id: query.id,
          prospectTag: query.column_name,
          condition: query.condition,
          value: query.value_1
        }
      })
      segmentAudience.ruleGroups.push(rule)
      segmentAudience.createdFromSegments = [audience.segments[0].id]
      segmantAudiences[audience.segments[0].id] = segmentAudience
    })
  return segmantAudiences
}

export const mapDataFromDataSourceForAudienceData = (segments, audiences) => {
  const response = {}
  segments.forEach(segment => {
    if (segment.status !== 'active') response[segment.id] = null
    else response[segment.id] = []
  })
  audiences.forEach(audience => {
    let formatedAudience

    if (audience.audience_type === 'custom') {
      formatedAudience = {}
      formatedAudience.id = audience.id
      formatedAudience.audienceName = audience.audience_name
      formatedAudience.audienceType = 'custom'
      formatedAudience.isEditable = false
      formatedAudience.source = audience.source
      formatedAudience.sourceId = audience.source_id
      formatedAudience.productId = audience.product_id
      formatedAudience.status = audience.status
      formatedAudience.matchedRecordsTotal = audience.matched_records_total
      formatedAudience.createdFromSegments = audience.segments.map(
        segment => segment.id + ''
      )
      formatedAudience.ruleGroups = []
      formatedAudience.matchedRecordIds = audience.matched_record_ids
      let rule = {}
      if (audience.match_type === 'and') rule.matchType = 'Match All'
      if (audience.match_type === 'or') rule.matchType = 'Match Any'
      rule.rules = audience.audience_queries.map(query => {
        return {
          id: query.id,
          prospectTag: query.column_name,
          condition: query.condition,
          value: query.value_1
        }
      })
      formatedAudience.ruleGroups.push(rule)
      const segmentId = formatedAudience.createdFromSegments[0]
      let elementIndex = null
      response[segmentId] &&
        response[segmentId].every((audience, index) => {
          if (audience === null) {
            const result = formatedAudience.createdFromSegments.filter(
              item => response[item][index] === null
            )
            if (result.length === formatedAudience.createdFromSegments.length) {
              elementIndex = index
              return false
            }
            return true
          } else {
            return true
          }
        })
      formatedAudience.createdFromSegments.forEach(id => {
        if (elementIndex === null) {
          response[id].push(formatedAudience)
        } else {
          response[id][elementIndex] = formatedAudience
        }
      })

      Object.keys(response).forEach(audienceId => {
        if (
          response[audienceId] &&
          !formatedAudience.createdFromSegments.includes(audienceId) &&
          !(elementIndex !== null)
        )
          response[audienceId].push(null)
      })
    }
  })
  return response
}

export const mapDataForDatasource = (payload, productId) => {
  const audience = {
    audience_name: payload.audienceName,
    audience_type: 'custom',
    match_type: payload.matchType,
    status: payload.status || 'active',
    segment_id: payload.createdFromSegments.map(segmentId =>
      parseInt(segmentId)
    ),
    source: payload.source,
    source_id: payload.sourceId || 1,
    product_id: productId || 2,
    is_editable: payload.isEditable
  }
  if (payload.id && !uuidValidate(payload.id)) audience.id = payload.id
  if (payload.ruleGroups[0].matchType === 'Match All')
    audience.match_type = 'and'
  if (payload.ruleGroups[0].matchType === 'Match Any')
    audience.match_type = 'or'
  audience.criteria = payload.ruleGroups[0].rules.map(rule => {
    const criteria = {
      column_name: rule.prospectTag,
      condition: rule.condition,
      value_1: rule.value,
      status: 'active'
    }
    if (rule && rule.id) criteria.id = rule.id
    return criteria
  })
  return {
    audience: [audience]
  }
}

export const mapDataForDeleteOperation = (payload, productId) => {
  const audience = {
    audience_name: payload.audienceName,
    audience_type: 'custom',
    match_type: payload.matchType,
    status: 'inactive',
    segment_id: payload.createdFromSegments.map(segmentId =>
      parseInt(segmentId)
    ),
    source: payload.source,
    source_id: payload.sourceId || 1,
    product_id: productId || 2,
    is_editable: payload.isEditable
  }
  if (payload.id && !uuidValidate(payload.id)) audience.id = payload.id
  if (payload.ruleGroups[0].matchType === 'Match All')
    audience.match_type = 'and'
  if (payload.ruleGroups[0].matchType === 'Match Any')
    audience.match_type = 'or'
  audience.criteria = payload.ruleGroups[0].rules.map(rule => {
    const criteria = {
      column_name: rule.prospectTag,
      condition: rule.condition,
      value_1: rule.value,
      status: 'active'
    }
    if (rule && rule.id) criteria.id = rule.id
    return criteria
  })
  return audience
}
