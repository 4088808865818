import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  queueCardContent: {
    margin: '0.8rem 0.8rem',
    display: 'flex'
  },
  acoountPaper: {
    width: '52rem'
  },
  actionButton: {
    textTransform: 'none',
    variant: 'outlined',
    width: '-webkit-fill-available',
    fontWeight: '300',
    fontSize: '0.875rem',
    margin: '0.4rem 0rem',
    color: '#a6a6a6',
    border: '1px solid #bfbfbf',
    transition: 'border 0.4s ease',
    '& span': {
      transition: 'color 0.4s ease'
    },
    '&:hover': {
      border: '1px solid #18A3E2',
      color: '#18A3E2',
      backgroundColor: 'inherit'
    }
  },
  actionButtonSection: {
    marginBottom: 'auto'
  },
  undoButtonSectionGrid: {
    margin: 'auto'
  },
  undoButtonSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      marginBottom: '1rem'
    },
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      fontWeight: '400',
      fontSize: '0.8rem',
      border: '1px solid #cccccc',
      '&:hover': {
        border: '1px solid #6699cc',
        color: '#6699cc',
        backgroundColor: 'inherit',
        fontWeight: 'bold'
      }
    }
  },
  neyoDataTable: {
    '& .MuiTableCell-root': {
      borderBottom: '0',
      paddingTop: '1.5rem'
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: '1rem',
      fontWeight: '300'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: '0',
      paddingBottom: '0.9rem',
      fontWeight: '300'
    },
    fontSize: '4px',
    fontWeight: '300'
  },
  neyoDataTitleButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '68px',
    height: '68px',
    borderRadius: '5px',
    border: '1px solid #cccccc',
    margin: '1.1rem 0.7rem'
  },
  neyoDataTitleIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  neyoAccountColumnCell: {
    width: '13rem'
  },
  neyoMatchColumnCell: {
    width: '7rem'
  },
  neyoAccountColumnData: {
    textTransform: 'none',
    fontSize: '0.8rem',
    fontWeight: '400',
    borderRadius: '5px',
    border: '1px solid #cccccc',
    padding: '0.4rem 0.7rem'
  },
  integrationData: {
    padding: '1.1rem 0.7rem 0.6rem 0.7rem',
    marginBottom: ''
  },
  duplicateIntegration: {
    backgroundColor: '#999999'
  },
  integrationDataFirstColumnHeader: {
    paddingLeft: '0.7rem',
    fontWeight: '300'
  },
  integrationDataFirstColumnValue: {
    textTransform: 'none',
    fontSize: '0.8rem',
    fontWeight: '400',
    borderRadius: '5px',
    border: '1px solid #cccccc',
    padding: '0.4rem 0.7rem',
    marginTop: '0.8rem',
    marginBottom: '0.7rem',
    backgroundColor: '#ffffff',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& .MuiButton-label': {
      justifyContent: 'left'
    }
  },
  integrationDataIconGrid: {
    display: 'flex',
    justifyContent: 'right',
    marginLeft: '20px'
  },
  integrationDataIcon: {
    width: 'auto',
    height: 'auto',
    borderRadius: '5px',
    border: '1px solid #cccccc',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    backgroundColor: '#ffffff'
  },
  duplicateIntegrationTag: {
    textAlign: 'center',
    '& .MuiTypography-body1': {
      color: '#ffffff',
      fontSize: '25px',
      fontWeight: '500'
    }
  },
  otherMatches: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px'
  },
  matchOption: {
    '& .MuiTableCell-body:first-child': {
      width: '0px'
    }
  },
  matchOptionButton: {
    textTransform: 'none',
    variant: 'outlined',
    width: 'auto',
    fontWeight: '400',
    padding: '0.3rem 0rem',
    fontSize: '0.8rem',
    '& .MuiButton-label': {
      justifyContent: 'center'
    }
  },
  titleCell: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
    '& .MuiIcon-root': {
      width: '3rem'
    }
  },
  titleCellButton: {
    variant: 'outlined',
    width: 'auto',
    fontWeight: '800',
    padding: '0.3rem 0rem',
    '& .MuiButton-label': {
      justifyContent: 'center',
      color: 'black'
    },
    border: '0'
  },
  viewOtherMatchesBox: {
    padding: '0px',
    '& .MuiTypography-root': {
      paddingLeft: '10px',
      color: 'black'
    },
    '&:hover': {
      background: 'none'
    },
    '& .MuiTypography-body2': {
      fontWeight: '300'
    }
  },
  viewOtherMatchesIcon: {
    borderRadius: '5px',
    display: 'flex'
  },
  viewOtherMatchesAccountSource: {
    marginTop: '0.8rem',
    marginBottom: '0.8rem'
  },
  isHighlighted: {
    border: '1px solid #6699cc',
    color: '#6699cc',
    fontWeight: 'bold'
  }
}))

export default useStyles
