/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCurrentEditingComponent,
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import Portal from '../../../../../../../customHooks/portal'

import {
  menuContainer,
  menuItemStyle,
  textEditorStyle,
  labelStyle,
  fieldStyle,
  inputStyle,
  linkEditorStyle,
  updateLinkBtnStyle,
  anchorStyle
} from './style'

const Menu = ({ parentPath, componentPath, parentUid }) => {
  const dispatch = useDispatch()
  const editorRef = useRef()
  const linkEditorRef = useRef()
  const [showTextEditor, toggleTextEditor] = useState(false)
  const [showLinkEditor, toggleLinkEditor] = useState(false)
  const [currentEditingMenuItem, setCurrentEditingMenuItem] = useState({})

  const [
    currentEditingMenuItemIndex,
    setCurrentEditingMenuItemIndex
  ] = useState(null)
  const [menuObj, setMenuObj] = useState({})
  const [editorCoords, setEditorCoords] = useState({
    top: '',
    left: '',
    width: 240
  })
  const [linkEditorCoords, setLinkEditorCoords] = useState({
    top: '',
    left: '',
    width: 240
  })

  useEffect(() => {
    const clickedOutside = event => {
      if (
        (editorRef.current || linkEditorRef.current) &&
        !(
          editorRef.current.contains(event.target) &&
          linkEditorRef.current.contains(event.target)
        )
      ) {
        toggleLinkEditor(false)
        toggleTextEditor(false)
        setCurrentEditingMenuItem(null)
        setCurrentEditingMenuItemIndex(null)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [editorRef, showTextEditor])

  const {
    currentEditingComponent: { path: editingPath } = {},
    editingComponents,
    currentEditedSegmentandAudience,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        editingComponents,
        currentEditedSegmentandAudience,
        emailTemplates
      }
    }) => ({
      currentEditingComponent,
      editingComponents,
      currentEditedSegmentandAudience,
      emailTemplates
    })
  )

  const handleMenuContainerClick = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'menu',
        parentUid
      })
    )
  }

  useEffect(() => {
    let menuObj = {
      menuItems: [
        {
          style: { textDecoration: 'none' },
          text: 'Men',
          linkTo: 'https://google.com'
        },
        {
          style: { textDecoration: 'none' },
          text: 'Women',
          linkTo: 'https://google.com'
        },
        {
          style: { textDecoration: 'none' },
          text: 'Shoes',
          linkTo: 'https://google.com'
        }
      ],
      color: '#000'
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        menuObj = { ...menuObj, ...(editingAudience[path] || {}) }
      }
    }
    setMenuObj(menuObj)
  }, [editingComponents])

  useEffect(() => {
    const { menuItems = [] } = menuObj || {}
    if (menuItems[currentEditingMenuItemIndex]) {
      setCurrentEditingMenuItem(menuItems[currentEditingMenuItemIndex])
    }
  }, [editingComponents, currentEditingMenuItemIndex, menuObj])

  const handleMenuItemFocus = index => {
    toggleTextEditor(true)
    setCurrentEditingMenuItemIndex(index)
  }

  const handleChange = (key, value) => {
    const updatedItem = { ...currentEditingMenuItem, [key]: value }
    setCurrentEditingMenuItem(updatedItem)
  }

  const handleStyleChange = (key, value) => {
    const menu = { ...menuObj, menuItems: menuObj['menuItems'].slice() }
    const currentItem = menu['menuItems'][currentEditingMenuItemIndex]
    const updatedItems = {
      ...currentItem,
      style: { ...currentItem['style'], [key]: value }
    }
    menu['menuItems'].splice(currentEditingMenuItemIndex, 1, updatedItems)
    setMenuObj(JSON.parse(JSON.stringify(menu)))
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: menu,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  const handleUpdateLink = () => {
    const menu = { ...menuObj, menuItems: menuObj['menuItems'].slice() }
    menu['menuItems'].splice(
      currentEditingMenuItemIndex,
      1,
      currentEditingMenuItem
    )
    setMenuObj(JSON.parse(JSON.stringify(menu)))
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: menu,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  return (
    <div
      css={menuContainer(`${parentPath}->${componentPath}` === editingPath)}
      onClick={event => {
        event.stopPropagation()
        handleMenuContainerClick()
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
      }}
    >
      {(menuObj['menuItems'] || []).map((menuItem, index) => {
        const { text, linkTo, style } = menuItem || {}
        return (
          <div
            key={index}
            onClick={({ target }) => {
              const rect = target.getBoundingClientRect()
              let left = rect.x
              let top = rect.y + rect.height
              setEditorCoords({
                left, // add half the width of the button for centering
                top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
                width: 240
              })
              handleMenuItemFocus(index)
              toggleLinkEditor(false)
            }}
            css={menuItemStyle}
            style={{
              padding: '12px'
            }}
          >
            <a
              css={anchorStyle}
              href={linkTo}
              target='_blank'
              style={{
                color: menuObj['color'],
                fontSize: menuObj['fontSize'],
                fontFamily: menuObj['fontFamily'],
                ...style
              }}
            >
              {text}
            </a>
          </div>
        )
      })}
      {showTextEditor && (
        <Portal>
          <div
            ref={editorRef}
            style={{ ...editorCoords, position: 'absolute' }}
            css={textEditorStyle}
          >
            <div
              className='tooltip-item'
              active={
                menuObj['menuItems'][currentEditingMenuItemIndex] &&
                menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                  'fontWeight'
                ] === 'bold'
                  ? 'true'
                  : ''
              }
            >
              <div
                className='icon'
                onClick={() => {
                  const value =
                    menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                      'fontWeight'
                    ] === 'bold'
                      ? 'normal'
                      : 'bold'
                  handleStyleChange('fontWeight', value)
                }}
              >
                <svg
                  width='12'
                  height='14'
                  viewBox='0 0 12 14'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '14px', height: '14px' }}
                >
                  <path d='M0.86499 13.8003V0.199707H5.96753C7.00659 0.199707 7.83846 0.338867 8.46313 0.617188C9.09399 0.889323 9.58569 1.31299 9.93823 1.88818C10.297 2.45719 10.4763 3.05404 10.4763 3.67871C10.4763 4.26009 10.3186 4.80745 10.0032 5.3208C9.68774 5.83415 9.21151 6.24854 8.57446 6.56396C9.39705 6.80518 10.0279 7.21647 10.467 7.79785C10.9124 8.37923 11.135 9.06575 11.135 9.85742C11.135 10.4945 10.9989 11.0882 10.7268 11.6387C10.4609 12.1829 10.13 12.6035 9.73413 12.9004C9.3383 13.1973 8.84041 13.423 8.24048 13.5776C7.64673 13.7261 6.91691 13.8003 6.05103 13.8003H0.86499ZM2.66479 5.91455H5.60571C6.40356 5.91455 6.97567 5.86198 7.32202 5.75684C7.7797 5.62077 8.12297 5.39502 8.35181 5.07959C8.58683 4.76416 8.70435 4.36833 8.70435 3.89209C8.70435 3.44059 8.59611 3.04476 8.37964 2.70459C8.16317 2.35824 7.85392 2.12321 7.4519 1.99951C7.04989 1.86963 6.36027 1.80469 5.38306 1.80469H2.66479V5.91455ZM2.66479 12.1953H6.05103C6.63241 12.1953 7.04061 12.1737 7.27563 12.1304C7.69002 12.0562 8.03638 11.9325 8.3147 11.7593C8.59302 11.5861 8.82186 11.3356 9.00122 11.0078C9.18058 10.6738 9.27026 10.2904 9.27026 9.85742C9.27026 9.35026 9.14038 8.91113 8.88062 8.54004C8.62085 8.16276 8.25903 7.8999 7.79517 7.75146C7.33748 7.59684 6.6757 7.51953 5.80981 7.51953H2.66479V12.1953Z'></path>
                </svg>
              </div>
            </div>
            <div
              className='tooltip-item'
              active={
                menuObj['menuItems'][currentEditingMenuItemIndex] &&
                menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                  'fontStyle'
                ] === 'italic'
                  ? 'true'
                  : ''
              }
            >
              <div
                className='icon'
                onClick={() => {
                  const value =
                    menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                      'fontStyle'
                    ] === 'italic'
                      ? 'none'
                      : 'italic'
                  handleStyleChange('fontStyle', value)
                }}
              >
                <svg
                  width='6'
                  height='14'
                  viewBox='0 0 6 14'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '14px', height: '14px' }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.25 2C4.80228 2 5.25 1.55228 5.25 1C5.25 0.447715 4.80228 0 4.25 0C3.69772 0 3.25 0.447715 3.25 1C3.25 1.55228 3.69772 2 4.25 2ZM2.80957 4.14746L0.75 14H2.4292L4.48877 4.14746H2.80957Z'
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className='tooltip-item'
              active={
                menuObj['menuItems'][currentEditingMenuItemIndex] &&
                menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                  'textDecoration'
                ] === 'underline'
                  ? 'true'
                  : ''
              }
            >
              <div
                className='icon'
                onClick={() => {
                  const value =
                    menuObj['menuItems'][currentEditingMenuItemIndex]['style'][
                      'textDecoration'
                    ] === 'underline'
                      ? 'none'
                      : 'underline'
                  handleStyleChange('textDecoration', value)
                }}
              >
                <svg
                  width='14'
                  height='17'
                  viewBox='0 0 14 17'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '14px', height: '14px' }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M1 0V8C1 11.3137 3.68629 14 7 14C10.3137 14 13 11.3137 13 8V0H11V8C11 10.2091 9.20914 12 7 12C4.79086 12 3 10.2091 3 8V0H1ZM0 15V17H14V15H0Z'
                  ></path>
                </svg>
              </div>
            </div>
            <div className='tooltip-item'>
              <div
                className='icon'
                onClick={() =>
                  window.open(currentEditingMenuItem['linkTo'], '_blank')
                }
              >
                <svg
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '14px', height: '14px' }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2 0C0.89543 0 0 0.895431 0 2V12C0 13.1046 0.895431 14 2 14H12C13.1046 14 14 13.1046 14 12V9H12V12H2V2H5V0H2ZM9 0H13H14V1V5H12V3.41421L6.70711 8.70711L5.29289 7.29289L10.5858 2H9V0Z'
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className='tooltip-item link-icon'
              onClick={event => {
                event.stopPropagation()
                const { target } = event
                const rect = target.getBoundingClientRect()
                let left = rect.x - 179
                let top = rect.y + rect.height + 30
                setLinkEditorCoords({
                  left, // add half the width of the button for centering
                  top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
                  width: 240
                })
                toggleLinkEditor(prev => !prev)
              }}
            >
              <div className='icon link'>
                <svg
                  width='20'
                  height='10'
                  viewBox='0 0 20 10'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    width: '20px',
                    height: '14px',
                    fill: 'rgb(78 175 226)'
                  }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9 8L5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2L9 2V0H5C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10H9V8ZM15 2H11V0H15C17.7614 0 20 2.23858 20 5C20 7.76142 17.7614 10 15 10H11V8H15C16.6569 8 18 6.65685 18 5C18 3.34315 16.6569 2 15 2ZM14 6H6V4H14V6Z'
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </Portal>
      )}
      {showLinkEditor && (
        <div
          ref={linkEditorRef}
          css={linkEditorStyle}
          style={{ ...linkEditorCoords, position: 'fixed' }}
        >
          <div style={{ width: '100%', marginBottom: '24px' }}>
            <label css={labelStyle}>Link To</label>
            <div css={fieldStyle}>
              <input
                onChange={({ target: { value } }) =>
                  handleChange('linkTo', value)
                }
                css={inputStyle}
                value={currentEditingMenuItem['linkTo']}
              />
            </div>
          </div>
          <div style={{ width: '100%', marginBottom: '24px' }}>
            <label css={labelStyle}>Text</label>
            <div css={fieldStyle}>
              <input
                onChange={({ target: { value } }) =>
                  handleChange('text', value)
                }
                css={inputStyle}
                value={currentEditingMenuItem['text']}
              />
            </div>
          </div>
          <button css={updateLinkBtnStyle} onClick={handleUpdateLink}>
            Update link
          </button>
        </div>
      )}
    </div>
  )
}

export default Menu
