import { css } from '@emotion/react/macro'

const nonEditableFormCss = (lostCustomer, fullHeight) => css`
  background-color: #4eafe2;
  ${fullHeight && 'padding-top: 23px;'}
  ${lostCustomer && 'background-color: rgba(151,151,151);'}
  border: 0;
`

const toggleHeightAnimation = fullHeight => css`
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  padding: 8px 24px 20px 21px;
  transition: all 0.5s ease-out;
  ${fullHeight && 'height: 515px; overflow: unset; max-height: 515px;'}
`

const setModalFormContainer = () => css`
  width: 100%;
  height: 100%;
  min-height: 100%;
  border: 0px;
  box-shadow: 0;
  padding: 24px;
`

export const formContainer = (
  isEditable,
  segmentDropdown,
  fullHeight,
  modalForm,
  index,
  lostCustomer
) => css`
  box-sizing: border-box;
  height: 621px;
  width: 252px;
  border: 4px dashed #cccccc;
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 12px;
  margin-right: 13px;
  ${index === 4 && 'margin-right: 0px;'}
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  // ${segmentDropdown && toggleHeightAnimation(fullHeight)}
  &::after {
    ${
      !modalForm &&
      'content: ""; position: absolute; height: calc(100% + 23px); width: 2px; background: #fff; right: -11px; top: -14px;'
    }
  }
  &.auto {
    height: auto;
  }
  ${!isEditable && nonEditableFormCss(lostCustomer, fullHeight)}
  &:hover {
    .toggleHeight {
      ${
        !fullHeight &&
        'display: flex;justify-content: center;align-items: center;border: 1px solid #e5e5e5;'
      }
    }
  }
  ${modalForm && setModalFormContainer()}
  .toggleHeight {
    ${!fullHeight && 'display: none;'}
    width: 28px;
    height: 28px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    background: #fff;
    top: 0px;
    color: #16a3e1;
    font-weight: 700;
    border-radius: 4px;
    line-height: 1.5;
  }
`

export const inputStyle = css`
  box-sizing: border-box;
  height: 40px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  margin-bottom: 8px;
`

export const segmentNameStyle = (modalForm, conditionInput) => css`
  width: 100%;
  ${modalForm && !conditionInput && 'width: 224px;margin-bottom: 20px;'}
  ${modalForm && conditionInput && 'margin-left: 10px;'}
`

export const nonEditableSegmentName = (fullHeight, lostCustomer) => css`
  color: #fff;
  font-family: BentonSans;
  font-size: 18px;
  display: flex;
  margin-bottom: 10px;
  position: relative;
  ${!lostCustomer && 'padding-right: 23px;'}
  .segmentName {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .deleteSegment {
    color: #fff;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const matchTypeStyle = (ruleGroupIndex, modalForm) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  label {
    white-space: normal;
    color: #333333;
    font-family: BentonSans;
    font-weight: 300;
    font-size: 13px;
  }
  &.nonEditable {
    margin-bottom: 41px;
    label {
      color: #fff;
      font-size: 16px;
    }
  }
  ${modalForm && 'width: 224px;'}
`

export const conditionContainer = css``

export const addDeleteIconContainer = css`
  margin-bottom: 9px;
  span {
    cursor: pointer;
    display: inline-block;
    width: 23px;
    height: 23px;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const unionStyle = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 300;
  line-height: 7px;
  width: 100%;
  margin: 11px auto 11px auto;
  text-align: center;
  &.nonEditable {
    color: #fff;
    margin: 30px auto 50px auto;
  }
`

export const extraRuleContainer = modalForm => css`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  ${modalForm && 'width: 224px;'}
`

export const extraRuleStyle = css`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  span {
    margin-right: 3px;
    font-size: 14px;
    font-weight: 700;
  }
`

export const saveButtonContainer = css`
  display: flex;
  justify-content: center;
  margin-top: 9px;
  &.nonEditable {
    margin-top: 37px;
  }
`

export const buttonStyle = css`
  box-sizing: border-box;
  height: 38px;
  width: 104px;
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 3px;
  &.nonEditable {
    font-size: 13px;
  }
`

export const iconStyle = css`
  height: 23px;
  width: 23px;
  img {
    width: inherit;
    height: inherit;
  }
`

export const nonEditableInputStyle = css`
  display: flex;
  align-items: center;
  color: #7c7c7c;
  div {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
`

export const conditionWrapper = modalForm => css`
  height: 406px;
  &.nonEditable {
    height: 368px;
    ${modalForm && 'height: 240px;'}
  }
  ${modalForm && 'height: 284px;'}
`

export const ruleGroupWrapper = (segmentDropdown, modalForm) => css`
  height: 460px;
  overflow-y: auto;
  ${segmentDropdown && 'height: 368px;'}
  ${modalForm && 'height: 336px;'}
`

const initialIndexStyle = css`
  background: transparent;
  padding: unset;
  border: 0;
`

export const ruleGroupContainer = ruleGroupIndex => css`
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  ${ruleGroupIndex === 0 && initialIndexStyle}
`

export const dropdownStyle = ruleGroupIndex => css`
  max-width: unset;
  margin-bottom: 8px;
  height: 40px;
  color: #333;
  padding: 4px 10px;
  .dropdown-menu {
    top: 34px;
    height: 170px;
    overflow-y: auto;
    height: 100px;
  }
`

export const matchTypeDropdownStyle = css`
  width: 116px;
  min-width: unset;
  height: 40px;
  .dropdown-menu {
    top: 40px;
  }
`
export const overlay = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
`

export const expandModalIconStyle = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 28px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  margin-right: 3px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  img {
    height: 16px;
    width: 16px;
  }
`
export const inputOption = modalForm => css`
  ${modalForm && 'display: flex;'}
`

export const nonEditableInputOption = modalForm => css`
  ${modalForm && 'display: flex;'}
`
