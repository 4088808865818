/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import Color from '../Colors'
import ButtonSettings from '../ButtonSettings'
import { updateEmailSettingsHandler } from '../../../../../../../store/dashboard/mailerSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  sidePanelTitle,
  container,
  canvasWidthContainer,
  textFieldStyle,
  additionalTextStyle,
  themeSettingStyle,
  colorTogglerStyle,
  colorTextStyle
} from './style'

const EmailSettings = () => {
  const [colorMenu, showColorMenu] = useState(false)
  const [showButtonSettings, setShowButtonSettings] = useState(false)
  const {
    savedSettings: {
      canvasWidth: savedCanvasWidth,
      backgroundColor: savedColor
    }
  } = useSelector(({ mailer: { emailSettings: savedSettings } = {} }) => ({
    savedSettings
  }))
  const dispatch = useDispatch()
  const [emailSettings, setEmailSettings] = useState({
    backgroundColor: savedColor || '#e3e5e8',
    canvasWidth: savedCanvasWidth || 600
  })

  useEffect(() => {
    dispatch(updateEmailSettingsHandler(emailSettings))
  }, [emailSettings])

  const updateEmailSettings = (key, value) => {
    const settings = {
      ...emailSettings,
      [key]: value
    }
    setEmailSettings(settings)
  }

  const hideEmailSettings = !(colorMenu || showButtonSettings)
  return (
    <div css={container}>
      {hideEmailSettings && (
        <>
          <div css={sidePanelTitle}>Email Settings</div>
          <div css={canvasWidthContainer}>
            <label className='label'>Canvas width</label>
            <div css={textFieldStyle}>
              <input
                type='number'
                value={emailSettings.canvasWidth}
                onChange={({ target: { value } }) =>
                  updateEmailSettings('canvasWidth', value)
                }
              />
            </div>
            <div css={additionalTextStyle}>
              We recommend using a 600-700px width
            </div>
          </div>
          <div css={themeSettingStyle}>Theme settings</div>
          <div css={colorTogglerStyle} onClick={() => showColorMenu(true)}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '24px', height: '24px' }}
            >
              <path
                d='M24 12C24 10.4241 23.6896 8.86371 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 -6.88831e-08 12 0L12 12H24Z'
                fill='#F5F5F5'
              ></path>
              <path
                d='M12 0C10.4241 -1.8792e-08 8.86371 0.310389 7.4078 0.913446C5.95189 1.5165 4.62902 2.40042 3.51472 3.51472C2.40041 4.62902 1.5165 5.95189 0.913445 7.4078C0.310389 8.86371 -2.37948e-07 10.4241 0 12L12 12V0Z'
                fill='#555C66'
              ></path>
              <path
                d='M0 12C-1.37766e-07 13.5759 0.310389 15.1363 0.913445 16.5922C1.5165 18.0481 2.40042 19.371 3.51472 20.4853C4.62902 21.5996 5.95189 22.4835 7.4078 23.0866C8.86371 23.6896 10.4241 24 12 24L12 12L0 12Z'
                fill='#B6B6B6'
              ></path>
              <path
                d='M12 24C13.5759 24 15.1363 23.6896 16.5922 23.0866C18.0481 22.4835 19.371 21.5996 20.4853 20.4853C21.5996 19.371 22.4835 18.0481 23.0866 16.5922C23.6896 15.1363 24 13.5759 24 12H12L12 24Z'
                fill='#E7E7E7'
              ></path>
            </svg>
            <div css={colorTextStyle}>Colors</div>
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: '16px',
                height: '16px',
                transform: 'rotate(-90deg)',
                marginLeft: 'auto',
                fill: 'rgb(78 175 226)'
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
          <div
            css={colorTogglerStyle}
            onClick={() => setShowButtonSettings(true)}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '24px', height: '24px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.5 8H18.5C20.433 8 22 9.567 22 11.5C22 13.433 20.433 15 18.5 15H5.5C3.567 15 2 13.433 2 11.5C2 9.567 3.567 8 5.5 8ZM0 11.5C0 8.46243 2.46243 6 5.5 6H18.5C21.5376 6 24 8.46243 24 11.5C24 14.5376 21.5376 17 18.5 17H5.5C2.46243 17 0 14.5376 0 11.5ZM19 11H5V12H19V11Z'
                fill='#555C66'
              ></path>
            </svg>
            <div css={colorTextStyle}>Buttons</div>
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: '16px',
                height: '16px',
                transform: 'rotate(-90deg)',
                marginLeft: 'auto',
                fill: 'rgb(78 175 226)'
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
        </>
      )}
      {colorMenu && (
        <Color
          toggleColorsMenu={() => showColorMenu(false)}
          backgroundColor={emailSettings.backgroundColor}
          updateEmailSettings={updateEmailSettings}
        />
      )}
      {showButtonSettings && (
        <ButtonSettings
          toggleButtonSettings={() => setShowButtonSettings(false)}
        />
      )}
    </div>
  )
}

export default EmailSettings
