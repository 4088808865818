/** @jsxImportSource @emotion/react */
import React from 'react'

import AudienceForm from './AudienceForm/index'

import {
  audienceCard,
  contractModalIconStyle,
  audienceAccountData,
  headerCard,
  contentCard
} from './style'

import salesforceIcon from '../../../../../assets/images/dashboard/audience/salesforce.png'
import bomboraIcon from '../../../../../assets/images/dashboard/audience/bombora.png'
import neyoColorIcon from '../../../../../assets/images/neyoMarkColor.svg'
import contractModalIcon from '../../../../../assets/images/dashboard/audience/contractModal.png'

const AudienceModalForm = ({
  audienceId,
  formValue,
  modalClickHandler = () => {},
  updateData = () => {},
  updateAudienceHandler = () => {},
  deleteAudienceHandler = () => {},
  editAudienceHandler = () => {},
  deleteNewAudienceHandler = () => {}
}) => {
  const getSourceIconBox = source => {
    let imageSource = neyoColorIcon
    if (source === 'salesforce') imageSource = salesforceIcon
    if (source === 'bombora') imageSource = bomboraIcon
    return <img className={source} alt={source} src={imageSource}></img>
  }

  return (
    <div css={audienceCard(formValue.isEditable)}>
      <div css={headerCard(formValue.isEditable)}>
        <span className='iconBox'>{getSourceIconBox('salesforce')}</span>
        <div css={audienceAccountData}>
          <label>{formValue.audienceName || 'Neyo Audience'}</label>
          <hr></hr>
          <span>{`${formValue.matchedRecordsTotal || 0} Targets`}</span>
        </div>
        <button css={contractModalIconStyle} onClick={modalClickHandler}>
          <img alt='expand-modal-icon' src={contractModalIcon} />
        </button>
      </div>
      <div css={contentCard(formValue.isEditable)}>
        <AudienceForm
          data={formValue}
          id={formValue.id}
          audienceId={audienceId}
          modalForm={true}
          updateData={updateData}
          updateAudienceHandler={updateAudienceHandler}
          deleteAudienceHandler={deleteAudienceHandler}
          editAudienceHandler={editAudienceHandler}
          deleteNewAudienceHandler={deleteNewAudienceHandler}
        />
      </div>
    </div>
  )
}

export default AudienceModalForm
