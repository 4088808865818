import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    margin: '0.6em'
  },
  appBar: {
    boxShadow: 'none',
    padding: '0.6em'
  },
  button: {
    textTransform: 'none',
    marginLeft: '12px',
    marginRight: '25px',
    fontFamily: 'BentonSans',
    width: '164px',
    height: '48px',
    backgroundColor: '#18A3E2'
  },
  title: {
    fontWeight: 'bold',
    marginLeft: '25px',
    '& img': {
      height: '24px'
    }
  },
  actionDescriptionText: {
    marginLeft: 'auto',
    paddingRight: '2em',
    fontFamily: 'BentonSans'
  },
  toolbar: {
    marginLeft: '15px',
    marginRight: '15px'
  }
}))

export default useStyles
