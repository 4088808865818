import { css } from '@emotion/react/macro'

export const cardContainerStyle = css`
  margin: 10px;
`

export const previewStyle = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 68px;
  width: 68px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

// new styles

export const containerStyle = css`
  box-sizing: content-box;
  overflow: auto;
  padding: 14px 12px;
  width: calc(100% - 117px);
  padding-left: 12px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`

export const headerStyle = (expandedView, saveCampaignName) => css`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  align-items: center;
  position: relative;
  height: 95px;
  border: 0.8px solid #cccccc;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -2px #0099ff;
  .toggleHeight {
    height: 0px;
    width: 0px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
  ${expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
  &:hover {
    ${
      !expandedView &&
      saveCampaignName &&
      '.toggleHeight { height: 28px; width: 28px; }'
    }
  }
`

export const summaryStyle = css`
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 239px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  padding: 6px 16px;
  background-color: #fff;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

export const titleStyle = css`
  color: #333;
  font-size: 13px;
  font-weight: 600;
  font-family: BentonSans;
  text-transform: capitalize;
`

export const targetStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 13px;
  font-family: BentonSans;
  padding-top: 3px;
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  padding-left: 5px;
  border-radius: 4px;
  margin-top: 2px;
  font-family: BentonSans;
  &.campaignName {
    margin-right: 4px;
  }
`

export const saveCampaignNameButton = compaignName => css`
  box-sizing: border-box;
  height: 34px;
  width: 50px;
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  color: #ffffff;
  border: 0.8px solid #cccccc;
  ${!compaignName && 'color: #333333; background-color: #EBEBEB;'}
  ${compaignName && 'background-color: #18A3E2;'}
  border-radius: 5px;
  padding-top: 2px;
  cursor: pointer;
`

export const mailsContainer = (saveCampaignName, expandedView) => css`
  display: flex;
  height: 0px;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 0.8px solid #cccccc;
  background-color: #e5e5e5;
  box-shadow: 0 2px 6px -2px #0099ff;
  overflow: hidden;
  transition: all 0.4s ease-out;
  flex-direction: column;
  ${saveCampaignName && expandedView && 'min-height: 200px; height: auto;'}
`

export const mailRowStyle = css`
  display: flex;
  margin-bottom: 10px;
  min-width: 100%;
  &:first-of-type {
    margin-top: 10px;
  }
`

export const dummyAddcardStyle = css`
  box-sizing: border-box;
  width: 231.4px;
  min-width: 231.4px;
  margin: 12px 10px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  margin-right: 23px;
  height: 86px;
  padding: 13px 24px 20px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(204, 204, 204, 0.4);
  border: 4px dashed #cccccc;
  border-radius: 4px;
  padding: 16px 24px 16px 21px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  .plusIcon {
    color: black;
  }
  img {
    display: block;
  }
`

export const previewContainerStyle = css`
 justify-self: center;
 display: flex;
 align-items: center;
 gap: 40px;
 overflow: auto;
`
