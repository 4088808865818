import { v4 as uuidv4 } from 'uuid'

const segments = {
  B2B: [
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Attention',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Prospect Tag',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'high potential'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lead',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contact date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: '90 Days'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Prospect',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last demo date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: 'high potential'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Customer',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is greater than',
              value: '',
              valuePlaceholder: 'today'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lost Customers',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is not',
              value: '',
              valuePlaceholder: 'null'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    }
  ],
  B2C: [
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Attention',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Prospect Tag',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'abandoned cart'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last form submission',
              condition: '',
              conditionPlaceholder: 'is not',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lead',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last phone call is',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: '90 Days'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last email date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: '90 Days'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Prospect',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Prospect Tag',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'high potential'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last purchase date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Customer',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last purchase date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: '365 Days'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lost Customers',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last purchase date',
              condition: '',
              conditionPlaceholder: 'is greater than',
              value: '',
              valuePlaceholder: '365 Days'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    }
  ],
  BOTH: [
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Attention',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Prospect Tag',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'high potential'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lead',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contact date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: '90 Days'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Prospect',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last demo date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: 'high potential'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Customer',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is greater than',
              value: '',
              valuePlaceholder: 'today'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Last contract date',
              condition: '',
              conditionPlaceholder: 'is',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      segmentName: '',
      segmentNamePlaceholder: 'Lost Customers',
      isEditable: true,
      status: 'active',
      newAddition: true,
      segmentType: 'custom',
      ruleGroups: [
        {
          matchType: 'Match All',
          rules: [
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is not',
              value: '',
              valuePlaceholder: 'null'
            },
            {
              prospectTag: '',
              prospectTagPlaceholder: 'Contract end date',
              condition: '',
              conditionPlaceholder: 'is less than',
              value: '',
              valuePlaceholder: 'null'
            }
          ]
        }
      ]
    }
  ]
}

export default segments
