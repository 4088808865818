/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentEditingComponent } from '../../../../../../../store/dashboard/mailerSlice'

import { logoContainer } from './style'

const Logo = ({ parentPath, componentPath, width, parentUid }) => {
  const dispatch = useDispatch()

  const {
    currentEditingComponent: { path: editingPath } = {},
    editingComponents,
    currentEditedSegmentandAudience
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        editingComponents,
        currentEditedSegmentandAudience
      }
    }) => ({
      currentEditingComponent,
      editingComponents,
      currentEditedSegmentandAudience
    })
  )

  const onImageElementSelect = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'logo',
        parentUid
      })
    )
  }

  const getImageObj = () => {
    let imgObj = {
      file: '',
      altText: '',
      link: '',
      alignment: 'center'
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        imgObj = { ...imgObj, ...(editingAudience[path] || {}) }
      }
    }
    return imgObj
  }

  return (
    <div
      css={logoContainer(`${parentPath}->${componentPath}` === editingPath)}
      style={{
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: getImageObj()['alignment']
          ? getImageObj()['alignment']
          : 'center'
      }}
      onClick={event => {
        event.stopPropagation()
        onImageElementSelect()
      }}
    >
      {/* <Image parentPath={parentPath} componentPath={componentPath} /> */}
      <img
        src={
          getImageObj()['file'] ||
          'https://app.omnisend.com/image/newsletter/2084/63e2b2bbcbd80f001c215cec'
        }
        alt='img-placeholder'
        style={{ maxWidth: '100%', width: width || '140px' }}
      />
    </div>
  )
}

export default Logo
