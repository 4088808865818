import { v4 as uuidv4 } from 'uuid'

export const audienceDataFromApi = {
  audience_id_1: [],
  audience_id_2: [],
  audience_id_3: []
}

export const segmentAudienceData = {
  audience_id_1: {
    source: 'neyo',
    audienceName: '',
    audienceType: 'Segment',
    id: uuidv4(),
    isEditable: false,
    ruleGroups: [
      {
        matchType: 'Match All',
        rules: [
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          },
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          }
        ]
      }
    ],
    createdFromSegments: ['audience_id_1']
  },
  audience_id_2: {
    source: 'neyo',
    audienceName: '',
    audienceType: 'Segment',
    id: uuidv4(),
    isEditable: false,
    ruleGroups: [
      {
        matchType: 'Match All',
        rules: [
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          },
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          }
        ]
      }
    ],
    createdFromSegments: ['audience_id_2']
  },
  audience_id_3: {
    source: 'neyo',
    audienceName: '',
    audienceType: 'Segment',
    id: uuidv4(),
    isEditable: false,
    ruleGroups: [
      {
        matchType: 'Match All',
        rules: [
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          },
          {
            prospectTag: 'Prospect Tag',
            condition: 'is',
            value: 'marketable contact'
          }
        ]
      }
    ],
    createdFromSegments: ['audience_id_3']
  }
}

export const bomboraDataFromApi = {
  [uuidv4()]: {
    id: uuidv4(),
    audienceName: 'High Intent Tier 1',
    options: {
      amazonWebServices: {
        key: 'Amazon Web Services',
        value: false
      },
      construction: {
        key: 'Construction',
        value: false
      },
      enterpriseApplications: {
        key: 'Enterprise Applications',
        value: false
      },
      internetSecurity: {
        key: 'Internet Security',
        value: false
      },
      itManagement: {
        key: 'IT Management',
        value: false
      },
      itSpending: {
        key: 'IT Spending',
        value: false
      },
      crmSoftware: {
        key: 'CRM Software',
        value: false
      },
      dataCenter: {
        key: 'Data Center',
        value: false
      },
      dataQuality: {
        key: 'Data Quality',
        value: false
      },
      businessAnalytics: {
        key: 'Business Analytics',
        value: false
      },
      cloudComputing: {
        key: 'Cloud Computing',
        value: false
      },
      cloudSecurity: {
        key: 'Cloud Security',
        value: false
      }
    }
  }
}

export const bomboraMockData = {
  id: uuidv4(),
  audienceName: '',
  mockedAudience: true,
  options: {
    amazonWebServices: {
      key: 'Amazon Web Services',
      value: false
    },
    construction: {
      key: 'Construction',
      value: false
    },
    enterpriseApplications: {
      key: 'Enterprise Applications',
      value: false
    },
    internetSecurity: {
      key: 'Internet Security',
      value: false
    },
    itManagement: {
      key: 'IT Management',
      value: false
    },
    itSpending: {
      key: 'IT Spending',
      value: false
    },
    crmSoftware: {
      key: 'CRM Software',
      value: false
    },
    dataCenter: {
      key: 'Data Center',
      value: false
    },
    dataQuality: {
      key: 'Data Quality',
      value: false
    },
    businessAnalytics: {
      key: 'Business Analytics',
      value: false
    },
    cloudComputing: {
      key: 'Cloud Computing',
      value: false
    },
    cloudSecurity: {
      key: 'Cloud Security',
      value: false
    }
  }
}

export const zoomInfoDataFromApi = {
  [uuidv4()]: {
    audienceName: 'Enterprise Healthcare',
    companyFirmographics: {
      industry: {
        key: 'Industry',
        value: ''
      },
      revenue: {
        key: 'Revenue',
        value: ''
      },
      employees: {
        key: 'Employees',
        value: ''
      },
      excludeAccounts: {
        key: 'Exclude Accounts',
        value: ''
      }
    },
    contacts: {
      leadLocation: {
        key: 'Lead Location',
        value: ''
      },
      city: {
        key: 'City',
        value: ''
      },
      jobTitle: {
        key: 'Job Title(include)',
        value: ''
      },
      jobFunction: {
        key: 'Job Function',
        value: ''
      },
      seniority: {
        key: 'Seniority',
        value: ''
      },
      limitsContactsPerAccount: {
        key: 'Limits Contacts per Account',
        value: ''
      }
    }
  }
}

export const mockedZoomInfoData = {
  audienceName: '',
  mockedAudience: true,
  companyFirmographics: {
    industry: {
      key: 'Industry',
      value: ''
    },
    revenue: {
      key: 'Revenue',
      value: ''
    },
    employees: {
      key: 'Employees',
      value: ''
    },
    excludeAccounts: {
      key: 'Exclude Accounts',
      value: ''
    }
  },
  contacts: {
    leadLocation: {
      key: 'Lead Location',
      value: ''
    },
    city: {
      key: 'City',
      value: ''
    },
    jobTitle: {
      key: 'Job Title(include)',
      value: ''
    },
    jobFunction: {
      key: 'Job Function',
      value: ''
    },
    seniority: {
      key: 'Seniority',
      value: ''
    },
    limitsContactsPerAccount: {
      key: 'Limits Contacts per Account',
      value: ''
    }
  }
}
