/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Icon from '@material-ui/core/Icon'

import AudienceForm from '../AudienceForm/index'

import salesforceIcon from '../../../../../assets/images/dashboard/audience/salesforce.png'
import downArrowIcon from '../../../../../assets/images/dashboard/audience/downArrow.png'
import bomboraIcon from '../../../../../assets/images/dashboard/audience/bombora.png'
import zoomInfoIcon from '../../../../../assets/images/dashboard/audience/zeta.png'
import trashIcon from '../../../../../assets/images/trash-icon.png'

import {
  audienceInitialForm,
  header,
  audienceHeader,
  selectedAudienceSource,
  audienceSource,
  audienceSourceButton,
  dropDownContent,
  sourceIconBox,
  inputStyle,
  actionButtonContainer,
  audienceFormContent,
  extensionButton,
  leftExtensionContainer,
  rightExtensionContainer,
  rightExtensionCard,
  leftExtensionCard,
  minimizeFromLeft,
  minimizeFromRight
} from './style'

const sourceList = [
  {
    key: 'salesforce',
    className: 'salesforceIcon',
    alt: 'salesforceIcon',
    src: salesforceIcon
  },
  {
    key: 'bombora',
    className: 'bomboraIcon',
    alt: 'bomboraIcon',
    src: bomboraIcon
  },
  {
    key: 'zoomInfo',
    className: 'zoomInfoIcon',
    alt: 'zoomInfoIcon',
    src: zoomInfoIcon
  }
]

const audienceMock = {
  id: uuidv4(),
  source: '',
  audienceName: '',
  audienceType: 'Custom',
  isEditable: true,
  isNewAudience: true,
  ruleGroups: [
    {
      matchType: 'Match All',
      rules: [
        {
          prospectTag: '',
          condition: '',
          value: ''
        },
        {
          prospectTag: '',
          condition: '',
          value: ''
        }
      ]
    }
  ],
  createdFromSegments: []
}

const NewAudienceForm = ({
  productId,
  audienceId,
  audienceSelectedList,
  addAudienceFlagStore,
  addNewAudienceFormHandler = () => {},
  addNewAudienceHandler = () => {},
  minimizeAudienceHandler = () => {},
  deleteNewAudienceHandler = () => {},
  createAudienceButtonHandler = () => {}
}) => {
  const formWidth = audienceSelectedList.length
  const rightMostAudienceId =
    audienceSelectedList &&
    audienceSelectedList[audienceSelectedList.length - 1]
  const [sourceListActive, setSourceListActive] = useState(false)
  const [sourceSelected, setSourceSelected] = useState('')
  const [createButtonClicked, setCreateButtonClicked] = useState(false)
  const [newAudineceData, setNewAudienceData] = useState({
    ...JSON.parse(JSON.stringify(audienceMock)),
    id: uuidv4()
  })

  const [rightExtensionHoverFlag, setRightExtensionHoverFlag] = useState(false)
  const [rightMinimizeHoverFlag, setRightMinimizeHoverFlag] = useState(false)
  const [leftExtensionHoverFlag, setLeftExtensionHoverFlag] = useState(false)
  const [leftMinimizeHoverFlag, setLeftMinimizeHoverFlag] = useState(false)

  const [showExtensionMinimizeButtons, setShowExtensionMinimizeButtons] =
    useState(true)

  const clickSourceHandler = () => {
    setSourceListActive(sourceListActive => !sourceListActive)
  }

  const selectSourceHandler = key => {
    setNewAudienceData({ ...newAudineceData, source: key })
    setSourceSelected(key)
  }

  const sourceComponent = () => {
    return sourceList.map(({ className, alt, src, key }) => (
      <span
        key={key}
        css={sourceIconBox}
        onClick={() => selectSourceHandler(key)}
      >
        <img className={className} alt={alt} src={src} />
      </span>
    ))
  }

  const getSource = key => {
    const { className, alt, src } = sourceList.find(source => {
      return source.key === key
    })
    return <img className={className} alt={alt} src={src} />
  }

  const createButtonHandler = (sourceSelected, audienceName) => {
    if (sourceSelected === 'salesforce') {
      setNewAudienceData(prev => {
        return { ...prev, createdFromSegments: audienceSelectedList }
      })
    } else {
      createAudienceButtonHandler(sourceSelected, audienceName)
    }
    setCreateButtonClicked(createButtonClicked => !createButtonClicked)
  }

  const updateData = formValue => {
    const prevData = JSON.parse(JSON.stringify(newAudineceData))
    const newData = { ...prevData, ...formValue }
    addNewAudienceHandler(audienceId, newData, productId)
    setNewAudienceData(newData)
  }

  const audienceNameChangeHandler = event => {
    setNewAudienceData({ ...newAudineceData, audienceName: event.target.value })
  }

  const showLeftExtensionButton = (
    showExtensionMinimizeButtons,
    addAudienceFlagStore
  ) => {
    const leftMostAudienceId = audienceSelectedList && audienceSelectedList[0]
    if (
      showExtensionMinimizeButtons &&
      addAudienceFlagStore &&
      addAudienceFlagStore[leftMostAudienceId] &&
      addAudienceFlagStore[leftMostAudienceId].prev &&
      addAudienceFlagStore[addAudienceFlagStore[leftMostAudienceId].prev]
        .isClickable
    )
      return true
    return false
  }

  const showRightExtensionButton = (
    showExtensionMinimizeButtons,
    addAudienceFlagStore
  ) => {
    const rightMostAudienceId =
      audienceSelectedList &&
      audienceSelectedList[audienceSelectedList.length - 1]
    if (
      showExtensionMinimizeButtons &&
      addAudienceFlagStore &&
      addAudienceFlagStore[rightMostAudienceId] &&
      addAudienceFlagStore[rightMostAudienceId].next &&
      addAudienceFlagStore[addAudienceFlagStore[rightMostAudienceId].next]
        .isClickable
    )
      return true
    return false
  }

  return (
    <div style={{ flex: 1, flexShrink: 0 }}>
      <div
        css={audienceInitialForm(createButtonClicked, formWidth)}
        onMouseEnter={() => {
          setShowExtensionMinimizeButtons(true)
        }}
        onMouseLeave={() => {
          setShowExtensionMinimizeButtons(false)
        }}
      >
        <div css={header}>
          {formWidth > 1 && (
            <div
              css={minimizeFromLeft(leftMinimizeHoverFlag, createButtonClicked)}
            >
              <Icon className='plusIcon'>remove</Icon>
            </div>
          )}
          <div css={audienceHeader}>
            {!createButtonClicked ? (
              <div css={audienceSource} onClick={clickSourceHandler}>
                <button css={audienceSourceButton}>
                  {(sourceSelected && getSource(sourceSelected)) || (
                    <p>Source</p>
                  )}
                  <img
                    alt='downArrowIcon'
                    className='downArrowIcon'
                    src={downArrowIcon}
                  ></img>
                </button>
                <div css={dropDownContent(sourceListActive)}>
                  {sourceComponent()}
                </div>
              </div>
            ) : (
              <div css={selectedAudienceSource}>
                <span className='iconBox'>{getSource(sourceSelected)}</span>
              </div>
            )}
            {showExtensionMinimizeButtons && formWidth > 1 && (
              <>
                <div
                  css={extensionButton(formWidth, createButtonClicked)}
                  className='leftMinimize'
                  onMouseEnter={() => {
                    setLeftMinimizeHoverFlag(true)
                  }}
                  onMouseLeave={() => {
                    setLeftMinimizeHoverFlag(false)
                  }}
                  onClick={() => {
                    minimizeAudienceHandler('left')
                    setLeftMinimizeHoverFlag(false)
                    setShowExtensionMinimizeButtons(false)
                  }}
                >
                  <label>{'>'}</label>
                </div>
                <div
                  css={extensionButton(formWidth, createButtonClicked)}
                  className='rightMinimize'
                  onMouseEnter={() => {
                    setRightMinimizeHoverFlag(true)
                  }}
                  onMouseLeave={() => {
                    setRightMinimizeHoverFlag(false)
                  }}
                  onClick={() => {
                    minimizeAudienceHandler('right')
                    setRightMinimizeHoverFlag(false)
                    setShowExtensionMinimizeButtons(false)
                  }}
                >
                  <label>{'<'}</label>
                </div>
              </>
            )}
            {showLeftExtensionButton(
              showExtensionMinimizeButtons,
              addAudienceFlagStore
            ) && (
              <div
                css={extensionButton(formWidth, createButtonClicked)}
                className='leftExtension'
                onMouseEnter={() => {
                  setLeftExtensionHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setLeftExtensionHoverFlag(false)
                }}
                onClick={() => {
                  addNewAudienceFormHandler(
                    addAudienceFlagStore[audienceId].prev
                  )
                  setLeftExtensionHoverFlag(false)
                }}
              >
                <label>{'<'}</label>
              </div>
            )}
            {showRightExtensionButton(
              showExtensionMinimizeButtons,
              addAudienceFlagStore
            ) && (
              <div
                css={extensionButton(formWidth, createButtonClicked)}
                className='rightExtension'
                onMouseEnter={() => {
                  setRightExtensionHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setRightExtensionHoverFlag(false)
                }}
                onClick={() => {
                  setRightExtensionHoverFlag(false)
                  addNewAudienceFormHandler(
                    addAudienceFlagStore[rightMostAudienceId].next
                  )
                }}
              >
                <label>{'>'}</label>
              </div>
            )}
            <div css={leftExtensionContainer(formWidth)}>
              <div
                css={leftExtensionCard(
                  createButtonClicked,
                  leftExtensionHoverFlag,
                  formWidth
                )}
              >
                <Icon className='plusIcon'>add</Icon>
              </div>
            </div>
            <div css={rightExtensionContainer(formWidth)}>
              <div
                css={rightExtensionCard(
                  createButtonClicked,
                  rightExtensionHoverFlag,
                  formWidth
                )}
              >
                <Icon className='plusIcon'>add</Icon>
              </div>
            </div>

            <div>
              <input
                css={inputStyle}
                placeholder={'Audience Name'}
                value={newAudineceData.audienceName}
                onChange={audienceNameChangeHandler}
              />
              {!createButtonClicked && (
                <div
                  css={actionButtonContainer(
                    sourceSelected,
                    newAudineceData.audienceName
                  )}
                >
                  <button
                    disabled={
                      sourceSelected && newAudineceData.audienceName
                        ? false
                        : true
                    }
                    className='createAudienceButton'
                    onClick={() =>
                      createButtonHandler(
                        sourceSelected,
                        newAudineceData.audienceName
                      )
                    }
                  >
                    Create
                  </button>
                  <span onClick={deleteNewAudienceHandler}>
                    <img src={trashIcon} alt='trash-icon' />
                  </span>
                </div>
              )}
            </div>
          </div>
          {formWidth > 1 && (
            <div
              css={minimizeFromRight(
                rightMinimizeHoverFlag,
                createButtonClicked
              )}
            >
              <Icon className='plusIcon'>remove</Icon>
            </div>
          )}
        </div>

        {sourceSelected === 'salesforce' && (
          <div css={audienceFormContent(createButtonClicked)}>
            <AudienceForm
              data={newAudineceData}
              id={newAudineceData.id}
              audienceId={audienceId}
              audienceSelectedList={audienceSelectedList}
              updateData={updateData}
              deleteNewAudienceHandler={deleteNewAudienceHandler}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NewAudienceForm
