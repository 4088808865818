import { css, keyframes } from '@emotion/react'

const drawerWidth = 115

const circle = keyframes`
0%{
  top:60px;
  height:5px;
  border-radius: 50px 50px 25px 25px;
  transform: scaleX(1.7);
}
40%{
  height:20px;
  border-radius: 50%;
  transform: scaleX(1);
}
100%{
  top:0%;
}
`

const shadow = keyframes`
0%{
  transform: scaleX(1.5);
}
40%{
  transform: scaleX(1);
  opacity: .7;
}
100%{
  transform: scaleX(.2);
  opacity: .4;
}
`

export const content = css`
  flex-grow: 1;
  background-color: #eeeeee;
  width: calc(100% - ${drawerWidth}px);
  margin-left: ${drawerWidth}px;
  margin-top: 27px;
`

export const subHeaderText = css`
  margin-left: 2rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.00938em;
`
export const subHeaderButton = css`
  text-transform: none;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: 117px;
  width: 180px;
  color: #fff;
  box-sizing: border-box;
  height: 38px;
  width: 131px;
  background-color: #18a3e2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  box-shadow: 0 2px 6px 2px rgb(102 153 204 / 40%);
  cursor: pointer;
  &.MuiButton-label {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
  }
`

export const dataGridPaper = css`
  margin: 1rem 1rem;
  position: relative;
  &.super-app-theme--grey {
    background-color: rgba(224, 183, 60, 0.55);
  }
  &:nth-of-type(odd) {
    background-color: blue;
  }
`

export const icon = css`
  width: 20px;
  height: 20px;
  overflow: unset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const iconImage = css`
  width: inherit;
  height: inherit;
`

export const filterDropdown = css`
  display: flex;
  margin-right: 73px;
`

export const filterSearch = css`
  display: flex;
  position: absolute;
  align-items: center;
  padding: 0 20px 0 36px;
  z-index: 20;
  background: #fff;
  padding-top: 27px;
`

export const modalText = css`
  color: #333;
  padding: 5px 0;
  font-size: 18px;
  text-align: center;
  font-family: 'BentonSans';
  font-size: 12px;
  font-weight: 300;
`

export const buttonContainer = css`
  display: flex;
  padding: 10px 0;
  button {
    height: 34px;
    width: 120px;
    margin-right: 12px;
    outline: none;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-transform: capitalize;
    cursor: pointer;
    &.primary {
      background: #18a3e2;
      color: #fff;
    }
  }
`

export const subHeaderPaper = css`
  height: 5rem;
  margin: 0rem 0.8rem 0.8rem 0.8rem;
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
`

export const grdStyle = () => css`
  &.ag-theme-alpine {
    .ag-header-group-cell {
      padding: 0;
    }
    .ag-header-group-cell-label {
      justify-content: center;
      align-items: center;
    }
    .ag-header {
      border: none;
      background-color: #fff;
      .ag-header-cell-text {
        font-size: 14px;
        font-family: BentonSans;
        color: rgb(51, 51, 51);
        line-height: 2;
      }
    }
    .ag-root-wrapper,
    .ag-header-row,
    .ag-header-row-column,
    .ag-header-cell {
      border: 0 !important;
    }
    .ag-pinned-left-header {
      border: 0;
    }
    .ag-pinned-left-cols-container {
      border: 0;
    }
    .ag-row {
      border: 0;
    }
    .ag-cell-focus {
      border: none !important;
    }
    .inputField {
      outline: none;
    }

    .ag-cell {
      display: flex;
      align-items: center;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .import_field {
      padding-left: 25px;
    }

    .customHeaderLabel {
      border: 1px solid #f5f5f5;
      border-radius: 8px;
    }

    .customSelect {
      #custom-dropdown {
        padding: 8px 14px;
        outline: none;
        background: #fff;
        font-weight: 300;
        font-size: 0.875rem !important;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      }
      .chevron {
        display: inline-block;
        transform: rotate(180deg);
        width: 12px;
        height: 12px;
        img {
          width: inherit;
          height: inherit;
          position: absolute;
        }
      }
    }
    .checkbox:checked + label:after {
      top: 10px !important;
    }

    .custom-tooltip {
      background: #000000a8;
      padding: 4px 10px;
      border-radius: 6px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        max-width: 200px;
        word-break: break-word;
        white-space: normal;
      }
    }
  }
`

export const iconWrapper = css`
  position: relative;
  height: 70px;
  width: 70px;
  display: flex;
  border: solid #cccc;
  border-width: 0.8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 36px;
  box-shadow: 0 2px 6px -1px rgb(102 153 204 / 40%);
  img {
    object-fit: contain;
    width: 35px;
    height: 35px;
  }
`
