/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { TABLE_TYPE } from '../../../constant/analytics'

import {
  graph,
  graphCardStyle,
  graphContainer,
  dropdownContainer,
  displayValueStyle,
  labelStyle,
  tabsContainer
} from './style'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer
} from 'recharts'
import SummaryTable from './SummaryTable'
import DeliveryReportCard from './DeliveryReportCard'
import { getDateRange } from '../../../utils/dateRangeHelper'
import { faker } from '@faker-js/faker'
import KPIPacing from './KpiPacing'

const TABS = {
  years: () => {},
  quarters: () => {},
  months: () => {},
  weeks: () => {},
  days: () => {}
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const Customradio = withStyles({
  root: {
    color: '#333',
    padding: '2px 9px',
    '&$checked': {
      color: '#17a3e1'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {}
})(props => <Radio color='default' {...props} />)

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '12px'
  }
})(props => <FormControlLabel {...props} />)

const CustomRadioGroup = withStyles({
  root: {
    height: '60px',
    width: 430
  }
})(({ children, ...rest }) => <RadioGroup {...rest}>{children}</RadioGroup>)

export const Card = ({ style, children }) => {
  return (
    <div css={graphCardStyle} style={style}>
      {children}
    </div>
  )
}

export const Tabs = ({
  currentTab = 'years',
  onTabSelection = () => {},
  radioOptionSelected,
  onRadioSelectionChange
}) => {
  const handleRadioSelection = event => {
    const { target: { checked, defaultValue } = {} } = event || {}
    if (checked) {
      onRadioSelectionChange(defaultValue)
    }
  }

  return (
    <div>
      <div css={tabsContainer}>
        {Object.keys(TABS).map((key, index) => (
          <span
            onClick={() => onTabSelection(key)}
            key={index}
            className={`tab-item ${key === currentTab ? 'active' : ''}`}
          >
            {key}
          </span>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'space-between'
        }}
      >
        <CustomRadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={radioOptionSelected}
          name='radio-buttons-group'
          onChange={handleRadioSelection}
        >
          <CustomFormControlLabel
            value={`prev_${currentTab.slice(0, -1)}`}
            control={<Customradio />}
            label={`Previous ${currentTab.slice(0, -1)}`}
          />

          <CustomFormControlLabel
            value={`next_${currentTab.slice(0, -1)}`}
            control={<Customradio />}
            label={`Next ${currentTab.slice(0, -1)}`}
          />

          <CustomFormControlLabel
            value={`last_5_${currentTab.slice(0, -1)}s`}
            control={<Customradio />}
            label={`Last 5 ${currentTab.slice(0, -1)}s`}
          />
          <CustomFormControlLabel
            value={`next_3_${currentTab.slice(0, -1)}s`}
            control={<Customradio />}
            label={`Next 3 ${currentTab.slice(0, -1)}s`}
          />
        </CustomRadioGroup>
      </div>
    </div>
  )
}

export const DateRangeSelector = ({ updateRange, range }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState('years')
  const [radioOptionSelected, setRadioOptionSelected] = useState('')

  useEffect(() => {
    if (currentTab) {
      setRadioOptionSelected(`prev_${currentTab.slice(0, -1)}`)
    }
  }, [currentTab])

  useEffect(() => {
    const dateRange = getDateRange(currentTab, radioOptionSelected)
    updateRange(dateRange)
  }, [radioOptionSelected, currentTab])

  return (
    <div
      css={dropdownContainer}
      style={{ padding: '3px', width: '170px', height: '28px', marginTop: 5 }}
    >
      <div onClick={() => setIsOpen(prev => !prev)} css={displayValueStyle}>
        <span
          style={{
            fontSize: '12px',
            lineHeight: '1.8',
            textTransform: 'capitalize'
          }}
        >
          {radioOptionSelected.split('_').join(' ') || 'Last 5 quarters'}
        </span>
        <img src={DropdownIcon} style={{ width: 12, height: 12 }} />
      </div>
      <div
        className={`dropdownMenu ${isOpen ? 'open' : ''}`}
        style={{ top: '28px', width: 442 }}
      >
        <Tabs
          onTabSelection={key => setCurrentTab(key)}
          currentTab={currentTab}
          radioOptionSelected={radioOptionSelected}
          onRadioSelectionChange={value => {
            setRadioOptionSelected(value)
            setIsOpen(false)
          }}
        />
        <hr style={{ borderWidth: 0.8 }} />
        <div style={{ color: '#333' }}>{range}</div>
      </div>
    </div>
  )
}

const getSummaryByTimeFrame = () => [
  {
    timeframe: '2023 Q1',
    impressions: faker.datatype.number(),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }),
    cost: faker.datatype.number(),
    leads: faker.datatype.number(),
    cp_lead: faker.datatype.number(),
    primed_leads: faker.datatype.number(),
    cp_primed_lead: faker.datatype.number()
  },
  {
    timeframe: '2023 Q2',
    impressions: faker.datatype.number(),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }),
    cost: faker.datatype.number(),
    leads: faker.datatype.number(),
    cp_lead: faker.datatype.number(),
    primed_leads: faker.datatype.number(),
    cp_primed_lead: faker.datatype.number()
  },
  {
    timeframe: '2023 Q3',
    impressions: faker.datatype.number(),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }),
    cost: faker.datatype.number(),
    leads: faker.datatype.number(),
    cp_lead: faker.datatype.number(),
    primed_leads: faker.datatype.number(),
    cp_primed_lead: faker.datatype.number()
  },
  {
    timeframe: '2023 Q4',
    impressions: faker.datatype.number(),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }),
    cost: faker.datatype.number(),
    leads: faker.datatype.number(),
    cp_lead: faker.datatype.number(),
    primed_leads: faker.datatype.number(),
    cp_primed_lead: faker.datatype.number()
  },
  {
    timeframe: '2024 Q1',
    impressions: faker.datatype.number(),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }),
    cost: faker.datatype.number(),
    leads: faker.datatype.number(),
    cp_lead: faker.datatype.number(),
    primed_leads: faker.datatype.number(),
    cp_primed_lead: faker.datatype.number()
  }
]

const getSummaryByChannel = () => [
  {
    channel: 'Direct Media',
    impressions: numberWithCommas(faker.datatype.number()),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
    cost: `$${numberWithCommas(faker.datatype.number())}`,
    leads: numberWithCommas(faker.datatype.number()),
    cp_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    primed_leads: numberWithCommas(faker.datatype.number()),
    cp_primed_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`
  },
  {
    channel: 'Paid Search',
    impressions: numberWithCommas(faker.datatype.number()),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
    cost: `$${numberWithCommas(faker.datatype.number())}`,
    leads: numberWithCommas(faker.datatype.number()),
    cp_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    primed_leads: numberWithCommas(faker.datatype.number()),
    cp_primed_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`
  },
  {
    channel: 'Paid Social',
    impressions: numberWithCommas(faker.datatype.number()),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
    cost: `$${numberWithCommas(faker.datatype.number())}`,
    leads: numberWithCommas(faker.datatype.number()),
    cp_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    primed_leads: numberWithCommas(faker.datatype.number()),
    cp_primed_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    segregatedData: [
      {
        channel: 'Facebook',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'LinkedIn',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'Xing',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'Wechat',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'Twitter',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      }
    ]
  },
  {
    channel: 'Programmatic',
    impressions: numberWithCommas(faker.datatype.number()),
    ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
    cost: `$${numberWithCommas(faker.datatype.number())}`,
    leads: numberWithCommas(faker.datatype.number()),
    cp_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    primed_leads: numberWithCommas(faker.datatype.number()),
    cp_primed_lead: `$${numberWithCommas(
      faker.datatype.number({ min: 1, max: 200 })
    )}`,
    segregatedData: [
      {
        channel: 'google display',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'dbm',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      },
      {
        channel: 'trade desk',
        impressions: numberWithCommas(faker.datatype.number()),
        ctr: faker.datatype.float({ min: 0, max: 1, precision: 0.01 }) + '%',
        cost: `$${numberWithCommas(faker.datatype.number())}`,
        leads: numberWithCommas(faker.datatype.number()),
        cp_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`,
        primed_leads: numberWithCommas(faker.datatype.number()),
        cp_primed_lead: `$${numberWithCommas(
          faker.datatype.number({ min: 1, max: 200 })
        )}`
      }
    ]
  }
]

const GraphCard = ({ showAnalyticsGraphs }) => {
  const [hoveredCell, setHoveredCell] = useState(null)
  const cellHighlightClassRules = {
    'highlight-cell': params =>
      params.node.rowIndex + '-' + params.colDef.field === hoveredCell
  }
  const [tableColumns] = useState([
    { headerComponent: () => <></>, field: 'timeframe', width: 115 },
    {
      headerName: 'Impressions',
      field: 'impressions',
      width: 140,
      valueGetter: ({ data }) => {
        const { impressions } = data
        return numberWithCommas(impressions)
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CTR',
      field: 'ctr',
      width: 90,
      valueGetter: ({ data }) => {
        const { ctr } = data
        return ctr + '%'
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Cost',
      field: 'cost',
      width: 90,
      valueGetter: ({ data }) => {
        const { cost } = data
        return '$' + numberWithCommas(cost)
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Leads',
      field: 'leads',
      width: 90,
      valueGetter: ({ data }) => {
        const { impressions } = data
        return numberWithCommas(impressions)
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CP Lead',
      field: 'cp_lead',
      width: 100,
      valueGetter: ({ data }) => {
        const { cost } = data
        return '$' + numberWithCommas(cost)
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Primed Leads',
      field: 'primed_leads',
      width: 150,
      valueGetter: ({ data }) => {
        const { impressions } = data
        return numberWithCommas(impressions)
      },
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CP Primed Lead',
      field: 'cp_primed_lead',
      width: 150,
      valueGetter: ({ data }) => {
        const { cost } = data
        return '$' + numberWithCommas(cost)
      },
      cellClassRules: cellHighlightClassRules
    }
  ])
  const [byChannelTableColumns] = useState([
    { headerComponent: () => <></>, field: 'channel', width: 115 },
    {
      headerName: 'Impressions',
      field: 'impressions',
      width: 140,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CTR',
      field: 'ctr',
      width: 90,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Cost',
      field: 'cost',
      width: 90,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Leads',
      field: 'leads',
      width: 90,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CP Lead',
      field: 'cp_lead',
      width: 90,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'Primed Leads',
      field: 'primed_leads',
      width: 150,
      cellClassRules: cellHighlightClassRules
    },
    {
      headerName: 'CP Primed Lead',
      field: 'cp_primed_lead',
      width: 150,
      cellClassRules: cellHighlightClassRules
    }
  ])

  const [summaryByTimeFrame, setSummaryByTimeFrame] = useState(
    getSummaryByTimeFrame()
  )

  const [summaryByChannel, setSummaryByChannel] = useState(
    getSummaryByChannel()
  )
  const [cummulativeData, setCumulativeData] = useState({})

  const DataFormater = number => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + 'B'
    } else if (number > 1000000) {
      return (number / 1000000).toString() + 'M'
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K'
    } else {
      return number.toString()
    }
  }

  const getValue = type => {
    let label = ''
    switch (type) {
      case 'ctr':
        label = 'CTR'
        break
      case 'impressions':
        label = 'Impressions'
        break
      case 'leads':
        label = 'Leads'
        break
      case 'cp_lead':
        label = 'CP Lead'
        break
      case 'cost':
        label = 'Cost'
        break
      case 'primed_leads':
        label = 'Primed Leads'
        break
      case 'cp_primed_leads':
        label = 'CP Primed Leads'
        break
      case 'cp_primed_lead':
        label = 'CP Primed Lead'
        break
    }
    return label
  }

  const [range, setRange] = useState('')

  const getCummulativeData = (data = []) => {
    let cummulativeData = {
      impressions: 0,
      ctr: 0,
      cost: 0,
      leads: 0,
      cp_lead: 0,
      primed_leads: 0,
      cp_primed_lead: 0
    }
    data.forEach(
      ({
        impressions,
        ctr,
        cost,
        leads,
        cp_lead,
        primed_leads,
        cp_primed_lead
      }) => {
        cummulativeData['impressions'] += impressions
        cummulativeData['ctr'] += ctr
        cummulativeData['cost'] += cost
        cummulativeData['leads'] += leads
        cummulativeData['cp_lead'] += cp_lead
        cummulativeData['primed_leads'] += primed_leads
        cummulativeData['cp_primed_lead'] += cp_primed_lead
      }
    )
    cummulativeData['ctr'] = (cummulativeData['ctr'] / data.length).toFixed(2)
    return cummulativeData
  }

  useEffect(() => {
    const summary = getSummaryByTimeFrame()
    const cummulativeDataMock = getCummulativeData(summary)
    const channelSummary = getSummaryByChannel()
    setSummaryByTimeFrame(summary)
    setCumulativeData(cummulativeDataMock)
    setSummaryByChannel(channelSummary)
  }, [range])

  const updateRange = value => {
    setRange(value)
  }

  return (
    <div css={graphContainer(showAnalyticsGraphs)}>
      <Card style={{ flex: 1 }}>
        <div style={{ display: 'flex' }}>
          <div>
            <p css={labelStyle}>Date Range</p>
            <DateRangeSelector updateRange={updateRange} range={range} />
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              marginLeft: 30,
              alignItems: 'center'
            }}
          >
            {Object.keys(cummulativeData).map((key, index) => {
              let label = getValue(key)
              let preAppend = ''
              let postAppend = ''
              if (['cost', 'cp_lead', 'cp_primed_lead'].includes(key)) {
                preAppend = '$'
              }
              if (key === 'ctr') {
                postAppend = '%'
              }
              return (
                <div
                  key={index}
                  style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                      color: '#333333',
                      fontWeight: 500,
                      fontSize: 12
                    }}
                  >
                    {label}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      marginTop: 5,
                      border: '0.8px solid #ccc',
                      borderRight: 0,
                      borderLeft: 0,
                      textAlign: 'center',
                      fontSize: 12
                    }}
                  >
                    {preAppend +
                      numberWithCommas(cummulativeData[key]) +
                      postAppend}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <p style={{ margin: '10px 0 2px 0', fontSize: 12 }}>
            <span style={{ fontWeight: 500 }}>Summary - By Quarter </span>
            <span>(click to filter results)</span>
          </p>
          <SummaryTable
            data={summaryByTimeFrame}
            columns={tableColumns}
            setHoveredCell={setHoveredCell}
            type={TABLE_TYPE.BY_TIMEFRAME}
          />
        </div>
        <div>
          <p style={{ margin: '50px 0 2px 0', fontSize: 12 }}>
            <span style={{ fontWeight: 500 }}>Summary - By Channel </span>
            <span>(click to filter results)</span>
          </p>
          <SummaryTable
            data={summaryByChannel}
            setHoveredCell={setHoveredCell}
            columns={byChannelTableColumns}
            type={TABLE_TYPE.BY_CHANNEL}
          />
        </div>
        <div style={{ width: '100%', marginTop: 20 }}>
          <div style={{ width: 930, height: 120 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart
                className='chart-data'
                data={summaryByTimeFrame}
                style={{ fontSize: 10 }}
                margin={{ marginBottom: -20 }}
              >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='timeframe' />
                <YAxis
                  yAxisId='left'
                  dataKey='impressions'
                  tickFormatter={DataFormater}
                  label={{
                    value: 'Impressions',
                    angle: -90,
                    position: 'insideBottomLeft',
                    offset: 10
                  }}
                />
                <YAxis
                  dataKey='ctr'
                  yAxisId='right'
                  orientation='right'
                  label={{
                    value: 'CTR',
                    angle: -90,
                    position: 'insideRight',
                    offset: 10
                  }}
                />
                <Tooltip />
                <Legend
                  iconType='square'
                  layout='vertical'
                  align='right'
                  verticalAlign='top'
                  content={({ payload }) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {payload.map(({ value, color }, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              lineHeight: 1,
                              marginTop: 3
                            }}
                          >
                            <span
                              style={{
                                width: 10,
                                height: 10,
                                background: color,
                                display: 'inline-block',
                                marginRight: 5
                              }}
                            />
                            <span
                              style={{ display: 'inline-block', maxWidth: 54 }}
                            >
                              {getValue(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )
                  }}
                />

                <Bar
                  dataKey='impressions'
                  yAxisId='left'
                  barSize={20}
                  fill='#cecb76'
                />
                <Line
                  yAxisId='right'
                  type='monotone'
                  dataKey='ctr'
                  stroke='#9d9b3d'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: 930, height: 120 }}>
            <ResponsiveContainer width='98.5%' height='100%'>
              <ComposedChart
                className='chart-data'
                data={summaryByTimeFrame}
                style={{ fontSize: 10 }}
                margin={{ marginBottom: -20 }}
              >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='timeframe' />
                <YAxis
                  yAxisId='left'
                  dataKey='leads'
                  tickFormatter={DataFormater}
                  label={{
                    value: 'Leads',
                    angle: -90,
                    position: 'insideBottomLeft',
                    offset: 10
                  }}
                />
                <YAxis
                  dataKey='cp_lead'
                  yAxisId='right'
                  tickFormatter={DataFormater}
                  orientation='right'
                  label={{
                    value: 'CP Lead',
                    angle: -90,
                    position: 'right',
                    offset: -10
                  }}
                />
                <Tooltip />
                <Legend
                  layout='vertical'
                  align='right'
                  verticalAlign='top'
                  iconType='square'
                  content={({ payload }) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {payload.map(({ value, color }, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              lineHeight: 1,
                              marginTop: 3
                            }}
                          >
                            <span
                              style={{
                                width: 10,
                                height: 10,
                                background: color,
                                display: 'inline-block',
                                marginRight: 5
                              }}
                            />
                            <span
                              style={{ display: 'inline-block', maxWidth: 54 }}
                            >
                              {getValue(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )
                  }}
                />

                <Bar
                  dataKey='leads'
                  yAxisId='left'
                  barSize={20}
                  fill='#e1575a'
                />
                <Scatter
                  shape='square'
                  yAxisId='right'
                  dataKey='cp_lead'
                  fill='#90728f'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: 940, height: 120, marginLeft: -3 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart
                className='chart-data'
                data={summaryByTimeFrame}
                style={{ fontSize: 10 }}
              >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='timeframe' />
                <YAxis
                  yAxisId='left'
                  dataKey='primed_leads'
                  tickFormatter={DataFormater}
                  label={{
                    value: 'Primed Leads',
                    angle: -90,
                    position: 'insideBottomLeft',
                    offset: 10
                  }}
                />
                <YAxis
                  dataKey='cp_primed_lead'
                  yAxisId='right'
                  tickFormatter={DataFormater}
                  orientation='right'
                  label={{
                    value: 'CP Primed Lead',
                    angle: -90,
                    position: 'insideTopRight',
                    offset: 15
                  }}
                />
                <Tooltip />
                <Legend
                  layout='vertical'
                  align='right'
                  verticalAlign='top'
                  iconType='square'
                  content={({ payload }) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {payload.map(({ value, color }, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              lineHeight: 1,
                              marginTop: 3
                            }}
                          >
                            <span
                              style={{
                                width: 10,
                                height: 10,
                                background: color,
                                display: 'inline-block',
                                marginRight: 5
                              }}
                            />
                            <span
                              style={{ display: 'inline-block', maxWidth: 54 }}
                            >
                              {getValue(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )
                  }}
                />

                <Bar
                  dataKey='primed_leads'
                  yAxisId='left'
                  barSize={20}
                  fill='#ff9888'
                />
                <Scatter
                  shape='cross'
                  yAxisId='right'
                  dataKey='cp_primed_lead'
                  fill='#b9a0b4'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>
      <div
        style={{
          width: 316,
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0
        }}
      >
        {/* <PacingCard /> */}
        <DeliveryReportCard />
        <KPIPacing range={range} data={summaryByTimeFrame} />
      </div>
    </div>
  )
}

export default GraphCard
