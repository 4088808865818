/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentEditingComponent } from '../../../../../../../store/dashboard/mailerSlice'
import { editableButtonContainer } from './style'

const Button = ({
  style: { justifyContent } = {},
  parentPath,
  componentPath,
  parentUid
}) => {
  const dispatch = useDispatch()
  const {
    currentEditingComponent: { path: editingPath } = {},
    editingComponents,
    currentEditedSegmentandAudience,
    btnStyles
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        editingComponents,
        currentEditedSegmentandAudience,
        btnStyles
      }
    }) => ({
      currentEditingComponent,
      editingComponents,
      currentEditedSegmentandAudience,
      btnStyles
    })
  )

  const onButtonElementSelect = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'button',
        parentUid
      })
    )
  }

  const getBtnObj = () => {
    let btnObj = {
      alignment: justifyContent || '',
      text: '',
      btnStyle: 'Primary button'
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        btnObj = { ...btnObj, ...(editingAudience[path] || {}) }
      }
    }
    return btnObj
  }

  return (
    <div
      css={[
        editableButtonContainer(
          `${parentPath}->${componentPath}` === editingPath
        )
      ]}
      onClick={event => {
        event.stopPropagation()
        onButtonElementSelect()
      }}
      style={{
        padding: '12px',
        display: 'flex',
        maxWidth: '100%',
        justifyContent:
          getBtnObj()['alignment'] && getBtnObj()['alignment'] != 'full'
            ? getBtnObj()['alignment']
            : 'center'
      }}
    >
      <button
        style={{
          backgroundColor: 'rgb(0,0,0)',
          borderRadius: '0px',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          color: 'rgb(255, 255, 255)',
          position: 'relative',
          display: 'inline',
          lineHeight: '1.2',
          padding: '16px',
          maxWidth: '100%',
          outline: 'none',
          ...(getBtnObj()['alignment'] == 'full' ? { width: '100%' } : {}),
          ...btnStyles[getBtnObj()['btnStyle']]
        }}
      >
        {getBtnObj()['text'] || 'Shop now'}
      </button>
    </div>
  )
}

export default Button
