/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'

import { container } from './style'

const QuickAddItem = ({ data, monitorDragging = () => {} }) => {
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'QUICK_ADD',
    item: data,
    collect: monitor => {
      return {
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging()
      }
    }
  })
  useEffect(() => {
    monitorDragging(isDragging)
  }, [isDragging])

  return (
    <div css={container} ref={drag} style={{ opacity }}>
      {data.component.type}
    </div>
  )
}
export default QuickAddItem
