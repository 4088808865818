/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Line, XAxis, YAxis, LineChart } from 'recharts'
import {
  kpiPacingGraph,
  kpiHeaderStyle,
  dropdownContainer,
  displayValueStyle
} from './style'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import { faker } from '@faker-js/faker'

const getChartData = (range, summaryByTimeFrame = []) => {
  let costTotal = 0,
    impressionsTotal = 0,
    clicksTotal = 0,
    leadsTotal = 0,
    primedLeadsTotal = 0

  clicksTotal = faker.datatype.number()

  summaryByTimeFrame.forEach(({ impressions, cost, leads, primed_leads }) => {
    costTotal += cost
    impressionsTotal += impressions
    leadsTotal += leads
    primedLeadsTotal += primed_leads
  })

  let data = {
    Cost: [
      {
        dayOfYear: 0,
        value: 0
      },
      {
        dayOfYear: 120,
        value: costTotal
      }
    ],
    Impressions: [
      {
        dayOfYear: 0,
        value: 0
      },
      {
        dayOfYear: 120,
        value: impressionsTotal
      }
    ],
    Clicks: [
      {
        dayOfYear: 0,
        value: 0
      },
      {
        dayOfYear: 120,
        value: clicksTotal
      }
    ],
    Leads: [
      {
        dayOfYear: 0,
        value: 0
      },
      {
        dayOfYear: 120,
        value: leadsTotal
      }
    ],
    'Primed Leads': [
      {
        dayOfYear: 0,
        value: 0
      },
      {
        dayOfYear: 120,
        value: primedLeadsTotal
      }
    ]
  }

  if (range === '01/01/2021 - 12/31/2021') {
    // previous year
    data = {
      Cost: [
        {
          dayOfYear: 0,
          value: 0
        },
        {
          dayOfYear: 364,
          value: costTotal
        }
      ],
      Impressions: [
        {
          dayOfYear: 0,
          value: 0
        },
        {
          dayOfYear: 364,
          value: impressionsTotal
        }
      ],
      Clicks: [
        {
          dayOfYear: 0,
          value: 0
        },
        {
          dayOfYear: 364,
          value: clicksTotal
        }
      ],
      Leads: [
        {
          dayOfYear: 0,
          value: 0
        },
        {
          dayOfYear: 364,
          value: leadsTotal
        }
      ],
      'Primed Leads': [
        {
          dayOfYear: 0,
          value: 0
        },
        {
          dayOfYear: 364,
          value: primedLeadsTotal
        }
      ]
    }
  }

  if (range === '01/01/2017 - 12/31/2021') {
    // last 5 year
    data = {
      Cost: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0,
          value3: 0,
          value4: 0
        },
        {
          dayOfYear: 364,
          value: costTotal,
          value2: faker.datatype.number(),
          value3: faker.datatype.number(),
          value4: faker.datatype.number()
        }
      ],
      Impressions: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0,
          value3: 0,
          value4: 0
        },
        {
          dayOfYear: 364,
          value: impressionsTotal,
          value2: faker.datatype.number(),
          value3: faker.datatype.number(),
          value4: faker.datatype.number()
        }
      ],
      Clicks: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0,
          value3: 0,
          value4: 0
        },
        {
          dayOfYear: 364,
          value: clicksTotal,
          value2: faker.datatype.number(),
          value3: faker.datatype.number(),
          value4: faker.datatype.number()
        }
      ],
      Leads: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0,
          value3: 0,
          value4: 0
        },
        {
          dayOfYear: 364,
          value: leadsTotal,
          value2: faker.datatype.number(),
          value3: faker.datatype.number(),
          value4: faker.datatype.number()
        }
      ],
      'Primed Leads': [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0,
          value3: 0,
          value4: 0
        },
        {
          dayOfYear: 364,
          value: primedLeadsTotal,
          value2: faker.datatype.number(),
          value3: faker.datatype.number(),
          value4: faker.datatype.number()
        }
      ]
    }
  }
  if (range === '07/01/2022 - 09/30/2022') {
    // previous Quarter
    data = {
      Cost: [
        {
          dayOfYear: 180,
          value: 0
        },
        { dayOfYear: 270, value: costTotal }
      ],
      Impressions: [
        {
          dayOfYear: 180,
          value: 0
        },
        { dayOfYear: 270, value: impressionsTotal }
      ],
      Clicks: [
        {
          dayOfYear: 180,
          value: 0
        },
        { dayOfYear: 270, value: clicksTotal }
      ],
      Leads: [
        {
          dayOfYear: 180,
          value: 0
        },
        { dayOfYear: 270, value: leadsTotal }
      ],
      'Primed Leads': [
        {
          dayOfYear: 180,
          value: 0
        },
        { dayOfYear: 270, value: primedLeadsTotal }
      ]
    }
  }

  if (range === '07/01/2021 - 09/30/2022') {
    //last 5 quarter
    data = {
      Cost: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0
        },
        { dayOfYear: 365, value: costTotal },
        { dayOfYear: 90, value2: faker.datatype.number() }
      ],
      Impressions: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0
        },
        { dayOfYear: 365, value: impressionsTotal },
        {
          dayOfYear: 90,
          value2: faker.datatype.number()
        }
      ],
      Clicks: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0
        },
        { dayOfYear: 365, value: clicksTotal },
        { dayOfYear: 90, value2: faker.datatype.number() }
      ],
      Leads: [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0
        },
        { dayOfYear: 365, value: leadsTotal },
        { dayOfYear: 90, value2: faker.datatype.number() }
      ],
      'Primed Leads': [
        {
          dayOfYear: 0,
          value: 0,
          value2: 0
        },
        { dayOfYear: 365, value: primedLeadsTotal },
        { dayOfYear: 90, value2: faker.datatype.number() }
      ]
    }
  }
  return data
}

export const KpiPacingDropdown = ({ selectedValue, setSelectedValue }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div css={dropdownContainer} style={{ width: 128, padding: 2, height: 24 }}>
      <div
        onClick={() => setIsOpen(prev => !prev)}
        css={displayValueStyle}
        style={{ fontSize: 12, justifyContent: 'space-between' }}
      >
        <div style={{ flex: 1 }}> {selectedValue || ''}</div>
        <img
          src={DropdownIcon}
          style={{ width: 10, height: 10, marginRight: 5 }}
        />
      </div>
      <div
        className={`dropdownMenu ${isOpen ? 'open' : ''}`}
        style={{ top: 24, width: 128 }}
      >
        <ul>
          <li
            style={{ textAlign: 'left', fontSize: 12, padding: 2 }}
            onClick={() => {
              setSelectedValue('Cost')
              setIsOpen(false)
            }}
          >
            Cost
          </li>
          <li
            style={{ textAlign: 'left', fontSize: 12, padding: 2 }}
            onClick={() => {
              setSelectedValue('Impressions')
              setIsOpen(false)
            }}
          >
            Impressions
          </li>
          <li
            style={{ textAlign: 'left', fontSize: 12, padding: 2 }}
            onClick={() => {
              setSelectedValue('Clicks')
              setIsOpen(false)
            }}
          >
            Clicks
          </li>
          <li
            style={{ textAlign: 'left', fontSize: 12, padding: 2 }}
            onClick={() => {
              setSelectedValue('Leads')
              setIsOpen(false)
            }}
          >
            Leads
          </li>
          <li
            style={{ textAlign: 'left', fontSize: 12, padding: 2 }}
            onClick={() => {
              setSelectedValue('Primed Leads')
              setIsOpen(false)
            }}
          >
            Primed Leads
          </li>
        </ul>
      </div>
    </div>
  )
}

const KPIPacing = ({ range, data }) => {
  const KpiPacingChart = [
    {
      dayOfYear: 265,
      value: 0,
      value2: 0
    },
    { dayOfYear: 345, value: 2000 }
  ]

  const [chartData, setChartData] = useState([])
  const [kpiPacingDropdownValue, setKpiPacingDropdownValue] = useState(
    'Impressions'
  )

  useEffect(() => {
    const mockData = (getChartData(range, data) || [])[kpiPacingDropdownValue]
    setChartData(mockData)
  }, [range, kpiPacingDropdownValue, data])

  return (
    <div css={kpiPacingGraph}>
      <div css={kpiHeaderStyle}>
        <p className='heading'>KPI Pacing</p>
        <p className='subheading'>
          {kpiPacingDropdownValue} by day of year(running total)
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 8
        }}
      >
        <KpiPacingDropdown
          selectedValue={kpiPacingDropdownValue}
          setSelectedValue={setKpiPacingDropdownValue}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            marginLeft: 20
          }}
        >
          <span
            style={{
              width: 0,
              height: 0,
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderBottom: '10px solid',
              marginRight: 10
            }}
          />
          28.7% YoY, YtD
        </div>
      </div>
      <LineChart
        width={315}
        height={550}
        data={chartData}
        style={{ fontSize: 10, marginTop: 20, marginLeft: -10 }}
      >
        <XAxis
          type='number'
          dataKey='dayOfYear'
          ticks={[0, 50, 100, 150, 200, 250, 300, 350]}
          label={{ value: 'Day of year', position: 'insideBottom' }}
        />
        <YAxis dataKey='value' />
        <Line type='monotone' dataKey='value' stroke='#ccc' />
        <Line type='monotone' dataKey='value2' stroke='red' strokeWidth={2} />
        <Line type='natural' dataKey='value3' stroke='green' strokeWidth={2} />
        <Line type='monotone' dataKey='value4' stroke='blue' strokeWidth={2} />
      </LineChart>
    </div>
  )
}

export default KPIPacing
