import facebookIcon from '../assets/images/dashboard/ads/facebookIcon.png'
import instagramIcon from '../assets/images/dashboard/ads/instagramIcon.png'
import googleIcon from '../assets/images/dashboard/ads/googleIcon.png'
import linkedinIcon from '../assets/images/dashboard/ads/linkedinIcon.png'

export const channelSourceList = [
  {
    key: 'facebook',
    className: 'facebookIcon',
    alt: 'facebookIcon',
    src: facebookIcon
  },
  {
    key: 'instagram',
    className: 'instagramIcon',
    alt: 'instagramIcon',
    src: instagramIcon
  },
  {
    key: 'google',
    className: 'googleIcon',
    alt: 'googleIcon',
    src: googleIcon
  },
  {
    key: 'linkedin',
    className: 'linkedinIcon',
    alt: 'linkedinIcon',
    src: linkedinIcon
  }
]

export const campaignDetailsMock = {
  budget: '',
  facebookBudget: '',
  instagramBudget: '',
  googleBudget: '',
  linkedinBudget: '',
  spreadBudgetAcrossChannels: false,
  text: '',
  headline: '',
  newsFeedLinkDescription: '',
  displayLink: '',
  callToAction: '',
  startDate: '',
  endDate: '',
  campaignName: '',
  objective: ''
}
