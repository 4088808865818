/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import Portal from '../../../../../../../customHooks/portal'
import {
  panelTitleStyle,
  backBtnStyle,
  backgroundColorContainerStyle,
  labelStyle,
  fieldStyle,
  inputStyle,
  colorBoxStyle
} from './style'

const Colors = ({
  toggleColorsMenu = () => {},
  updateEmailSettings,
  backgroundColor
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })
  const colorRef = useRef(null)
  const colorPickerRef = useRef(null)

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  useEffect(() => {
    const clickedOutside = event => {
      if (
        showColorPicker &&
        colorPickerRef.current &&
        !colorRef.current.contains(event.target) &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [colorPickerRef, showColorPicker, colorRef])

  const onColorSelect = color => {
    updateEmailSettings('backgroundColor', color.hex)
  }
  return (
    <>
      <div css={panelTitleStyle}>
        <button css={backBtnStyle} onClick={toggleColorsMenu}>
          <div
            style={{
              width: '16px',
              height: '16px',
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center'
            }}
          >
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '16px', height: '16px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
        </button>
        Colors
      </div>
      <div css={backgroundColorContainerStyle}>
        <label css={labelStyle}>Background color</label>
        <div css={fieldStyle} ref={colorRef}>
          <input
            css={inputStyle}
            type='text'
            value={backgroundColor}
            onChange={({ target: { value } }) =>
              updateEmailSettings('backgroundColor', value)
            }
          />
          <span
            css={colorBoxStyle}
            style={{ backgroundColor }}
            onClick={() => {
              setShowColorPicker(!showColorPicker)
              updateColorCoordinate(colorRef.current)
            }}
          />
        </div>
        {showColorPicker && (
          <Portal>
            <div
              style={{ ...colorCoords, position: 'absolute' }}
              ref={colorPickerRef}
            >
              <SketchPicker
                color={backgroundColor}
                onChangeComplete={onColorSelect}
              />
            </div>
          </Portal>
        )}
      </div>
    </>
  )
}

export default Colors
