import { css } from '@emotion/react/macro'

export const dropdownMenu = css`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  position: absolute;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
  width: inherit; /* Ensure the menu width matches the dropdown container */
`

export const blanket = css`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed; /* Corrected to fixed without quotes */
  z-index: 1;
`

export const dropdownSearch = css`
  position: relative;
  max-width: 200px;
  background: #fff;
  min-width: 150px;
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 5px;
  padding: 4px 17px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const optionsPanel = css`
  width: 100%;
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
`

export const chevron = css`
  margin-top: 3px;
  display: inline-block;
  transform: rotate(180deg);
  width: 12px;
  height: 12px;
  img {
    width: inherit;
    height: inherit;
  }
`

export const button = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
`
