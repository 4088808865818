/** @jsxImportSource @emotion/react */
import React from 'react'

import SideDrawer from '../SideDrawer'
import OnboardingDashboardHeader from '../../ui/Header/OnBoardingDashboardHeader/Header'

import { headerSection } from './styles'

const Header = ({ logOutHandler = () => {} }) => {
  return (
    <section id='header' css={headerSection}>
      <SideDrawer section={'header'} />
      <OnboardingDashboardHeader logOutHandler={logOutHandler} />
    </section>
  )
}

export default Header
