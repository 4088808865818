/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { overlayStyle, contentContainer, crossIconStyle } from './style'

const ESCAPE = 27

const Modal = ({ showCross = true, children, style, onClose = () => {} }) => {
  const [showModal, setShowModal] = useState(true)
  let content = null
  const modalElement = document.getElementById('modal-root')
  const close = () => {
    setShowModal(false)
    onClose()
  }

  useEffect(() => {
    document.addEventListener('keyup', e => {
      e.keyCode === ESCAPE && close()
    })
    return () => {
      document.removeEventListener('keyup', () => {})
    }
  })
  if (showModal) {
    content = (
      <div css={overlayStyle}>
        <div css={[contentContainer, style]}>
          {showCross && (
            <div css={crossIconStyle}>
              <span onClick={() => close()}>x</span>
            </div>
          )}
          {children}
        </div>
      </div>
    )
  }
  return modalElement ? createPortal(content, modalElement) : null
}

export default Modal
