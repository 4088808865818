import { css } from '@emotion/react'

const setDisplayAdForm = () => css`
  background-color: rgba(204, 204, 204, 0.4);
  border: 4px dashed #cccccc;
  border-radius: 4px;
  padding: 16px 24px 16px 21px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  .plusIcon {
    color: black;
  }
  img {
    display: block;
  }
`

export const addAudienceContainer = displayForm => css`
  box-sizing: border-box;
  width: 231.4px;
  min-width: 231.4px;
  margin: 0 auto;
  position: relative;
  border-radius: 4px;
  height: 86px;
  padding: 13px 24px 20px 21px;
  padding: 16px 24px 16px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  .plusIcon {
    color: transparent;
    transition: color 0.5s ease-out;
  }
  img {
    display: none;
  }
  @media (min-width: 1441px) {
    margin: 12px 24px;
    margin-bottom: 0;
  }
  ${displayForm && setDisplayAdForm()}
`

export const addAudienceDummyContainer = css`
  box-sizing: border-box;
  width: 231.4px;
  min-width: 231.4px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  padding: 13px 24px 20px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media (min-width: 1441px) {
    margin: 12px 24px;
    margin-bottom: 0;
  }
`
