/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import SelectField from '../../../../../../ui/SelectFormField'
import { useSelector, useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import Portal from '../../../../../../../customHooks/portal'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  textEditorContainer,
  labelStyle,
  textColorContainerStyle,
  fieldStyle,
  inputStyle,
  colorBoxStyle,
  personalizationTagWrapper
} from './style'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const PersonalizationTagDropdown = ({ options, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = value => {
    onSelect(value)
    handleClose()
  }

  return (
    <div css={personalizationTagWrapper}>
      <IconButton
        aria-label='personalization-tag'
        onClick={handleClick}
        style={{
          borderRadius: '50%',
          overflow: 'hidden',
          fontWeight: 700,
          fontSize: '14px'
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '40px',
            height: '40px',
            alignItems: 'center'
          }}
        >
          &#123;&#8230;&#125;
          <ExpandMoreIcon />
        </div>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => handleMenuItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const textStyles = {
  'Heading Large': { size: '36px', lineHeight: '1.25' },
  'Heading Medium': { size: '30px', lineHeight: '1.25' },
  'Heading Small': { size: '24px', lineHeight: '1.25' },
  Paragraph: { size: '14px', lineHeight: '1.5' },
  Footnote: { size: '12px', lineHeight: '1.5' }
}

const TextEditor = ({ style, textStyle = 'Paragraph' }) => {
  const [editorObj, setEditorObj] = useState({
    textStyle,
    font: 'Arial',
    size: '14px',
    lineHeight: '1.5',
    color: '#000'
  })

  const [textColorPicker, setTextColorPicker] = useState(false)

  const textColorRef = useRef(null)
  const textColorPickerRef = useRef(null)

  const dispatch = useDispatch()

  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })

  const {
    currentEditingComponent,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates,
    personalizationTags
  } = useSelector(state => ({
    currentEditingComponent: state.mailer.currentEditingComponent,
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    editingComponents: state.mailer.editingComponents,
    emailTemplates: state.mailer.emailTemplates,
    personalizationTags: state.mailer.personalizationTags
  }))

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  const handleChange = (key, value) => {
    const newEditorObj = {
      ...editorObj,
      [key]: value,
      ...(key === 'textStyle' ? textStyles[value] : {})
    }

    setEditorObj({ ...newEditorObj })
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  const handleVariableSelect = selectedVariable => {
    const updatedContent = `[[${selectedVariable}]]`
    const newEditorObj = { ...editorObj, content: updatedContent }
    setEditorObj(newEditorObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
  }

  useEffect(() => {
    const clickedOutside = event => {
      if (
        textColorPicker &&
        textColorPickerRef.current &&
        !textColorRef.current.contains(event.target) &&
        !textColorPickerRef.current.contains(event.target)
      ) {
        setTextColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [textColorPickerRef, textColorPicker, textColorRef])

  useEffect(() => {
    let style = {
      textStyle,
      font: 'Arial',
      size: '14px',
      lineHeight: '1.5',
      color: '#000'
    }
    if (currentEditingComponent) {
      const { segmentId, audienceId } = currentEditedSegmentandAudience
      const editingSegment = editingComponents[segmentId]
      if (editingSegment) {
        const editingAudience = editingSegment[audienceId]
        if (editingAudience) {
          style = {
            ...style,
            ...(editingAudience[currentEditingComponent.path] || {})
          }
        }
      }
    }
    setEditorObj(JSON.parse(JSON.stringify(style)))
  }, [currentEditingComponent, editingComponents])

  return (
    <div css={[textEditorContainer, style]}>
      <div
        style={{
          marginBottom: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #e7e7e7'
        }}
      >
        <label css={labelStyle}>Text style</label>
        <SelectField
          name='textStyle'
          onChange={(item, name) => handleChange('textStyle', item.value)}
          value={editorObj['textStyle']}
          title='Text Style'
          width='100%'
          list={[
            { label: 'Heading Large', value: 'Heading Large' },
            { label: 'Heading Medium', value: 'Heading Medium' },
            { label: 'Heading Small', value: 'Heading Small' },
            { label: 'Paragraph', value: 'Paragraph' },
            { label: 'Footnote', value: 'Footnote' }
          ]}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginBottom: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #e7e7e7',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ flex: 2 }}>
          <label css={labelStyle}>Font</label>
          <SelectField
            name='font'
            onChange={(item, name) => handleChange('font', item.value)}
            value={editorObj['font']}
            title='Font'
            width='90%'
            list={[
              { label: 'Arial', value: 'Arial' },
              { label: 'Helvetica', value: 'Helvetica' },
              { label: 'Courier New', value: 'Courier New' },
              { label: 'Georgia', value: 'Georgia' },
              { label: 'Lucida', value: 'Lucida' }
            ]}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label css={labelStyle}>Size</label>
          <SelectField
            name='size'
            onChange={(item, name) => handleChange('size', item.value)}
            value={editorObj['size']}
            title='Font Size'
            width='100%'
            list={[
              { label: '8px', value: '8px' },
              { label: '9px', value: '9px' },
              { label: '10px', value: '10px' },
              { label: '11px', value: '11px' },
              { label: '12px', value: '12px' },
              { label: '14px', value: '14px' },
              { label: '16px', value: '16px' },
              { label: '18px', value: '18px' },
              { label: '20px', value: '20px' },
              { label: '22px', value: '22px' },
              { label: '24px', value: '24px' },
              { label: '26px', value: '26px' },
              { label: '28px', value: '28px' },
              { label: '30px', value: '30px' },
              { label: '36px', value: '36px' },
              { label: '48px', value: '48px' },
              { label: '60px', value: '60px' },
              { label: '72px', value: '72px' },
              { label: '90px', value: '90px' }
            ]}
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #e7e7e7'
        }}
      >
        <label css={labelStyle}>Line Height</label>
        <SelectField
          name='lineHeight'
          onChange={(item, name) => handleChange('lineHeight', item.value)}
          value={editorObj['lineHeight']}
          title='Line Height'
          width='100%'
          list={[
            { label: 'Single', value: 'Single' },
            { label: '1.15', value: '1.15' },
            { label: '1.25', value: '1.25' },
            { label: '1.5', value: '1.5' },
            { label: 'Double', value: '2' }
          ]}
        />
      </div>
      <div
        style={{
          marginBottom: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #e7e7e7'
        }}
      >
        <label css={labelStyle}>Unified IDs</label>
        <PersonalizationTagDropdown
          options={personalizationTags}
          selectedValue={editorObj}
          onSelect={handleVariableSelect}
        />
      </div>
      <div css={textColorContainerStyle}>
        <label css={labelStyle}>Text color</label>
        <div css={fieldStyle} ref={textColorRef}>
          <input
            css={inputStyle}
            type='text'
            value={editorObj.color}
            onChange={({ target: { value } }) => {
              handleChange('color', value)
            }}
          />
          <span
            css={colorBoxStyle}
            style={{ backgroundColor: editorObj.color }}
            onClick={() => {
              setTextColorPicker(!textColorPicker)
              updateColorCoordinate(textColorRef.current)
            }}
          />
        </div>
        {textColorPicker && (
          <Portal>
            <div
              style={{ ...colorCoords, position: 'absolute' }}
              ref={textColorPickerRef}
            >
              <SketchPicker
                color={editorObj.color}
                onChangeComplete={color => {
                  handleChange('color', color.hex)
                }}
              />
            </div>
          </Portal>
        )}
      </div>
    </div>
  )
}

export default TextEditor
