import { css } from '@emotion/react'

export const segmentSection = css`
  display: flex;
`
export const segmentForms = css`
  display: flex;
  margin: 18px 0px 18px 0px;
`

export const segmentContent = css`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  &[data-selected='false'] {
    opacity: 0.3;
    pointer-events:none;
  }
  img {
    margin-left: 4px;
  }
  .actionButton {
    
  }
  .stageDescription {
    display: flex;
    align-items: center;
    margin: 6px 0px 16px 0px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 9px;
  }
`

export const actionButton = css`
    box-sizing: border-box;
    margin-top: 10px;
    cursor: pointer;
    height: 50px;
    width: 341px;
    color: #FFFFFF;
    border: 0.8px solid #CCCCCC;
    background-color: #18A3E2;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0px rgba(102,153,204,0.4);
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 0;
    text-align: center;
`
