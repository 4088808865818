/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentEditingComponent } from '../../../../../../../store/dashboard/mailerSlice'
import { container, imageInfoStyle } from './style'

const Image = ({ parentPath, componentPath, filePath, parentUid }) => {
  const dispatch = useDispatch()
  const imageContainerRef = useRef(null)
  const {
    currentEditingComponent: { path: editingPath } = {},
    editingComponents,
    currentEditedSegmentandAudience
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        editingComponents,
        currentEditedSegmentandAudience
      }
    }) => ({
      currentEditingComponent,
      editingComponents,
      currentEditedSegmentandAudience
    })
  )
  const onImageElementSelect = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'image',
        parentUid
      })
    )
  }

  const getImageObj = () => {
    let imgObj = {
      file: filePath || '',
      altText: '',
      link: '',
      alignment: ''
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        imgObj = { ...imgObj, ...(editingAudience[path] || {}) }
      }
    }
    return imgObj
  }
  return (
    <div
      css={container(`${parentPath}->${componentPath}` === editingPath)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto'
      }}
    >
      <div
        style={{
          backgroundColor: !getImageObj()['file'] ? '#f5f5f5' : '',
          display: 'flex',
          alignItems: getImageObj()['alignment']
            ? getImageObj()['alignment']
            : 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: !getImageObj()['file'] ? '276px' : 'auto',
          padding: '12px'
        }}
        onClick={event => {
          event.stopPropagation()
          onImageElementSelect()
        }}
        ref={imageContainerRef}
      >
        {!getImageObj()['file'] && (
          <>
            <img
              src='https://app.omnisend.com/content-editor-app/assets/global/gc-image-upload-placeholder.svg'
              alt='img-placeholder'
              style={{ width: '24px', marginBottom: '24px', color: '#979797' }}
            />
            <div
              style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: '400',
                color: '#555c66'
              }}
            >
              Upload image
            </div>
            <div css={imageInfoStyle}>
              Recommended image width -{' '}
              {imageContainerRef.current &&
                imageContainerRef.current.getBoundingClientRect().width + ' px'}
            </div>
          </>
        )}
        {getImageObj()['file'] && (
          <>
            <img
              src={getImageObj()['file']}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
              alt={getImageObj()['altText']}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Image
