/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'

import { addAudienceContainer } from './style'

const AddAudienceForm = ({
  index,
  audienceId,
  addNewAudienceFormHandler = () => {}
}) => {
  const [displayAddAudienceForm, setDisplayAddAudienceForm] = useState(
    index === 0 ? true : false
  )

  const onMouseEnterHandler = () => {
    setDisplayAddAudienceForm(true)
  }

  const onMouseLeaveHandler = () => {
    setDisplayAddAudienceForm(false)
  }

  const onclickHandler = audienceId => {
    addNewAudienceFormHandler(audienceId)
  }

  return (
    <div style={{ flex: 1, flexShrink: 0 }}>
      <div
        css={addAudienceContainer(displayAddAudienceForm)}
        onClick={() => onclickHandler(audienceId)}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <Icon className='plusIcon'>add</Icon>
      </div>
    </div>
  )
}

export default AddAudienceForm
