import React, { useRef, useState } from 'react'
import useOnScreen from '../../../customHooks/useOnScreenCb'
/**
 *
 * @param {function} callback function to fire when intersection is observed
 * @param {string} offsetTop NOTE: should explicity contain 'px' or '%' like 10px or 10%
 * @param {string} offsetBottom NOTE: should explicity contain 'px' or '%' like 10px or 10%
 * @param {function} onEnter callback when element enters in viewport
 * @param {function} onLeave callback when element leaves from viewport
 * @param {boolean} fireOnceOnEnter {default true} onEnter will be called once
 * @param {boolean} fireOnceOnLeave {default !onLeave} if onLeave is given than this will be false
 */
export default function Waypoint (props) {
  const {
    children,
    offsetTop,
    offsetBottom,
    onEnter,
    onLeave,
    rootMargin,
    fireOnceOnEnter = true,
    fireOnceOnLeave = !onLeave,
    wpKey
  } = props
  const tracker = useRef({
    firedOnEnter: false,
    firedOnLeave: false,
    canLeave: false
  })
  const [disableComponent, setDisableComponent] = useState(false)

  const onChange = entry => {
    const { isIntersecting } = entry
    if (isIntersecting) {
      tracker.current.canLeave = true
    }
    const {
      current: { firedOnEnter, firedOnLeave, canLeave }
    } = tracker
    const onEnterFireCondition = isIntersecting && onEnter
    if (onEnterFireCondition) {
      if (fireOnceOnEnter) {
        !firedOnEnter && onEnter()
        tracker.current.firedOnEnter = true
      } else {
        onEnter()
      }
    }
    const onLeaveFireCondition = !isIntersecting && onLeave && canLeave
    if (onLeaveFireCondition) {
      if (fireOnceOnLeave) {
        !firedOnLeave && onLeave()
        tracker.current.firedOnLeave = true
        setDisableComponent(true)
      } else {
        onLeave()
      }
    }
  }

  const [ref] = useOnScreen({
    keepAlive: !!onLeave || !fireOnceOnEnter,
    rootMargin:
      rootMargin || `${offsetTop || '0px'} 0px ${offsetBottom || '0px'} 0px`,
    onChange
  })

  if (disableComponent) {
    return null
  }
  return children ? (
    <div ref={ref} key={wpKey}>
      {children}
    </div>
  ) : (
    <div ref={ref} key={wpKey} />
  )
}
