/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { container } from './PacingcardStyle'
import { faker } from '@faker-js/faker'

const DeliveryReportCard = () => {
  const deliveryReportData = [
    {
      category: 'Email',
      delivered: faker.datatype.number({ min: 0, max: 300 }),
      clicks: 50,
      ctr: 25,
      unsub: 10
    },
    {
      category: 'SMS',
      delivered: 200,
      clicks: 50,
      ctr: 25,
      unsub: 10
    },
    {
      category: 'Totals',
      delivered: 400,
      clicks: 100,
      ctr: 25,
      unsub: 20
    }
  ]

  let emailsDelivered = faker.datatype.number({ min: 0, max: 300 })
  let smsDelivered = faker.datatype.number({ min: 0, max: 300 })
  let emailsClick = faker.datatype.number({ min: 0, max: 300 })
  let smsClick = faker.datatype.number({ min: 0, max: 300 })
  let emailsCtr = faker.datatype.number({ min: 0, max: 300 })
  let smsCtr = faker.datatype.number({ min: 0, max: 300 })
  let emailsUnb = faker.datatype.number({ min: 0, max: 100 })
  let smsUnsub = faker.datatype.number({ min: 0, max: 100 })
  const totalDelivered = emailsDelivered + smsDelivered
  const totalsClick = emailsClick + smsClick
  const totalsCtr = emailsCtr + smsCtr
  const totalUnsub = (emailsUnb + smsUnsub) / 2

  return (
    <div css={container} style={{ height: 105, padding: 10 }}>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead style={{ height: 30 }}>
          <tr>
            <th style={{ width: 50, fontSize: 12 }}></th>
            <th style={{ width: 50, fontSize: 12 }}>Delivered</th>
            <th style={{ width: 50, fontSize: 12 }}>Clicks</th>
            <th style={{ width: 50, fontSize: 12 }}>CTR</th>
            <th style={{ width: 50, fontSize: 12 }}>Unsub(%)</th>
          </tr>
        </thead>
        <tbody style={{ marginTop: 5 }}>
          <tr style={{ backgroundColor: '#f5f5f5', height: 20 }}>
            <td style={{ width: 50, fontSize: 12 }}>Emails</td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {emailsDelivered}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {emailsClick}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {emailsCtr}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {emailsUnb}
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: 50, fontSize: 12 }}>SMS</td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {smsDelivered}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {smsClick}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {smsCtr}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {smsUnsub}
            </td>
          </tr>
          <tr style={{ backgroundColor: '#f5f5f5', height: 20 }}>
            <td style={{ width: 50, fontSize: 12 }}>Totals</td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {totalDelivered}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {totalsClick}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {totalsCtr}
            </td>
            <td style={{ width: 50, fontSize: 12, textAlign: 'center' }}>
              {totalUnsub}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DeliveryReportCard
