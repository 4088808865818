import { css } from '@emotion/react/macro'

export const drawerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 115px;
  min-width: 105px;
  min-height: 746px;
  background: #ffffff;
`

const toggleMenu = () => css`
  width: 115px;
  padding: 8px 8px 19px 8px;
  border: 0.8px solid #cccccc;
`

export const menu = menuView => css`
  display: flex;
  height: 742px;
  max-height: 742px;
  width: 0px;
  overflow: hidden;
  background-color: #e5e5e5;
  padding-top: 8px;
  padding-bottom: 19px;
  border: 0.8px solid #ffffff;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px #0099ff;
  transition: all 0.4s ease-out;
  ${menuView && toggleMenu()}
`

export const expandButton = css`
  margin-bottom: 2px;
  text-align: end;
  img {
    cursor: pointer;
  }
`

export const boxStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 87px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(51, 153, 255, 0.4);
  margin-bottom: 15px;
  &.campaignName {
    min-height: 161px;
  }
  &.textType {
    min-height: 176px;
  }
  &.additionalFilter {
    min-height: 322px;
    margin-bottom: 0px;
  }
  span {
    font-size: 13px;
    color: #333333;
    font-family: BentonSans;
    letter-spacing: 0;
    line-height: 0;
    text-align: center;
    font-weight: 600;
  }
`
