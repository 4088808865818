import { css } from '@emotion/react'

export const setWidthOfForm = formWidth => css`
    width: ${formWidth * 231.4 + (formWidth - 1) * 33}px;
    min-width: ${formWidth * 231.4 + (formWidth - 1) * 33}px;
`

export const audienceInitialForm = (createButtonClicked, formWidth = 1) => {
  return css`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 82px;
        width: 231.4px;
        min-width: 231.4px;
        border: 4px dashed #CCCCCC;
        background-color: rgba(204,204,204,0.4);
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        margin: 10px auto;
        margin-bottom: 0px;
        position: relative;
        transition: all 0.5s ease-out;
        ${createButtonClicked && 'height: fit-content;'}
        ${formWidth > 1 && setWidthOfForm(formWidth)}
    `
}

export const header = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const audienceHeader = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px;
    height: 100%;
    .iconBox {
        height: 61px;
        width: 61px;
        border: 0.8px solid #cccccc;
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        display: inherit;
        justify-content: center;
        align-items: inherit;
        border-radius: 5px;
        background-color: #FFFFFF;
        .salesforceIcon {
            height: 28px;
            width: 40px;
        }
        .bomboraIcon {
            height: 33px;
            width: 28px;
        }
        .zoomInfoIcon {
            height: 34px;
            width: 34px;
        }
    }
`

export const selectedAudienceSource = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`

export const audienceSource = css`
    box-sizing: border-box;
    display: flex;
    position: relative;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    height: 61px;
    width: 61px;
    min-width: 61px;
    border: 0.8px solid #CCCCCC;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px rgba(102,153,204,0.4);
    cursor: pointer;
    margin-right: 10px;
`

export const audienceSourceButton = css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFFFFF;
    height: 61px;
    width: 61px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102,153,204,0.4);
    border-radius: 4px;
    cursor: pointer;
    .bomboraIcon {
        margin: auto;
        height: 26px;
        width: 21px;
    }
    .salesforceIcon {
        margin: auto;
        height: 28px;
        width: 40px;
    }
    .downArrowIcon {
        height: 14px;
        margin: 0 auto;
        border: 0px;
        background-color: #FFFFFF;
    }
    .zoomInfoIcon {
        margin: auto;
        height: 26px;
        width: 26px;
    }
    p {
        color: #999999;
        font-family: BentonSans;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 0;
        text-align: center;
        margin-top: 17px;
    }
`
export const dropDownContent = selectSource => {
  return css`
        display: none;
        ${selectSource && 'display: block;'}
        cursor: pointer;
        position: absolute;
        z-index: 1;
    `
}

export const sourceIconBox = css`
    height: 61px;
    width: 61px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102,153,204,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #FFFFFF;
    .salesforceIcon {
        height: 28px;
        width: 40px;
    }
    .bomboraIcon {
        height: 33px;
        width: 28px;
    }
    .zoomInfoIcon {
            height: 34px;
            width: 34px;
    }
`

export const inputStyle = css`
  height: 35px;
  width: 140px;
  border: 0.8px solid #CCCCCC;
  background-color: #FFFFFF;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  box-shadow: 0 2px 6px rgba(102,153,204,0.4);
`

export const actionButtonContainer = (sourceSelected, audienceName) => css`
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    span {
        box-sizing: border-box;
        height: 34px;
        width: 47px;
        border: 0.8px solid #CCCCCC;
        background-color: #FFFFFF;
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            height: 20px;
            width: 20px;
        }
    }
    .createAudienceButton {
        height: 35px;
        width: 88px;
        display: block;
        border: 0.8px solid #CCCCCC;
        background-color: #ffffff;
        border-radius: 4px;
        color: #333333;
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        cursor: pointer;
        ${
          sourceSelected &&
          audienceName &&
          'background-color: #18A3E2; color: #ffffff;'
        }
    }
`

export const audienceFormContent = createButtonClicked => css`
    height: 0px;
    max-height: 0px;
    transition: all 0.5s ease-out;
    overflow: hidden;
    ${
      createButtonClicked &&
      'height: 630px; max-height: 630px; overflow: unset;'
    }
`

export const extensionButton = (
  cardLength = 1,
  createButtonClicked = false
) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 82px;
    width: 14px;
    overflow: hidden;
    cursor: pointer;
    border: 0.8px solid #CCCCCC;
    background-color: #18A3E2;
    box-shadow: 0 2px 6px -2px rgba(102,153,204,0.4);
    label {
        color: #FFFFFF;
        font-family: BentonSans;
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;
    }
    &.leftExtension {
        position: absolute;
        left: -14px;
        ${cardLength > 1 && 'left: -28px;'}
    }
    &.leftMinimize {
        position: absolute;
        left: -14px;
    }
    &.rightExtension {
        position: absolute;
        right: -14px;
        ${cardLength > 1 && 'right: -28px;'}
    }
    &.rightMinimize {
        position: absolute;
        right: -14px;
    }
    ${createButtonClicked && 'height: 86px;'}
`

export const rightExtensionContainer = (cardLength = 1) => css`
    display: flex;
    position: absolute;
    transform: translate(100%, 0);
    right: -14px;
    justify-content: flex-end;
    ${cardLength > 1 && 'right: -28px;'}
`

export const leftExtensionContainer = (cardLength = 1) => css`
    display: flex;
    position: absolute;
    transform: translate(-100%, 0);
    left: -14px;
    justify-content: flex-end;
    ${cardLength > 1 && 'left: -28px;'}
`

export const rightExtensionCard = (
  createButtonClicked,
  extensionHoverFlag,
  cardLength
) => css`
    box-sizing: border-box;
    z-index: 1;
    width: 0px;
    min-width: 0px;
    overflow: hidden;
    margin-bottom: 0px;
    position: relative;
    border-radius: 4px;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: rgba(204, 204, 204, 0.4);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    cursor: pointer;
    transition: all 0.5s ease-out;
    span {
        width: 20px;
        height: 20px;
        font-size: 20px;
        font-weight: 900;
    }
    ${
      extensionHoverFlag &&
      'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
    }
    ${
      extensionHoverFlag &&
      cardLength === 1 &&
      'width: 250.4px; min-width: 250.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
    }
    ${createButtonClicked && 'height: 86px;'}
`

export const leftExtensionCard = (
  createButtonClicked,
  extensionHoverFlag,
  cardLength
) => css`
    box-sizing: border-box;
    z-index: 1;
    width: 0px;
    min-width: 0px;
    overflow: hidden;
    margin-bottom: 0px;
    position: relative;
    border-radius: 4px;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: rgba(204, 204, 204, 0.4);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    cursor: pointer;
    transition: all 0.5s ease-out;
    span {
        width: 20px;
        height: 20px;
        font-size: 20px;
        font-weight: 900;
    }
    ${
      extensionHoverFlag &&
      'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
    }
    ${
      extensionHoverFlag &&
      cardLength === 1 &&
      'width: 251.4px; min-width: 251.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
    }
    ${createButtonClicked && 'height: 86px;'}
`

export const minimizeFromLeft = (
  leftMinimizeHoverFlag,
  createButtonClicked
) => css`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    cursor: pointer;
    transition: all 0.5s ease-out;
    height: 82px;
    width: 0px; 
    min-width: 0px;
    overflow: hidden;
    ${
      leftMinimizeHoverFlag &&
      'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-left: 0px; background-color: rgba(229, 229, 229, 1);'
    }
    ${createButtonClicked && 'height: 86px;'}
    span {
        width: 20px;
        height: 20px;
        font-size: 20px;
        font-weight: 900;
    }
`

export const minimizeFromRight = (
  rightMinimizeHoverFlag,
  createButtonClicked
) => css`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    cursor: pointer;
    transition: all 0.5s ease-out;
    height: 82px;
    width: 0px; 
    min-width: 0px;
    overflow: hidden;
    ${
      rightMinimizeHoverFlag &&
      'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-right: 0px; background-color: rgba(229, 229, 229, 1);'
    }
    ${createButtonClicked && 'height: 86px;'}
    span {
        width: 20px;
        height: 20px;
        font-size: 20px;
        font-weight: 900;
    }
`
