import { css } from '@emotion/react'

export const addAudienceContainer = css`
  box-sizing: border-box;
  z-index: 1;
  width: 251.4px;
  min-width: 251.4px;
  margin-left: 38px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  margin-right: 23px;
  height: 86px;
  padding: 20px 24px 20px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1); 
  border: 4px dashed #cccccc; 
  border-radius: 4px; 
  border-left: 0px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900; 
  }
  .plusIcon {
    color: #333333; 
    transition: color 0.5s ease-out;
  }
`
