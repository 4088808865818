/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { headerContainer, minimizerStyle } from './style'

const CustomHeader = (props, img) => {
  const {
    columnGroup: { groupId }
  } = props
  const [expandState, setExpandState] = useState('collapsed')

  const expandOrCollapse = () => {
    let currentState = props.columnGroup.getProvidedColumnGroup().isExpanded()
    props.setExpanded(!currentState)
  }

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getProvidedColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    )
  }

  useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons)
    syncExpandButtons()

    return () => {
      props.columnGroup
        .getProvidedColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons)
    }
  }, [])

  const childrenLength =
    props.columnGroup &&
    props.columnGroup.children &&
    props.columnGroup.children.length

  return (
    <div
      className='ag-header-group-cell-label'
      css={headerContainer(childrenLength)}
    >
      <div className='customHeaderLabel'>
        <img src={img} alt='source logo' className={groupId} />
        <div
          className={`customExpandButton ${expandState}`}
          onClick={() => expandOrCollapse()}
        >
          {expandState === 'expanded' ? (
            <span css={minimizerStyle}>+</span>
          ) : (
            <span css={minimizerStyle}>-</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomHeader
