import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 115

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    left: 'unset'
  },
  companyTitleToolbar: {
    ...theme.mixins.toolbar,
    margin: '0.6em',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    marginTop: '21px'
  },
  companyTitle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#333333',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: '#333333'
    },
    '& img': {
      width: '80%',
      marginTop: '4px'
    }
  },
  navigationList: {
    padding: '0px'
  },
  navigationButtonIcon: {
    fill: '#cccccc',
    '& g': {
      transition: 'fill 0.4s ease'
    }
  },
  navigationButton: {
    display: 'block',
    textAlign: 'center',
    color: '#cccccc',
    padding: '0.65rem 0.2rem 1.5rem 0.2rem',
    '& div': {
      transition: 'color 0.4s ease'
    },
    '&:hover': {
      background: 'none',
      color: '#333333',
      '& g': {
        fill: '#333333'
      }
    }
  },
  navigationButtonActive: {
    color: '#333333',
    '& g': {
      fill: '#333333'
    }
  },
  navigationButtonText: {
    fontSize: '0.85rem'
  }
}))

export default useStyles
