import { createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../axiosConfig'

import mapDataFromDataSource from '../../mapper/cdp/importQueue'

const initialQueueState = {
  queueData: {
    accountChangeTemplateData: [],
    emailChangeTemplateData: [],
    accountAndEmailChangeTemplateData: [],
    loading: false
  }
}

const importQueueSlice = createSlice({
  name: 'importQueue',
  initialState: initialQueueState,
  reducers: {
    getInitialData (state, action) {
      state.queueData = action.payload
    },
    updateLoading (state, action) {
      state.loading = action.payload
    }
  }
})

export const getQueueData = () => {
  return async dispatch => {
    dispatch(importQueueSlice.actions.updateLoading(true))
    const { data, status } = await axiosInstance.get('/matches')
    if (status === 200) {
      dispatch(importQueueSlice.actions.updateLoading(false))
      const mappedData = mapDataFromDataSource(data)
      dispatch(importQueueSlice.actions.getInitialData(mappedData))
    } else {
      dispatch(importQueueSlice.actions.updateLoading(false))
      throw new Error('Error obtaining data from source')
    }
  }
}

export const tagAsDuplicate = matchId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/queue/tag_as_duplicate/${matchId}`
    )
    if (status === 200) {
      console.log(data.message)
    } else {
      throw new Error('Error marking as duplicate')
    }
  }
}

export const updatePrimaryAccount = matchId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/queue/update_primary_account/${matchId}`
    )
    if (status === 200) {
      console.log(data.message)
    } else {
      throw new Error('Error marking as duplicate')
    }
  }
}

export const addNewAffliatedAccount = matchId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/queue/add_new_affiliated_account/${matchId}`
    )
    if (status === 200) {
      console.log(data.message)
    } else {
      throw new Error('Error marking as duplicate')
    }
  }
}

export const addSecondaryEmail = matchId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/queue/add_secondary_email_to_account/${matchId}`
    )
    if (status === 200) {
      console.log(data.message)
    } else {
      throw new Error('Error marking as duplicate')
    }
  }
}

export const createNewRecord = matchId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/queue/create_new_profile/${matchId}`
    )
    if (status === 200) {
      console.log(data.message)
    } else {
      throw new Error('Error marking as duplicate')
    }
  }
}

export const queueActions = importQueueSlice.actions

export default importQueueSlice.reducer
