import { useState } from 'react'

const useSignUpForm = submitHandler => {
  const [enteredName, setEnteredName] = useState('')
  const [enteredEmail, setEnteredEmail] = useState('')
  const [enteredPassword, setEnteredPassword] = useState('')
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState('')

  const [showPassword, setPasswordVisibility] = useState(false)
  const [showConfirmPassword, setConfirmPasswordVisibility] = useState(false)

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'name':
        setEnteredName(value)
        setNameError('')
        break
      case 'email':
        setEnteredEmail(value)
        setEmailError('')
        break
      case 'password':
        setEnteredPassword(value)
        setPasswordError('')
        break
      case 'confirmPassword':
        setEnteredConfirmPassword(value)
        setConfirmPasswordError('')
        break
      default:
        break
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisibility(prevVisibility => !prevVisibility)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(prevVisibility => !prevVisibility)
  }

  const validateForm = () => {
    let isValid = true

    if (!enteredName) {
      setNameError('Name is required')
      isValid = false
    }

    if (!enteredEmail || !/^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/.test(enteredEmail)) {
      setEmailError('Email address not valid')
      isValid = false
    }

    if (!enteredPassword) {
      setPasswordError('Password is required')
      isValid = false
    }

    if (enteredConfirmPassword !== enteredPassword) {
      setConfirmPasswordError('Passwords do not match')
      isValid = false
    }

    return isValid
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (validateForm()) {
      submitHandler({
        username: enteredEmail,
        password: enteredPassword,
        company_name: 'Neyo',
        email: enteredEmail
      })
    }
  }

  return {
    enteredName,
    enteredEmail,
    enteredPassword,
    enteredConfirmPassword,
    showPassword,
    showConfirmPassword,
    nameError,
    emailError,
    passwordError,
    confirmPasswordError,
    handleInputChange,
    togglePasswordVisibility,
    toggleConfirmPasswordVisibility,
    handleSubmit
  }
}

export default useSignUpForm
