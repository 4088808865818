import { css } from '@emotion/react'

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 751px;
  min-width: 751px;
  margin: 10px;
  position: relative;
  border: 4px dashed #cccccc;
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  height: min-content;
  border-radius: 4px 4px 0px 0px;
  .iconBox {
    height: 61px;
    width: 61px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    display: inherit;
    justify-content: center;
    align-items: inherit;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 294px;
    .image {
      height: 33px;
      width: 28px;
    }
  }
`

export const header = css`
  display: flex;
  height: 78px;
  min-height: 78px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border: 0.8px solid #cccccc;
  border-bottom: 0px solid #ffffff;
  border-radius: 4px 4px 0px 0px;
`

export const audienceAccountData = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 6px;
  text-align: center;
  margin-left: 39px;
  hr {
    box-sizing: border-box;
    height: 0.8px;
    width: 105px;
    border: none;
    border-bottom: 0.8px solid #cccccc;
    margin-top: 7px;
    margin-bottom: 10px;
  }
`

export const content = css`
  overflow: hidden;
  border: 0.8px solid #cccccc;
  border-top: 0px #ffffff;
  border-radius: 0px 0px 4px 4px;
  color: #333333;
  font-family: BentonSans;
  font-size: 15px;
  padding: 0px 24px;
  height: 396px;
  max-height: 396px;
`

export const optionsHeader = css`
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 20px 24px;
  .plus {
    padding-right: 8px;
  }
`

export const optionsToCheck = css`
  display: flex;
  flex-wrap: wrap;
`

export const optionStyle = css`
  flex: 0 0 33.333333%;
  padding-left: 64px;
  padding-bottom: 17px;
  display: flex;
  .optionKeyName {
    padding-top: 4px;
  }
`

export const actionButtonSection = css`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`

export const buttonStyle = css`
  box-sizing: border-box;
  height: 40px;
  width: 130px;
  color: #333333;
  font-family: BentonSans;
  font-size: 15px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  border-radius: 5px;
  cursor: pointer;
  margin-left: 7px;
  &.viewContacts {
    background-color: #18a3e2;
    color: #ffffff;
  }
  &.saveContacts {
    background-color: #18a3e2;
    color: #ffffff;
  }
`
