/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AdCampaign from './AdCampaign'
import AdsHeader from './AdsHeader'

import { campaignDetailsMock } from '../../../constant/ads'

import { getAudienceData } from '../../../store/dashboard/audienceSlice'
import { createNewAdCampaign } from '../../../store/dashboard/adsSlice'
import Modal from '../../ui/Modal'
import {
  updateModalState,
  updateAdsValidationModalResponse,
  updateRefCampaignType
} from '../../../store/dashboard/dashboardCommonSlice'
import {
  checkForValidAdCampaign,
  convertToTitleCase
} from '../../../utils/adsUtils'

import {
  containerStyle,
  modalInnerContainer,
  validationTextStyle
} from './style'
import { DataObjectTwoTone } from '@mui/icons-material'

const getAllAudiences = (audienceDataFromApi, segmentAudienceData) => {
  const audienceCollection = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    if (audienceDataFromApi[segmentId]) {
      audienceCollection[segmentId] = [
        segmentAudienceData[segmentId],
        ...audienceDataFromApi[segmentId]
      ]
    } else {
      audienceCollection[segmentId] = [segmentAudienceData[segmentId]]
    }
  })
  return audienceCollection
}

const getInitialAdCampaignData = segmentAudienceData => {
  const data = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    if (segmentAudienceData[segmentId].status === 'active')
      data[segmentId] = [{}]
    else data[segmentId] = [null]
  })
  return data
}

const createSegmentIdLinkedList = campaignData => {
  const segmentList = Object.keys(campaignData)
  const segmentLinkedMapper = {}
  segmentList.forEach((key, index) => {
    segmentLinkedMapper[key] = {
      prev: segmentList[index - 1] || null,
      next: segmentList[index + 1] || null
    }
  })
  return segmentLinkedMapper
}

const Ads = ({
  productId,
  adsClicked,
  editingAdsCampaignData,
  setCampaignCreationType = () => {},
  setEditingAdsCampaignData = () => {}
}) => {
  const dispatch = useDispatch()
  const [showConfirmModal, setShowConfirmModal] = useState()
  const [adsCampaignValidity, setAdCampaignValidity] = useState(null)
  const [errorModal, setShowErrorModal] = useState(false)
  const [adsValidityFields, setAdsValidityFields] = useState({})

  const {
    audienceDataFromApi,
    segmentAudienceData,
    adsValidationModalResponse
  } = useSelector(
    ({ audiences, dashboardCommon: { adsValidationModalResponse } }) => ({
      audienceDataFromApi: audiences.audienceDataFromApi,
      segmentAudienceData: audiences.segmentAudienceData,
      adsValidationModalResponse
    })
  )

  const [adCampaignData, setAdCampaignData] = useState(
    (editingAdsCampaignData && editingAdsCampaignData.adCampaignData) ||
      getInitialAdCampaignData(segmentAudienceData)
  )

  const [segmentLinkedMapper, setSegmentLinkedMapper] = useState(
    createSegmentIdLinkedList(adCampaignData)
  )

  const audienceCollection = getAllAudiences(
    audienceDataFromApi,
    segmentAudienceData
  )

  const [campaignDetails, setCampaignDetails] = useState(
    (editingAdsCampaignData && editingAdsCampaignData.campaignData) || {
      ...campaignDetailsMock
    }
  )

  useEffect(() => {
    dispatch(getAudienceData(productId))
  }, [dispatch, productId])

  const getAdsValidityFields = () => {
    let validityObj = {}
    const segments = Object.keys(adsCampaignValidity)
    segments.forEach(segment => {
      const adIds = Object.keys(adsCampaignValidity[segment] || {})
      if (adIds && adIds.length) {
        adIds.forEach(id => {
          const adObject = adsCampaignValidity[segment][id]
          if (Object.values(adObject).every(value => value)) {
            return
          }
          if (Object.values(adObject).length) {
            validityObj[id] = Object.keys(adObject).filter(
              key => !adObject[key]
            )
          }
        })
      }
    })

    return validityObj
  }

  useEffect(() => {
    if (errorModal) {
      const validityFields = getAdsValidityFields()
      Object.keys(validityFields).forEach(key => {
        validityFields[key] = validityFields[key].filter(field => {
          if (
            ['text', 'headline', 'newsFeedLinkDescription'].includes(field) &&
            campaignDetails[field]
          )
            return false
          return true
        })
      })
      setAdsValidityFields(validityFields)
    }
  }, [errorModal])

  useEffect(() => {
    if (adsValidationModalResponse === 'Delete') {
      setCampaignCreationType('close')
      dispatch(updateRefCampaignType(null))
      dispatch(updateAdsValidationModalResponse(null))
      setEditingAdsCampaignData(null)
    }
    if (adsValidationModalResponse === 'save_draft') {
      saveAdsDraftCampaign()
    }
  }, [adsValidationModalResponse])

  useEffect(() => {
    if (segmentAudienceData && Object.keys(segmentAudienceData).length > 0) {
      const initialAdCampaignData =
        (editingAdsCampaignData && editingAdsCampaignData.adCampaignData) ||
        getInitialAdCampaignData(segmentAudienceData)
      setAdCampaignData(initialAdCampaignData)
      setSegmentLinkedMapper(createSegmentIdLinkedList(initialAdCampaignData))
    }
  }, [segmentAudienceData, editingAdsCampaignData])

  const saveAdsDraftCampaign = () => {
    setEditingAdsCampaignData(null)
    dispatch(updateRefCampaignType(null))
    setCampaignCreationType('close')
    dispatch(
      createNewAdCampaign(productId, adCampaignData, {
        ...campaignDetails,
        isDraft: true
      })
    )
  }

  const saveAdsCampaign = () => {
    const validityObject = checkForValidAdCampaign(
      adCampaignData,
      campaignDetails
    )
    setAdCampaignValidity(validityObject)
    const validSegments = Object.keys(validityObject).filter(
      key => Object.keys(validityObject[key]).length > 0
    )
    const isValidCampaign =
      validSegments.length &&
      validSegments.every(segment => {
        return Object.keys(validityObject[segment]).every(key =>
          Object.keys(validityObject[segment][key]).every(
            field => validityObject[segment][key][field]
          )
        )
      })
    if (isValidCampaign) {
      setShowConfirmModal(true)
    } else {
      setShowErrorModal(true)
    }
  }

  return (
    <>
      <AdsHeader
        adsData={adCampaignData}
        audienceCount={campaignDetails.matchedRecordsTotal}
        onCancelHandler={() => {
          dispatch(updateModalState('ads_cancel'))
        }}
        saveAdsDraftCampaign={saveAdsDraftCampaign}
        saveAdsCampaign={() => saveAdsCampaign()}
      />
      <div css={containerStyle}>
        {adsClicked && (
          <AdCampaign
            adsCampaignValidity={adsCampaignValidity}
            audienceCollection={audienceCollection}
            adCampaignData={adCampaignData}
            segmentLinkedMapper={segmentLinkedMapper}
            campaignDetails={campaignDetails}
            editingAdsCampaignData={editingAdsCampaignData}
            setAdCampaignData={setAdCampaignData}
            setCampaignDetails={setCampaignDetails}
          />
        )}
      </div>
      {showConfirmModal && (
        <Modal showCross={false}>
          <div css={modalInnerContainer}>
            <p className='heading'>Ready to launch?!!</p>
            <p className='sub-heading'>
              Your campaign will begin sending within minutes
            </p>
            <div className='buttonContainer'>
              <button
                className='edit-button'
                onClick={() => {
                  setShowConfirmModal(false)
                }}
              >
                Keep Editing
              </button>
              <button
                onClick={() => {
                  setShowConfirmModal(false)
                  saveAdsDraftCampaign()
                }}
              >
                Save Draft
              </button>
              <button
                className='go-live'
                onClick={() => {
                  dispatch(
                    createNewAdCampaign(productId, adCampaignData, {
                      ...campaignDetails,
                      isDraft: false
                    })
                  )
                  setShowConfirmModal(false)
                  setEditingAdsCampaignData(null)
                  setCampaignCreationType('close')
                }}
              >
                Go Live
              </button>
            </div>
          </div>
        </Modal>
      )}
      {errorModal && (
        <Modal showCross={false}>
          <div css={modalInnerContainer}>
            <p style={{ textAlign: 'left' }}>
              Please edit the following required fields for highlighted ads to
              save campaign:
            </p>
            {Object.keys(adsValidityFields).map((key, index) => {
              return (
                <div css={validationTextStyle} key={`${key}-${index}`}>
                  <div className='emailName'>{key}</div>
                  <div className='fields'>
                    {(adsValidityFields[key] || []).map(item => (
                      <span>{convertToTitleCase(item)}</span>
                    ))}
                  </div>
                </div>
              )
            })}
            <div className='buttonContainer'>
              <button
                onClick={() => {
                  setShowErrorModal(false)
                  saveAdsDraftCampaign()
                  dispatch(updateRefCampaignType(null))
                }}
              >
                Save Draft
              </button>
              <button onClick={() => setShowErrorModal(null)}>
                Keep Editing
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Ads
