/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import NavigationDrawer from '../ui/CdpNavigationDrawer/CdpNavigationDrawer'
import DropdownWithSearch from '../ui/DropdownWithSearch/DropdownWithSearch'
import ViewContact from './ViewContactConditionForm/NewViewContactForm'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../ui/Loader'
import {
  getRecordsData,
  getSegments,
  getAccounts
} from '../../store/cdp/recordsSlice'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {
  viewContactWrapper,
  container,
  recordDropdown,
  recordLabelStyle,
  dropdownSearchStyle,
  conditionDataStyle,
  downloadBtnGroup,
  buttonStyle,
  primaryStyle,
  grdStyle
} from './style'
import exportFromJSON from 'export-from-json'
import { v4 as uuidv4 } from 'uuid'

const contactConditionMock = {
  id: uuidv4(),
  ruleGroups: [
    {
      matchType: 'All',
      rules: [
        {
          prospectTag: '',
          condition: '',
          value: ''
        }
      ]
    }
  ]
}

const ViewContactsPage = props => {
  const Location = useLocation()
  const dispatch = useDispatch()
  const { recordsData, loading } = useSelector(
    ({ records: { recordsData, loading } }) => ({
      recordsData,
      loading
    })
  )
  const [gridData, setGridData] = useState(recordsData || [])
  const [selectedValue, setSelectedValue] = useState({ key: '', value: '' })
  const [contactConditionData, setContactConditionData] = useState([
    JSON.parse(JSON.stringify(contactConditionMock))
  ])

  const [originalData, setOriginaldata] = useState(recordsData)
  const [columnDefs, setColumnDefs] = useState([
    { headerName: 'First Name', field: 'FirstName' },
    { headerName: 'Last Name', field: 'LastName' },
    { headerName: 'Email', field: 'Email' },
    { headerName: 'Phone', field: 'Phone' },
    {
      headerName: 'Account',
      valueGetter: params => {
        return params?.data?.account?.Name
      },
      wrapText: true
    }
  ])
  const params = new URLSearchParams(Location.search)
  const [mainDropdownOptions, setMainDropdownOptions] = useState([
    { key: 'Contacts', value: '17380' },
    { key: 'Accounts', value: '17000' },
    { key: 'Attention', value: '15000' }
  ])

  const updateRule = (key, value) => {
    const clonedData = JSON.parse(JSON.stringify(contactConditionData))
    const { ruleGroups = [] } = clonedData[0]
    let rule = {
      prospectTag: '',
      condition: '',
      value: ''
    }
    switch (key) {
      case 'segment':
        rule = {
          prospectTag: 'Contact > Segments',
          condition: 'equals',
          value: [value]
        }
        break
      default:
        break
    }
    ;((ruleGroups[0] || {}).rules || []).splice(0, 1, rule)
    setContactConditionData(clonedData)
  }

  useEffect(() => {
    let matchedRecordIds = params.get('matched_record_ids')
    let payload = { contact_ids: [], segments: [], accounts: [] }
    if (matchedRecordIds) {
      payload['contact_ids'] = matchedRecordIds.split(',')
    }
    let segment = params.get('segment')
    if (segment) {
      updateRule('segment', segment)
      payload['segments'] = [...payload.segments, segment]
    }
    dispatch(getRecordsData(payload))
  }, [])

  useEffect(() => {
    dispatch(getSegments())
    dispatch(getAccounts())
  }, [])

  useEffect(() => {
    const [{ ruleGroups }] = contactConditionData
    let payloadObj = {
      segments: [],
      accounts: [],
      contact_ids: []
    }
    let callApi = false
    ruleGroups.forEach(group => {
      const { rules } = group || {}
      rules.forEach(rule => {
        const { prospectTag, value } = rule || {}
        const splitArr = prospectTag.split('>')
        const condition = (splitArr[splitArr.length - 1] || '').trim()
        if (condition == 'Segments') {
          payloadObj['segments'] = [...payloadObj.segments, ...(value || [])]
        } else if (condition == 'Accounts') {
          payloadObj['accounts'] = [...payloadObj.segments, ...(value || [])]
        }
        if (value) {
          callApi = true
        }
      })
    })
    if (callApi) {
      dispatch(getRecordsData(payloadObj))
    }
  }, [contactConditionData])

  useEffect(() => {
    if (recordsData && recordsData.length) {
      setMainDropdownOptions([{ key: 'Contacts', value: recordsData.length }])
      setGridData(recordsData)
      setOriginaldata(recordsData)
    }
  }, [recordsData])

  useEffect(() => {
    setSelectedValue(mainDropdownOptions[0])
  }, [mainDropdownOptions])

  const updateData = (id, key, value) => {
    const prevConditionData = JSON.parse(JSON.stringify(contactConditionData))
    const prevData = prevConditionData.find(({ id: dataId }) => dataId === id)
    const index = prevConditionData.findIndex(({ id: dataId }) => dataId === id)
    const newData = { ...prevData, [key]: value }
    prevConditionData.splice(index, 1, newData)
    setContactConditionData(prevConditionData)
    newData.ruleGroups.forEach(({ rules, matchType }) => {
      if (rules.every(({ value }) => value)) {
        filterGrid(rules, matchType)
      }
    })
  }

  const createCondition = (column, condition, rule) => {
    switch (condition) {
      case 'equals':
        return rule.map(ele => ele).includes(column)
      case 'is not equals':
        return column !== (rule[0] || '').toLowerCase()
      case 'checkbox':
        return rule.map(ele => ele).includes(column)
      case 'is less than':
      case 'is greater than':
        const dateDiff = Math.floor(
          Math.abs((new Date() - new Date(column)) / (1000 * 60 * 60 * 24))
        )
        return condition === 'is less than' ? dateDiff < rule : dateDiff > rule
      default:
        return
    }
  }

  const filterGrid = (rules, matchType) => {
    const updatedGrid = originalData.filter(dataRow => {
      const matchedCondition = (rules || [])
        .filter(({ value }) =>
          Array.isArray(value) ? value.length > 0 : !!value
        )
        .map(({ prospectTag, condition, value }) => {
          if (prospectTag && value) {
            const columnName = prospectTag.split('>')
            const column = columnName[columnName.length - 1].trim()
            if (column == 'account') {
              return createCondition(dataRow[column]?.Name, condition, value)
            }
            return createCondition(dataRow[column], condition, value)
          }
        })
      const isConditionFullfilled =
        matchType === 'All'
          ? (matchedCondition || []).every(ele => ele)
          : (matchedCondition || []).some(ele => ele)
      return isConditionFullfilled
    })
    setGridData(updatedGrid)
  }

  const exportToExcel = () => {
    const exportType = exportFromJSON.types.csv

    exportFromJSON({ data: gridData, fileName: 'reports', exportType })
  }

  const containerStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      overflow: 'auto'
    }),
    []
  )

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      cellStyle: {
        outline: 'none',
        fontWeight: '300',
        fontSize: '13px',
        color: 'rgb(0 0 0 / 60%)',
        lineHeight: '18px',
        minWidth: '300px'
      }
    }
  }, [])
  const [gridApi, setGridApi] = useState()

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const onGridReady = params => {
    setGridApi(params.api)
  }

  const gridRef = useRef()

  const getRowStyle = params => {
    let rowStyle = {}
    if (params.node.rowIndex % 2 === 0) {
      rowStyle = { ...rowStyle, background: '#f5f5f5' }
    }
    return rowStyle
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div css={viewContactWrapper}>
      <NavigationDrawer />
      <div css={container}>
        <div css={recordDropdown}>
          <DropdownWithSearch
            options={mainDropdownOptions}
            value={selectedValue}
            showValue={true}
            style={dropdownSearchStyle}
            onOptionSelected={value => {
              setSelectedValue(value)
            }}
          />
          <div css={recordLabelStyle}>{selectedValue.value} Records</div>
        </div>
        <div css={conditionDataStyle}>
          {contactConditionData.map((data, index, id) => {
            return (
              <ViewContact
                data={data}
                key={index}
                id={id}
                updateData={updateData}
              />
            )
          })}
          <div css={downloadBtnGroup}>
            {/* <button css={buttonStyle}>View Archive</button> */}
            <button css={[buttonStyle, primaryStyle]} onClick={exportToExcel}>
              Download
            </button>
          </div>
        </div>
        <div style={containerStyle}>
          <div className='ag-theme-alpine' css={grdStyle()} style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowHeight={48}
              style={{ width: '100%;', height: '100%;' }}
              rowData={gridData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              domLayout={'autoHeight'}
              tooltipShowDelay={500}
              tooltipHideDelay={2000}
              getRowStyle={getRowStyle}
              suppressRowClickSelection='true'
              rowMultiSelectWithClick='false'
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewContactsPage
