/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import SelectField from '../../../../ui/SelectFormField'
import DeleteIcon from '../../../../../assets/images/trash-icon.png'
import DropdownIcon from '../../../../../assets/images/dropdown-caret.png'
import {
  container,
  inputStyle,
  triggerRuleControlStyle,
  dropdownContainer,
  displayValueStyle
} from './style'
import { useSelector } from 'react-redux'

const DropdownField = ({
  selectedValue,
  onValueSelect,
  options,
  placeholder = ''
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div css={dropdownContainer}>
      <div
        onClick={() => setIsOpen(prev => !prev)}
        css={displayValueStyle}
        style={{ justifyContent: 'space-between' }}
      >
        <div
          style={{
            lineHeight: '1.2',
            textAlign: 'left'
          }}
          className='label'
        >
          {selectedValue || (isOpen ? '' : placeholder)}
        </div>
        <img src={DropdownIcon} style={{ marginLeft: 'auto' }} />
      </div>
      <div className={`dropdownMenu ${isOpen ? 'open' : ''}`}>
        <ul>
          {options.map(({ label, value }, index) => (
            <li
              style={{ padding: '4px 2px', textAlign: 'left' }}
              key={index}
              className='list-item'
              onClick={event => {
                event.stopPropagation()
                onValueSelect(value)
                setIsOpen(false)
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const ConditionForm = ({
  data,
  updateData = () => {},
  ruleIndex,
  deleteRule = () => {},
  addRule = () => {}
}) => {
  const { prospectTag, condition, value } = data || {}
  const [conditionData, setConditionData] = useState({
    prospectTag: '',
    condition: '',
    value: ''
  })

  const {
    segmentColumnNames = [],
    segmentSupportedConditions = []
  } = useSelector(
    ({ segments: { segmentColumnNames, segmentSupportedConditions } }) => ({
      segmentColumnNames,
      segmentSupportedConditions
    })
  )

  useEffect(() => {
    setConditionData({
      prospectTag,
      condition,
      value
    })
  }, [prospectTag, condition, value])

  const handleOnChange = (key, value) => {
    setConditionData(prev => {
      return { ...prev, [key]: value }
    })
    updateData(ruleIndex, key, value)
  }
  return (
    <div css={container}>
      <div css={triggerRuleControlStyle}>
        <span className='add-rule' onClick={addRule}>
          +
        </span>
        <span className='delete' onClick={() => deleteRule(ruleIndex)}>
          <img src={DeleteIcon} alt='delete-icon' />
        </span>
      </div>
      <DropdownField
        placeholder='Prospect Tag'
        selectedValue={conditionData.prospectTag}
        options={segmentColumnNames.map(item => ({ label: item, value: item }))}
        onValueSelect={value => handleOnChange('prospectTag', value)}
      />
      <DropdownField
        placeholder='Condition'
        selectedValue={conditionData.condition}
        options={segmentSupportedConditions.map(item => ({
          label: item,
          value: item
        }))}
        onValueSelect={value => handleOnChange('condition', value)}
      />
      <input
        css={inputStyle}
        value={conditionData.value || ''}
        onChange={event => {
          event.stopPropagation()
          const {
            target: { value }
          } = event
          handleOnChange('value', value)
        }}
      />
    </div>
  )
}

export default ConditionForm
