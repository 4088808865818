import React from 'react'
import Icon from '@material-ui/core/Icon'
import CheckMark from '../../../../assets/images/checkMark.png'
import WithMockCellEditor from '../../../../HOC/WithMockCellEditor'
import { MockEditingContext } from '../../../../contexts/mockEditingContext'
import Checkbox from '../../../ui/Checkbox/Checkbox'

class QueueRendere extends React.Component {
  state
  checkboxRef
  static contextType = MockEditingContext

  constructor (props) {
    super(props)
    this.state = {
      value: false
    }
    this.checkboxRef = React.createRef()
  }

  componentDidMount () {
    this.setState({
      value: this.props.getValue() || false
    })
  }

  getValue () {
    const field = this.props.column.getColId()
    return [field, this.state.value]
  }

  onClickHandler = e => {
    this.setState({ value: e.target.checked })
  }

  render () {
    return (
      <>
        {this.props.isMockEditing ? (
          <Checkbox
            checked={this.state.value}
            onChange={this.onClickHandler}
            ref={this.checkboxRef}
            index={this.props.column.getColId() + this.props.node.id}
          />
        ) : this.state.value ? (
          <Icon>
            <img alt='check mark' src={CheckMark} />
          </Icon>
        ) : null}
      </>
    )
  }
}

export default WithMockCellEditor(QueueRendere)
