/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAdsData } from '../../../store/dashboard/adsSlice'
import AdIcon from '../../../assets/images/dashboard/ad.png'
import minusIcon from '../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../assets/images/dashboard/audience/plusIcon.png'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'

import { channelSourceList, campaignDetailsMock } from '../../../constant/ads'

import Portal from '../../../customHooks/portal'
import BuildAdd from '../CreateAdsCompaign/Forms/BuildAdd'
import SwitchController from '../../ui/SwitchController'
import Loader from '../../ui/Loader'

import {
  container,
  sideDrawer,
  textStyle,
  iconStyle,
  campaignContainerStyle,
  campaignHeaderStyle,
  previewChannelContainer,
  previewChannelStyle,
  campaignSummaryStyle,
  campaignTitleStyle,
  inputStyle,
  createAddContainer,
  adRowStyle,
  dropdownContainer,
  drowndownBtnStyle,
  editDropdownStyle,
  emptyCardView
} from './style'

const getSelectedChannels = campaignData => {
  const uniqueChannels = {}
  channelSourceList.forEach(channelObj => {
    uniqueChannels[channelObj.key] = false
  })
  Object.keys(campaignData).forEach(segmentId => {
    campaignData[segmentId].forEach(adData => {
      if (adData && adData.source) {
        uniqueChannels[adData.source] = true
      }
    })
  })
  const selectedChannels = Object.keys(uniqueChannels).filter(
    channel => uniqueChannels[channel]
  )
  const dataForCampaignHeader = []
  for (let counter = 0; counter < 4; counter++) {
    if (selectedChannels[counter])
      dataForCampaignHeader.push(selectedChannels[counter])
    else dataForCampaignHeader.push(null)
  }
  return dataForCampaignHeader
}

const getChannelSource = channel => {
  let channelSource = channelSourceList.find(
    channelSource => channelSource.key === channel
  )
  return (
    <img
      className={channelSource.className}
      alt={channelSource.alt}
      src={channelSource.src}
    ></img>
  )
}

const createSegmentIdLinkedList = campaignData => {
  const segmentList = Object.keys(campaignData)
  const segmentLinkedMapper = {}
  segmentList.forEach((key, index) => {
    segmentLinkedMapper[key] = {
      prev: segmentList[index - 1] || null,
      next: segmentList[index + 1] || null
    }
  })
  return segmentLinkedMapper
}

const CampaignAds = ({
  productId,
  isFirstAd,
  campaignIndex,
  adCampaignData,
  campaignData,
  editCampaign = () => {},
  deleteCampaignHandler = () => {},
  copyAdCampaignHandler = () => {}
}) => {
  const [expandedView, setExpandedView] = useState(false)

  const editorDropdownRef = useRef()
  const [openEditDropdown, setOpenEditDropdown] = useState(false)
  const [editorDropdownCoords, setEditorDropdownCoords] = useState({
    top: '',
    left: '',
    width: ''
  })

  const { showLoader } = useSelector(({ ads: { showLoader } }) => ({
    showLoader
  }))

  useEffect(() => {
    if (openEditDropdown) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [openEditDropdown])

  const toggleHeightHandler = () => {
    setExpandedView(expandedView => !expandedView)
  }

  const getMaxAdListLength = adCampaignData => {
    let max = 0
    Object.keys(adCampaignData).forEach(segmentId => {
      if (adCampaignData[segmentId] && adCampaignData[segmentId].length > max) {
        max = adCampaignData[segmentId].length
      }
    })
    let maxAdsArr = []
    for (let i = 0; i < max; i++) {
      maxAdsArr.push(i)
    }
    return maxAdsArr
  }

  const updateEditorCoordinate = ({ currentTarget }) => {
    const rect = currentTarget.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 148
    }
    setEditorDropdownCoords({
      left,
      top,
      width: 87
    })
  }
  return (
    <div css={container}>
      <div css={sideDrawer}>
        {isFirstAd && (
          <>
            <span css={iconStyle}>
              <img src={AdIcon} alt='ad-icon' width={37} height={33} />
            </span>
            <span css={textStyle}>Ads</span>
          </>
        )}
      </div>
      <div css={campaignContainerStyle}>
        {showLoader && (
          <div css={campaignHeaderStyle()} style={{ marginBottom: 10 }}>
            <Loader />
          </div>
        )}
        <div css={campaignHeaderStyle(expandedView)}>
          <span className='toggleHeight' onClick={toggleHeightHandler}>
            <img alt='expandIcon' src={expandedView ? minusIcon : plusIcon} />
          </span>
          {getSelectedChannels(adCampaignData).map((channel, index) => (
            <div css={previewChannelContainer(channel)} key={index}>
              <div css={previewChannelStyle}>
                {channel && getChannelSource(channel)}
              </div>
              {channel && <SwitchController style={{ top: '57px' }} />}
            </div>
          ))}
          <div css={campaignSummaryStyle}>
            <div css={campaignTitleStyle}>
              <label className='campaign-name'>
                {campaignData.campaignName}
              </label>
              <span>{`${campaignData.matchedRecordsTotal} Targets`}</span>
            </div>
            <SwitchController style={{ top: '58px' }} state='active' />
            <div css={editDropdownStyle} key={'index'}>
              <img
                css={editDropdownStyle}
                src={DropdownIcon}
                alt='dropdownIcon'
                onClick={event => {
                  setOpenEditDropdown(prev => !prev)
                  updateEditorCoordinate(event)
                }}
              />
            </div>
          </div>
        </div>
        <div css={createAddContainer(expandedView)}>
          {getMaxAdListLength(adCampaignData).map(index => {
            return (
              <div key={index} css={adRowStyle}>
                {Object.keys(adCampaignData).map((segmentId, segmentIndex) => {
                  const ad =
                    (adCampaignData[segmentId] || []).find(
                      adData => (adData || {}).rowIndex === index
                    ) || {}
                  if (Object.keys(ad).length) {
                    if (ad.createdFromSegments[0] === segmentId)
                      return (
                        <BuildAdd
                          key={`${segmentId}-${index}`}
                          segmentId={segmentId}
                          adIndex={index}
                          segmentIndex={segmentIndex}
                          campaignDetails={campaignData}
                          // copyIconClicked={copyIconClicked}
                          // setCopyIconClicked={setCopyIconClicked}
                          // setCopiedAdData={setCopiedAdData}
                          adData={ad}
                          segmentLinkedMapper={createSegmentIdLinkedList(
                            adCampaignData
                          )}
                          adCampaignData={adCampaignData}
                          isCampaignEditable={false}
                          // updateAdDetails={updateAdDetails}
                          // handleAdDelete={handleAdDelete}
                          // handleAdExtensionMinimize={handleAdExtensionMinimize}
                        />
                      )
                    else {
                      return null
                    }
                  }
                  return <div css={emptyCardView}></div>
                })}
              </div>
            )
          })}
        </div>
        {openEditDropdown && (
          <Portal>
            <div
              style={{ ...editorDropdownCoords, position: 'absolute' }}
              ref={editorDropdownRef}
            >
              <div css={dropdownContainer}>
                <button
                  css={drowndownBtnStyle}
                  className='edit-btn'
                  onClick={() => {
                    editCampaign(adCampaignData, campaignData)
                    setOpenEditDropdown(false)
                    // setCurrentSelectedIndex(null)
                  }}
                >
                  Edit
                </button>
                <button
                  css={drowndownBtnStyle}
                  onClick={() => {
                    copyAdCampaignHandler(adCampaignData, campaignData)
                    setOpenEditDropdown(false)
                  }}
                >
                  Copy
                </button>

                <button
                  css={drowndownBtnStyle}
                  onClick={() => {
                    deleteCampaignHandler(campaignData.id)
                    setOpenEditDropdown(false)
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Portal>
        )}
      </div>
    </div>
  )
}

export default CampaignAds
