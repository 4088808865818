import { css } from '@emotion/react'

export const panelTitleStyle = css`
  font-family: Roboto;
  line-height: 32px;
  font-weight: 400;
  color: #555c66;
  font-size: 15px;
  padding-bottom: 18px;
  display: inline-flex;
  align-items: center;
  margin-right: auto;
`

export const backBtnStyle = css`
  transform: rotate(90deg);
  margin-left: auto;
  fill: #555c66;
  margin-right: 16px;
  cursor: pointer;
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  border-radius: 5px;
  &:before {
    border-radius: 4px;
    bottom: -4px;
    content: ' ';
    left: -4px;
    position: absolute;
    right: -4px;
    top: -4px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }

  &:hover {
    background-color: #f5f5f5;
    border: 1px solid transparent;
    outline: none;
  }
`

export const backgroundColorContainerStyle = css`
  padding-bottom: 24px;
  width: 100%;
`

export const labelStyle = css`
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0 0 4px;
  font-size: 13px;
  line-height: 22px;
`

export const fieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  padding: 6px;
  position: relative;
  transition: border-color 0.1s linear;
`

export const inputStyle = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
`

export const colorBoxStyle = css`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgb(218, 148, 110);
  border: 1px solid rgb(158, 88, 50);
  box-shadow: none;
  cursor: pointer;
`
