/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'

import { layoutContainer, sectionTitle } from './style'

const QuickAddLayout = ({ data, monitorDragging = () => {} }) => {
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'QUICK_ADD_LAYOUT',
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    monitorDragging(isDragging)
  }, [isDragging])
  return (
    <div css={layoutContainer} ref={drag} style={{ opacity }}>
      <img src={data.component.img} alt='preview image' />
      <div css={sectionTitle}>{data.component.type}</div>
    </div>
  )
}
export default QuickAddLayout
