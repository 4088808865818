/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import NavigationButton from '../../ui/NavigationButton'
import { useDispatch } from 'react-redux'

import GraphCard from './GraphCard'
import DashboardHeader from '../../ui/Header/DashboardHeader/Header'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import SegmentAudienceFilter from './SegmentAudienceFilter'
import { updateExpandAudience } from '../../../store/dashboard/topfoldSlice'

import {
  container,
  sideDrawer,
  companyTitleToolbar,
  companyTitle,
  dropdownContainer,
  displayValueStyle,
  caretIconStyle
} from './style'

import neyoIcon from '../../../assets/images/NeyoIcon.png'

const PerformanceMarketing = ({
  selectedValue,
  showAnalyticsGraphs,
  setShowAnalyticsGraphs
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div css={dropdownContainer} style={{ padding: 0 }}>
      <div
        // onClick={() => setIsOpen(prev => !prev)}
        css={displayValueStyle}
        onClick={e => {
          e.stopPropagation()
          setShowAnalyticsGraphs(prev => !prev)
        }}
        style={{ justifyContent: 'space-between' }}
      >
        <div
          style={{ flex: 1, lineHeight: '1.2', fontSize: 15, paddingTop: 5 }}
        >
          {' '}
          {selectedValue || 'Performance Marketing'} <br />
          <span style={{ fontSize: 12 }}>Dashboard</span>
        </div>
        <img src={DropdownIcon} css={caretIconStyle(showAnalyticsGraphs)} />
      </div>
      {/* <div className={`dropdownMenu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>Performance Marketing</li>
        </ul>
      </div> */}
    </div>
  )
}

const Topfold = ({ logOutHandler = () => {} }) => {
  const [showAnalyticsGraphs, setShowAnalyticsGraphs] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!showAnalyticsGraphs) {
      dispatch(updateExpandAudience(true))
    }
  }, [])
  return (
    <>
      <div css={container(showAnalyticsGraphs)}>
        <div css={sideDrawer}>
          <div css={companyTitleToolbar}>
            <span css={companyTitle}>
              <img alt='neyo-icon' src={neyoIcon} />
            </span>
          </div>
          <NavigationButton style={{ marginTop: '32px' }} />
        </div>
        <div
          style={{
            paddingLeft: '12px',
            height: '496px',
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(100% - 115px)'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 6
            }}
          >
            <PerformanceMarketing
              showAnalyticsGraphs={showAnalyticsGraphs}
              setShowAnalyticsGraphs={setShowAnalyticsGraphs}
            />
            <DashboardHeader logOutHandler={logOutHandler} />
          </div>
          <SegmentAudienceFilter showAnalyticsGraphs={showAnalyticsGraphs} />
          <GraphCard showAnalyticsGraphs={showAnalyticsGraphs} />
        </div>
      </div>
    </>
  )
}

export default Topfold
