import { css } from '@emotion/react'

export const sectionTitle = css`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  padding-top: 24px;
`

export const imgStyle = css`
  cursor: grab;
  width: 100%;
  max-width: 216px;
  background-color: #fff;
  box-shadow: 0 2px 4px #0000001a;
  border-radius: 4px;
`
