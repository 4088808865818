import { createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../axiosConfig'
import { showToast } from '../toast/toastSlice'

const initialState = {
  createdCampaigns: [],
  audiences: {},
  segmentIds: [],
  currentEditedSegmentandAudience: {},
  emailTemplates: {},
  editingComponents: {},
  currentEditingComponent: {},
  emailSettings: {},
  closeCampaign: false,
  campaignName: '',
  btnStyles: {
    'Primary button': {
      backgroundColor: '#000',
      border: '0px solid rgb(255, 255, 255)',
      borderRadius: '0px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      color: '#fff',
      padding: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'unset',
      borderStyle: 'solid',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderColor: '#000'
    },
    'Secondary button': {
      backgroundColor: '#fff',
      border: '2px solid rgb(0, 0, 0)',
      borderRadius: '0px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      color: '#000',
      padding: '16px 38px 16px 16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'unset',
      borderStyle: 'solid',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '38px',
      borderColor: '#000'
    },
    'Tertiary button': {
      backgroundColor: '#000',
      border: '2px solid rgb(255, 255, 255)',
      borderRadius: '0px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      color: '#fff',
      padding: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'unset',
      borderStyle: 'solid',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderColor: '#000'
    }
  },
  templateTree: {},
  emails: [],
  savingMail: false,
  showLoader: false,
  deletedElement: null,
  movedElement: null,
  mailValidityObject: {},
  mailCounter: 1,
  personalizationTags: []
}

const mailerSlice = createSlice({
  name: 'mailer',
  initialState,
  reducers: {
    updateMailerAudience (state, actions) {
      state.audiences = actions.payload
    },
    updateSegmentIds (state, actions) {
      state.segmentIds = actions.payload
    },
    updateEmailTemplate (state, actions) {
      state.emailTemplates = actions.payload
    },
    updateCurrentEditingComponent (state, actions) {
      state.currentEditingComponent = actions.payload
    },
    updateCurrentEditedSegmentAndAudience (state, actions) {
      state.currentEditedSegmentandAudience = actions.payload
    },
    updateEdtingComponents (state, actions) {
      state.editingComponents = actions.payload
    },
    updateEmails (state, actions) {
      state.emails = actions.payload
    },
    updateEmailSettingsHandler (state, actions) {
      state.emailSettings = actions.payload
    },
    updatebtnStyle (state, actions) {
      state.btnStyles = actions.payload
    },
    updateTemplateTree (state, actions) {
      state.templateTree = actions.payload
    },
    updateSavedCampaigns (state, actions) {
      state.createdCampaigns = actions.payload
    },
    closeCampaign (state, actions) {
      state.closeCampaign = actions.payload
    },
    updateSavingMail (state, actions) {
      state.savingMail = actions.payload
    },
    updateDeletedElement (state, actions) {
      state.deletedElement = actions.payload
    },
    updateMovedElement (state, actions) {
      state.movedElement = actions.payload
    },
    updateMailCampaignValidityObject (state, actions) {
      state.mailValidityObject = actions.payload
    },
    updateMailCounter (state, actions) {
      state.mailCounter = actions.payload
    },
    updateShowLoader (state, actions) {
      state.showLoader = actions.payload
    },
    updatePersonalizationTags (state, actions) {
      state.personalizationTags = actions.payload
    },
    updateCampaignName (state, actions) {
      state.campaignName = actions.payload
    }
  }
})

export const updateEmailTemplateHandler = (
  state = {},
  { audienceId, segmentId }
) => {
  return dispatch => {
    const pageWrapper = document.getElementById('page-wrapper')
    const controlPanelElement = pageWrapper.querySelector('.controlPanel')

    if (controlPanelElement) {
      controlPanelElement.remove()
    }
    const emailString = pageWrapper.innerHTML
    const clonedState = JSON.parse(JSON.stringify(state))
    if (state.hasOwnProperty(segmentId)) {
      const currrentEditingTemplateIndex = (state[segmentId] || []).findIndex(
        item => item.audienceId === audienceId
      )
      let updatedTemplate = {}
      if (currrentEditingTemplateIndex !== -1) {
        updatedTemplate = {
          ...state[segmentId][currrentEditingTemplateIndex],
          emailString
        }
        clonedState[segmentId].splice(
          currrentEditingTemplateIndex,
          1,
          updatedTemplate
        )
      } else {
        updatedTemplate = {
          audienceId,
          emailString
        }
        clonedState[segmentId].push(updatedTemplate)
      }
    } else {
      clonedState[segmentId] = [
        {
          audienceId,
          emailString
        }
      ]
    }
    dispatch(mailerSlice.actions.updateEmailTemplate(clonedState))
  }
}

export const updateEditingComponent = ({
  path,
  value,
  currentEditedSegmentandAudience: { audienceId, segmentId },
  state
}) => {
  return dispatch => {
    const clonedState = JSON.parse(JSON.stringify(state))
    if (state.hasOwnProperty(segmentId)) {
      clonedState[segmentId][audienceId] = {
        ...(clonedState[segmentId][audienceId] || {}),
        [path]: value
      }
    } else {
      clonedState[segmentId] = {
        [audienceId]: {
          [path]: value
        }
      }
    }
    dispatch(mailerSlice.actions.updateEdtingComponents(clonedState))
  }
}

const parseMailData = data => {
  return data.map(
    ({
      id,
      name: campaignName,
      mail_options: mailData = [],
      is_draft: isDraft
    }) => {
      return {
        id,
        campaignName,
        isDraft,
        emails: mailData.map(
          ({
            name: emailName,
            email_type: emailType,
            subject: subjectLine,
            additional_audience_filter: additionalAudienceFilter,
            audience_ids: audienceId,
            sender,
            backup_sender: backupSender,
            test_email: testEmail,
            backup_sender_email: backupSenderEmail,
            recurring,
            recurring_rule: recurringRule,
            template_string: templateString,
            segment_ids: createdFromSegments,
            email_layout_tree: emailLayoutTree,
            send_date: sendDate,
            custom_sender_email: customSenderEmail,
            id
          }) => ({
            id,
            emailName,
            emailType,
            subjectLine,
            additionalAudienceFilter,
            audienceId,
            sender,
            backupSender,
            testEmail,
            backupSenderEmail,
            recurring,
            recurringRule,
            templateString,
            createdFromSegments,
            emailLayoutTree,
            sendDate,
            customSenderEmail
          })
        )
      }
    }
  )
}

export const getCampaigns = () => {
  return async dispatch => {
    const { id: productId } = JSON.parse(
      sessionStorage.getItem('productInfo')
    ) || { id: 2 }
    const { status, data } = await axiosInstance.get(
      `/mail_options/all_campaigns?product_id=${productId}`
    )
    if (status === 200) {
      dispatch(mailerSlice.actions.updateSavedCampaigns(parseMailData(data)))
    }
  }
}

export const postEmailCampaignHandler = payload => {
  return async dispatch => {
    try {
      dispatch(mailerSlice.actions.updateShowLoader(true))
      const { data, status } = await axiosInstance.post(
        '/mail_options/universal',
        payload
      )
      if (status === 200) {
        dispatch(mailerSlice.actions.closeCampaign(true))
        dispatch(getCampaigns())
        dispatch(mailerSlice.actions.updateSavingMail(false))
        dispatch(mailerSlice.actions.updateShowLoader(false))
        setTimeout(() => {
          dispatch(mailerSlice.actions.closeCampaign(false))
        }, 500)
      }
    } catch (error) {
      console.log(error)
      dispatch(mailerSlice.actions.updateShowLoader(false))
    }
  }
}

export const updateTemplateTreeHandler = (parentKey, data) => {
  return (dispatch, getState) => {
    let {
      mailer: {
        currentEditedSegmentandAudience: { segmentId, audienceId } = {},
        templateTree
      } = {}
    } = getState() || {}
    const templateTreeClone = JSON.parse(JSON.stringify(templateTree))
    const currentTree = (templateTree[segmentId] || {})[audienceId] || {}
    const cloneCurrentTree = JSON.parse(JSON.stringify(currentTree))
    cloneCurrentTree[parentKey] = data
    templateTreeClone[segmentId] = {
      ...(templateTreeClone[segmentId] || {}),
      [audienceId]: cloneCurrentTree
    }
    dispatch(mailerSlice.actions.updateTemplateTree(templateTreeClone))
  }
}

export const updateTemplateTreeComponents = () => {
  return (dispatch, getState) => {
    const {
      mailer: {
        currentEditingComponent,
        templateTree,
        currentEditedSegmentandAudience,
        editingComponents
      } = {}
    } = getState() || {}
    const cloneTemplateTree = JSON.parse(JSON.stringify(templateTree))
    const { parentUid, path } = currentEditingComponent
    const editingIndex = (path || '').slice(-1)
    const { segmentId, audienceId } = currentEditedSegmentandAudience || {}
    const currentComponentChanges = ((editingComponents[segmentId] || {})[
      audienceId
    ] || {})[path]
    const currentEmailTree =
      (cloneTemplateTree[segmentId] || {})[audienceId] || {}
    if (editingIndex && currentComponentChanges) {
      const component = (currentEmailTree[parentUid] || [])[+editingIndex - 1]
      const updatedComponent = { ...component, styles: currentComponentChanges }
      currentEmailTree[parentUid].splice(+editingIndex - 1, 1, updatedComponent)
      cloneTemplateTree[segmentId][audienceId] = currentEmailTree
      dispatch(mailerSlice.actions.updateTemplateTree(cloneTemplateTree))
    }
  }
}

export const deleteCampaignHandler = id => {
  return async dispatch => {
    const { status } = await axiosInstance.delete(`/campaigns/${id}`)
    if (status === 200) {
      dispatch(getCampaigns())
    }
  }
}

export const handleElementDelete = deletedData => {
  return (dispatch, getState) => {
    dispatch(mailerSlice.actions.updateDeletedElement(deletedData))
    if (deletedData) {
      const { mailer: { templateTree, currentEditedSegmentandAudience } = {} } =
        getState() || {}
      const cloneTemplateTree = JSON.parse(JSON.stringify(templateTree))
      const { segmentId, audienceId } = currentEditedSegmentandAudience || {}
      const currentTree = (cloneTemplateTree[segmentId] || {})[audienceId] || {}
      const { componentId, index } = deletedData || {}
      currentTree[0] = currentTree[0].filter(
        ({ component, index: componentIndex }) =>
          componentId !== component && componentIndex !== index
      )
      if (currentTree[componentId]) {
        delete currentTree[componentId]
      }
      dispatch(mailerSlice.actions.updateTemplateTree(cloneTemplateTree))
    }
  }
}

export const fetchPersonalizationTags = () => {
  return async (dispatch, getState) => {
    const { data, status } = await axiosInstance.get(`/mail_options/fields`)
    if (status === 200) {
      dispatch(mailerSlice.actions.updatePersonalizationTags(data))
    }
  }
}

export const sendTestMail = payload => {
  return async dispatch => {
    const { data, status } = await axiosInstance.post(
      `/mail_options/test_mail`,
      payload
    )
    if (status === 200) {
      dispatch(
        showToast({ message: data.message, type: 'success', isVisible: true })
      )
    }
  }
}

export const {
  updateMailerAudience,
  updateSegmentIds,
  updateCurrentEditedSegmentAndAudience,
  updateCurrentEditingComponent,
  updateEmails,
  updateEmailSettingsHandler,
  updatebtnStyle,
  updateTemplateTree,
  updateEdtingComponents,
  closeCampaign,
  updateEmailTemplate,
  updateSavingMail,
  updateMovedElement,
  updateMailCampaignValidityObject,
  updateMailCounter,
  updateCampaignName
} = mailerSlice.actions

export default mailerSlice.reducer
