/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect } from 'react'
import MailerCard from './MailCard'
import { useDispatch, useSelector } from 'react-redux'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'
import AddMail from './AddMailComponent'
import { v4 as uuidv4 } from 'uuid'
import {
  updateCurrentEditedSegmentAndAudience,
  updateMailCounter,
  updateEmailTemplate,
  fetchPersonalizationTags
} from '../../../../store/dashboard/mailerSlice'
import { isEmpty } from 'lodash'
import Icon from '@material-ui/core/Icon'

import {
  previewStyle,
  headerStyle,
  summaryStyle,
  titleStyle,
  targetStyle,
  inputStyle,
  saveCampaignNameButton,
  containerStyle,
  mailsContainer,
  mailRowStyle,
  dummyAddcardStyle,
  previewContainerStyle
} from './style'

const getMailDefaultDetails = (
  audienceCollection,
  segmentId,
  mailDetails = {},
  copyAction = false,
  counter = 1
) => {
  let mailMockData = {
    id: uuidv4(),
    newMail: true,
    isEditable: false,
    createdFromSegments: [segmentId],
    emailSendDate: '',
    emailSendTime: '',
    subjectLine: '',
    sender: '',
    backupSender: '',
    recurringRule: [{ prospectTag: '', condition: '', value: '' }],
    additionalAudienceFilter: [{ prospectTag: '', condition: '', value: '' }],
    emailName: `Untitled mail ${counter}`,
    emailType: '',
    recurring: '',
    testEmail: '',
    backupSenderEmail: ''
  }

  if (copyAction) {
    mailMockData = {
      ...mailDetails,
      id: uuidv4(),
      createdFromSegments: [segmentId]
    }
  }
  mailMockData.audienceData =
    audienceCollection[segmentId] &&
    audienceCollection[segmentId]
      .filter(audience => {
        return audience ? true : false
      })
      .map(audience => {
        return { ...audience, isSelected: false }
      })
  return mailMockData
}

const CampaignContainer = ({
  campaignName,
  setCampaignName = () => {},
  audienceCollection,
  mailCampaignData,
  segmentLinkedMapper,
  setMailCampaignData = () => {},
  isEditingCampaign
}) => {
  const dispatch = useDispatch()
  const [inEditMode, setInEditMode] = useState(false)
  const {
    emailTemplates,
    currentEditedSegmentandAudience,
    audienceData,
    mailValidityObject,
    counter
  } = useSelector(state => ({
    emailTemplates: state.mailer.emailTemplates,
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    audienceData: state.audiences,
    mailValidityObject: state.mailer.mailValidityObject,
    counter: state.mailer.mailCounter
  }))

  const [showDummyAddCard, setShowDummyAddCard] = useState()

  useEffect(() => {
    let noMailPresent = true
    Object.keys(mailCampaignData).forEach(key => {
      const mailArr = mailCampaignData[key]
      mailArr.forEach(mail => {
        if (!isEmpty(mail)) {
          noMailPresent = false
        }
      })
    })
    setShowDummyAddCard(noMailPresent)
  }, [mailCampaignData])

  useEffect(() => {
    if (Object.values(currentEditedSegmentandAudience).length > 0) {
      setInEditMode(true)
    }
  }, [currentEditedSegmentandAudience])

  const [expandedView, setExpandedView] = useState(false)
  const [saveCampaignName, setSaveCampaignName] = useState(false)
  const [copyIconClicked, setCopyIconClicked] = useState(false)
  const [copiedMailData, setCopiedMailData] = useState(null)

  const getMaxMailListLength = mailCampaignData => {
    let max = 0
    Object.keys(mailCampaignData).forEach(segmentId => {
      if (
        mailCampaignData[segmentId] &&
        mailCampaignData[segmentId].length > max
      ) {
        max = mailCampaignData[segmentId].length
      }
    })
    let maxMailsArr = []
    for (let i = 0; i < max; i++) {
      maxMailsArr.push(i)
    }
    return maxMailsArr
  }

  useEffect(() => {
    if (isEditingCampaign) {
      setSaveCampaignName(true)
      toggleHeightHandler(prev => !prev)
    }
  }, [isEditingCampaign])

  useEffect(() => {
    dispatch(fetchPersonalizationTags())
  }, [])

  const toggleHeightHandler = () => {
    setExpandedView(expandedView => !expandedView)
  }

  const preview = useMemo(() => {
    let preview = []
    if (Object.keys(emailTemplates || {}).length) {
      Object.keys(emailTemplates || {}).map(key => {
        preview = [
          ...preview,
          ...(emailTemplates[key].map(({ emailString }) => emailString) || [])
        ]
      })
    }
    return preview.length > 5 ? preview.slice(0, 5) : preview
  }, [emailTemplates])

  const handleNewMailForm = (segmentId, index, copiedMailData) => {
    const newMailCampaignData = JSON.parse(JSON.stringify(mailCampaignData))
    newMailCampaignData[segmentId][index] = getMailDefaultDetails(
      audienceCollection,
      segmentId,
      copiedMailData,
      copyIconClicked,
      counter
    )
    const increasedCount = counter + 1
    dispatch(updateMailCounter(increasedCount))
    let count = 0

    for (let i = index + 1; i < newMailCampaignData[segmentId].length; i++) {
      if (!newMailCampaignData[segmentId][i]) {
        newMailCampaignData[segmentId][i] = {}
        count += 1
        break
      } else if (
        newMailCampaignData[segmentId][i] &&
        Object.keys(newMailCampaignData[segmentId][i]).length === 0
      ) {
        count += 1
        break
      }
    }
    if (!count) newMailCampaignData[segmentId].push({})
    setMailCampaignData(newMailCampaignData)
    setCopiedMailData(null)
    setCopyIconClicked(false)
  }

  const setCurrentEditingMail = (segmentId, audienceId) => {
    const { segmentId: currentSegment, audienceId: currentAudience } =
      currentEditedSegmentandAudience
    if (segmentId !== currentSegment || audienceId !== currentAudience) {
      dispatch(updateCurrentEditedSegmentAndAudience({ segmentId, audienceId }))
    }
  }

  const updateMailDetails = (segmentId, mailIndex, updatedDataSet) => {
    const newMailCampaignData = JSON.parse(JSON.stringify(mailCampaignData))
    newMailCampaignData[segmentId][mailIndex] = {
      ...newMailCampaignData[segmentId][mailIndex],
      ...updatedDataSet
    }
    setMailCampaignData(newMailCampaignData)
  }

  const updateEmailTemplateObject = (segmentId, mailIndex) => {
    const clonedTemplates = JSON.parse(JSON.stringify(emailTemplates))
    clonedTemplates[segmentId].splice(mailIndex, 1)
    dispatch(updateEmailTemplate(clonedTemplates))
  }

  const handleMailDelete = (segmentId, mailIndex) => {
    const newMailCampaignData = JSON.parse(JSON.stringify(mailCampaignData))
    newMailCampaignData[segmentId][mailIndex].createdFromSegments.forEach(
      segmentIdDetail => {
        newMailCampaignData[segmentIdDetail][mailIndex] = {}
      }
    )

    updateEmailTemplateObject(segmentId, mailIndex)
    setMailCampaignData(newMailCampaignData)
    dispatch(updateCurrentEditedSegmentAndAudience({}))
  }

  const isMailValid = (mailId, segmentId) => {
    const fields = (mailValidityObject[segmentId] || {})[mailId] || {}
    return !Object.keys(fields).filter(key => !fields[key]).length
  }

  const handleMailExtensionMinimize = (
    mailCampaignData,
    segmentId,
    mailIndex,
    type
  ) => {
    const prevMailCampaignData = JSON.parse(JSON.stringify(mailCampaignData))
    const { segmentAudienceData, audienceDataFromApi } = audienceData || {}

    switch (type) {
      case 'rightExtension': {
        const createdFromSegmentsArr =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments
        const lastSegmentId =
          createdFromSegmentsArr[createdFromSegmentsArr.length - 1]
        const nextSegmentId = segmentLinkedMapper[lastSegmentId].next
        const newCreatedFromSegments = [
          ...prevMailCampaignData[segmentId][mailIndex].createdFromSegments,
          nextSegmentId
        ]
        let updatedAudience = []
        newCreatedFromSegments.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        createdFromSegmentsArr.forEach(id => {
          prevMailCampaignData[id][mailIndex].createdFromSegments =
            newCreatedFromSegments
          prevMailCampaignData[id][mailIndex].audienceData = updatedAudience
        })
        if (!prevMailCampaignData[nextSegmentId][mailIndex]) {
          prevMailCampaignData[nextSegmentId][mailIndex] = JSON.parse(
            JSON.stringify(prevMailCampaignData[segmentId][mailIndex])
          )
        } else {
          prevMailCampaignData[nextSegmentId].splice(
            mailIndex,
            0,
            JSON.parse(
              JSON.stringify(prevMailCampaignData[segmentId][mailIndex])
            )
          )
        }
        setMailCampaignData(prevMailCampaignData)
        break
      }
      case 'rightMinimize': {
        const lastSegmentId =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments.pop()
        const newCreatedFromSegmentsArr =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments
        if (
          prevMailCampaignData[lastSegmentId][mailIndex + 1] &&
          typeof prevMailCampaignData[lastSegmentId][mailIndex + 1] === 'object'
        ) {
          if (
            Object.keys(prevMailCampaignData[lastSegmentId][mailIndex + 1])
              .length === 0
          ) {
            prevMailCampaignData[lastSegmentId][mailIndex + 1] = null
          }
          prevMailCampaignData[lastSegmentId][mailIndex] = {}
        } else {
          prevMailCampaignData[lastSegmentId][mailIndex] = null
        }
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        newCreatedFromSegmentsArr.forEach(id => {
          prevMailCampaignData[id][mailIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevMailCampaignData[id][mailIndex].audienceData = updatedAudience
        })

        setMailCampaignData(prevMailCampaignData)
        break
      }
      case 'leftExtension': {
        const createdFromSegmentsArr =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments
        const firstSegmentId = createdFromSegmentsArr[0]
        const prevSegmentId = segmentLinkedMapper[firstSegmentId].prev
        const newCreatedFromSegmentsArr = [
          prevSegmentId,
          ...prevMailCampaignData[segmentId][mailIndex].createdFromSegments
        ]
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        createdFromSegmentsArr.forEach(id => {
          prevMailCampaignData[id][mailIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevMailCampaignData[id][mailIndex].audienceData = updatedAudience
        })
        if (!prevMailCampaignData[prevSegmentId][mailIndex]) {
          prevMailCampaignData[prevSegmentId][mailIndex] = JSON.parse(
            JSON.stringify(prevMailCampaignData[segmentId][mailIndex])
          )
        } else {
          prevMailCampaignData[prevSegmentId].splice(
            mailIndex,
            0,
            JSON.parse(
              JSON.stringify(prevMailCampaignData[segmentId][mailIndex])
            )
          )
        }
        setMailCampaignData(prevMailCampaignData)
        break
      }
      case 'leftMinimize': {
        const firstSegmentId =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments.shift()
        const newCreatedFromSegmentsArr =
          prevMailCampaignData[segmentId][mailIndex].createdFromSegments
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        if (
          prevMailCampaignData[firstSegmentId][mailIndex + 1] &&
          typeof prevMailCampaignData[firstSegmentId][mailIndex + 1] ===
            'object'
        ) {
          if (
            Object.keys(prevMailCampaignData[firstSegmentId][mailIndex + 1])
              .length === 0
          ) {
            prevMailCampaignData[firstSegmentId][mailIndex + 1] = null
          }
          prevMailCampaignData[firstSegmentId][mailIndex] = {}
        } else {
          prevMailCampaignData[firstSegmentId][mailIndex] = null
        }

        newCreatedFromSegmentsArr.forEach(id => {
          prevMailCampaignData[id][mailIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevMailCampaignData[id][mailIndex].audienceData = updatedAudience
        })
        setMailCampaignData(prevMailCampaignData)
        break
      }
      default:
        break
    }
  }
  return (
    <div css={containerStyle}>
      {/* new campign flow */}
      <div css={headerStyle(expandedView, saveCampaignName)}>
        <span className='toggleHeight' onClick={toggleHeightHandler}>
          <img alt='expandIcon' src={expandedView ? minusIcon : plusIcon} />
        </span>
        <div css={previewContainerStyle}>
          {preview.map((email, index) => (
            <div css={previewStyle} key={index}>
              <div
                style={{ transform: 'scale(0.1, 0.05' }}
                dangerouslySetInnerHTML={{ __html: email }}
              />
            </div>
          ))}
        </div>
        <div css={summaryStyle} style={{ justifySelf: 'center' }}>
          <div css={titleStyle}>Campaign Name</div>
          <div css={targetStyle}>
            <input
              type='text'
              css={inputStyle}
              disabled={saveCampaignName}
              className='campaignName'
              value={campaignName}
              onChange={({ target: { value } = {} }) => setCampaignName(value)}
            />
            <button
              css={saveCampaignNameButton(campaignName)}
              disabled={!campaignName}
              onClick={() => {
                setSaveCampaignName(prev => !prev)
                toggleHeightHandler()
              }}
            >
              {saveCampaignName ? 'Edit' : 'Save'}
            </button>
          </div>
        </div>
      </div>
      <div
        css={mailsContainer(saveCampaignName, expandedView)}
        onMouseEnter={() => setShowDummyAddCard(false)}
      >
        {showDummyAddCard && (
          <div css={dummyAddcardStyle}>
            <Icon className='plusIcon'>add</Icon>
          </div>
        )}
        {!showDummyAddCard &&
          getMaxMailListLength(mailCampaignData).map(index => {
            return (
              <div key={index} css={mailRowStyle}>
                {Object.keys(mailCampaignData).map(
                  (segmentId, segmentIndex) => {
                    if (
                      mailCampaignData[segmentId][index] &&
                      Object.keys(mailCampaignData[segmentId][index] || {})
                        .length
                    ) {
                      if (
                        mailCampaignData[segmentId][index]
                          .createdFromSegments[0] == segmentId
                      ) {
                        const {
                          id: mailId,
                          emailName,
                          emailType
                        } = mailCampaignData[segmentId][index] || {}
                        return (
                          <MailerCard
                            inEditMode={inEditMode}
                            key={`${segmentId}-${index}`}
                            segmentId={segmentId}
                            mailIndex={index}
                            segmentIndex={segmentIndex}
                            copyIconClicked={copyIconClicked}
                            setCopiedMailData={setCopiedMailData}
                            setCopyIconClicked={setCopyIconClicked}
                            mailData={mailCampaignData[segmentId][index]}
                            segmentLinkedMapper={segmentLinkedMapper}
                            mailCampaignData={mailCampaignData}
                            updateMailDetails={updateMailDetails}
                            handleMailDelete={handleMailDelete}
                            emailName={emailName}
                            emailType={emailType}
                            isMailDataValid={isMailValid(emailName, segmentId)}
                            handleMailExtensionMinimize={
                              handleMailExtensionMinimize
                            }
                            setCurrentEditingMail={() =>
                              setCurrentEditingMail(segmentId, mailId)
                            }
                          />
                        )
                      } else {
                        return null
                      }
                    } else {
                      return (
                        <AddMail
                          key={`${segmentId}-${index}`}
                          index={index}
                          segmentId={segmentId}
                          copiedMailData={copiedMailData}
                          mail={mailCampaignData[segmentId][index]}
                          addNewMailFormHandler={handleNewMailForm}
                        />
                      )
                    }
                  }
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default CampaignContainer
