/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { differenceWith, isEqual, pickBy } from 'lodash'
import { usePrompt } from '../../../customHooks/usePrompt'
import QuickSearchToolbar from '../QuickSearchToolbar/QuickSearchToolbar'

import WithMockEditingContext from '../../../HOC/WithMockEditingContext'
import SalesforceIcon from '../../../assets/images/salesforce.png'
import neyoMarkColor from '../../../assets/images/neyoMarkColor.svg'
import bomboraIcon from '../../../assets/images/dashboard/audience/bombora.png'
import DropdownWithSearch from '../../ui/DropdownWithSearch/DropdownWithSearch.js'
import {
  fetchUnifiedIdData,
  updateUnifiedData
} from '../../../store/cdp/unifiedIdSlice'
import HeaderGroupComponent from './CustomeHeader'
import QueueRenderer from './QueueRenderer'

import {
  content,
  subHeaderText,
  subHeaderButton,
  dataGridPaper,
  filterDropdown,
  filterSearch,
  subHeaderPaper,
  modalText,
  buttonContainer,
  grdStyle,
  iconWrapper
} from './styles'

import Loader from '../../ui/Loader'
import Modal from '../../ui/Modal'

import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ActionRenderer from './ActionRenderer'
import CustomInputRenderer from './CustomInputRenderer'
import DropdownRenderer from './DropdownRenderer'
import { MockEditingContext } from '../../../contexts/mockEditingContext'
import GridToolTip from './GridToolTip'

function escapeRegExp (value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

const UnifiedIds = props => {
  const dispatch = useDispatch()

  let { tableData = [], loading } = useSelector(
    ({ unifiedId: { unifiedIdData, loading } }) => ({
      tableData: unifiedIdData,
      loading
    })
  )
  let connector = useSelector(state => state.unifiedId.connector)

  const [searchText, setSearchText] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  const [data, setData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [selectedValue, setSelectedValue] = useState('All Displayed')
  const [isBlocking, setIsBlocking] = useState(false)
  const [alertUpdate, setAlertUpdateModal] = useState(false)
  const { navigator } = useContext(NavigationContext)
  const [url, setUrl] = useState('')
  const [modifiedDataCount, setModifiedDataCount] = useState(0)
  const context = useContext(MockEditingContext)

  const gridRef = useRef()
  const [gridApi, setGridApi] = useState()
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const [prevEditingId, setPrevEditingid] = useState(null)

  useEffect(() => {
    dispatch(fetchUnifiedIdData())
  }, [])

  useEffect(() => {
    if (tableData.length && connector.length) {
      const gridData = tableData.map(data => {
        const { id, uif, field_type, updated_at, uif_settings } = data || {}
        return {
          id,
          uif,
          field_type,
          updated_at,
          ...Object.assign(
            ...uif_settings.map(el => {
              const { connector_id, export_field, queue, import_field } =
                el || {}
              const { name: contact_name } =
                connector.find(({ id }) => id === connector_id) || {}
              let retObj = {}
              if (export_field) {
                retObj[`${contact_name}_export_field`] = export_field
              }
              if (import_field) {
                retObj[`${contact_name}_import_field`] = import_field
              }
              if (queue) {
                retObj[`${contact_name}_export_queue`] = queue
              }
              return retObj
            })
          )
        }
      })
      setData(gridData)
      setOriginalData(JSON.parse(JSON.stringify(gridData)))
      setFilteredRows(gridData)
    }
  }, [tableData, connector])

  const dropdownValues = () => {
    const optionValues = [...new Set(tableData.map(value => value.field_type))]
    optionValues.push('All Displayed')
    return optionValues
  }

  const dataTableRows = value => {
    if (value === 'All Displayed') {
      return setFilteredRows(data)
    } else {
      const dataRows = data.filter(
        row => row.field_type.trim() === value.trim()
      )
      return setFilteredRows(dataRows)
    }
  }

  const requestSearch = searchValue => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const rows = data.filter(row => {
      return Object.keys(row).some(field => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredRows(rows)
  }

  const { saveData } = usePrompt(
    'There is some unsaved data, do you want to save it?',
    isBlocking,
    setIsBlocking,
    setUrl
  )

  useEffect(() => {
    const modifiedObjectArr = differenceWith(data, originalData, isEqual)
    if (!url && modifiedObjectArr.length && isEditable) {
      setIsBlocking(true)
      setModifiedDataCount(modifiedObjectArr.length)
    } else {
      setIsBlocking(false)
    }
  }, [data, url, isEditable])

  useEffect(() => {
    if (saveData) {
      setAlertUpdateModal(true)
    }
  }, [saveData])

  const onEditOrSaveButtonClicked = () => {
    const modifiedObjectArr = differenceWith(data, originalData, isEqual)
    if (isEditable && modifiedObjectArr.length) {
      setAlertUpdateModal(true)
    } else {
      setIsEditable(true)
      gridRef.current.api.setColumnDefs(columnWithEdit)
    }
  }

  const setSaveOrEditFields = () => {
    if (isEditable) {
      if (context.mockEditingId) {
        commitChanges(context.mockEditingId)
      }
      setTimeout(() => {
        context.setMockEditingId(null)
        const modifiedObjectArr = differenceWith(data, originalData, isEqual)
        const updatedArr = modifiedObjectArr.map(obj => {
          const { id } = obj
          const originalIndex = originalData.findIndex(
            ({ id: objId }) => objId === id
          )
          const modifiedFields = pickBy(
            obj,
            (v, k) => !isEqual(originalData[originalIndex][k], v)
          )

          const sourceObjMap = connector.slice(0, 2).map(({ name, id }) => {
            const modifiedSourceField = pickBy(modifiedFields, (value, key) => {
              return key.includes(name)
            })
            let obj = { connector_id: id }
            Object.keys(modifiedSourceField).forEach(key => {
              const newKey = key.slice(name.length + 1)
              return (obj[newKey] = modifiedSourceField[key])
            })
            return obj
          })
          return {
            id,
            uif_settings: sourceObjMap
          }
        })
        gridRef &&
          gridRef.current &&
          gridRef.current.api.setColumnDefs(columnWithoutEdit)
        if (modifiedObjectArr.length) {
          dispatch(updateUnifiedData(updatedArr))
        }
        setIsEditable(false)
        setAlertUpdateModal(false)
      }, 0)
      return
    }
  }

  const saveAndNavigate = () => {
    setSaveOrEditFields()
    if (url) {
      navigator.push(url)
    }
  }

  const commitChanges = mockEditingId => {
    const mockEditingNode = gridApi.getRowNode(mockEditingId)
    const updatedData = { ...mockEditingNode.data }

    const mockEditors = gridApi.getCellRendererInstances({
      rowNodes: [mockEditingNode]
    })

    mockEditors.forEach(editor => {
      if (typeof editor.getValue === 'function') {
        const [field, updatedValue] = editor.getValue && editor.getValue()
        updatedData[field] = updatedValue
      }
    })

    const objIndex = data.findIndex(item => item.id === updatedData.id)

    data.splice(objIndex, 1, updatedData)

    setData(JSON.parse(JSON.stringify(data)))

    gridApi.applyTransaction({ update: [updatedData] })
  }

  const editHeader = () => {
    return <div />
  }

  const lastEditedRenderer = props => {
    const date = new Date(props.getValue())
    return <div>{date.toLocaleDateString()}</div>
  }

  const UnifiedIdHeader = () => (
    <div css={iconWrapper}>
      <img src={neyoMarkColor} alt='Neyo Icon' />
    </div>
  )

  const columnWithEdit = [
    {
      headerComponent: editHeader,
      cellRendererFramework: ActionRenderer,
      cellRendererParams: {
        commit: commitChanges
      },
      pinned: 'left',
      editable: false,
      width: 60,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContext: 'center'
      }
    },
    {
      headerGroupComponent: props => UnifiedIdHeader(),
      children: [
        {
          headerName: 'Unified ID Field',
          field: 'uif',
          tooltipField: 'uif',
          pinned: 'left',
          width: 298,
          cellRendererFramework: CustomInputRenderer
        }
      ]
    },
    {
      headerName: 'Field Type',
      field: 'field_type',
      tooltipField: 'field_type',
      pinned: 'left',
      width: 144,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      cellRendererFramework: DropdownRenderer
    }
  ]

  const columnWithoutEdit = [
    {
      headerGroupComponent: props => UnifiedIdHeader(),
      children: [
        {
          headerName: 'Unified ID Field',
          field: 'uif',
          pinned: 'left',
          width: 230,
          tooltipField: 'uif',
          cellRendererFramework: CustomInputRenderer
        }
      ]
    },
    {
      headerName: 'Field Type',
      field: 'field_type',
      pinned: 'left',
      tooltipField: 'field_type',
      cellEditorPopup: true,
      width: 190,
      cellRendererFramework: DropdownRenderer
    }
  ]

  const [columnDefs, setColumnDefs] = useState(columnWithoutEdit)

  useEffect(() => {
    if (tableData.length && connector.length) {
      const sources = (tableData[0] || {})['uif_settings']
      const gridSourcesColumn = sources.map((source, index) => {
        const { connector_id, export_field, import_field, export_queue } =
          source || {}
        const { name: contact_name } =
          connector.find(({ id }) => id === connector_id) || {}
        let sourceColumns = []
        if (import_field) {
          sourceColumns.push({
            headerName: 'Import Field',
            field: `${contact_name}_import_field`,
            tooltipField: `${contact_name}_import_field`,
            columnGroupShow: 'closed',
            width: 238,
            groupId: `${contact_name}_groupColumns`,
            headerClass: 'import_field',
            cellStyle: { paddingLeft: '25px' },
            cellRendererFramework: DropdownRenderer
          })
        }
        if (export_queue !== null) {
          let cellStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
          if (!export_field) {
            cellStyle['borderRight'] = '2px solid #fff'
          }
          sourceColumns.push({
            headerName: 'Queue?',
            field: `${contact_name}_export_queue`,
            cellRendererFramework: QueueRenderer,
            width: 94,
            groupId: `${contact_name}_groupColumns`,
            cellStyle
          })
        }
        // remove it after the demo
        if (export_field && index !== 1) {
          sourceColumns.push({
            headerName: 'Export Field',
            field: `${contact_name}_export_field`,
            tooltipField: `${contact_name}_export_field`,
            width: 238,
            groupId: `${contact_name}_groupColumns`,
            columnGroupShow: 'closed',
            cellRendererFramework: DropdownRenderer,
            cellStyle: { borderRight: '2px solid #fff' }
          })
        }
        let columnsObj = {
          headerGroupComponent: props =>
            HeaderGroupComponent(
              props,
              contact_name === 'salesforce' ? SalesforceIcon : bomboraIcon
            ),
          groupId: `${contact_name}groupColumns`,
          children: sourceColumns
        }
        return columnsObj
      })

      gridSourcesColumn.push({
        headerName: 'Last Edited',
        field: 'updated_at',
        editable: false,
        cellRendererFramework: lastEditedRenderer,
        width: 205
      })

      columnWithEdit.push(...gridSourcesColumn)
      columnWithoutEdit.push(...gridSourcesColumn)

      if (gridRef.current.api) {
        if (isEditable) {
          gridRef.current.api.setColumnDefs(columnWithEdit)
        } else {
          gridRef.current.api.setColumnDefs(columnWithoutEdit)
        }
      }
    }
  }, [tableData, isEditable, gridRef, connector])

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      cellStyle: {
        outline: 'none'
      },
      tooltipComponent: GridToolTip
    }
  }, [])

  const containerStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      padding: '112px 1rem',
      overflow: 'auto'
    }),
    []
  )

  const onGridReady = params => {
    setGridApi(params.api)
  }

  useEffect(() => {
    if (prevEditingId !== props.mockEditingId) {
      const idToUpdate =
        props.mockEditingId === null ? prevEditingId : props.mockEditingId
      const nodeToUpdate = gridApi.getRowNode(idToUpdate)
      const refreshCellsParams = { rowNodes: [nodeToUpdate], force: true }
      gridApi.refreshCells(refreshCellsParams)
      setPrevEditingid(props.mockEditingId)
    }
  }, [props.mockEditingId])

  if (loading) {
    return <Loader />
  }

  return (
    <main css={content}>
      {alertUpdate && (
        <Modal showCross={false}>
          <div css={modalText}>
            This change will impact {modifiedDataCount} records. This dictionary
            change will affect audiences, reporting and triggers. Once this
            change is complete, it cannot be undone.
          </div>
          <div css={buttonContainer}>
            <button
              onClick={() => setAlertUpdateModal(false)}
              style={{
                fontFamily: 'BentonSans',
                border: '0.8px solid #CCCCCC',
                backgroundColor: '#E5E5E5',
                color: '#333'
              }}
            >
              Keep Editing
            </button>
            <button
              onClick={() => setAlertUpdateModal(false)}
              style={{
                fontFamily: 'BentonSans',
                border: '0.8px solid #CCCCCC',
                backgroundColor: '#FFF',
                color: '#333'
              }}
            >
              Cancel
            </button>

            <button className='primary' onClick={saveAndNavigate}>
              File Changes
            </button>
          </div>
        </Modal>
      )}
      <Paper variant='outlined' css={subHeaderPaper}>
        <Typography variant='h6' css={subHeaderText}>
          Unified ID Fields
        </Typography>
        <Button
          color='primary'
          variant='contained'
          css={subHeaderButton}
          onClick={onEditOrSaveButtonClicked}
        >
          {isEditable ? 'Save Fields' : 'Edit Fields'}
        </Button>
      </Paper>
      <Paper variant='outlined' css={dataGridPaper}>
        <div css={filterSearch}>
          <div css={filterDropdown}>
            <span
              style={{
                marginTop: '12px',
                marginRight: '10px',
                color: '#333333',
                fontWeight: '500'
              }}
            >
              Filter
            </span>
            <DropdownWithSearch
              options={dropdownValues}
              placeholder='All Displayed'
              value={selectedValue}
              onOptionSelected={value => {
                setSelectedValue(value)
                dataTableRows(value)
              }}
            />
          </div>
          <QuickSearchToolbar
            value={searchText}
            onChange={event => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        </div>

        <div style={containerStyle}>
          <div className='ag-theme-alpine' css={grdStyle()} style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              style={{ width: '100%;', height: '100%;' }}
              rowData={filteredRows}
              rowHeight={54}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              groupHeaderHeight={80}
              domLayout={'autoHeight'}
              tooltipShowDelay={500}
              tooltipHideDelay={2000}
              suppressRowClickSelection='true'
              rowMultiSelectWithClick='false'
              rowClass='unifiedRow'
              getRowClass={params => {
                if (params.node.id % 2 === 0) return 'unifiedRowEven'
              }}
            ></AgGridReact>
          </div>
        </div>
      </Paper>
    </main>
  )
}

export default WithMockEditingContext(UnifiedIds)
