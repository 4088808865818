/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'

import SideDrawer from '../SideDrawer'
import AxiosInstance from '../../../axiosConfig'
import { useDispatch } from 'react-redux'

import { updateProduct } from '../../../store/onboardingDashboard'

import {
  businessTypeSection,
  businessTypeContent,
  businessTypeOptions,
  businessTypeStyle,
  productNameStyle,
  addProductOption,
  inputStyle
} from './styles'

const BusinessTypeSection = React.forwardRef(
  (
    {
      businessType,
      selectedSection,
      setBusinessType = () => {},
      scrollHandler = () => {},
      setSelectedSection = () => {}
    },
    ref
  ) => {
    const [productName, setProductName] = useState('')
    const dispatch = useDispatch()
    const [productNameSaved, setProductNameSaved] = useState(false)
    const clickHandler = type => {
      ref.current.setAttribute('data-clicked', 'true')
      setBusinessType(type)
      setSelectedSection('segment')
      setTimeout(() => {
        scrollHandler('segment')
      }, 100)
    }

    const handleSubmit = async () => {
      if (!productNameSaved) {
        const { data, status } = await AxiosInstance.post('/products', {
          product: { name: productName }
        })
        if (status === 200 || status === 201) {
          dispatch(updateProduct(data))
        }
      }
      setProductNameSaved(prev => !prev)
    }
    return (
      <section
        id='businessType'
        ref={ref}
        data-clicked={false}
        css={businessTypeSection}
      >
        <SideDrawer
          section={'businessType'}
          selectedSection={selectedSection}
        />
        <div
          css={businessTypeContent}
          data-selected={selectedSection === 'businessType'}
        >
          <span>
            Great job! Now you can segment your audiences based off how they've
            interacted with your organization.
          </span>
          <div css={productNameStyle}>
            <span>Product name:</span>
            <div css={addProductOption}>
              <input
                css={inputStyle}
                value={productName}
                onChange={({ target: { value } = {} }) => setProductName(value)}
              />
              <button className='submitButton' onClick={handleSubmit}>
                {productNameSaved ? 'Edit' : 'Submit'}
              </button>
              {productName && productNameSaved && (
                <span className='submissionStatus'>Submitted!!</span>
              )}
            </div>
          </div>
          <div css={businessTypeOptions}>
            <span>My business is</span>
            <div
              css={businessTypeStyle(businessType)}
              className='B2C'
              onClick={() => clickHandler('B2C')}
            >
              <strong>B2C</strong>
            </div>
            <div
              css={businessTypeStyle(businessType)}
              className='B2B'
              onClick={() => clickHandler('B2B')}
            >
              <strong>B2B</strong>
            </div>
            <div
              css={businessTypeStyle(businessType)}
              className='BOTH'
              onClick={() => clickHandler('BOTH')}
            >
              <strong>BOTH</strong>
            </div>
          </div>
          <span>
            (We want to make sure we're offering use cases that make sense for
            your adoption funnel.)
          </span>
        </div>
      </section>
    )
  }
)

export default BusinessTypeSection
