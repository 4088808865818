import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import initFacebookSdk from './_helpers/init-facebook-sdk'
import ErrorBoundary from './HOC/ErrorBoundary'
import { Provider } from 'react-redux'
import store from './store'

initFacebookSdk().then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <App />
          </Provider>
        </DndProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
