/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import Dropzone from '../../Droplist'
import EditableTextComponent from '../EditableTextComponent'
import { LibraryItems } from '../../../../../../../constant/mailer/libraryItem'
import { handleMoveSidebarComponentIntoParent } from '../../helpers'
import {
  emailBodyContainer,
  controlPanelStyle,
  controlItemStyle
} from './style'
import TrashIcon from '../../../../../../../assets/images/trash-icon.png'
import ArrowUp from '../../../../../../../assets/images/arrow-up.png'
import {
  updateEmailTemplateHandler,
  updateTemplateTreeHandler,
  handleElementDelete,
  updateMovedElement
} from '../../../../../../../store/dashboard/mailerSlice'

const textMap = {
  1: 'A phone case for every taste',
  2: 'Every phone, every shape, every color'
}

const EmailBody1 = ({ path: parentPath }) => {
  const {
    isDragging,
    currentEditedSegmentandAudience,
    emailTemplates,
    templateTree
  } = useSelector(state => ({
    isDragging: state.dragAndDrop.isDragging,
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    emailTemplates: state.mailer.emailTemplates,
    templateTree: state.mailer.templateTree
  }))
  const [showControlPanel, setShowControlPanel] = useState(false)
  const dispatch = useDispatch()
  const [layout, setLayout] = useState([
    {
      type: 'ROW',
      id: uuidv4(),
      children: [
        {
          type: 'COMPONENT',
          id: uuidv4(),
          uId: 1,
          parentKey: 'Quick add item',
          component: props => (
            <EditableTextComponent
              {...props}
              text={textMap[1]}
              style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '30px',
                color: 'rgb(0, 0, 0)',
                fontWeight: 700,
                lineHeight: '150%'
              }}
            />
          )
        },
        {
          type: 'COMPONENT',
          id: uuidv4(),
          uId: 1,
          parentKey: 'Quick add item',
          component: props => (
            <EditableTextComponent
              {...props}
              text={textMap[2]}
              style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                color: ' rgb(153, 153, 153)',
                lineHeight: '150%'
              }}
            />
          )
        }
      ]
    }
  ])

  useEffect(() => {
    setTimeout(
      () =>
        dispatch(
          updateEmailTemplateHandler(
            emailTemplates,
            currentEditedSegmentandAudience
          )
        ),
      300
    )
    if (layout[0].children.length) {
      setTimeout(() => {
        let components = []
        layout[0].children.forEach((element, index) => {
          const { uId, dropzonePath, parentKey, id } = element
          components.push({
            component: uId,
            index,
            dropzonePath,
            parentKey,
            id
          })
        })
        dispatch(updateTemplateTreeHandler(20, components))
      }, 300)
    }
  }, [layout])

  useEffect(() => {
    if (currentEditedSegmentandAudience) {
      const { segmentId, audienceId } = currentEditedSegmentandAudience
      const savedLayout = (templateTree[segmentId] || {})[audienceId] || {}
      if ((savedLayout[20] || []).length) {
        const components = savedLayout[20]
        if (components.length) {
          let newLayout = []
          components.forEach(
            ({ component, index, parentKey, id, dropzonePath }) => {
              const parentComponent = LibraryItems.find(
                ({ title }) => title === parentKey
              )
              const renderingComponent = (
                (parentComponent || {}).subMenus || []
              ).find(({ component: { uId } }) => uId === component)
              const newComponent = {
                id,
                type: 'COMPONENT',
                dropzonePath,
                ...renderingComponent.component
              }
              newLayout = handleMoveSidebarComponentIntoParent(
                newLayout,
                [index.toString()],
                newComponent
              )
            }
          )
          setLayout([{ ...layout[0], children: newLayout }])
        }
      } else {
        setLayout([{ ...layout[0] }])
      }
    }
  }, [currentEditedSegmentandAudience])

  const handleDrop = (dropZone, item) => {
    const splitDropzonePath = dropZone.path.toString().split('-')
    if (
      item.type === 'SIDEBAR_ITEM' &&
      (item.category === 'item' || item.category === 'layout')
    ) {
      const newComponent = {
        id: uuidv4(),
        type: 'COMPONENT',
        dropzonePath: splitDropzonePath,
        ...item.component
      }
      setLayout(
        handleMoveSidebarComponentIntoParent(
          layout,
          splitDropzonePath,
          newComponent
        )
      )
    }
  }

  const renderComponent = (component, path) => {
    return (
      <div data-path={path}>
        {component.component({
          parentPath,
          componentPath: path,
          parentUid: 20
        })}
      </div>
    )
  }

  const renderRow = (data, path) => {
    return (
      <React.Fragment>
        {data.children.map((component, index) => {
          const currentPath = `${path}-${index}`
          return (
            <React.Fragment key={component.id}>
              {isDragging && (
                <Dropzone
                  data={{
                    path: currentPath
                  }}
                  onDrop={handleDrop}
                />
              )}
              {renderComponent(component, currentPath)}
            </React.Fragment>
          )
        })}
        {isDragging && (
          <Dropzone
            data={{
              path: `${path}-${data.children.length}`
            }}
            onDrop={handleDrop}
          />
        )}
      </React.Fragment>
    )
  }

  const handleContainerDelete = () => {
    dispatch(handleElementDelete({ componentId: 20, index: parentPath - 1 }))
  }

  const handleContainerMove = direction => {
    dispatch(
      updateMovedElement({ componentId: 20, index: parentPath - 1, direction })
    )
  }

  return (
    <div
      css={emailBodyContainer}
      onMouseEnter={() => setShowControlPanel(true)}
      onMouseLeave={() => setShowControlPanel(false)}
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '24px',
        backgroundPosition: 'unset',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      {layout.map((row, index) => {
        const currentPath = `${index}`
        return (
          <React.Fragment key={row.id}>
            {renderRow(row, currentPath)}
          </React.Fragment>
        )
      })}
      {showControlPanel && (
        <div css={controlPanelStyle}>
          <div css={controlItemStyle} onClick={handleContainerDelete}>
            <img src={TrashIcon} />
          </div>
          <div css={controlItemStyle} onClick={() => handleContainerMove('up')}>
            <img src={ArrowUp} />
          </div>
          <div
            css={controlItemStyle}
            onClick={() => handleContainerMove('down')}
          >
            <img src={ArrowUp} className='arrowDown' />
          </div>
        </div>
      )}
    </div>
  )
}

export default EmailBody1
