import { css } from '@emotion/react'

export const container = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 24px;
`

export const sidePanelTitle = css`
  font-family: Roboto;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #555c66;
  font-size: 15px;
  padding-bottom: 18px;
`

export const canvasWidthContainer = css`
  display: inline-flex;
  flex-direction: column;
  min-width: 214px;
  padding-bottom: 24px;
  .label {
    display: block;
    color: #555c66;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 4px;
  }
`

export const textFieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  height: 36px;
  padding: 6px;
  input {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #555c66;
    font-family: Roboto;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    padding: 0 6px;
    width: 100%;
    font-size: 13px;
    line-height: 22px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`

export const additionalTextStyle = css`
  color: #555c66;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin: 4px 0 0;
`

export const themeSettingStyle = css`
  font-family: Roboto;
  font-weight: 400;
  color: #555c66;
  font-size: 13px;
  line-height: 22px;
  padding-bottom: 8px;
`

export const colorTogglerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-bottom: 10px;
  max-width: 392px;
  height: 54px;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  width: 100%;
`

export const colorTextStyle = css`
  font-family: Roboto;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  font-size: 13px;
  line-height: 22px;
  padding-left: 24px;
`
