import { css } from '@emotion/react'

export const container = css`
  display: flex;
`

export const sideDrawer = () => css`
  width: 115px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-items: center;
  padding-top: 36px;
`

export const textStyle = css`
  height: 11px;
  width: 65px;
  color: #333333;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 27px;
  display: block;
`

export const iconStyle = css`
  display: block;
  width: 38px;
  height: 31px;
  margin-bottom: 13px;
  img {
    width: 100%;
  }
`

export const campaignContainerStyle = css`
  box-sizing: content-box;
  overflow: auto;
  padding: 14px 12px;
  width: 1407px;
  padding-left: 12px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`

export const campaignHeaderStyle = (expandedView, saveCampaignName) => css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 95px;
  border: 0.8px solid #cccccc;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -2px #0099ff;
  .toggleHeight {
    height: 0px;
    width: 0px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
  ${expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
  &:hover {
    ${
      !expandedView &&
      saveCampaignName &&
      '.toggleHeight { height: 28px; width: 28px; }'
    }
  }
`

export const previewChannelContainer = channel => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 70px;
  height: 68px;
  width: 68px;
  position: relative;
  ${
    channel &&
    'border-radius: 4px; border: 0.8px solid #cccccc; background-color: #ffffff;box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);'
  }
`

export const previewChannelStyle = css`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const campaignSummaryStyle = css`
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 239px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  padding: 6px 16px;
  background-color: #fff;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

export const campaignTitleStyle = css`
  color: #333;
  font-size: 13px;
  font-weight: 600;
  font-family: BentonSans;
  text-transform: capitalize;
`

export const campaignTargetStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 13px;
  font-family: BentonSans;
  padding-top: 3px;
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  padding-left: 5px;
  border-radius: 4px;
  margin-top: 2px;
  font-family: BentonSans;
  &.campaignName {
    margin-right: 4px;
  }
`

export const createAddContainer = (saveCampaignName, expandedView) => css`
  display: flex;
  flex-direction: column;
  height: 0px;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 0.8px solid #cccccc;
  background-color: #e5e5e5;
  box-shadow: 0 2px 6px -2px #0099ff;
  overflow: hidden;
  transition: all 0.4s ease-out;
  ${
    saveCampaignName &&
    expandedView &&
    'min-height: 200px; height: auto; padding-bottom: 12px;'
  }
`

export const adRowStyle = css`
  display: flex;
  min-width: 100%;
`

export const saveCampaignNameButton = compaignName => css`
  box-sizing: border-box;
  height: 34px;
  width: 50px;
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  color: #ffffff;
  border: 0.8px solid #cccccc;
  ${!compaignName && 'color: #333333; background-color: #EBEBEB;'}
  ${compaignName && 'background-color: #18A3E2;'}
  border-radius: 5px;
  padding-top: 2px;
  cursor: pointer;
`

export const dummyAdsForm = css`
  box-sizing: border-box;
  height: 82px;
  width: 231.4px;
  min-width: 231.4px;
  padding: 13px 24px 20px 21px;
  margin: 10px;
  margin-bottom: 0px;
`
