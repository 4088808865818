/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import DeleteIcon from '../../../../assets/images/trash-icon.png'
import DropdownWithSearch from '../../../ui/DropdownWithSearch/DropdownWithSearch'
import {
  addDeleteIconContainer,
  conditionContainer,
  inputStyle,
  segmentNameStyle,
  iconStyle,
  dropdownStyle,
  inputOption
} from './style'

const SegmentConditionForm = ({
  data,
  ruleIndex,
  ruleGroupIndex,
  modalForm,
  updateConditionsData = () => {},
  addCondition = () => {},
  deleteCondition = () => {}
}) => {
  const { prospectTag, condition, value } = data
  const [conditionData, setConditionData] = useState({
    prospectTag: '',
    condition: '',
    value: ''
  })
  useEffect(() => {
    setConditionData({
      prospectTag,
      condition,
      value
    })
  }, [prospectTag, condition, value])

  let segmentColumnNames =
    useSelector(state => state.segments.segmentColumnNames) || []

  let segmentSupportedConditions =
    useSelector(state => state.segments.segmentSupportedConditions) || []

  const handleOnChange = (key, value) => {
    setConditionData(prev => {
      return { ...prev, [key]: value }
    })
    updateConditionsData({ ruleGroupIndex, ruleIndex, key, value })
  }

  return (
    <div css={conditionContainer}>
      <div css={addDeleteIconContainer}>
        <Icon onClick={() => addCondition(ruleGroupIndex, ruleIndex)}>add</Icon>
        <span
          css={iconStyle}
          onClick={() => deleteCondition(ruleGroupIndex, ruleIndex)}
        >
          <img src={DeleteIcon} alt='delete-icon' className='del' />
        </span>
      </div>
      <div css={inputOption(modalForm)}>
        <DropdownWithSearch
          options={segmentColumnNames}
          style={dropdownStyle(ruleGroupIndex)}
          value={conditionData.prospectTag}
          onOptionSelected={value => handleOnChange('prospectTag', value)}
          // selectPlaceholder={'Prospect Tag'}
        />
        <DropdownWithSearch
          options={segmentSupportedConditions}
          style={dropdownStyle(ruleGroupIndex)}
          value={conditionData.condition}
          onOptionSelected={value => handleOnChange('condition', value)}
          // placeholder={'Is'}
        />
        <input
          onChange={({ target: { value } = {} }) =>
            handleOnChange('value', value)
          }
          value={conditionData.value}
          css={[inputStyle, segmentNameStyle(modalForm, true)]}
          // placeholder={'marketable contact'}
        />
      </div>
    </div>
  )
}

export default SegmentConditionForm
