import React from 'react'

import useStyles from './styles'
import SignUpContent from './SignUpContent/SignUpContent'
import LandingPageHeader from '../ui/Header/LandingPageHeader/Header'
import Footer from '../ui/LandingPageFooter/Footer'

const SignUp = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LandingPageHeader
        title='Neyo'
        toPage=''
        actionText='Sign In'
        actionDescriptionText='Already have an account?'
      />
      <SignUpContent />
      <Footer contentText='© 2023 Neyo' />
    </div>
  )
}

export default SignUp
