import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'BentonSans'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: '164px',
    height: '48px',
    fontFamily: 'BentonSans'
  },
  socialButton: {
    fontSize: '0.5rem'
  },
  signUpText: {
    paddingTop: theme.spacing(12),
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Lato Black',
    color: '#333333'
  },
  signUpTextDesc: {
    paddingBottom: theme.spacing(5),
    textAlign: 'center',
    fontFamily: 'BentonSans',
    marginTop: '14px',
    color: '#333333'
  },
  bottomText: {
    padding: theme.spacing(8),
    textAlign: 'center',
    color: '#333333',
    fontWeight: 'bold'
  },
  formContainer: {
    padding: '2rem 4rem'
  },
  textField: {
    '& label': {
      fontFamily: 'BentonSans'
    }
  }
}))

export default useStyles
