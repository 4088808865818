import moment from 'moment'

const getPrevRange = timeframe => {
  let range = ''
  if (timeframe === 'year') {
    const prevYear = new Date(
      moment().subtract(1, timeframe + 's')
    ).getFullYear()
    const start = moment([prevYear])
      .startOf('year')
      .format('MM/DD/YYYY')
    const end = moment([prevYear])
      .endOf('year')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }
  if (timeframe === 'month') {
    const prevMonth = moment().subtract(1, timeframe + 's')
    const start = moment(prevMonth)
      .startOf('month')
      .format('MM/DD/YYYY')
    const end = moment(prevMonth)
      .endOf('month')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'week') {
    const currentWeekStart = moment().startOf('week')
    const currentWeekEnd = moment().endOf('week')
    const start = moment(currentWeekStart)
      .subtract(1, 'week')
      .format('MM/DD/YYYY')
    const end = moment(currentWeekEnd)
      .subtract(1, 'week')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'quarter') {
    const currentQuarterStart = moment().startOf('quarter')
    const currentQuarterEnd = moment().endOf('quarter')
    const start = moment(currentQuarterStart)
      .subtract(1, 'quarter')
      .format('MM/DD/YYYY')
    const end = moment(currentQuarterEnd)
      .subtract(1, 'quarter')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'day') {
    const currentDayStart = moment().startOf('day')
    const currentDayEnd = moment().endOf('day')
    const start = moment(currentDayStart)
      .subtract(1, 'day')
      .format('MM/DD/YYYY')
    const end = moment(currentDayEnd)
      .subtract(1, 'day')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  return range
}

const getNextRange = timeframe => {
  let range = ''
  if (timeframe === 'year') {
    const nextYear = new Date(moment().add(1, timeframe + 's')).getFullYear()
    const start = moment([nextYear])
      .startOf('year')
      .format('MM/DD/YYYY')
    const end = moment([nextYear])
      .endOf('year')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }
  if (timeframe === 'month') {
    const nextMonth = moment().add(1, timeframe + 's')
    const start = moment(nextMonth)
      .startOf('month')
      .format('MM/DD/YYYY')
    const end = moment(nextMonth)
      .endOf('month')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'week') {
    const currentWeekStart = moment().startOf('week')
    const currentWeekEnd = moment().endOf('week')
    const start = moment(currentWeekStart)
      .add(1, 'week')
      .format('MM/DD/YYYY')
    const end = moment(currentWeekEnd)
      .add(1, 'week')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'quarter') {
    const currentQuarterStart = moment().startOf('quarter')
    const currentQuarterEnd = moment().endOf('quarter')
    const start = moment(currentQuarterStart)
      .add(1, 'quarter')
      .format('MM/DD/YYYY')
    const end = moment(currentQuarterEnd)
      .add(1, 'quarter')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'day') {
    const currentDayStart = moment().startOf('day')
    const currentDayEnd = moment().endOf('day')
    const start = moment(currentDayStart)
      .add(1, 'day')
      .format('MM/DD/YYYY')
    const end = moment(currentDayEnd)
      .add(1, 'day')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }
  return range
}

const getCustomPrevYear = (timeframe, count = 5) => {
  let range = ''
  if (timeframe === 'year') {
    const start = moment()
      .startOf('year')
      .subtract(count, timeframe + 's')
      .format('MM/DD/YYYY')
    const end = moment()
      .endOf('year')
      .subtract(1, timeframe + 's')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }
  if (timeframe === 'month') {
    const start = moment()
      .startOf('month')
      .subtract(count, timeframe + 's')
      .format('MM/DD/YYYY')
    const end = moment()
      .endOf('month')
      .subtract(1, 'months')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'week') {
    const currentWeekEnd = moment().endOf('week')
    const start = moment()
      .startOf('week')
      .subtract(count, 'week')
      .format('MM/DD/YYYY')
    const end = moment(currentWeekEnd)
      .subtract(1, 'weeks')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'quarter') {
    const currentQuarterEnd = moment().endOf('quarter')
    const start = moment()
      .startOf('quarter')
      .subtract(count, 'quarter')
      .format('MM/DD/YYYY')
    const end = moment(currentQuarterEnd)
      .subtract(1, 'quarters')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'day') {
    const currentDayEnd = moment().endOf('day')
    const start = moment()
      .startOf('day')
      .subtract(count, 'day')
      .format('MM/DD/YYYY')
    const end = moment(currentDayEnd)
      .subtract(1, 'days')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  return range
}

const getCustomNextRange = (timeframe, count = 3) => {
  let range = ''
  if (timeframe === 'year') {
    const start = moment()
      .add(1, timeframe + 's')
      .startOf('year')
      .format('MM/DD/YYYY')
    const end = moment()
      .endOf('year')
      .add(count, timeframe + 's')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }
  if (timeframe === 'month') {
    const start = moment()
      .add(1, timeframe + 's')
      .startOf('month')
      .format('MM/DD/YYYY')
    const end = moment()
      .add(count, 'months')
      .endOf('month')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'week') {
    const start = moment()
      .startOf('week')
      .add(1, 'week')
      .format('MM/DD/YYYY')
    const end = moment()
      .endOf('week')
      .add(count, 'weeks')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'quarter') {
    const currentQuarterEnd = moment().endOf('quarter')
    const start = moment()
      .startOf('quarter')
      .add(1, 'quarter')
      .format('MM/DD/YYYY')
    const end = moment(currentQuarterEnd)
      .add(count, 'quarters')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  if (timeframe === 'day') {
    const currentDayEnd = moment().endOf('day')
    const start = moment()
      .startOf('day')
      .add(1, 'day')
      .format('MM/DD/YYYY')
    const end = moment(currentDayEnd)
      .add(count, 'days')
      .format('MM/DD/YYYY')
    range = `${start} - ${end}`
  }

  return range
}

export const getDateRange = (timeframe, value) => {
  const trimmedTimeFrame = timeframe.slice(0, -1)
  let range = ''
  switch (value) {
    case `prev_${trimmedTimeFrame}`:
      range = getPrevRange(trimmedTimeFrame)
      break
    case `next_${trimmedTimeFrame}`:
      range = getNextRange(trimmedTimeFrame)
      break
    case `last_5_${trimmedTimeFrame}s`:
      range = getCustomPrevYear(trimmedTimeFrame)
      break
    case `next_3_${trimmedTimeFrame}s`:
      range = getCustomNextRange(trimmedTimeFrame)
      break
    default:
      break
  }

  return range
}
