/** @jsxImportSource @emotion/react */
import React from 'react'

import {
  container,
  welcomeSection,
  crm,
  crmSection,
  leadIntelligenceSection,
  socialAndAnalytics,
  adsSection,
  emailSection,
  analyticsSection,
  businessTypeSection,
  segmentSection,
  takeToDashboardSection
} from './styles'

import neyoIcon from '../../../assets/images/NeyoIcon.png'
import crmIcon from '../../../assets/images/onboardingDashboard/crmIcons/crmIcon.png'
import leadIntelligenceIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/leadIntelligenceIcon.png'
import segmentIcon from '../../../assets/images/onboardingDashboard/segmentIcon.png'
import adsIcon from '../../../assets/images/onboardingDashboard/ads/adsIcon.png'
import emailIcon from '../../../assets/images/onboardingDashboard/email/emailIcon.png'
import analyticsIcon from '../../../assets/images/onboardingDashboard/analytics/analyticsIcon.png'

const SideDrawer = ({ section, selectedSection }) => {
  return (
    <div css={container}>
      {section === 'welcome' && (
        <div css={welcomeSection(selectedSection)}>
          <img alt='neyoIcon' src={neyoIcon} />
        </div>
      )}
      {section === 'crm' && (
        <div css={crm(selectedSection)}>
          <div css={crmSection}>
            <img alt='crmIcon' src={crmIcon} />
            <span>CRM</span>
          </div>
          <div css={leadIntelligenceSection}>
            <img alt='leadIntelligenceIcon' src={leadIntelligenceIcon} />
            <span>Lead Intelligence</span>
          </div>
        </div>
      )}
      {section === 'socialAndAnalytics' && (
        <div css={socialAndAnalytics(selectedSection)}>
          <div css={adsSection}>
            <img alt='adsIcon' src={adsIcon} />
            <span>Ads</span>
          </div>
          <div css={emailSection}>
            <img alt='emailIcon' src={emailIcon} />
            <span>Email</span>
          </div>
          <div css={analyticsSection}>
            <img alt='analyticsIcon' src={analyticsIcon} />
            <span>Analytics</span>
          </div>
        </div>
      )}
      {section === 'businessType' && (
        <div css={businessTypeSection(selectedSection)}>
          <img alt='segmentIcon' src={segmentIcon} />
          <span>Segment</span>
        </div>
      )}
      {section === 'segment' && (
        <div css={segmentSection(selectedSection)}>
          <img alt='segmentIcon' src={segmentIcon} />
          <span>Segment</span>
        </div>
      )}
      {section === 'takeToDashboard' && (
        <div css={takeToDashboardSection(selectedSection)}></div>
      )}
    </div>
  )
}

export default SideDrawer
