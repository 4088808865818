import { css } from '@emotion/react'

export const filterContainer = showAnalyticsGraphs => css`
  display: flex;
  width: 100%;
  height: 0;
  overflow: hidden;
  ${showAnalyticsGraphs && 'height: unset; overflow: unset;'}
`

export const filterItemContainer = css`
  display: flex;
  align-items: center;
  &:first-of-type {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-left: 60px;
  }
  &.segment-filter {
    width: 746px;
  }
`

export const label = css`
  flex-shrink: 0;
  margin-right: 40px;
  p {
    margin: 0;
  }
  .heading {
    font-size: 14px;
    font-weight: 600;
  }
  .subHeading {
    font-size: 12px;
    color: #333;
  }
`

export const segmentContainerStyle = css`
  display: flex;
  flex: 1;
`

export const segmentStyle = css`
  border: 0.8px solid #cccccc;
  background-color: rgb(23 163 225);
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  padding: 5px;
  min-width: 118px;
  border-radius: 4px;
  height: 32px;
  text-align: center;
  line-height: 20px;
  &.lost-customer {
    background: rgba(151, 151, 151);
    color: #fff;
  }
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &.fade {
    opacity: 0.2;
  }
`

export const blankSegmentStyle = css`
  min-width: 118px;
  height: 27px;
`

export const campaignContainerStyle = css`
  display: flex;
  align-items: center;
`

export const campaignIcon = type => css`
  background: #17a3e1;
  width: 27px;
  height: 27px;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  &.fade {
    opacity: 0.2;
  }
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  ${type === 'mail' && `background: #17a3e1;`}

  span {
    position: absolute;
    opacity: 0;
    font-size: 8px;
    white-space: nowrap;
    padding: 8px;
    transform: translateY(20px);
    left: -10px;
    transition: all 0.5s;
  }

  &:hover {
    span {
      opacity: 1;
      transform: translateY(30px);
      background: #fff;
      box-shadow: 0 2px 4px 1px rgba(102, 153, 204, 0.4);
    }
  }
`
