import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Avatar from '@material-ui/core/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { clearDbHandler } from '../../../../store/authSlice'
import { clearSyncReportData } from '../../../../store/onboardingDashboard'

import { ReactComponent as SettingsIcon } from '../../../../assets/images/cdpHeader/SettingsIcon.svg'
import useStyles from './styles'

const Header = ({ logOutHandler = () => {}, headerTitle = '' }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { userRole } = useSelector(({ auth: { userRole } }) => ({ userRole }))

  const [auth] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    setAnchorEl(null)
    logOutHandler()
  }

  const handleClearDB = () => {
    dispatch(clearDbHandler())
    dispatch(clearSyncReportData())
  }

  return (
    <React.Fragment>
      <AppBar color='secondary' position='relative' className={classes.appBar}>
        <Toolbar>
          <Typography variant='body2' className={classes.avatarText}>
            Jimmy Butler
          </Typography>
          <IconButton aria-label='account of current user' color='inherit'>
            <Avatar className={classes.avatar} alt='Remy Sharp' src=''>
              JB
            </Avatar>
          </IconButton>
          <IconButton
            aria-label='delete'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
          >
            <SettingsIcon height={20} width={20} />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorReference='anchorEl'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={open}
            onClose={handleClose}
            style={{ top: '4rem', right: '2rem' }}
          >
            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
            {userRole == 'super_user' && (
              <MenuItem onClick={handleClearDB}>Clear DB</MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      {/* <div className={classes.toolbarMargin}></div> */}
    </React.Fragment>
  )
}

export default Header
