import { createSlice } from '@reduxjs/toolkit'

import {
  mapDataFromDataSourceForSegmentAudiences,
  mapDataFromDataSourceForAudienceData,
  mapDataForDatasource,
  mapDataForDeleteOperation
} from '../../mapper/dashboard/audience'
import axiosInstance from '../../axiosConfig'

const initialSegments = {
  segmentAudienceData: {},
  audienceDataFromApi: null
}

const audiencesSlice = createSlice({
  name: 'audiences',
  initialState: initialSegments,
  reducers: {
    getInitialData(state, action) {
      state.segmentAudienceData = action.payload.segmentAudienceMappedData
      state.audienceDataFromApi = action.payload.audiencesMappedData
    }
  }
})

export const getAudienceData = productId => {
  return async dispatch => {
    try {
      const [segments, audiences] = await Promise.all([
        axiosInstance.get(`/segments/?product_id=${productId}`),
        axiosInstance.get(`/audiences?product_id=${productId}`)
      ])
      if (segments.status === 200 && audiences.status === 200) {
        const segmentAudienceMappedData =
          mapDataFromDataSourceForSegmentAudiences(
            segments.data,
            audiences.data
          )

        const audiencesMappedData = mapDataFromDataSourceForAudienceData(
          segments.data,
          audiences.data
        )
        dispatch(
          audiencesSlice.actions.getInitialData({
            segmentAudienceMappedData,
            audiencesMappedData
          })
        )
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const updateAudiences = (payload, productId) => {
  return async dispatch => {
    try {
      console.log('payload------updateAudiences', payload, productId)
      const audience = mapDataForDatasource(payload, productId)
      console.log('payload', payload, 'audience', audience)
      const { data, status } = await axiosInstance.post(
        `/audiences/universal`,
        audience
      )
      console.log(' data, status', data, status)
      if (status === 200) {
        dispatch(getAudienceData(productId))
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const deleteAudience = (payload, productId) => {
  return async dispatch => {
    try {
      let audience = []
      payload.forEach(audienceData => {
        audience.push(mapDataForDeleteOperation(audienceData, productId))
      })
      const { data, status } = await axiosInstance.post(
        `/audiences/universal`,
        { audience }
      )
      console.log('deleteAudience -- response', data, status)
      if (status === 200) {
        dispatch(getAudienceData(productId))
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const queueActions = audiencesSlice.actions

export default audiencesSlice.reducer
