import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    width: '164px',
    height: '48px',
    cursor: 'pointer',
    fontFamily: 'BentonSans',
    border: '0px solid #CCCCCC',
    backgroundColor: props =>
      props.enteredEmail.length > 0 && props.enteredPassword.length > 0
        ? '#18A3E2'
        : 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    color: props =>
      props.enteredEmail.length > 0 && props.enteredPassword.length > 0
        ? '#ffffff'
        : 'rgba(0, 0, 0, 0.26)',
    fontSize: '0.9rem',
    boxShadow: '0 2px 6px rgba(102, 153, 204, 0.4)'
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    border: '1px solid #CCCCCC',
    backgroundColor: '#18A3E2',
    borderRadius: '4px',
    boxShadow: '0 2px 6px rgba(102, 153, 204, 0.4)',
    padding: '0px 3px',
    '& .signUpText': {
      height: '17px',
      width: '152px',
      color: '#FFFFFF',
      fontFamily: 'BentonSans',
      fontSize: '0.9rem',
      fontWeight: '300',
      letterSpacing: '0',
      lineHeight: '1.3'
    },
    '& .image': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '42px',
      marginRight: '20px',
      backgroundColor: '#FFFFFF'
    }
  },
  socialButton: {
    fontSize: '0.5rem'
  },
  signInText: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(6),
    textAlign: 'center',
    color: '#333333',
    fontWeight: '900',
    fontFamily: 'Lato Black'
  },
  bottomText: {
    padding: theme.spacing(8),
    textAlign: 'center',
    color: '#333333',
    fontWeight: 'bold'
  },
  formContainer: {
    padding: '2rem 4rem',
    width: '625px'
  }
}))

export default useStyles
