import { css } from '@emotion/react'

export const container = css`
  width: 100%;
  background: #fff;
  height: 100px;
  margin: 0 10px;
  border: 0.8px solid #ccc;
  border-radius: 4px;
`

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
`

export const labelStyle = css`
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  color: #333;
  opacity: 0.8;
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  padding-left: 5px;
  border-radius: 4px;
  margin-top: 2px;
  font-family: BentonSans;
  padding-left: 20px;
`
export const pacingChartStyle = css`
  width: 100%;
  height: 50px;
  border-left: 2px solid #ccc;
  margin: 0 10px;
  position: relative;
  .budgetLine {
    width: 2px;
    height: 100%;
    background: red;
    position: absolute;
    right: 40px;
  }
  .budgetlabel {
    position: absolute;
    right: 45px;
    top: 0px;
    margin: 0;
    font-size: 10px;
  }
`

export const pacingProgressStyle = css`
  height: 20px;
  position: absolute;
  width: 65.2%;
  background: #333;
  top: 15px;
  text-align: center;
  color: #fff;
`
