import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import useStyles from './styles'
import { Paper } from '@material-ui/core'

// import linkedinIcon from '../../../assets/images/linkedinIcon.png'
// import googleIcon from '../../../assets/images/googleIcon.png'

const SignInContent = ({ signInHandler = () => {} }) => {
  const [enteredEmail, setEnteredEmail] = useState('')
  const [enteredPassword, setEnteredPassword] = useState('')
  const [showPassword, setPasswordVisibility] = useState(false)

  const classes = useStyles({ enteredEmail, enteredPassword })

  const emailChangeHandler = event => {
    setEnteredEmail(event.target.value)
  }

  const passwordChangeHandler = event => {
    setEnteredPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const submitHandler = event => {
    event.preventDefault()
    const data = {
      username: enteredEmail,
      password: enteredPassword
    }
    signInHandler(data)
  }

  return (
    <Box className={classes.paper}>
      <div>
        <Typography variant='h2' className={classes.signInText}>
          Sign In
        </Typography>
        <Paper
          elevation={0}
          variant='outlined'
          className={classes.formContainer}
        >
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.socialIcons}>
                <div className='image'>
                  <img
                    alt='linkedin-icon'
                    src={linkedinIcon}
                    height={30}
                    width={30}
                  />
                </div>
                <div className='signUpText'>Sign Up with LinkedIn</div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.socialIcons}>
                <div className='image'>
                  <img alt='google-icon' src={googleIcon} />
                </div>
                <div className='signUpText'>Sign Up with Google</div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontSize: '1.2rem',
                  fontFamily: 'BentonSans'
                }}
              >
                or
              </Typography>
            </Grid>
          </Grid> */}
          <form className={classes.form} onSubmit={submitHandler} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={enteredEmail}
              onChange={emailChangeHandler}
              InputProps={{ style: { fontFamily: 'BentonSans' } }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type={showPassword ? 'text' : 'password'}
              id='password'
              value={enteredPassword}
              onChange={passwordChangeHandler}
              autoComplete='current-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { fontFamily: 'BentonSans' }
              }}
            />
            <Link
              href='#'
              variant='body2'
              style={{
                fontFamily: 'BentonSans',
                marginRight: 'auto'
              }}
            >
              Forgot password?
            </Link>
            <button
              type='submit'
              value='Submit'
              disabled={
                enteredEmail.length > 0 && enteredPassword.length > 0
                  ? false
                  : true
              }
              className={classes.submit}
            >
              Sign In
            </button>
          </form>
        </Paper>
      </div>
      <Typography
        variant='h6'
        style={{ fontWeight: '900', padding: '4rem', fontFamily: 'Lato Black' }}
      >
        EXPLORE THE <span style={{ color: '#2196f3' }}>FUTURE</span> OF CUSTOMER
        DATA PLATFORMS
      </Typography>
    </Box>
  )
}

export default SignInContent
