/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import {
  container,
  inputStyle,
  chipStyle,
  dropdownMenu,
  optionsPanel
} from './style'

const Menu = props => {
  return <div css={dropdownMenu} {...props} className='dropdown-menu' />
}

const Dropdown = ({ children, isOpen, onClose, style }) => (
  <>{isOpen ? <Menu>{children}</Menu> : null}</>
)

const InputWithChips = ({
  options,
  value,
  style,
  onOptionSelected = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [chips, setChips] = useState(value || [])
  const [suggestedList, setSuggestedList] = useState([])
  const ref = useRef(null)
  useEffect(() => {
    setSuggestedList(options)
  }, [options])

  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const onInputChange = ({ target: { value } = {} } = {}) => {
    if (value) setIsOpen(true)
    setInputValue(value)
    const newSuggestedList = options.filter(ele =>
      ele.toLocaleLowerCase().includes(value.toLowerCase())
    )
    setSuggestedList(newSuggestedList)
  }

  const setChipsValue = value => {
    const values = [...chips, value]
    setChips(values)
    onOptionSelected(values)
    setInputValue('')
    setSuggestedList(options)
  }

  const onRemovingChips = index => {
    const cloneChips = [...chips]
    cloneChips.splice(index, 1)
    onOptionSelected(cloneChips)
    setChips(cloneChips)
  }
  return (
    <div css={[container, style]}>
      {(chips || []).map((ele, index) => (
        <div css={chipStyle} key={index}>
          <span>{ele}</span>
          <span className='crossIcon' onClick={() => onRemovingChips(index)}>
            x
          </span>
        </div>
      ))}
      <div className='inputContainer'>
        <input
          css={inputStyle}
          onKeyUp={({ code }) => {
            if (code === 'Enter') {
              setChipsValue(inputValue)
              setIsOpen(false)
            }
          }}
          onFocus={() => setIsOpen(true)}
          onChange={onInputChange}
          value={inputValue}
        />
        <Dropdown style={container} isOpen={isOpen} onClose={toggleOpen}>
          <div css={optionsPanel} ref={ref}>
            <ul>
              {(suggestedList || []).length ? (
                suggestedList.map((ele, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setChipsValue(ele)
                      setIsOpen(false)
                    }}
                  >
                    {ele}
                  </li>
                ))
              ) : (
                <li>No Matching Result</li>
              )}
            </ul>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default InputWithChips
