/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'

import Dropdown from '../../../../../ui/Dropdown/Dropdown'
import SegmentConditionForm from './ConditionForm'

import trashIcon from '../../../../../../assets/images/trash-icon.png'
import editIcon from '../../../../../../assets/images/editIcon.png'
import expandModalIcon from '../../../../../../assets/images/dashboard/audience/expandModal.png'

import {
  formContainer,
  audienceTypeSection,
  overlay,
  expandModalIconStyle,
  segmentNameStyle,
  inputStyle,
  matchTypeStyle,
  unionStyle,
  extraRuleContainer,
  extraRuleStyle,
  buttonStyle,
  actionButtonContainer,
  nonEditableInputStyle,
  conditionWrapper,
  ruleGroupWrapper,
  ruleGroupContainer,
  nonEditableRuleOption
} from './style'

const AudienceForm = ({
  data,
  id,
  audienceId,
  modalForm = false,
  audienceSelectedList,
  updateData = () => {},
  updateAudienceHandler = () => {},
  editAudienceHandler = () => {},
  deleteAudienceHandler = () => {},
  deleteNewAudienceHandler = () => {},
  setShowExtensionMinimizeButtons = () => {}
}) => {
  const {
    source,
    audienceName,
    audienceType,
    matchType,
    ruleGroups,
    isEditable,
    isNewAudience,
    matchedRecordIds = []
  } = data

  const [formValue, setFormValue] = useState({})
  const [modalViewFlag, setModalViewFlag] = useState(modalForm ? true : false)

  useEffect(() => {
    setFormValue({
      id,
      source,
      audienceName,
      audienceType,
      matchType,
      ruleGroups,
      isEditable,
      isNewAudience
    })
  }, [
    id,
    source,
    audienceName,
    audienceType,
    matchType,
    ruleGroups,
    isNewAudience
  ])

  const handleOnChange = (key, value) => {
    setFormValue(prev => {
      return { ...JSON.parse(JSON.stringify(prev)), [key]: value }
    })
  }

  const updateRuleGroupData = (ruleGroupIndex, key, value) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    const newRuleGroupData = { ...ruleGroupData, [key]: value }
    formValue.ruleGroups.splice(ruleGroupIndex, 1, newRuleGroupData)
    handleOnChange('ruleGroups', formValue.ruleGroups)
  }

  const updateConditionData = ({ ruleGroupIndex, ruleIndex, key, value }) => {
    const ruleGroupData = JSON.parse(JSON.stringify(ruleGroups[ruleGroupIndex]))
    const rule = JSON.parse(
      JSON.stringify((ruleGroupData.rules || [])[ruleIndex])
    )
    const newRule = { ...rule, [key]: value }
    ruleGroupData.rules.splice(ruleIndex, 1, newRule)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const saveButtonHandler = value => {
    updateData({ ...formValue, isEditable: value, isNewAudience: value })
  }

  const addCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    if (!ruleIndex && ruleIndex !== 0) {
      ruleGroupData.rules.push({
        prospectTag: '',
        condition: '',
        value: ''
      })
    } else {
      ruleGroupData.rules.splice(ruleIndex, 0, {
        prospectTag: '',
        condition: '',
        value: ''
      })
    }
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const deleteCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    ruleGroupData.rules.splice(ruleIndex, 1)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const modalClickHandler = () => {
    if (!modalViewFlag) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    setModalViewFlag(prev => !prev)
  }

  const getQueryParams = () => {
    let qParams = ''
    if (matchedRecordIds && matchedRecordIds.length > 0) {
      qParams = `matched_record_ids=${matchedRecordIds.join(',')}`
    }
    return qParams
  }

  const isSingleRule = () => {
    let onlyOneRule = false
    ;(formValue.ruleGroups || []).forEach(({ rules }) => {
      rules.forEach(rule => {
        onlyOneRule = Object.keys(rule).some(
          key =>
            ['prospectTag', 'condition', 'value'].includes(key) && !rule[key]
        )
      })
    })
    return onlyOneRule
  }

  return (
    <>
      {formValue.isEditable ? (
        <div
          css={formContainer(modalForm, formValue.isEditable)}
          className={
            formValue.ruleGroups && formValue.ruleGroups.length > 1
              ? 'auto'
              : ''
          }
        >
          <div css={audienceTypeSection(modalForm, formValue.isEditable)}>
            <span className='audienceTypeKey'>Audience Type:</span>
            <div className='audienceTypeValueSection'>
              <span className='audienceTypeValue'>
                {formValue.audienceType}
              </span>
              {!modalForm && (
                <button
                  css={expandModalIconStyle}
                  onClick={() => modalClickHandler()}
                >
                  <img alt='expand-modal-icon' src={expandModalIcon} />
                </button>
              )}
            </div>
          </div>

          <div css={ruleGroupWrapper(modalForm)}>
            {(formValue.ruleGroups || []).map(({ rules, matchType }, index) => {
              const ruleGroupIndex = index
              return (
                <React.Fragment key={ruleGroupIndex}>
                  <div css={ruleGroupContainer(ruleGroupIndex)}>
                    <div css={matchTypeStyle(ruleGroupIndex)}>
                      <label>Match Type:</label>
                      <Dropdown
                        options={['Match All', 'Match Any']}
                        style={{
                          width: '116px',
                          minWidth: 'unset',
                          height: '40px'
                        }}
                        value={matchType}
                        onOptionSelected={value =>
                          updateRuleGroupData(
                            ruleGroupIndex,
                            'matchType',
                            value
                          )
                        }
                      />
                    </div>
                    <div css={conditionWrapper(modalForm)}>
                      {(rules || []).map((conditionData, index) => {
                        const ruleIndex = index
                        return (
                          <React.Fragment key={index}>
                            <SegmentConditionForm
                              data={conditionData}
                              ruleIndex={ruleIndex}
                              ruleGroupIndex={ruleGroupIndex}
                              modalForm={modalForm}
                              deleteCondition={(ruleGroupIndex, ruleIndex) =>
                                deleteCondition(ruleGroupIndex, ruleIndex)
                              }
                              updateConditionsData={updateConditionData}
                              addCondition={(ruleGroupIndex, ruleIndex) =>
                                addCondition(ruleGroupIndex, ruleIndex)
                              }
                            />
                            {index !== rules.length - 1 && (
                              <div css={unionStyle}>
                                {matchType === 'Match All' ? 'And' : 'or'}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      })}
                      {ruleGroupIndex > 0 && (
                        <div css={extraRuleContainer}>
                          <div
                            css={extraRuleStyle}
                            onClick={() => addCondition(ruleGroupIndex)}
                          >
                            <Icon>add</Icon>
                            new rule
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {ruleGroupIndex !== formValue.ruleGroups.length - 1 && (
                    <div css={unionStyle}>And</div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          <div css={extraRuleContainer}>
            <div css={extraRuleStyle} onClick={() => addCondition(0)}>
              <Icon>add</Icon>
              new rule
            </div>
            <div css={extraRuleStyle}>
              <Icon>add</Icon>
              New rule group
            </div>
          </div>
          <div
            css={actionButtonContainer(modalForm)}
            className={!isNewAudience && 'notNewAudience'}
          >
            {isNewAudience && (
              <>
                <button css={buttonStyle} onClick={deleteNewAudienceHandler}>
                  Delete
                </button>
                <button
                  css={buttonStyle}
                  className='saveButton'
                  onClick={() => {
                    saveButtonHandler(false)
                  }}
                >
                  Save
                </button>
              </>
            )}
            {!isNewAudience && (
              <>
                <button
                  css={buttonStyle}
                  className='saveButton'
                  onClick={() => {
                    saveButtonHandler(false)
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            formValue.ruleGroups && formValue.ruleGroups.length > 1
              ? 'auto'
              : ''
          }
          css={formContainer(modalForm, formValue.isEditable)}
        >
          <div css={audienceTypeSection(modalForm, formValue.isEditable)}>
            <label className='audienceTypeKey'>Audience Type:</label>
            <div className='audienceTypeValueSection'>
              <label className='audienceTypeValue'>
                {formValue.audienceType}
              </label>
              {!modalForm && (
                <button css={expandModalIconStyle} onClick={modalClickHandler}>
                  <img alt='expand-modal-icon' src={expandModalIcon} />
                </button>
              )}
            </div>
          </div>
          <div
            css={ruleGroupWrapper(modalForm, isSingleRule())}
            className='nonEditable'
          >
            {(formValue.ruleGroups || []).map(({ matchType, rules }, index) => {
              const ruleGroupIndex = index
              return (
                <React.Fragment key={ruleGroupIndex}>
                  <div css={matchTypeStyle()} className='nonEditable'>
                    <label>Match Type:</label>
                    <label>{matchType}</label>
                  </div>
                  <div
                    css={conditionWrapper(modalForm)}
                    className='nonEditable'
                  >
                    {(rules || []).map((conditionData, index) => {
                      const ruleIndex = index
                      const showBlankBox = Object.keys(conditionData).some(
                        key =>
                          ['prospectTag', 'condition', 'value'].includes(key) &&
                          !conditionData[key]
                      )
                      return !showBlankBox ? (
                        <React.Fragment key={ruleIndex}>
                          <div css={nonEditableRuleOption(modalForm)}>
                            <div css={[segmentNameStyle()]}>
                              <div>
                                {conditionData.prospectTag}{' '}
                                {conditionData.condition} {conditionData.value}
                              </div>
                            </div>
                            {/* <div
                              css={[segmentNameStyle(), nonEditableInputStyle]}
                            >
                              <div>{conditionData.condition}</div>
                            </div>
                            <div
                              css={[segmentNameStyle(), nonEditableInputStyle]}
                            >
                              <div>{conditionData.value}</div>
                            </div> */}
                          </div>
                          {!isSingleRule() && index !== rules.length - 1 && (
                            <div css={unionStyle} className='nonEditable'>
                              {matchType === 'Match All' ? 'And' : 'or'}
                            </div>
                          )}
                        </React.Fragment>
                      ) : null
                    })}
                  </div>
                  {ruleGroupIndex !== formValue.ruleGroups.length - 1 && (
                    <div css={unionStyle} className='nonEditable'>
                      And
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          <div
            css={actionButtonContainer(modalForm)}
            className={
              audienceType === 'custom' ? 'nonEditable' : 'segmentAudience'
            }
          >
            {audienceType === 'custom' ? (
              <>
                <span
                  onClick={() => {
                    if (!modalForm) setShowExtensionMinimizeButtons(true)
                    editAudienceHandler(audienceId, id)
                  }}
                >
                  <img src={editIcon} alt='edit-icon' />
                </span>
                <span onClick={() => deleteAudienceHandler(audienceId, id)}>
                  <img src={trashIcon} alt='trash-icon' />
                </span>
                <Link
                  to={`/cdp/view-contacts?${getQueryParams()}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <button
                    css={buttonStyle}
                    style={{ backgroundColor: '#18A3E2', color: '#FFFFFF' }}
                  >
                    View Contacts
                  </button>
                </Link>
              </>
            ) : (
              <Link
                to={`/cdp/view-contacts?${getQueryParams()}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <button
                  css={buttonStyle}
                  style={{ backgroundColor: '#18A3E2', color: '#FFFFFF' }}
                >
                  View Contacts
                </button>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default AudienceForm
