/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect, useRef } from 'react'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import {
  dropdownMenu,
  blanket,
  dropdownSearch,
  optionsPanel,
  chevron,
  button,
  searchInput
} from './style'

const Button = ({ onClick, iconAfter, children }) => {
  return (
    <div onClick={onClick} css={button} className='button'>
      {children}
      {iconAfter ? iconAfter : null}
    </div>
  )
}

const ChevronDown = () => (
  <span css={chevron}>
    <img src={DropdownIcon} alt='dropdown-caret' />
  </span>
)

const Menu = props => {
  return <div css={dropdownMenu} {...props} className='dropdown-menu' />
}

const Blanket = props => <div css={blanket} {...props} />

const Dropdown = React.forwardRef(
  ({ children, isOpen, target, onClose, style }, ref) => (
    <div css={[dropdownSearch, style]} ref={ref}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  )
)

const Select = ({ options, onOptionSeleted, placeholder, showValue }) => {
  const [originalOptions] = useState(options)
  const [searchValue, setSearchValue] = useState('')

  const filteredOptions = useMemo(() => {
    const filteredOptions = originalOptions.filter(option => {
      return typeof option === 'object'
        ? option.key.toLowerCase().includes(searchValue.toLowerCase())
        : option.toLowerCase().includes(searchValue.toLowerCase())
    })
    return filteredOptions.length ? filteredOptions : originalOptions
  }, [searchValue])

  const onSearch = e => {
    setSearchValue(e.target.value)
  }

  return (
    <div css={optionsPanel}>
      <ul>
        <input
          onChange={onSearch}
          value={searchValue}
          placeholder={placeholder || 'Search'}
          type='text'
          css={searchInput}
        />
        {filteredOptions.map((option, index) => {
          if (typeof option === 'object') {
            const { key, value } = option
            return (
              <li key={index} onClick={() => onOptionSeleted(option)}>
                <span>{key}</span> {showValue && <span>{value}</span>}
              </li>
            )
          }
          return (
            <li key={index} onClick={() => onOptionSeleted(option)}>
              {option}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const DropdownWithSearch = React.forwardRef(
  (
    {
      value,
      options,
      onOptionSelected = () => {},
      placeholder,
      selectPlaceholder = '',
      showValue,
      showValueSelection = false,
      style
    },
    ref
  ) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const defaultValue =
      value && typeof value === 'object'
        ? showValueSelection
          ? value.value
          : value.key
        : value
    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
      setSelectedValue(defaultValue)
    }, [defaultValue])

    const toggleOpen = () => {
      setIsOpen(!isOpen)
    }

    const onSelectChange = option => {
      toggleOpen()
      onOptionSelected(option)
      if (typeof option === 'object') {
        const { key, value } = option
        if (showValueSelection) {
          setSelectedValue(value)
          return
        }
        setSelectedValue(key)
      } else {
        setSelectedValue(option)
      }
    }

    const handleDocumentClick = event => {
      // Close the dropdown if the click is outside the dropdown or options panel
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.closest('.dropdown-menu') === null
      ) {
        setIsOpen(false)
      }
    }

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick)

      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    }, [isOpen])

    return (
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        style={style}
        ref={node => {
          dropdownRef.current = node
          if (ref) {
            if (typeof ref === 'function') {
              ref(node)
            } else {
              ref.current = node
            }
          }
        }}
        target={
          <Button
            iconAfter={<ChevronDown />}
            onClick={toggleOpen}
            isSelected={isOpen}
          >
            {selectedValue ? (
              <span
                style={{
                  display: 'inline-block',
                  maxWidth: '85%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {selectedValue}
              </span>
            ) : (
              <span
                style={{
                  display: 'inline-block',
                  maxWidth: '85%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {selectPlaceholder}
              </span>
            )}
          </Button>
        }
      >
        <Select
          options={options}
          placeholder={placeholder}
          showValue={showValue}
          onOptionSeleted={onSelectChange}
        />
      </Dropdown>
    )
  }
)

export default DropdownWithSearch
