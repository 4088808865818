/** @jsxImportSource @emotion/react */
import React from 'react'

import {
  container,
  sidebar,
  content,
  actionButtonContainer,
  campaignActionButton
} from './style'

const AdsHeader = ({
  audienceCount = '0',
  onCancelHandler = () => {},
  saveAdsDraftCampaign = () => {},
  saveAdsCampaign = () => {},
  adsData
}) => {
  const checkIfNoAdsPresent = () => {
    let isEmpty = true
    const segments = Object.keys(adsData)
    for (let i = 0; i < segments.length; i++) {
      const emailsArr = adsData[segments[i]]
      if (emailsArr.some(email => Object.values(email || {}).length)) {
        isEmpty = false
        break
      }
    }
    return isEmpty
  }
  return (
    <div css={container}>
      <span css={sidebar}></span>
      <div css={content}>
        <span className='header'>Create Ad Campaign</span>
        <div css={actionButtonContainer}>
          <button css={campaignActionButton} onClick={onCancelHandler}>
            Cancel
          </button>
          <button
            css={campaignActionButton}
            disabled={checkIfNoAdsPresent()}
            onClick={saveAdsDraftCampaign}
          >
            Save Draft
          </button>
          <button
            className='saveButton'
            disabled={checkIfNoAdsPresent()}
            css={campaignActionButton}
            onClick={saveAdsCampaign}
          >
            Save
          </button>
        </div>
        <span className='estimatedAudience'>
          Estimated Audience: {audienceCount}
        </span>
      </div>
    </div>
  )
}

export default AdsHeader
