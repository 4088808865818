import { css } from '@emotion/react'

export const businessTypeSection = css`
  display: flex;
`

export const businessTypeContent = css`
  padding-left: 12px;
  &[data-selected='false'] {
    opacity: 0.3;
    pointer-events: none;
  }
  span {
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 9px;
  }
`

export const businessTypeOptions = css`
  display: flex;
  align-items: center;
  margin: 16px 0px;
`

export const businessTypeStyle = businessType => css`
  display: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 26px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  strong {
    height: 13px;
    width: 32px;
    color: #333333;
    font-family: 'BentonSans';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 11.25px;
  }
  &.${businessType} {
    strong {
      color: #ffffff;
    }
    background-color: #18a3e2;
  }
`

export const productNameStyle = css`
  margin-top: 35px;
  display: flex;
  align-items: center;
`

export const addProductOption = css`
  display: flex;
  align-items: center;
  margin-left: 26px;
  justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  width: 483px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  position: relative;
  .submitButton {
    cursor: pointer;
    box-sizing: border-box;
    height: 33px;
    width: 64px;
    border-radius: 4px;
    border: 0.8px solid #cccccc;
    background-color: #18a3e2;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    color: #ffffff;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    padding-top: 4px;
    text-align: center;
    margin: 0px;
    margin-right: 12px;
  }
  .submissionStatus {
    color: #333333;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    margin: 0px;
    margin-left: 12px;
    position: absolute;
    right: -90px;
  }
`

export const inputStyle = css`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 0;
  background-color: inherit;
  outline: none;
  padding-left: 12px;
  font-family: BentonSans;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 0.9;
`
