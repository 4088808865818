/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectField from '../../../../../../ui/SelectFormField'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  buttonEditorContainer,
  fieldBlockStyle,
  labelStyle,
  inputStyle,
  textFieldStyle,
  noticeTextStyle,
  titleStyle,
  btnGroupStyle,
  alignmentBtnStyle,
  alignmentBtnTextStyle
} from './style'

const ButtonEditor = () => {
  const dispatch = useDispatch()
  const {
    currentEditingComponent,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      currentEditingComponent,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )
  const [btnObj, setBtnObj] = useState({
    text: 'Shop now',
    btnStyle: 'Primary button',
    alignment: 'center'
  })

  const handleButtonObjChange = (key, value) => {
    const updatedObj = {
      ...btnObj,
      [key]: value
    }
    setBtnObj(updatedObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: updatedObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }
  return (
    <div css={buttonEditorContainer}>
      <label css={titleStyle}>Button</label>
      <div css={fieldBlockStyle}>
        <label css={labelStyle}>Text</label>
        <div css={textFieldStyle}>
          <input
            css={inputStyle}
            value={btnObj['text']}
            onChange={({ target: { value } }) => {
              handleButtonObjChange('text', value)
            }}
          />
        </div>
      </div>
      <div css={fieldBlockStyle}>
        <label css={labelStyle}>Button style</label>
        <SelectField
          name='btnStyle'
          value={btnObj['btnStyle']}
          title='Button Style'
          width='100%'
          onChange={(item, name) =>
            handleButtonObjChange('btnStyle', item.value)
          }
          list={[
            { label: 'Primary button', value: 'Primary button' },
            { label: 'Secondary button', value: 'Secondary button' },
            { label: 'Tertiary button', value: 'Tertiary button' }
          ]}
        />
      </div>
      <div css={noticeTextStyle}>
        <div className='icon'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width: '24px', height: '24px' }}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12 7.00619C10.1332 5.85667 6.77061 5.1554 3.7226 7.18798C3.58353 7.28072 3.5 7.43681 3.5 7.60397V17.604C3.5 17.7934 3.60702 17.9665 3.77643 18.0512C3.94585 18.1359 4.14857 18.1176 4.30007 18.0039C5.23917 17.2993 6.27027 16.8472 7.46128 16.7905C8.65553 16.7336 10.0616 17.0719 11.7519 18.0381C11.9057 18.126 12.0945 18.1259 12.2481 18.0381C13.9384 17.0719 15.3445 16.7336 16.5387 16.7905C17.7297 16.8472 18.7608 17.2993 19.6999 18.0039C19.8514 18.1176 20.0542 18.1359 20.2236 18.0512C20.393 17.9665 20.5 17.7934 20.5 17.604V7.60397C20.5 7.43681 20.4165 7.28072 20.2774 7.18798C17.2294 5.1554 13.8668 5.85667 12 7.00619ZM12.5 7.87258V16.7713C14.0051 16.0294 15.356 15.733 16.5863 15.7916C17.6727 15.8433 18.6357 16.1705 19.5 16.6722V7.87746C16.894 6.27765 14.057 6.89945 12.5 7.87258ZM11.5 7.87258C9.94298 6.89945 7.10598 6.27765 4.5 7.87746V16.6722C5.36433 16.1705 6.32729 15.8433 7.41372 15.7916C8.64401 15.733 9.99495 16.0294 11.5 16.7713V7.87258Z'
              fill='#555C66'
            ></path>
          </svg>
        </div>
        <div className='notice-text'>
          Use <span className='notice-link'>Button settings</span> to edit every
          button style on your email in one go
        </div>
      </div>
      <div css={fieldBlockStyle}>
        <label css={labelStyle}>Button alignment</label>
        <div css={btnGroupStyle}>
          <button
            css={alignmentBtnStyle(btnObj.alignment === 'flex-start')}
            onClick={() => {
              handleButtonObjChange('alignment', 'flex-start')
            }}
          >
            <div css={alignmentBtnTextStyle}>Left</div>
          </button>
          <button
            css={alignmentBtnStyle(btnObj.alignment === 'center')}
            onClick={() => {
              handleButtonObjChange('alignment', 'center')
            }}
          >
            <div css={alignmentBtnTextStyle}>Center</div>
          </button>
          <button
            css={alignmentBtnStyle(btnObj.alignment === 'flex-end')}
            onClick={() => {
              handleButtonObjChange('alignment', 'flex-end')
            }}
          >
            <div css={alignmentBtnTextStyle}>Right</div>
          </button>
          <button
            css={alignmentBtnStyle(btnObj.alignment === 'full')}
            onClick={() => {
              handleButtonObjChange('alignment', 'full')
            }}
          >
            <div css={alignmentBtnTextStyle}>Full</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ButtonEditor
