/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { headerCard, iconSection, customAudiences } from './style'
import SwitchController from '../../../ui/SwitchController'

import salesforceIcon from '../../../../assets/images/dashboard/audience/salesforce.png'
import bomboraIcon from '../../../../assets/images/dashboard/audience/bombora.png'
import zetaIcon from '../../../../assets/images/dashboard/audience/zeta.png'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const Header = props => {
  // const {
  //   audiences: { segmentAudienceData = {}, audienceDatafromApi = {} }
  // } = useSelector(({ audiences }) => ({ audiences }))

  const { segmentAudienceData, audienceDataFromApi } = useSelector(state => ({
    segmentAudienceData: state.audiences.segmentAudienceData || {},
    audienceDataFromApi: state.audiences.audienceDataFromApi || {}
  }))

  const [totalRecords, setTotalRecords] = useState(0)
  const [totalAudiences, setTotalAudiences] = useState(0)

  useEffect(() => {
    let sum = 0
    let numOfAudiences = 0
    Object.keys(segmentAudienceData).forEach(key => {
      const { matchedRecordsTotal = 0 } = segmentAudienceData[key] || {}
      sum += matchedRecordsTotal
      if (
        segmentAudienceData[key] &&
        Object.keys(segmentAudienceData[key]).length
      ) {
        numOfAudiences += 1
      }
    })
    Object.keys(audienceDataFromApi).forEach(key => {
      const segmentCustomAudiences = audienceDataFromApi[key] || []
      const customAudiencesSum =
        Array.isArray(segmentCustomAudiences) &&
        segmentCustomAudiences.reduce((prev, next) => {
          prev += (next && next.matchedRecordsTotal) || 0
          if (next && Object.keys(next)) {
            numOfAudiences += 1
          }
          return prev
        }, 0)
      sum += customAudiencesSum
    })
    setTotalAudiences(numOfAudiences)
    setTotalRecords(sum)
  }, [segmentAudienceData, audienceDataFromApi])

  return (
    <div css={headerCard(props.expandedView)}>
      <span
        className='toggleHeight'
        onClick={() => props.toggleHeightHandler(false)}
      >
        <img alt='expandIcon' src={props.expandedView ? minusIcon : plusIcon} />
      </span>
      <div css={iconSection}>
        <span className='iconBox1'>
          <img
            className='image1'
            alt='salesforceIcon'
            src={salesforceIcon}
          ></img>
        </span>
        <span className='iconBox2'>
          <img className='image2' alt='bomboraIcon' src={bomboraIcon}></img>
          <SwitchController />
        </span>
        <span className='iconBox3'>
          <img className='image3' alt='zetaIcon' src={zetaIcon}></img>
          <SwitchController />
        </span>
      </div>
      <div css={customAudiences}>
        <label className='audienceCount'>{`${totalAudiences} Audiences`}</label>
        {/* <hr></hr> */}
        <span className='targets'>{`${totalRecords} Targets`}</span>
      </div>
    </div>
  )
}

export default Header
