import { css } from '@emotion/react/macro'

export const conditionWrapper = css`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const conditionContainerStyle = css`
  display: flex;
  background: none;
  width: 100%;
  padding-bottom: 5px;
`

export const crossIconContainerStyle = css`
  background: none;
  padding-left: 14px;
  cursor: pointer;
`

export const iconStyle = css`
  width: 15px;
  height: 15px;
  img {
    height: inherit;
    width: inherit;
  }
`

export const inputChipStyle = css`
  flex: 1;
  flex-wrap: wrap;
  overflow-y: auto;
  display: flex;
  .inputContainer {
    flex-shrink: 0;
    flex-grow: 1;
    min-width: 155px;
    input {
      width: 100%;
    }
  }
`

export const dropdownStyle = css`
  width: fit-content;
  min-width: 227px;
  max-width: unset;
  margin-right: 10px;
  font-family: BentonSans;
  font-size: 14px;
  color: #333;
  height: 34px;
  line-height: 1.5;
  .dropdown-menu {
    position: fixed;
    width: inherit;
    margin-top: 30px;
    top: unset;
    li {
      height: unset;
      min-height: 30px;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

export const checkboxContainerStyle = css`
  display: flex;
  align-items: center;
`

export const checkboxStyle = css`
  margin-right: 20px;
  display: inline-block;
`

export const dateContainerStyle = css`
  display: flex;
  align-items: center;
  color: #333;
  font-family: BentonSans;
  input {
    height: 34px;
    border: 0;
    border-bottom: 1px solid #ccc;
    outline: none;
    background: #fafafa;
    width: 58px;
    margin-right: 10px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
