import { css } from '@emotion/react'

export const audienceCard = isEditable => css`
  box-sizing: border-box;
  position: relative;
  height: 532px;
  width: 824px;
  border: 0.8px solid #cccccc;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 153, 204, 0.4);
  ${isEditable && 'border: 4px dashed #CCCCCC;background-color: #E5E5E5;'}
  .iconBox {
    height: 61px;
    width: 61px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    display: inherit;
    justify-content: center;
    align-items: inherit;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 9px;
    .neyo {
      height: 33px;
      width: 47px;
    }
    .salesforce {
      height: 33px;
      width: 47px;
    }
    .bombora {
      height: 33px;
      width: 28px;
    }
  }
`

export const contractModalIconStyle = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  img {
    height: 16px;
    width: 16px;
  }
`

export const audienceAccountData = css`
  margin: auto;
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 6px;
  text-align: center;
  margin-left: 41px;
  hr {
    box-sizing: border-box;
    height: 0.8px;
    width: 105px;
    border: none;
    border-bottom: 0.8px solid #cccccc;
    margin-top: 7px;
    margin-bottom: 10px;
  }
`

export const headerCard = isEditable => css`
  display: flex;
  height: 82px;
  min-height: 82px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #ffffff;
  ${isEditable && 'background-color: inherit;'}
`

export const contentCard = isEditable => css`
  width: 100%;
  height: 442px;
  padding: 16px;
  background-color: #ffffff;
  overflow: hidden;
  background-color: #ffffff;
  border-top: 0px #ffffff;
  border-radius: 0px 0px 4px 4px;
  ${isEditable && 'background-color: inherit;'}
`
