const getInitialcampaignData = segmentAudienceData => {
  const data = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    data[segmentId] = [{}]
  })
  return data
}

export const getAllAudiences = (audienceDataFromApi, segmentAudienceData) => {
  const audienceCollection = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    if (audienceDataFromApi[segmentId]) {
      audienceCollection[segmentId] = [
        segmentAudienceData[segmentId],
        ...audienceDataFromApi[segmentId]
      ]
    } else {
      audienceCollection[segmentId] = [segmentAudienceData[segmentId]]
    }
  })
  return audienceCollection
}

export const processEmails = (
  emails,
  segmentAudienceData,
  audienceCollection
) => {
  let data = getInitialcampaignData(segmentAudienceData)
  emails.forEach(email => {
    let newMail = { ...email }
    const { createdFromSegments, audienceId } = newMail
    if (audienceCollection) {
      newMail['audienceData'] =
        audienceCollection[createdFromSegments[0]] &&
        audienceCollection[createdFromSegments[0]]
          .filter(audience => {
            return audience ? true : false
          })
          .map(audience => {
            return { ...audience, isSelected: audienceId.includes[audience.id] }
          })
    }
    createdFromSegments.forEach(segmentId => {
      if (data[segmentId] && data[segmentId].length) {
        data[segmentId].unshift(newMail)
      }
    })
  })
  return data
}

export const checkForEmptyCampaigns = emails => {
  let isEmpty = false
  let keys = Object.keys(emails)
  for (let i = 0; i < keys.length; i++) {
    const emailsArr = emails[keys[i]]
    if (
      emailsArr.every(
        value => value === null || !Object.keys(value || {}).length
      )
    ) {
      isEmpty = true
    } else {
      isEmpty = false
      break
    }
  }

  return isEmpty
}

const getRequiredFields = saveType =>
  !saveType
    ? ['emailName', 'emailType', 'audienceData', 'subjectLine', 'sender']
    : []

const checkForRequiredFieldsInEmail = (email, requiredFields) => {
  let validityObject = {}
  requiredFields.forEach(key => (validityObject[key] = true))
  Object.keys(email || {}).forEach(key => {
    if (requiredFields.includes(key)) {
      if (key === 'audienceData') {
        const audienceSelected = email[key].some(({ isSelected }) => isSelected)
        validityObject[key] = audienceSelected
      } else if (key === 'emailType') {
        if (email[key] === 'one-time') {
          validityObject[key] = !!email[key]
          validityObject['sendDate'] = !!email['sendDate']
        } else if (email[key] === 'triggered') {
          validityObject[key] = !!email[key]
          validityObject['triggerCriteria'] = !!email['recurringRule'].some(
            criteria => Object.values(criteria).every(item => item)
          )
        } else {
          validityObject[key] = !!email[key]
        }
      } else {
        validityObject[key] = !!email[key]
      }
    }
  })
  return validityObject
}

export const checkForValidCampaign = (emails, saveType) => {
  const segmentIds = Object.keys(emails) || []
  const requiredFields = getRequiredFields(saveType)
  let validationObject = {}
  segmentIds.forEach(segment => (validationObject[segment] = {}))
  segmentIds.forEach(segment => {
    const segmentEmails = emails[segment] || []
    segmentEmails.forEach((email, index) => {
      if (email && Object.values(email).length) {
        const { emailName } = email || {}
        validationObject[segment][emailName] = checkForRequiredFieldsInEmail(
          email,
          requiredFields
        )
      }
    })
  })
  return validationObject
}

export const convertToTitleCase = text => {
  return text.replace(/([A-Z])/g, ' $1')
}
