import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isDragging: false
}

const dndSlice = createSlice({
  name: 'dragAndDrop',
  initialState: initialState,
  reducers: {
    updateDragData: (state, action) => {
      state.isDragging = action.payload
    }
  }
})

export const { updateDragData } = dndSlice.actions

export default dndSlice.reducer
