import React from 'react'
import { useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Paper } from '@material-ui/core'
import useSignUpForm from './useSignUpForm'

import { signUpHandler } from '../../../store/authSlice'

import useStyles from './styles'

const SignUpContent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    enteredName,
    enteredEmail,
    enteredPassword,
    enteredConfirmPassword,
    showPassword,
    showConfirmPassword,
    nameError,
    emailError,
    passwordError,
    confirmPasswordError,
    handleInputChange,
    togglePasswordVisibility,
    toggleConfirmPasswordVisibility,
    handleSubmit
  } = useSignUpForm(signUpObj => dispatch(signUpHandler(signUpObj)))

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Box className={classes.paper}>
      <Container component='main' maxWidth='sm'>
        <Typography variant='h2' className={classes.signUpText}>
          Let's Go!
        </Typography>
        <Typography variant='h5' className={classes.signUpTextDesc}>
          Create a <b>Free</b> Trial Account
        </Typography>
        <Paper
          elevation={0}
          variant='outlined'
          className={classes.formContainer}
        >
          <div className={classes.form}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              name='name'
              error={!!nameError}
              helperText={nameError}
              className={classes.textField}
              value={enteredName}
              onChange={e => handleInputChange('name', e.target.value)}
              autoFocus
            />
            <TextField
              error={!!emailError}
              helperText={emailError}
              autoComplete='off'
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              className={classes.textField}
              label='Email Address'
              name='email'
              value={enteredEmail}
              onChange={e => handleInputChange('email', e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              error={!!passwordError}
              helperText={passwordError}
              className={classes.textField}
              name='password'
              label='Password'
              type={showPassword ? 'text' : 'password'}
              id='password'
              value={enteredPassword}
              onChange={e => handleInputChange('password', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={togglePasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
              className={classes.textField}
              name='confirmpassword'
              label='Confirm Password'
              type={showConfirmPassword ? 'text' : 'password'}
              id='confirmPassword'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={toggleConfirmPasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={enteredConfirmPassword}
              onChange={e =>
                handleInputChange('confirmPassword', e.target.value)
              }
            />
            <Button
              type='submit'
              fullWidth
              onClick={handleSubmit}
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Sign Up
            </Button>
          </div>
        </Paper>
      </Container>
      <Typography
        variant='h6'
        style={{ fontWeight: '900', padding: '2rem', fontFamily: 'Lato Black' }}
      >
        EXPLORE THE <span style={{ color: '#2196f3' }}>FUTURE</span> OF CUSTOMER
        DATA PLATFORMS
      </Typography>
      <Typography variant='body2' style={{ fontFamily: 'BentonSans' }}>
        By creating an account above, you agree to our <b>Terms of Service</b>{' '}
        and <b>Privacy Policy</b>
      </Typography>
    </Box>
  )
}

export default SignUpContent
