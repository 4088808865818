import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2, 2),
    maxWidth: `calc(100% - 115px)`,
    marginTop: 'auto',
    marginLeft: 'auto'
  }
}))

export default useStyles
