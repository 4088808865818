// import AWS from 'aws-sdk'

// const S3_BUCKET = 'neyoimage'
// const REGION = 'us-east-1'

// let ACCESS_KEY_ID = ''
// let SECRET_ACCESS_KEY = ''

// if (process.env.NODE_ENV !== 'production') {
//   ACCESS_KEY_ID = process.env.REACT_APP_AWS_DEV_ACCESS_KEY
//   SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_DEV_SECRET_ACCESS_KEY
// } else {
//   ACCESS_KEY_ID = process.env.REACT_APP_AWS_PROD_ACCESS_KEY
//   SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_PROD_SECRET_ACCESS_KEY
// }

// AWS.config.update({
//   accessKeyId: ACCESS_KEY_ID,
//   secretAccessKey: SECRET_ACCESS_KEY
// })

// const s3 = new AWS.S3({
//   region: REGION,
//   accessKeyId: ACCESS_KEY_ID,
//   secretAccessKey: SECRET_ACCESS_KEY,
//   signatureVersion: 'v4'
// })

// async function generateUploadUrl (key) {
//   const params = {
//     Bucket: S3_BUCKET,
//     Key: key
//   }
//   const uploadUrl = await s3.getSignedUrlPromise('putObject', params)
//   return uploadUrl
// }

let BASE_URL = 'https://neyoaibucket.s3.amazonaws.com'

export const uploadFileToS3 = (file, key) => {
  return async (dispatch, getState) => {
    const {
      mailer: { campaignName }
    } = getState()
    const lastDotIndex = key.lastIndexOf('.')
    let filenameWithoutExtension = key
    if (lastDotIndex !== -1 && lastDotIndex !== 0) {
      filenameWithoutExtension = key.slice(0, lastDotIndex)
    }

    let url = `${BASE_URL}/${campaignName}/${filenameWithoutExtension}`
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'inline'
      },
      body: file
    }).then(res => {
      const { url, status } = res
      if (status === 200) {
        return { url }
      } else {
        return {}
      }
    })
  }
}

// export const deleteFileFromS3 = key => {
//   console.log(key)
//   return new Promise((resolve, reject) => {
//     s3.deleteObject({ Bucket: S3_BUCKET, Key: key }, (err, data) => {
//       if (data) {
//         resolve(data)
//       } else {
//         reject(err)
//       }
//     })
//   })
// }
