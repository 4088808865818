/** @jsxImportSource @emotion/react */
import React from 'react'
import expandIcon from '../../../../assets/images/dashboard/sms/expandIcon.png'

import { drawerContainer, menu, expandButton, boxStyle } from './style'

const SideDrawer = ({ menuView, menuHandler = () => {} }) => {
  return (
    <div css={drawerContainer}>
      <div css={menu(menuView)}>
        <span css={expandButton} onClick={menuHandler}>
          <img alt='expandIcon' src={expandIcon} />
        </span>
        <div css={boxStyle} className='campaignName'>
          <div>
            <span>Email Name</span>
          </div>
        </div>
        <div css={boxStyle} className='textType'>
          <div>
            <span>Email Type</span>
          </div>
        </div>
        <div css={boxStyle} className='additionalFilter'>
          <div>
            <span>Additional Audience Filters</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideDrawer
