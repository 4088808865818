/** @jsxImportSource @emotion/react */
import React from 'react'
import TextEditor from './TextEditor'
import { useSelector } from 'react-redux'
import { rightPanelContainer } from './style'
import EmailSettings from './EmailSettings'
import ImageEditor from './ImageEditor'
import ButtonEditor from './ButtonEditor'
import MenuEditor from './MenuEditor'
import LineSpaceEditor from './LineSpaceEditor'
import SocialMediaEditor from './SocialMediaEditor'
import PreHeaderEditor from './PreheaderEditor'

const RightPanel = () => {
  const { componentType } = useSelector(
    ({
      mailer: {
        currentEditingComponent: { type: componentType = 'emailSettings' }
      }
    }) => ({ componentType })
  )

  const getEditorComponent = () => {
    let component = null
    switch (componentType) {
      case 'text':
        component = <TextEditor />
        break
      case 'image':
      case 'logo':
        component = <ImageEditor componentType={componentType} />
        break
      case 'button':
        component = <ButtonEditor />
        break
      case 'menu':
        component = <MenuEditor />
        break
      case 'line/space':
        component = <LineSpaceEditor />
        break
      case 'socialMedia':
        component = <SocialMediaEditor />
        break
      case 'preheader':
        component = <PreHeaderEditor />
        break
      default:
        component = <EmailSettings />
        break
    }
    return component
  }
  return <div css={rightPanelContainer}>{getEditorComponent()}</div>
}

export default RightPanel
