import { css, keyframes } from '@emotion/react'

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`
export const takeToDashboardSection = css`
    display: flex;
`

export const content = css`
    position: relative;
    flex-shrink: 0;
    min-width: 1335px;
    position: relative;
    &[data-selected='false'] {
        opacity: 0.3;
        pointer-events:none;
    }
    text-align: center;
    img {
        width: 1335px;
    }
    button {
        position: absolute;
        bottom: 72px;
        left: 456px;
        box-sizing: border-box;
        cursor: pointer;
        color: #FFFFFF;
        height: 50px;
        width: 270px;
        border-radius: 4px;
        border: 0.8px solid #CCCCCC;
        background-color: #18A3E2;
        box-shadow: 0 2px 6px 0px rgba(102,153,204,0.4);
        font-family: BentonSans;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 0;
        text-align: center;
    }
`

export const contactsImported = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 128px;
    left: 128px;
    align-items: center;
    height: 65px;
    width: 173px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    .dataCount {
        font-size: 36px;
    }
`

export const accountsImported = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 264px;
    left: 128px;
    height: 65px;
    width: 178px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    line-height: 1;
    .dataCount {
        font-size: 36px;
    }
`

export const audienceCreated = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 128px;
    right: 256px;
    height: 61px;
    width: 173px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    line-height: 1;
    .dataCount {
        font-size: 36px;
    }
`

export const duplicateRecords = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 264px;
    right: 216px;
    height: 65px;
    width: 243px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    line-height: 1;
    .dataCount {
        font-size: 36px;
    }
`

export const loader = css`
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -12px;
    animation: ${spinAnimation} 1s linear infinite;
`

export const actionButton = loading => css`
${
  loading &&
  `opacity: 0.6;
    pointer-events: none;
    &.connect-button {
      text-align: left;
    }
    `
}
`
