/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  container,
  header,
  audienceAccountData,
  content,
  optionsHeader,
  optionsToCheck,
  optionStyle,
  actionButtonSection,
  buttonStyle
} from './style'

import bomboraIcon from '../../../../assets/images/dashboard/audience/bombora.png'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'

const BomboraAudienceCard = ({
  bomboraAudienceId,
  bomboraOptions,
  deleteBomboraAudience = () => {},
  saveBomboraAudience = () => {}
}) => {
  const { audienceName, options } = bomboraOptions
  const [fullViewFlag, setFullViewFLag] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [optionsData, setOptionsData] = useState({
    ...JSON.parse(JSON.stringify(options))
  })

  const audienceViewHandler = () => {
    setFullViewFLag(prev => !prev)
  }

  const optionOnChangeHandler = (event, optionKey) => {
    setOptionsData(prev => {
      return {
        ...prev,
        [optionKey]: { ...prev[optionKey], value: event.target.checked }
      }
    })
  }

  const editButtonHandler = () => {
    setIsEditable(prev => !prev)
  }

  const saveAudience = (bomboraAudienceId, optionsData) => {
    setIsEditable(prev => !prev)
    saveBomboraAudience(bomboraAudienceId, optionsData)
  }

  return (
    <div css={container(fullViewFlag)}>
      <div css={header(fullViewFlag)}>
        <span className='toggleAudienceHeight' onClick={audienceViewHandler}>
          <img alt='expandIcon' src={fullViewFlag ? minusIcon : plusIcon} />
        </span>
        <span className='iconBox'>
          <img className='image' alt='bomboraIcon' src={bomboraIcon}></img>
        </span>
        <div css={audienceAccountData}>
          <label>{audienceName}</label>
          <hr></hr>
          <span>8 Topics</span>
        </div>
      </div>
      <div css={content(fullViewFlag)}>
        <div css={optionsHeader}>
          <label className='plus'>+</label>
          <label>12 Topics Available</label>
        </div>
        <div css={optionsToCheck}>
          {Object.keys(optionsData).map((option, index) => {
            return (
              <div css={optionStyle} key={option}>
                <div>
                  <input
                    type='checkbox'
                    className={`input-edit checkbox`}
                    id={`checkbox-${index}`}
                    checked={optionsData[option].value}
                    disabled={!isEditable}
                    onChange={event => optionOnChangeHandler(event, option)}
                  />
                  <label htmlFor={`checkbox-${index}`} />
                </div>
                <span className='optionKeyName'>{options[option].key}</span>
              </div>
            )
          })}
        </div>
        <div css={actionButtonSection}>
          {!isEditable ? (
            <>
              <button css={buttonStyle} onClick={editButtonHandler}>
                Edit
              </button>
              <button
                css={buttonStyle}
                onClick={() => deleteBomboraAudience(bomboraAudienceId)}
              >
                Archive
              </button>
              <Link
                to='/cdp/view-contacts'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <button css={buttonStyle} className='viewContacts'>
                  View Contacts
                </button>
              </Link>
            </>
          ) : (
            <>
              <button
                css={buttonStyle}
                className='saveContacts'
                onClick={() => saveAudience(bomboraAudienceId, optionsData)}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BomboraAudienceCard
