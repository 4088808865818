/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon'

import DeleteIcon from '../../../../../assets/images/trash-icon.png'
import DropdownWithSearch from '../../../../ui/DropdownWithSearch/DropdownWithSearch'
import {
  addDeleteIconContainer,
  conditionContainer,
  inputStyle,
  segmentNameStyle,
  iconStyle,
  ruleOption,
  dropdownStyle
} from './style'
import { Hidden } from '@material-ui/core'

const SegmentConditionForm = ({
  data,
  ruleIndex,
  ruleGroupIndex,
  modalForm,
  updateConditionsData = () => {},
  addCondition = () => {},
  deleteCondition = () => {}
}) => {
  const { prospectTag, condition, value } = data
  const [conditionData, setConditionData] = useState({
    prospectTag: '',
    condition: '',
    value: ''
  })
  useEffect(() => {
    setConditionData({
      prospectTag,
      condition,
      value
    })
  }, [prospectTag, condition, value])

  let segmentColumnNames =
    useSelector(state => state.segments.segmentColumnNames) || []

  let segmentSupportedConditions =
    useSelector(state => state.segments.segmentSupportedConditions) || []

  const handleOnChange = (key, value) => {
    setConditionData(prev => {
      return { ...prev, [key]: value }
    })
    updateConditionsData({ ruleGroupIndex, ruleIndex, key, value })
  }

  // const getDropdownWithSearchStyle = modalForm => {
  //   const style = {
  //     maxWidth: 'unset',
  //     marginBottom: '9px',
  //     height: '40px',
  //     color: '#333',
  //     'overflow-x': 'hidden',
  //     'overflow-y': 'auto'
  //   }
  //   if (modalForm) style.width = '230px'
  //   return style
  // }

  return (
    <div css={conditionContainer}>
      <div css={addDeleteIconContainer}>
        <Icon onClick={() => addCondition(ruleGroupIndex, ruleIndex)}>add</Icon>
        <span
          css={iconStyle}
          onClick={() => deleteCondition(ruleGroupIndex, ruleIndex)}
        >
          <img alt='delete-rule' src={DeleteIcon} />
        </span>
      </div>
      <div css={ruleOption(modalForm)}>
        <DropdownWithSearch
          options={segmentColumnNames}
          style={dropdownStyle(modalForm)}
          value={conditionData.prospectTag}
          onOptionSelected={value => handleOnChange('prospectTag', value)}
          // selectPlaceholder={'Prospect Tag'}
        />
        <DropdownWithSearch
          options={segmentSupportedConditions}
          style={dropdownStyle(modalForm)}
          value={conditionData.condition}
          onOptionSelected={value => handleOnChange('condition', value)}
          // selectPlaceholder={'Is'}
        />
        <input
          onChange={({ target: { value } = {} }) =>
            handleOnChange('value', value)
          }
          value={conditionData.value}
          css={[inputStyle, segmentNameStyle(ruleGroupIndex)]}
          // placeholder={'marketable contact'}
        />
      </div>
    </div>
  )
}

export default SegmentConditionForm
