import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'

import salesforceIcon from '../../../../assets/images/Salesforce.svg'
import plusIcon from '../../../../assets/images/plusIcon.png'
import minusIcon from '../../../../assets/images/minusIcon.png'
import linkImage from '../../../../assets/images/LinkImage.png'

import { ReactComponent as UndoIcon } from '../../../../assets/images/UndoIcon.svg'

import neyoMarkColor from '../../../../assets/images/neyoMarkColor.svg'

import useStyles from './styles'

const EmailChangeQueue = props => {
  const classes = useStyles()
  const [viewOtherMatches, setViewOtherMatches] = useState(false)
  const [actionSaved, setActionSaved] = useState(false)

  const [matchingDataFirstRow, setMatchingDataFirstRow] = useState(
    props.data.matchingData.first
  )
  const [matchingDataFirstTempRow, setMatchingDataFirstTempRow] = useState(
    props.data.matchingData.first
  )
  const [matchingDataOtherRows, setMatchingDataOtherRows] = useState(
    props.data.matchingData.viewOtherMatchesRows
  )
  const [integrationData, setIntegrationData] = useState(
    props.data.dataFromDataSource
  )

  const [createRecordSelected, setCreateRecordSelected] = useState(false)

  const selectNeyoDataHandler = row => {
    const temp = matchingDataFirstRow[0]
    setMatchingDataFirstRow([{ ...row, title: 'N' }])
    setMatchingDataFirstTempRow([{ ...row, title: 'N' }])
    setMatchingDataOtherRows(prevState => {
      let filteredRows = prevState.filter(matchData => matchData.id !== row.id)
      return [...filteredRows, { ...temp }]
    })
  }

  const onHoverReplaceHandler = () => {
    setMatchingDataFirstRow(prev => {
      let row = prev[0]
      return [
        {
          ...row,
          email: props.data.dataFromDataSource.email,
          isHighlighted: true
        }
      ]
    })
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: true }
    })
  }

  const onMouseLeaveReplaceHandler = () => {
    setMatchingDataFirstRow([
      { ...matchingDataFirstTempRow[0], isHighlighted: false }
    ])
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: false }
    })
  }

  const onHoverAddAccountHandler = () => {
    setMatchingDataFirstRow(prev => {
      let row = prev[0]
      return [
        { ...row, isHighlighted: true },
        {
          id: uuidv4(),
          email: integrationData.email,
          link: false,
          isHighlighted: true
        }
      ]
    })
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: true }
    })
  }

  const onMouseLeaveAddAccountHandler = () => {
    setMatchingDataFirstRow(prev => {
      let row = prev[0]
      return [{ ...row, isHighlighted: false }]
    })
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: false }
    })
  }

  const onHoverCreateRecordHandler = () => {
    setCreateRecordSelected(prev => !prev)
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: true }
    })
  }

  const onMouseLeaveCreateRecordHandler = () => {
    setCreateRecordSelected(prev => !prev)
    setIntegrationData(prev => {
      return { ...prev, isHighlighted: false }
    })
  }

  const onHoverAddDuplicate = () => {
    setMatchingDataFirstRow(prev => {
      return [{ ...prev[0], isHighlighted: true }]
    })
    setIntegrationData(prev => {
      return { ...prev, isDuplicate: true, isHighlighted: true }
    })
  }

  const onMouseLeaveRemoveDuplicate = () => {
    setMatchingDataFirstRow(prev => {
      return [{ ...prev[0], isHighlighted: false }]
    })
    setIntegrationData(prev => {
      return { ...prev, isDuplicate: false, isHighlighted: false }
    })
  }

  const actionClickHandler = (matchId, actionTaken) => {
    setActionSaved(prev => !prev)
    props.actionHandler(matchId, actionTaken)
  }

  const viewOtherMatchesClickHandler = event => {
    setViewOtherMatches(prevState => !prevState)
  }

  const matches = row => (
    <TableRow key={row.id}>
      <TableCell align='left' className={classes.titleCell}>
        <Button
          variant='outlined'
          className={classes.matchOptionButton}
          onClick={() => selectNeyoDataHandler(row)}
        >
          Select
        </Button>
      </TableCell>
      <TableCell align='left' className={classes.neyoAccountColumnCell}>
        <Typography className={classes.neyoAccountColumnData}>
          {row.account}
        </Typography>
      </TableCell>
      <TableCell align='left'>{row.contact}</TableCell>
      {/* <TableCell align="left">{row.email}</TableCell> */}
      <TableCell align='left'>
        <Typography
          className={`${classes.neyoEmailColumnData} ${row.isHighlighted &&
            classes.isHighlighted}`}
        >
          {row.email}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Icon>
          <img alt='Plus Icon' src={linkImage} height={15} width={15} />
        </Icon>
      </TableCell>
      <TableCell align='center'>{row.match}</TableCell>
    </TableRow>
  )

  return (
    <Box className={classes.queueCardContent}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5} style={{ display: 'flex' }}>
          {/* <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
            spacing={2}
          > */}
          {/* <Grid item xs={6}> */}
          <Grid
            container
            component={Paper}
            justifyContent='flex-start'
            alignItems='flex-start'
            className={`${
              classes.integrationData
            } ${integrationData.isDuplicate &&
              classes.duplicateIntegration} ${integrationData.isHighlighted &&
              createRecordSelected &&
              classes.highlightedAccount}`}
          >
            <Grid item sm={8} xl={8}>
              <Typography
                variant='body2'
                className={classes.integrationDataFirstColumnHeader}
              >
                Email
              </Typography>
              <Typography
                className={`${
                  classes.integrationDataFirstColumnValue
                } ${integrationData.isHighlighted && classes.isHighlighted}`}
              >
                {integrationData.email}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              xl={3}
              className={classes.integrationDataIconGrid}
            >
              <Icon className={classes.integrationDataIcon}>
                <img
                  alt='Salesforce'
                  src={salesforceIcon}
                  height={65}
                  width={55}
                />
              </Icon>
            </Grid>
            <Grid
              item
              sm={12}
              className={`${
                classes.duplicateIntegrationTag
              } ${!integrationData.isDuplicate &&
                classes.duplicateIntegrationTagRemoved}`}
            >
              <Typography>IGNORE</Typography>
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid
            item
            xs={6}
            className={`${actionSaved && classes.undoButtonSectionGrid}`}
          >
            {!actionSaved ? (
              <div className={classes.actionButtonSection}>
                <Button
                  variant='outlined'
                  className={classes.actionButton}
                  onMouseEnter={onHoverAddDuplicate}
                  onMouseLeave={onMouseLeaveRemoveDuplicate}
                  onClick={() =>
                    actionClickHandler(props.data.id, 'tagAsDuplicate')
                  }
                >
                  {props.data.actions['1'].key}
                </Button>
                <Button
                  variant='outlined'
                  className={classes.actionButton}
                  onMouseEnter={onHoverReplaceHandler}
                  onMouseLeave={onMouseLeaveReplaceHandler}
                  onClick={() =>
                    actionClickHandler(props.data.id, 'updatePrimaryAccount')
                  }
                >
                  {props.data.actions['2'].key}
                </Button>
                <Button
                  variant='outlined'
                  className={classes.actionButton}
                  onMouseEnter={onHoverAddAccountHandler}
                  onMouseLeave={onMouseLeaveAddAccountHandler}
                  onClick={() =>
                    actionClickHandler(props.data.id, 'addSecondaryEmail')
                  }
                >
                  {props.data.actions['3'].key}
                </Button>
                <Button
                  variant='outlined'
                  className={classes.actionButton}
                  onMouseEnter={onHoverCreateRecordHandler}
                  onMouseLeave={onMouseLeaveCreateRecordHandler}
                  onClick={() =>
                    actionClickHandler(props.data.id, 'createNewRecord')
                  }
                >
                  {props.data.actions['4'].key}
                </Button>
              </div>
            ) : (
              <div className={classes.undoButtonSection}>
                <Typography variant='body2'>Changes Saved.</Typography>
                <Button
                  variant='outlined'
                  startIcon={<UndoIcon height={15} width={15} />}
                  onClick={() => actionClickHandler(props.data.id, 'undo')}
                >
                  Undo
                </Button>
              </div>
            )}
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <Box component={Paper} style={{ height: '100%' }}>
            <span className={classes.neyoDataTitleButton}>
              <img alt='NeyoIcon' src={neyoMarkColor} height={35} width={45} />
            </span>
            <TableContainer>
              <Table className={classes.neyoDataTable}>
                <TableHead>
                  <TableRow>
                    <TableCell width='8%'></TableCell>
                    <TableCell
                      align='left'
                      width='10%'
                      style={{ paddingLeft: '1.7rem' }}
                    >
                      Account
                    </TableCell>
                    <TableCell align='left' width='10%'>
                      Contact
                    </TableCell>
                    <TableCell
                      align='left'
                      width='12%'
                      style={{ paddingLeft: '1.7rem' }}
                    >
                      Email
                    </TableCell>
                    <TableCell align='center' width='8%'>
                      Link
                    </TableCell>
                    <TableCell align='center' width='12%'>
                      % Match
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matchingDataFirstRow.map(row => (
                    <TableRow key={row.id}>
                      <TableCell
                        align='left'
                        className={classes.titleCell}
                      ></TableCell>
                      <TableCell
                        align='left'
                        className={classes.neyoAccountColumnCell}
                      >
                        <Typography
                          className={`${classes.neyoAccountColumnData}`}
                        >
                          {row.account}
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="left">{row.account}</TableCell> */}
                      <TableCell align='left'>{row.contact}</TableCell>
                      {/* <TableCell align="left">{row.email}</TableCell> */}
                      <TableCell
                        align='left'
                        className={classes.neyoEmailColumnCell}
                      >
                        <Typography
                          className={`${
                            classes.neyoEmailColumnData
                          } ${row.isHighlighted && classes.isHighlighted}`}
                        >
                          {row.email}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        {row.link && (
                          <Icon>
                            <img
                              alt='Plus Icon'
                              src={linkImage}
                              height={15}
                              width={15}
                            />
                          </Icon>
                        )}
                      </TableCell>
                      <TableCell
                        align='center'
                        className={classes.neyoMatchColumnCell}
                      >
                        {row.match}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align='left' colSpan={3}>
                      <IconButton
                        disableRipple
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                        className={classes.viewOtherMatchesBox}
                        onClick={viewOtherMatchesClickHandler}
                      >
                        <span className={classes.viewOtherMatchesIcon}>
                          <img
                            alt='view-other-matches-icon'
                            src={viewOtherMatches ? minusIcon : plusIcon}
                          />
                        </span>
                        <Typography variant='body2'>
                          View Other Matches
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {viewOtherMatches &&
                    matchingDataOtherRows.map(row => matches(row))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmailChangeQueue
