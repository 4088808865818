import { css } from '@emotion/react/macro'

export const container = css``

export const dropdownStyle = css`
  max-width: unset;
  height: 34px;
  margin-top: 8px;
  color: #333;
`

export const inputStyle = css`
  border: 0.8px solid #ccc;
  background: #fff;
  height: 40px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding-left: 18px;
  color: #333;
`

export const triggerRuleControlStyle = css`
  display: flex;
  margin-top: 8px;
  align-items: center;
  margin-bottom: 10px;
  .add-rule {
    font-size: 20px;
    color: #333;
    font-weight: 600;
    cursor: pointer;
  }
  .delete {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-left: 5px;
    img {
      width: inherit;
    }
  }
`

export const dropdownContainer = css`
  width: 100%;
  padding: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: #fff;
  height: 40px;
  margin-bottom: 8px;
  border: 0.8px solid #ccc;
  border-radius: 4px;
  .label {
    overflow: hidden;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dropdownMenu {
    opacity: 0;
    position: absolute;
    top: 40px; // same as your nav height
    left: 0;
    background: #fff;
    border: 0.8px solid #ccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    padding: 10px;
    text-align: center;
    transition: all 1000ms ease;
    width: 100%;
    display: none;
    z-index: 2;
    max-height: 95px;
    overflow: auto;
    .list-item {
      &:hover {
        background: #f5f5f5;
      }
    }
    &.open {
      opacity: 1;
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
`

export const displayValueStyle = css`
  display: flex;
  align-items: center;
  span {
    flex: 1;
    text-align: center;
    display: inline-block;
  }

  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    transform: rotate(180deg);
    display: inline-block;
  }
`
