import { css } from '@emotion/react/macro'

export const gridContainerStyle = css`
  background: #fff;
  width: calc(100% - 115px);
  margin-left: 115px;
  border: 0.8px solid #cccccc;
  border-radius: 5px;
`

export const disabledStyle = css`
  background: #ccc;
  color: #333;
  box-shadow: 0 2px 6px 2px rgb(182 196 209 / 40%);
  pointer-events: none;
`

export const tableSourceIconStyle = css`
  width: 68px;
  height: 68px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #fff;
`

export const addConnectorBtnStyle = isDisabled => css`
  height: 38px;
  width: 131px;
  border: 0.8px solid #ccc;
  background-color: #18a3e2;
  color: #fff;
  border-radius: 5px;
  font-family: BentonSans;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  ${isDisabled &&
    'background: #f5f5f5;box-shadow: none; color: #333; opacity: 0.6; pointer-events: none;'}
`

export const downloadBtnStyle = css`
  width: 36px;
  height: 36px;
  border: 0.8px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
`

export const modalHeadingStyle = css`
  font-family: BentonSans;
  font-size: 22px;
  font-weight: 300;
  color: #333;
  padding: 32px;
`

export const availableConnectorRowStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  width: 100%;
`

export const addButtonStyle = isAdded => css`
  height: 38px;
  width: 72px;
  border: 0.8px solid #ccc;
  background-color: #18a3e2;
  color: #fff;
  border-radius: 5px;
  font-family: BentonSans;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  ${isAdded &&
    'background-color: #a9a6a6; box-shadow: none; cursor: not-allowed;'}
`

export const grdStyle = () => css`
  &.ag-theme-alpine {
    .ag-center-cols-container,
    .ag-header-container,
    .ag-header-row {
      width: 100% !important;
    }
    .ag-header-group-cell {
      padding: 0;
    }
    .ag-header-group-cell-label {
      justify-content: center;
      align-items: center;
    }
    .ag-header {
      border: none;
      background-color: #fff;
      .ag-header-cell-text {
        font-size: 14px;
        font-family: BentonSans;
        color: rgb(51, 51, 51);
        line-height: 2;
      }
    }
    .ag-root-wrapper,
    .ag-header-row,
    .ag-header-row-column,
    .ag-header-cell {
      border: 0 !important;
    }
    .ag-pinned-left-header {
      border: 0;
    }
    .ag-pinned-left-cols-container {
      border: 0;
    }
    .ag-row {
      border: 0;
    }
    .ag-cell-focus {
      border: none !important;
    }

    .ag-cell {
      display: flex;
      align-items: center;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .customHeaderLabel {
      border: 1px solid #f5f5f5;
      border-radius: 8px;
    }
  }
`
