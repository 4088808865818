/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react'
import SelectField from '../../../../../../ui/SelectFormField'
import Portal from '../../../../../../../customHooks/portal'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'

import {
  menuEditorContainer,
  labelStyle,
  buttonContainer,
  btnStyle,
  titleStyle,
  fieldStyle,
  inputStyle,
  colorBoxStyle
} from './style'

const MenuEditor = () => {
  const textColorRef = useRef()
  const textColorPickerRef = useRef()
  const dispatch = useDispatch()
  const {
    currentEditingComponent,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      currentEditingComponent,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )

  const [showColorPicker, toggleColorPicker] = useState(false)
  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })
  const [editorObj, setEditorObj] = useState({})

  useEffect(() => {
    let menuObj = {
      menuItems: [
        {
          style: { textDecoration: 'none' },
          text: 'Men',
          linkTo: 'https://google.com'
        },
        {
          style: { textDecoration: 'none' },
          text: 'Women',
          linkTo: 'https://google.com'
        },
        {
          style: { textDecoration: 'none' },
          text: 'Shoes',
          linkTo: 'https://google.com'
        }
      ],
      color: '#000'
    }
    let path = currentEditingComponent.path
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        menuObj = { ...menuObj, ...(editingAudience[path] || {}) }
      }
    }
    setEditorObj(menuObj)
  }, [editingComponents, currentEditingComponent])

  useEffect(() => {
    const clickedOutside = event => {
      if (
        showColorPicker &&
        textColorPickerRef.current &&
        !textColorRef.current.contains(event.target) &&
        !textColorPickerRef.current.contains(event.target)
      ) {
        toggleColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [textColorPickerRef, showColorPicker])

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  const addRemoveLinkHandler = action => {
    let menuItems = [...editorObj.menuItems]
    switch (action) {
      case 'add':
        menuItems.push({
          style: { textDecoration: 'none' },
          text: 'Sale',
          linkTo: 'https://www.google.com'
        })
        break
      case 'remove':
        menuItems.pop()
        break
      default:
        return
    }
    handleChange('menuItems', menuItems)
  }

  const handleChange = (key, value) => {
    const newMenuObj = {
      ...editorObj,
      [key]: value
    }
    setEditorObj(newMenuObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newMenuObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }
  return (
    <div css={menuEditorContainer}>
      <label css={titleStyle}>Menu</label>
      <div css={buttonContainer}>
        <button
          css={btnStyle}
          onClick={() => {
            addRemoveLinkHandler('add')
          }}
        >
          Add link
        </button>
        <button
          css={btnStyle}
          onClick={() => {
            addRemoveLinkHandler('remove')
          }}
        >
          Remove link
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          paddingBottom: '24px',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ flex: 2 }}>
          <label css={labelStyle}>Font</label>
          <SelectField
            name='font'
            onChange={(item, name) => handleChange('fontFamily', item.value)}
            value={editorObj['fontFamily']}
            title='Font'
            width='90%'
            list={[
              { label: 'Arial', value: 'Arial' },
              { label: 'Helvetica', value: 'Helvetica' },
              { label: 'Courier New', value: 'Courier New' },
              { label: 'Georgia', value: 'Georgia' },
              { label: 'Lucida', value: 'Lucida' }
            ]}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label css={labelStyle}>Size</label>
          <SelectField
            name='size'
            onChange={(item, name) => handleChange('fontSize', item.value)}
            value={editorObj['fontSize']}
            title='Font Size'
            width='100%'
            list={[
              { label: '8px', value: '8px' },
              { label: '9px', value: '9px' },
              { label: '10px', value: '10px' },
              { label: '11px', value: '11px' },
              { label: '12px', value: '12px' },
              { label: '14px', value: '14px' },
              { label: '16px', value: '16px' },
              { label: '18px', value: '18px' },
              { label: '20px', value: '20px' },
              { label: '22px', value: '22px' },
              { label: '24px', value: '24px' },
              { label: '26px', value: '26px' },
              { label: '28px', value: '28px' },
              { label: '30px', value: '30px' },
              { label: '36px', value: '36px' },
              { label: '48px', value: '48px' },
              { label: '60px', value: '60px' },
              { label: '72px', value: '72px' },
              { label: '90px', value: '90px' }
            ]}
          />
        </div>
      </div>
      <div>
        <label css={labelStyle}>Link color</label>
        <div css={fieldStyle} ref={textColorRef}>
          <input
            css={inputStyle}
            type='text'
            value={editorObj.color}
            onChange={({ target: { value } }) => {
              handleChange('color', value)
            }}
          />
          <span
            css={colorBoxStyle}
            style={{ backgroundColor: editorObj.color }}
            onClick={() => {
              toggleColorPicker(prev => !prev)
              updateColorCoordinate(textColorRef.current)
            }}
          />
        </div>
      </div>
      {showColorPicker && (
        <Portal>
          <div
            style={{ ...colorCoords, position: 'absolute' }}
            ref={textColorPickerRef}
          >
            <SketchPicker
              color={editorObj.color}
              onChangeComplete={color => {
                handleChange('color', color.hex)
              }}
            />
          </div>
        </Portal>
      )}
    </div>
  )
}

export default MenuEditor
