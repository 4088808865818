import { css } from '@emotion/react'

export const container = isSelected => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isSelected ? 'border: 2px solid #18a3e2;' : null}
`

export const logoContainer = isSelected => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isSelected ? 'border: 2px solid #18a3e2;' : null}
`

export const menuContainer = isSelected => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  position: relative;
  ${isSelected ? 'border: 2px solid #18a3e2;' : null}
`

export const menuItemStyle = css`
  &:hover {
    border: 1px solid #18a3e2;
    border-top: 0px;
    border-bottom: 0;
  }
`

const isActiveStyle = css`
  border: 2px solid #18a3e2;
`

export const lineContainer = isActive => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isActive && isActiveStyle}
`
export const socialContainer = isActive => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isActive && isActiveStyle}
`

export const socialItem = css`
  &:hover {
    border: 1px solid #18a3e2;
    border-top: 0px;
    border-bottom: 0;
  }
`

export const imageInfoStyle = css`
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  padding: 0 8px;
`

export const textEditorStyle = css`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideWithOpacity 0.2s;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgb(85 92 102 / 25%);
  display: flex;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  transition: all 0.1s ease;
  .link-icon {
    background: #22adf62b;
    border-radius: 4px;
  }
  .tooltip-item {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    &:not(.link-icon):hover {
      background: whitesmoke;
      border-radius: 4px;
      cursor: pointer;
    }
    .icon {
      width: 14px;
      height: 14px;
      fill: #333;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      &.link {
        width: 18px;
      }
    }
    &:not(.link-icon)[active='true'] {
      background: whitesmoke;
      border-radius: 4px;
    }
  }
`

export const labelStyle = css`
  font-size: 13px;
  line-height: 22px;
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 4px;
`

export const fieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  padding: 6px;
  position: relative;
  transition: border-color 0.1s linear;
`

export const inputStyle = css`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
`

export const linkEditorStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slideWithOpacity 0.2s;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgb(85 92 102 / 25%);
  display: flex;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  transition: all 0.1s ease;
  padding: 16px;
`

export const updateLinkBtnStyle = css`
  align-items: center;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  justify-content: center;
  line-height: 25px;
  padding: 7px 15px;
  white-space: nowrap;
  background-color: #4eafe2;
  border: 1px solid #4eafe2;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  outline: none;
  padding: 0;
  position: relative;
  transition: background-color 0.1s ease-out, border 0.1s ease-out;
`

export const anchorStyle = css`
  pointer-events: none;
`
