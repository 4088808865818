/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentEditingComponent } from '../../../../../../../store/dashboard/mailerSlice'

import { lineContainer } from './style'

const Line = ({ parentPath, componentPath, parentUid }) => {
  const [editorObj, setEditorObj] = useState({})
  const {
    editingPath,
    editingComponents,
    currentEditedSegmentandAudience
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent: { path: editingPath },
        editingComponents,
        currentEditedSegmentandAudience
      }
    }) => ({ editingPath, editingComponents, currentEditedSegmentandAudience })
  )

  useEffect(() => {
    let editorObj = {
      type: 'line',
      style: 'solid',
      alignment: 'center',
      borderColor: '#000'
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        editorObj = { ...editorObj, ...(editingAudience[path] || {}) }
      }
    }
    setEditorObj(editorObj)
  }, [editingComponents, editingPath])

  const dispatch = useDispatch()
  const onLineElementSelect = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'line/space',
        parentUid
      })
    )
  }
  return (
    <div
      css={lineContainer(`${parentPath}->${componentPath}` === editingPath)}
      style={{
        padding: '6px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: editorObj.alignment
      }}
      onClick={event => {
        event.stopPropagation()
        onLineElementSelect()
      }}
    >
      {editorObj.type === 'line' && (
        <div
          style={{
            borderTop: `2px ${editorObj.style} ${editorObj.borderColor}`,
            width: '50%'
          }}
        />
      )}
    </div>
  )
}

export default Line
