import { css } from '@emotion/react'

export const setWidthOfForm = formWidth => css`
  width: ${formWidth * 231.4 + (formWidth - 1) * 33}px;
  min-width: ${formWidth * 231.4 + (formWidth - 1) * 33}px;
`

export const audienceCard = (fullViewFlag, formWidth, isEditable) => css`
    width: 231.4px;
    min-width: 231.4px;
    margin: 10px;
    margin-bottom: 0px;
    position: relative;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    ${formWidth > 1 && setWidthOfForm(formWidth)}
    
    ${fullViewFlag && 'height: min-content; border-radius: 4px 4px 0px 0px;'}
    ${isEditable &&
      'box-sizing: border-box; border: 4px dashed #CCCCCC; background-color: rgba(204,204,204,0.4); box-shadow: 0 2px 6px rgba(102,153,204,0.4);'}
    .iconBox {
        height: 61px;
        width: 61px;
        border: 0.8px solid #cccccc;
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        display: inherit;
        justify-content: center;
        align-items: inherit;
        border-radius: 4px;
        background-color: #FFFFFF;
       
        .neyo {
            height: 33px;
            width: 47px;
        }
        .salesforce {
            height: 28px;
            width: 40px;
        }
        .bombora {
            height: 33px;
            width: 28px;
        }
    }
    .salesforce {
      height: 28px;
      width: 40px;
    }
    .bombora {
      height: 33px;
      width: 28px;
    }
`

export const audienceAccountData = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 6px;
  text-align: center;
  margin: 16.5px 22.5px;
  hr {
    box-sizing: border-box;
    height: 0.8px;
    width: 105px;
    border: none;
    border-bottom: 0.8px solid #cccccc;
    margin-top: 7px;
    margin-bottom: 10px;
  }
`

export const header = (isEditable, fullViewFlag) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  ${!isEditable &&
    'background-color: #ffffff; box-shadow: 0 2px 6px rgba(0, 153, 204, 0.4);'}
  ${fullViewFlag && 'border-radius: 4px 4px 0px 0px;'}
`

export const headerCard = (isEditable, formWidth, fullViewFlag) => css`
  display: flex;
  height: 64px;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background-color: #ffffff;
  border-bottom: 0px solid #ffffff;
  border-radius: 4px 4px 0px 0px;
  transition: all 0.5s ease-out;
  width: 100%;
  ${formWidth === 1 && 'justify-content: flex-start;'}
  ${isEditable &&
    'background-color: rgba(204,204,204,0.4); box-shadow: 0 0px 0px rgba(0,153,204,0.4); height: 86px;'}
  .toggleAudienceHeight {
    height: 0px;
    width: 0px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
  ${fullViewFlag && '.toggleAudienceHeight { height: 28px;width: 28px; }'}
  &:hover {
    ${!fullViewFlag && '.toggleAudienceHeight { height: 28px;width: 28px; }'}
  }
`

export const contentCard = (fullViewFlag, isEditable, isSingleRule) => css`
  display: flex;
  justify-content: center;
  height: 0px;
  max-height: 0px;
  background-color: #ffffff;
  overflow: hidden;
  background-color: #ffffff;
  border-top: 0px #ffffff;
  box-shadow: 0 5px 6px rgba(0, 153, 204, 0.4);
  border-radius: 0px 0px 4px 4px;
  transition: all 0.5s ease-out;
  ${fullViewFlag && 'height: 518px; overflow-x: auto; max-height: 518px;'}
  ${isSingleRule && !isEditable && 'height: 302px'}
  ${fullViewFlag &&
    isEditable &&
    'height: 640px; overflow-x: auto; max-height: 640px;background-color: rgba(204,204,204,0.4);box-shadow: 0 0px 0px rgba(0,153,204,0.4);'}
  ${fullViewFlag &&
    !isEditable &&
    'height: fit-content; overflow-x: auto; max-height: 404px;'}
`

export const extensionButton = (cardLength = 1) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  height: 88px;
  width: 14px;
  overflow: hidden;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  label {
    color: #ffffff;
    font-family: BentonSans;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
  }
  &.leftExtension {
    position: absolute;
    left: -14px;
    ${cardLength > 1 && 'left: -28px;'}
  }
  &.leftMinimize {
    position: absolute;
    left: -14px;
  }
  &.rightExtension {
    position: absolute;
    right: -14px;
    ${cardLength > 1 && 'right: -28px;'}
  }
  &.rightMinimize {
    position: absolute;
    right: -14px;
  }
`

export const minimizeFromLeft = leftMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${leftMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-left: 0px; background-color: rgba(229, 229, 229, 1);'}
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const minimizeFromRight = rightMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${rightMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-right: 0px; background-color: rgba(229, 229, 229, 1);'}
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const rightExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(100%, 0);
  right: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'right: -28px;'}
`

export const leftExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(-100%, 0);
  left: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'left: -28px;'}
`

export const rightExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'}
  ${extensionHoverFlag &&
    cardLength === 1 &&
    'width: 250.4px; min-width: 250.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'}
`

export const leftExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'}
  ${extensionHoverFlag &&
    cardLength === 1 &&
    'width: 251.4px; min-width: 251.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'}
`
