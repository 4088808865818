import React from 'react'

import { MockEditingContext } from '../contexts/mockEditingContext'

const WithMockCellRenderer = WrappedComponent =>
  class extends React.Component {
    state
    static contextType = MockEditingContext
    constructor (props) {
      super(props)
      this.state = {
        isMockEditing: false
      }
    }

    refresh () {
      this.setState({
        isMockEditing: this.context.mockEditingId === this.props.node.id
      })
      return true
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          isMockEditing={this.state.isMockEditing}
        />
      )
    }
  }

export default WithMockCellRenderer
