/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import TextEditor from '../TextEditor'
import { useDispatch, useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'
import Portal from '../../../../../../../customHooks/portal'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  preHeaderContainer,
  titleStyle,
  labelStyle,
  inputStyle,
  fieldStyle,
  textEditorStyle,
  colorBoxStyle,
  btnGroupStyle,
  typeBtnStyle,
  typeBtnTextStyle,
  toggleContent,
  paddingsContainer,
  paddingText,
  imagePlaceholderStyle,
  selectFileBtnStyle,
  imageUploadBlockStyle
} from './style'

const PreHeaderEditor = () => {
  const dispatch = useDispatch()
  const linkColorRef = useRef()
  const linkColorPickerRef = useRef()
  const backgroundColorRef = useRef()
  const backgroundColorPickerRef = useRef()
  const fileRef = useRef()
  const [showLinkColorPicker, setLinkColorPicker] = useState(false)
  const [showBackgroundColorPicker, setBackgroundColorPicker] = useState(false)
  const [preheaderEditorObj, updatePreheaderEditorObj] = useState({})
  const [showPaddingContainer, togglePaddingContainer] = useState(false)
  const [showColorContainer, toggleColorContainer] = useState(false)

  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })

  const {
    editingPath,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent: { path: editingPath },
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      editingPath,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )

  useEffect(() => {
    const clickedOutside = event => {
      if (
        showLinkColorPicker &&
        linkColorPickerRef.current &&
        !linkColorRef.current.contains(event.target) &&
        !linkColorPickerRef.current.contains(event.target)
      ) {
        setLinkColorPicker(false)
      }
      if (
        showBackgroundColorPicker &&
        !backgroundColorRef.current.contains(event.target) &&
        !backgroundColorPickerRef.current.contains(event.target)
      ) {
        setBackgroundColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [
    linkColorRef,
    linkColorPickerRef,
    showLinkColorPicker,
    backgroundColorRef,
    showBackgroundColorPicker,
    backgroundColorPickerRef
  ])

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  useEffect(() => {
    let editorObj = {
      preHeaderText: 'If you cannot see images',
      linkToWeb: 'click here',
      linkColor: '#17a3e1',
      alignment: 'left',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '12px',
      paddingRight: '12px',
      backgroundType: 'color',
      backgroundColor: '#fff',
      file: '',
      imageStyle: 'contain'
    }
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        editorObj = { ...editorObj, ...(editingAudience[editingPath] || {}) }
      }
    }
    updatePreheaderEditorObj(editorObj)
  }, [editingComponents, editingPath])

  const handleChange = (key, value) => {
    const updatedPreHeader = { ...preheaderEditorObj, [key]: value }
    updatePreheaderEditorObj(updatedPreHeader)
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: updatedPreHeader,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  const handleFileChange = event => {
    const {
      target: { files }
    } = event
    if (files && files[0]) {
      const fileReader = new FileReader()
      fileReader.onload = event => {
        handleChange('file', event.target.result)
      }
      fileReader.readAsDataURL(files[0])
    }
  }

  return (
    <div css={preHeaderContainer}>
      <div css={titleStyle}>Preheader</div>
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Text</label>
        <div css={fieldStyle}>
          <input
            css={inputStyle}
            value={preheaderEditorObj.preHeaderText}
            onChange={({ target: { value } }) =>
              handleChange('preHeaderText', value)
            }
            placeholder='If you cannot see images'
          />
        </div>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Link to web version</label>
        <div css={fieldStyle}>
          <input
            css={inputStyle}
            value={preheaderEditorObj.linkToWeb}
            onChange={({ target: { value } }) =>
              handleChange('linkToWeb', value)
            }
            placeholder='click here'
          />
        </div>
      </div>
      <TextEditor style={textEditorStyle} textStyle='Footnote' />
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Link color</label>
        <div css={fieldStyle} ref={linkColorRef}>
          <input
            css={inputStyle}
            type='text'
            value={preheaderEditorObj.linkColor}
            onChange={({ target: { value } }) => {
              handleChange('linkColor', value)
            }}
          />
          <span
            css={colorBoxStyle}
            style={{ backgroundColor: preheaderEditorObj.linkColor }}
            onClick={() => {
              setLinkColorPicker(prev => !prev)
              updateColorCoordinate(linkColorRef.current)
            }}
          />
        </div>
        {showLinkColorPicker && (
          <Portal>
            <div
              style={{ ...colorCoords, position: 'absolute' }}
              ref={linkColorPickerRef}
            >
              <SketchPicker
                color={preheaderEditorObj.linkColor}
                onChangeComplete={color => {
                  handleChange('linkColor', color.hex)
                }}
              />
            </div>
          </Portal>
        )}
      </div>
      <div
        style={{
          paddingBottom: '24px',
          borderTop: '1px solid #e7e7e7',
          borderBottom: '1px solid #e7e7e7',
          paddingTop: '24px'
        }}
      >
        <label css={labelStyle}>Alignment</label>
        <div css={btnGroupStyle}>
          <button
            css={typeBtnStyle(preheaderEditorObj.alignment === 'left')}
            onClick={() => handleChange('alignment', 'left')}
          >
            <div css={typeBtnTextStyle}>Left</div>
          </button>
          <button
            css={typeBtnStyle(preheaderEditorObj.alignment === 'center')}
            onClick={() => handleChange('alignment', 'center')}
          >
            <div css={typeBtnTextStyle}>Center</div>
          </button>
          <button
            css={typeBtnStyle(preheaderEditorObj.alignment === 'right')}
            onClick={() => handleChange('alignment', 'right')}
          >
            <div css={typeBtnTextStyle}>Right</div>
          </button>
        </div>
      </div>
      <div css={toggleContent}>
        <div
          className='toggle-arrow-wrap'
          onClick={() => {
            togglePaddingContainer(prev => !prev)
          }}
        >
          <div className='toggle-title'>Padding</div>
          <div className={`arrow ${showPaddingContainer && 'is-open'}`}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '10px', height: '10px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0634553 10L0 0L6 4.91624L0.0634553 10Z'
                fill='#17a3e1'
              ></path>
            </svg>
          </div>
        </div>
        <div
          className='content'
          style={{ display: showPaddingContainer ? 'block' : 'none' }}
        >
          <span css={paddingText}>
            {' '}
            Use padding to adjust the size of the button.
          </span>
          <div css={paddingsContainer}>
            <div className='padding' style={{ gridColumn: '3/4' }}>
              <div className='padding-input'>
                <input
                  value={(preheaderEditorObj['paddingTop'] || '').slice(0, -2)}
                  onChange={({ target: { value } }) =>
                    handleChange('paddingTop', value + 'px')
                  }
                />
              </div>
            </div>
            <div className='padding' style={{ gridColumn: '1/3' }}>
              <div className='padding-input'>
                <input
                  value={(preheaderEditorObj['paddingLeft'] || '').slice(0, -2)}
                  onChange={({ target: { value } }) =>
                    handleChange('paddingLeft', value + 'px')
                  }
                />
              </div>
            </div>
            <div className='padding-icon'>
              <svg
                width='106'
                height='84'
                viewBox='0 0 106 84'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ width: '100px', height: '100px' }}
              >
                <rect width='106' height='84' rx='4' fill='#F5F5F5'></rect>
                <rect x='20' y='31' width='66' height='2' fill='#C4C4C4'></rect>
                <rect x='20' y='38' width='56' height='2' fill='#C4C4C4'></rect>
                <rect x='20' y='44' width='61' height='2' fill='#C4C4C4'></rect>
                <rect x='20' y='51' width='47' height='2' fill='#C4C4C4'></rect>
              </svg>
            </div>
            <div className='padding' style={{ gridColumn: '4/4' }}>
              <div className='padding-input'>
                <input
                  value={(preheaderEditorObj['paddingRight'] || '').slice(
                    0,
                    -2
                  )}
                  onChange={({ target: { value } }) =>
                    handleChange('paddingRight', value + 'px')
                  }
                />
              </div>
            </div>
            <div className='padding' style={{ gridColumn: '3/4' }}>
              <div className='padding-input'>
                <input
                  value={(preheaderEditorObj['paddingBottom'] || '').slice(
                    0,
                    -2
                  )}
                  onChange={({ target: { value } }) =>
                    handleChange('paddingBottom', value + 'px')
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={toggleContent} style={{ borderBottom: 'none' }}>
        <div
          className='toggle-arrow-wrap'
          onClick={() => {
            toggleColorContainer(prev => !prev)
          }}
        >
          <div className='toggle-title'>Colors</div>
          <div className={`arrow ${showColorContainer && 'is-open'}`}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '10px', height: '10px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0634553 10L0 0L6 4.91624L0.0634553 10Z'
                fill='#17a3e1'
              ></path>
            </svg>
          </div>
        </div>
        <div
          className='content'
          style={{ display: showColorContainer ? 'block' : 'none' }}
        >
          <div
            style={{
              paddingBottom: '24px'
            }}
          >
            <label css={labelStyle}>Background</label>
            <div css={btnGroupStyle}>
              <button
                css={typeBtnStyle(
                  preheaderEditorObj.backgroundType === 'color'
                )}
                onClick={() => handleChange('backgroundType', 'color')}
              >
                <div css={typeBtnTextStyle}>Color</div>
              </button>
              <button
                css={typeBtnStyle(
                  preheaderEditorObj.backgroundType === 'image'
                )}
                onClick={() => handleChange('backgroundType', 'image')}
              >
                <div css={typeBtnTextStyle}>Image</div>
              </button>
            </div>
          </div>
          {preheaderEditorObj.backgroundType === 'image' && (
            <>
              <div style={{ paddingBottom: '24px' }}>
                <label css={labelStyle}>
                  Background image
                  <span>
                    You can use JPG, PNG, and GIF formats, each under 2000px in
                    dimension
                  </span>
                </label>
                <div css={imageUploadBlockStyle}>
                  <input
                    ref={fileRef}
                    type='file'
                    hidden
                    onChange={handleFileChange}
                    accept='image/png,image/jpeg,image/jpg,image/gif'
                  />
                  <div css={imagePlaceholderStyle}>
                    <img
                      src={
                        preheaderEditorObj.file ||
                        'https://app.omnisend.com/content-editor-app/assets/global/gc-image-upload-placeholder.svg'
                      }
                    />
                  </div>
                  <button
                    css={selectFileBtnStyle}
                    onClick={() => {
                      fileRef.current.click()
                    }}
                  >
                    <div className='text'>
                      {preheaderEditorObj.file ? 'Replace' : 'Select file'}
                    </div>
                  </button>
                  {preheaderEditorObj.file && (
                    <>
                      <div
                        style={{
                          margin: '0 10px',
                          height: '15px',
                          backgroundColor: '#333',
                          width: 1
                        }}
                      ></div>
                      <button
                        css={selectFileBtnStyle}
                        onClick={() => {
                          const updatedPreHeader = {
                            ...preheaderEditorObj,
                            file: ''
                          }
                          updatePreheaderEditorObj(updatedPreHeader)
                          dispatch(
                            updateEditingComponent({
                              path: editingPath,
                              value: updatedPreHeader,
                              currentEditedSegmentandAudience,
                              state: editingComponents
                            })
                          )
                          setTimeout(() => {
                            dispatch(
                              updateEmailTemplateHandler(
                                emailTemplates,
                                currentEditedSegmentandAudience
                              )
                            )
                          }, 300)
                        }}
                      >
                        <div className='text'>Remove</div>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  paddingBottom: '24px'
                }}
              >
                <label css={labelStyle}>Style</label>
                <div css={btnGroupStyle}>
                  <button
                    css={typeBtnStyle(
                      preheaderEditorObj.imageStyle === 'cover'
                    )}
                    onClick={() => handleChange('imageStyle', 'cover')}
                  >
                    <div css={typeBtnTextStyle}>Fill</div>
                  </button>
                  <button
                    css={typeBtnStyle(
                      preheaderEditorObj.imageStyle === 'contain'
                    )}
                    onClick={() => handleChange('imageStyle', 'contain')}
                  >
                    <div css={typeBtnTextStyle}>Fit</div>
                  </button>
                  <button
                    css={typeBtnStyle(
                      preheaderEditorObj.imageStyle === 'repeat'
                    )}
                    onClick={() => handleChange('imageStyle', 'repeat')}
                  >
                    <div css={typeBtnTextStyle}>Repeat</div>
                  </button>
                </div>
              </div>
            </>
          )}
          <div style={{ marginBottom: '24px' }}>
            <label css={labelStyle}>
              {preheaderEditorObj['backgroundType'] === 'color'
                ? 'Color'
                : 'Fallback color'}
            </label>
            <div css={fieldStyle} ref={backgroundColorRef}>
              <input
                css={inputStyle}
                type='text'
                value={preheaderEditorObj.backgroundColor}
                onChange={({ target: { value } }) => {
                  handleChange('backgroundColor', value)
                }}
              />
              <span
                css={colorBoxStyle}
                style={{ backgroundColor: preheaderEditorObj.backgroundColor }}
                onClick={() => {
                  setBackgroundColorPicker(prev => !prev)
                  updateColorCoordinate(backgroundColorRef.current)
                }}
              />
            </div>
            {showBackgroundColorPicker && (
              <Portal>
                <div
                  style={{ ...colorCoords, position: 'absolute' }}
                  ref={backgroundColorPickerRef}
                >
                  <SketchPicker
                    color={preheaderEditorObj.backgroundColor}
                    onChangeComplete={color => {
                      handleChange('backgroundColor', color.hex)
                    }}
                  />
                </div>
              </Portal>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreHeaderEditor
