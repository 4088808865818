/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getAdsData,
  deleteAdCampaign,
  copyAdCampaign
} from '../../../store/dashboard/adsSlice'

import AdIcon from '../../../assets/images/dashboard/ad.png'

import { container, sideDrawer, textStyle, iconStyle } from './style'

import CampaignAds from './CampaignAds'

const Ads = ({ productId, editCampaign }) => {
  const dispatch = useDispatch()

  const { campaignCollection } = useSelector(state => ({
    campaignCollection: state.ads.campaignCollection
  }))

  useEffect(() => {
    dispatch(getAdsData(productId))
  }, [dispatch, productId])

  const deleteCampaignHandler = id => {
    dispatch(deleteAdCampaign(productId, id))
  }

  const copyAdCampaignHandler = (adCampaignData, campaignData) => {
    dispatch(copyAdCampaign(productId, adCampaignData, campaignData))
  }

  return campaignCollection && campaignCollection.length ? (
    campaignCollection.map(({ campaignData, adsCampaignData }, index) => (
      <CampaignAds
        key={index}
        isFirstAd={index === 0}
        campaignIndex={index}
        adCampaignData={adsCampaignData}
        campaignData={campaignData}
        editCampaign={editCampaign}
        deleteCampaignHandler={deleteCampaignHandler}
        copyAdCampaignHandler={copyAdCampaignHandler}
      />
    ))
  ) : (
    <div css={container}>
      <div css={sideDrawer}>
        <span css={iconStyle}>
          <img src={AdIcon} alt='ad-icon' width={37} height={33} />
        </span>
        <span css={textStyle}>Ads</span>
      </div>
    </div>
  )
}

export default Ads
