import { css } from '@emotion/react/macro'

const stickySegmentStyle = css`
  position: sticky;
  top: 0px;
  z-index: 11;
  background: #fafafa;
`

export const container = (segmentDropdown, stickyHeader, fullHeight) => css`
  display: flex;
  max-width: 1537px;
  ${segmentDropdown && stickyHeader && stickySegmentStyle}
`

const segmentDropdownStyle = fullheight => css`
  height: 66px;
  min-height: 66px;
  max-height: 66px;
  padding-top: 14px;
  transition: max-height 0.5s ease-out;
  ${fullheight && 'height: 528px; max-height: 528px;'}
`

export const sideDrawer = (
  segmentDropdown,
  fullheight,
  isSingleCriteriaSegment,
  showSegment
) => css`
  width: 115px;
  background: #fff;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 79px;
  flex-shrink: 0;
  ${segmentDropdown && segmentDropdownStyle(fullheight)}
  ${isSingleCriteriaSegment && 'height: auto;'}
  ${showSegment && 'z-index: 1;'}
`

export const mainContent = (segmentDropdown, editSegments) => css`
  padding: 14px 12px 12px 12px;
  width: 1432px;
  display: flex;
  ${!segmentDropdown && 'height: 654px;'}
`

export const buttonGroup = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

export const buttonStyle = css`
  box-sizing: border-box;
  height: 40px;
  width: 81px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 5px;
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  margin-bottom: 8px;
  cursor: pointer;
  &.disable {
    pointer-events: none;
    background: #e8e8e8;
    color: #333;
    box-shadow: none;
  }
`

export const addSegmentContainer = css`
  box-sizing: border-box;
  height: 621px;
  width: 252px;
  border: 4px dashed #cccccc;
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 13px 24px 20px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 13px;
  &:hover {
    cursor: pointer;
  }
  span {
    width: 20px;
    height: 20px;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
    }
  }
`

export const updateBtnStyle = css`
  color: #fff;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  padding-top: 4px;
  &[data-isChanged='false'] {
    opacity: 0.5;
    pointer-events: none;
  }
`
const toggleHeightAnimation = fullHeight => css`
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  padding: 8px 24px 20px 21px;
  transition: all 0.5s ease-out;
  ${fullHeight && 'height: 498px; overflow: unset; max-height: 498px;'}
`

export const nonActiveSegmentContainer = fullHeight => css`
  height: auto;
  width: 252px;
  padding: 13px 24px 20px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 13px;
 
`

export const modalInnerContainer = css`
  padding: 20px;
  width: 100%;
  p {
    margin: 0;
    font-family: 'BentonSans';
    text-align: center;
  }
  .heading {
    font-size: 33px;
    font-weight: 500;
    color: #292929;
  }
  .heading2 {
    font-size: 17px;
    font-weight: 500;
    color: #292929;
  }
  .sub-heading {
    color: #2d2d2d;
    font-size: 12px;
    margin-top: 20px;
  }
  .buttonContainer {
    text-align: center;
    margin-top: 20px;
  }
  button {
    box-sizing: border-box;
    height: 35px;
    width: 105px;
    border: 0.8px solid #c5c5c5;
    background-color: #ffffff;
    border-radius: 4px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  .edit-button {
    border: 0.8px solid #c5c5c5;
    background-color: #e1e1e1;
  }
  .go-live {
    background: #3296dc;
    color: #fff;
  }
`
