import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useStyles from './styles'
import NavigationDrawer from '../ui/CdpNavigationDrawer/CdpNavigationDrawer'
import Footer from '../ui/LandingPageFooter/Footer'
import Header from '../ui/Header/CdpHeader/Header'

import UnifiedIds from './UnifiedIds/UnifiedIds'
import ImportQueue from './ImportQueue/ImportQueue'
import Connectors from '../Connectors'

import { authActions } from '../../store/authSlice'
import ViewContactsPage from '../ViewContacts/ViewContacts'
import { isBoolean } from 'lodash'

const CdpPage = ({ onboarded }) => {
  const classes = useStyles()
  let params = useParams()
  const dispatch = useDispatch()

  let compToRender
  let tabValue = 2
  let headerTitle = ''
  if (params.pageId === 'unifiedIds') {
    tabValue = 1
    compToRender = <UnifiedIds />
  } else if (params.pageId === 'connectors') {
    tabValue = 4
    headerTitle = 'Connectors'
    compToRender = <Connectors />
  } else if (params.pageId === 'view-contacts') {
    tabValue = 0
    compToRender = <ViewContactsPage />
  } else compToRender = <ImportQueue />

  const logOutHandler = () => {
    dispatch(authActions.logout())
  }

  return (
    <div className={classes.root}>
      <Header logOutHandler={logOutHandler} headerTitle={headerTitle} />
      <NavigationDrawer tabValue={tabValue} onboarded={onboarded} />
      {compToRender}
      <Footer contentText='All Rights Reserved. © 2023 Neyo' />
    </div>
  )
}

export default CdpPage
