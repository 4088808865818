/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SegmentForm from './SegmentForm/SegmentForm'
import PlusIcon from '../../../assets/images/dashboard/plus.png'
import Modal from '../../ui/Modal'
import {
  getSegmentsData,
  updateSegments
} from '../../../store/dashboard/segmentsSlice'
import { filterModifiedObject } from '../../../utils/segmentHelper'

import {
  container,
  sideDrawer,
  buttonGroup,
  mainContent,
  buttonStyle,
  addSegmentContainer,
  updateBtnStyle,
  nonActiveSegmentContainer,
  modalInnerContainer
} from './style'

const segmentMock = {
  segmentName: '',
  isEditable: true,
  status: 'active',
  newAddition: true,
  segmentType: 'custom',
  ruleGroups: [
    {
      matchType: 'Match All',
      rules: [
        {
          prospectTag: '',
          condition: '',
          value: ''
        },
        {
          prospectTag: '',
          condition: '',
          value: ''
        }
      ]
    }
  ]
}

const Segments = ({
  productId,
  stickyHeader,
  editSegments,
  onSegmentClick = () => {}
}) => {
  const dispatch = useDispatch()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const recordsUpdated = useRef(null)

  let segmentsDataFromApi = useSelector(state => state.segments.segmentsData)

  useEffect(() => {
    if (segmentsDataFromApi.length) {
      recordsUpdated.current = null
    }
  }, [segmentsDataFromApi])

  const [segmentData, setSegmentData] = useState(
    JSON.parse(
      JSON.stringify(
        segmentsDataFromApi.length ? segmentsDataFromApi : [segmentMock]
      )
    )
  )
  const [segmentDropdown, setSegmentDropdown] = useState(true)
  const [fullHeight, setFullHeight] = useState(false)

  useEffect(() => {
    dispatch(getSegmentsData(productId))
  }, [dispatch, productId])

  useEffect(() => {
    if (segmentsDataFromApi.length) {
      setSegmentData(segmentsDataFromApi)
    }
  }, [segmentsDataFromApi])

  useEffect(() => {
    setFullHeight(editSegments)
  }, [editSegments])

  const addNewSegment = id => {
    const updatedSegments = [...JSON.parse(JSON.stringify(segmentData))]
    const segmentDataIndex = updatedSegments.findIndex(data => {
      return data.id === id
    })
    updatedSegments[segmentDataIndex] = {
      ...updatedSegments[segmentDataIndex],
      ...segmentMock
    }
    setSegmentData(updatedSegments)
  }
  const updateData = (id, key, value) => {
    const prevSegments = JSON.parse(JSON.stringify(segmentData))
    const prevData = prevSegments.find(({ id: dataId }) => dataId === id)
    const index = prevSegments.findIndex(({ id: dataId }) => dataId === id)
    const newData = { ...prevData, [key]: value, dataFromApi: false }
    prevSegments.splice(index, 1, newData)
    setSegmentData(prevSegments)
  }

  const deleteSegment = id => {
    const prevSegments = JSON.parse(JSON.stringify(segmentData))
    const index = prevSegments.findIndex(({ id: dataId }) => dataId === id)
    prevSegments[index]['status'] = 'inactive'
    prevSegments[index]['dataFromApi'] = false
    setSegmentData(prevSegments)
  }

  const saveSegmentData = productId => {
    setSegmentDropdown(true)
    dispatch(updateSegments(segmentData, productId))
  }

  const isSegmentsInEditMode = () => {
    return (
      !segmentData.length ||
      segmentData.every(({ dataFromApi }) => dataFromApi === true) ||
      segmentData.some(({ isEditable }) => isEditable === true)
    )
  }

  const cancelHandler = () => {
    setSegmentDropdown(true)
    dispatch(getSegmentsData(productId))
  }

  const checkForDataChange = () => {
    const changedSegments = filterModifiedObject(
      segmentsDataFromApi,
      segmentData
    )
    if (changedSegments.length > 0) {
      let recordsChanged = 0
      recordsChanged = (changedSegments || []).reduce((prev, acc) => {
        prev += acc?.matchedRecordIds?.length
        return prev
      }, 0)
      recordsUpdated.current = recordsChanged
      return true
    }
    return false
  }

  const showSegment = segmentDropdown && editSegments

  const checkSingleCriteriaSegment = useMemo(() => {
    return (segmentData || []).every(
      ({ ruleGroups }) =>
        ruleGroups &&
        ruleGroups.length < 2 &&
        ruleGroups.every(({ rules }) => rules && rules.length < 2)
    )
  }, [segmentData])

  return (
    <>
      <div css={container(segmentDropdown, stickyHeader, fullHeight)}>
        <div
          css={sideDrawer(
            segmentDropdown,
            fullHeight,
            checkSingleCriteriaSegment,
            showSegment
          )}
        >
          {!segmentDropdown && (
            <>
              <div css={buttonGroup}>
                <button css={buttonStyle}>Preview</button>
                <button
                  className={isSegmentsInEditMode() ? 'disable' : ''}
                  css={[buttonStyle, updateBtnStyle]}
                  data-isChanged={checkForDataChange()}
                  onClick={() => {
                    setShowConfirmationModal(true)
                  }}
                >
                  Update
                </button>
              </div>
              <button css={buttonStyle} onClick={cancelHandler}>
                Cancel
              </button>
            </>
          )}
          {showSegment && (
            <>
              <button
                css={[buttonStyle, updateBtnStyle]}
                onClick={() => setSegmentDropdown(false)}
              >
                Edit
              </button>
            </>
          )}
        </div>
        <div css={mainContent(segmentDropdown, editSegments)}>
          {!segmentDropdown && (
            <>
              {segmentData.map((data, index) => {
                const { id } = data
                return data.status === 'active' ? (
                  <SegmentForm
                    data={data}
                    index={index}
                    key={index}
                    id={id}
                    isSingleCriteriaSegment={checkSingleCriteriaSegment}
                    deleteSegment={id => deleteSegment(id)}
                    updateData={updateData}
                    fullHeight={true}
                  />
                ) : segmentData[index - 1].status === 'active' ? (
                  <div
                    key={index}
                    css={addSegmentContainer}
                    onClick={() => addNewSegment(id)}
                  >
                    <span>
                      <img src={PlusIcon} alt='plus-icon' />
                    </span>
                  </div>
                ) : (
                  <div key={index} css={nonActiveSegmentContainer}></div>
                )
              })}
            </>
          )}
          {segmentDropdown &&
            segmentData.map((data, index) => {
              return data.status === 'active' ? (
                <SegmentForm
                  data={data}
                  key={index}
                  index={index}
                  isSingleCriteriaSegment={checkSingleCriteriaSegment}
                  segmentDropdown={segmentDropdown}
                  toggleHeight={() => setFullHeight(!fullHeight)}
                  fullHeight={fullHeight}
                />
              ) : (
                <div
                  key={index}
                  css={nonActiveSegmentContainer(fullHeight)}
                ></div>
              )
            })}
        </div>
      </div>
      {showConfirmationModal && (
        <Modal showCross={false}>
          <div css={modalInnerContainer}>
            <p className='heading'>Alert</p>
            <p className='heading2'>
              This change will impact {recordsUpdated?.current} records
            </p>
            <p className='sub-heading'>
              This dictionary change will affect audiences, reporting and
              triggers. Once this change is complete, it cannot be undone.
            </p>
            <p className='sub-heading'>
              If changes to segments are necessary, it is suggested to edit them
              at the beginning of a month or quarter for reporting purposes.
            </p>
            <div className='buttonContainer'>
              <button
                className='edit-button'
                onClick={() => {
                  setShowConfirmationModal(false)
                }}
              >
                Keep Editing
              </button>
              <button
                onClick={() => {
                  setShowConfirmationModal(false)
                  cancelHandler()
                }}
              >
                Cancel
              </button>
              <button
                className='go-live'
                onClick={() => {
                  setShowConfirmationModal(false)
                  saveSegmentData(productId)
                }}
              >
                File Changes
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Segments
