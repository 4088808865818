import React from 'react'

import { MockEditingContext } from '../contexts/mockEditingContext'

const WithMockEditingContext = WrappedComponent =>
  class extends React.Component {
    state
    constructor (props) {
      super(props)
      this.state = {
        mockEditingId: null,
        setMockEditingId: this.setMockEditingId
      }
    }
    setMockEditingId = id => {
      this.setState(prevState => ({
        ...prevState,
        mockEditingId: id
      }))
    }

    render () {
      return (
        <MockEditingContext.Provider value={this.state}>
          <WrappedComponent
            {...this.props}
            mockEditingId={this.state.mockEditingId}
          />
        </MockEditingContext.Provider>
      )
    }
  }

export default WithMockEditingContext
