import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toast from './ui/Toast'

import theme from './ui/theme'
import LandingPage from './LandingPage/LandingPage'

function App () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LandingPage />
      <Toast />
    </ThemeProvider>
  )
}

export default App
