/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import ContentEditable from 'react-contenteditable'
import { editableTextContainer } from './style'
import {
  updateEmailTemplateHandler,
  updateCurrentEditingComponent
} from '../../../../../../../store/dashboard/mailerSlice'

import { useDispatch, useSelector } from 'react-redux'

import debounce from '../../../../../../../utils/debounce'

const EditableText = ({
  text,
  style: overRideStyle,
  containerStyle,
  parentPath,
  componentPath,
  parentUid
}) => {
  const [html, setHtml] = useState(text || 'Enter your text here')
  const [contentEditable, setContentEditable] = useState(false)

  const dispatch = useDispatch()
  const {
    currentEditedSegmentandAudience,
    emailTemplates,
    editingComponents,
    currentEditingComponent: { path: editingPath } = {}
  } = useSelector(state => ({
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    emailTemplates: state.mailer.emailTemplates,
    editingComponents: state.mailer.editingComponents,
    currentEditingComponent: state.mailer.currentEditingComponent
  }))

  let path = `${parentPath}->${componentPath}`

  const { segmentId, audienceId } = currentEditedSegmentandAudience
  const editingSegment = editingComponents[segmentId]
  const editorObj = (editingSegment || {})[audienceId] || {}

  useEffect(() => {
    const { [path]: { content } = {} } = editorObj
    if (content) {
      setHtml(`${html} ${content}`)
    }
  }, [editorObj])

  const dispatchEmailUpdate = () => {
    dispatch(
      updateEmailTemplateHandler(
        emailTemplates,
        currentEditedSegmentandAudience
      )
    )
  }

  const debounceEmailUpdates = debounce(dispatchEmailUpdate, 800)

  const handleChange = ({ target: { value } }) => {
    setHtml(value)
    debounceEmailUpdates()
  }

  const onTextElementSelect = () => {
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}->${componentPath}`,
        type: 'text',
        parentUid
      })
    )
  }

  const getStyles = () => {
    let style = {
      ...overRideStyle
    }
    let path = `${parentPath}->${componentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        const { font, size, lineHeight, color } = editingAudience[path] || {}
        if (editingAudience[path]) {
          style = {
            ...style,
            fontFamily: font,
            fontSize: size,
            lineHeight,
            color
          }
        }
      }
    }
    return style
  }

  return (
    <div
      onClick={event => {
        event.stopPropagation()
        onTextElementSelect()
        setContentEditable(true)
      }}
      css={[
        editableTextContainer(`${parentPath}->${componentPath}` === editingPath)
      ]}
      style={{ padding: '12px', ...containerStyle }}
    >
      <ContentEditable
        style={{ margin: 0, ...overRideStyle, ...getStyles() }}
        html={html}
        disabled={!contentEditable}
        onBlur={() => setContentEditable(false)}
        onChange={handleChange}
      />
    </div>
  )
}

export default EditableText
