import { css } from '@emotion/react'

export const container = css`
  box-sizing: border-box;
  height: 282px;
  width: 498px;
  padding: 18px 52px;
  margin: auto;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(51, 153, 255, 0.4);
`

export const header = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
`

export const subHeader = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 0px 0px 16px 0px;
`

export const content = css`
  text-align: center;
  span {
    font-size: 13px;
    display: inline-block;
    height: auto;
    text-align: center;
  }
  .top {
    padding: 0px 15px;
    margin-bottom: 12px;
  }
`

export const buttonContainer = css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    box-sizing: border-box;
    height: 35px;
    width: 105px;
    border: 0.8px solid #cccccc;
    font-family: BentonSans;
    font-size: 13px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    border-radius: 5px;
    cursor: pointer;
  }
`

export const buttonStyle = css`
  padding-top: 2px;
  &.keepEditing {
    background-color: #e5e5e5;
  }
  &.cancel {
    margin-left: 12px;
    background-color: #ffffff;
  }
  &.fileChanges {
    color: #ffffff;
    margin-left: 12px;
    background-color: #18a3e2;
  }
`

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 400px;
`

export const sidebarStyle = css`
  width: 115px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  flex-shrink: 0;
  padding-top: 55px;
`

export const textStyle = css`
  height: 11px;
  width: 65px;
  color: #333333;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 27px;
  display: block;
`

export const iconStyle = css`
  display: block;
  width: 33px;
  height: 30px;
  margin-bottom: 13px;
  img {
    width: 100%;
    object-fit: contain;
  }
`
