import { css, keyframes } from '@emotion/react'

export const imageEditorContainer = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 24px;
`

export const labelStyle = css`
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  font-size: 13px;
  line-height: 22px;
  padding-bottom: 12px;
  display: inline-block;
  margin-bottom: 0.5rem;
  span:not(.title) {
    display: block;
    font-family: Roboto;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #979797;
    font-size: 13px;
    line-height: 22px;
  }
  .title {
    text-transform: capitalize;
  }
`

export const imageUploadBlockStyle = css`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  [hidden] {
    display: none !important;
  }
`

export const imagePlaceholderStyle = css`
  display: flex;
  align-items: center;
  width: 60px;
  height: 40px;
  overflow: hidden;
  margin-right: 16px;
  img {
    max-width: 60px;
    max-height: 40px;
  }
`

export const selectFileBtnStyle = css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  color: #555c66;
  font-family: Roboto;
  position: relative;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  fill: #555c66;
  border-radius: 4px;
  cursor: pointer;
  &:before {
    border-radius: 6px;
    bottom: -6px;
    content: ' ';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }
  .text {
    padding: 0 6px;
    height: auto;
  }
  &:hover {
    background-color: #fafafa;
    border: 1px solid #d8d8d8;
    outline: none;
  }
`

export const fieldBlockStyle = css`
  display: inline-flex;
  flex-direction: column;
  min-width: 214px;
  padding-bottom: 24px;
  width: 100%;
`

export const textFieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  transition: border-color 0.1s linear;
  height: 36px;
  padding: 6px;
`

export const inputStyle = css`
  padding: 0 6px;
  font-size: 13px;
  line-height: 22px;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  outline: none;
  width: 100%;
`

export const altLabelStyle = css`
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0 0 4px;
  font-size: 13px;
  line-height: 22px;
`

export const btnGroupStyle = css`
  display: inline-flex;
`

export const alignmentBtnStyle = isActive => css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  width: 100%;
  border-radius: 4px;
  outline: none;
  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
  &:before {
    border-radius: 6px;
    bottom: -6px;
    content: ' ';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }
  ${isActive && 'background-color: #4eafe2; color: #fff;'}
`

export const alignmentBtnTextStyle = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  line-height: 25px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
  height: auto;
  &:after {
    border-radius: 4px;
    bottom: 0;
    content: ' ';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    line-height: 22px;
  }
`

const bouncedelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

export const loaderWrapperStyle = css`
  width: 70px;
  text-align: center;
  &.spinner > div {
    width: 13px;
    height: 13px;
    background-color: #17a3e1;
    border-radius: 100%;
    display: inline-block;

    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  }

  &.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`
