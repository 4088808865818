import { v4 as uuidv4 } from 'uuid'
import EditableTextComponent from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/EditableTextComponent'
import Button from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/Button'
import Preheader from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/PreHeader'
import Header_01 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/Header/Header_01'
import Header_02 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/Header/Header_02'
import Header_03 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/Header/Header_03'
import Hero1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/HeroImage/hero_1'
import ProductListing_1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/ProductListing/productListing1'
import ImageGallery1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/ImageGallery/imageGalley_1'
import Footer1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/Footer/footer1'
import EmailBody1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/EmailBody/emailBody1'
import QuickAddLayout1 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout1'
import QuickAddLayout2 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout2'
import QuickAddLayout3 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout3'
import QuickAddLayout4 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout4'
import QuickAddLayout5 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout5'
import QuickAddLayout6 from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddLayout/quickAddLayout6'
import Image from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddItem/image'
import Logo from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddItem/logo'
import Menu from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddItem/menu'
import Line from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddItem/line'
import SocialMedia from '../../components/Dashboard/MailerEditor/CampaignBuilder/Editor/RenderableComponents/QuickAddItem/socialMedia'

export const LibraryItems = [
  {
    id: uuidv4(),
    title: 'Quick add item',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'text',
          uId: 1,
          parentKey: 'Quick add item',
          component: props => <EditableTextComponent {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'button',
          uId: 2,
          parentKey: 'Quick add item',
          component: props => <Button {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'image',
          uId: 3,
          parentKey: 'Quick add item',
          component: props => <Image {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'logo',
          uId: 4,
          parentKey: 'Quick add item',
          component: props => <Logo {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'menu',
          uId: 5,
          parentKey: 'Quick add item',
          component: props => <Menu {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'line/space',
          uId: 6,
          parentKey: 'Quick add item',
          component: props => <Line {...props} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'item',
        component: {
          type: 'social media',
          uid: 7,
          parentKey: 'Quick add item',
          component: props => <SocialMedia {...props} />
        }
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Quick add layout',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'one column',
          uId: 8,
          parentKey: 'Quick add layout',
          img:
            'https://preview.soundestlink.com/image/template/empty_section_1.jpg',
          component: ({ path }) => <QuickAddLayout1 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'two column',
          uId: 9,
          parentKey: 'Quick add layout',
          img:
            'https://preview.soundestlink.com/image/template/empty_section_2.jpg',
          component: ({ path }) => <QuickAddLayout2 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'three column',
          uId: 10,
          parentKey: 'Quick add layout',
          img:
            'https://preview.soundestlink.com/image/template/empty_section_3.jpg',
          component: ({ path }) => <QuickAddLayout3 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'four column',
          uId: 11,
          parentKey: 'Quick add layout',
          img:
            'https://preview.soundestlink.com/image/template/empty_section_4.jpg',
          component: ({ path }) => <QuickAddLayout4 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'Mixed left',
          parentKey: 'Quick add layout',
          uId: 24,
          img:
            'https://preview.soundestlink.com/image/template/empty_section_big_small.jpg',
          component: ({ path }) => <QuickAddLayout5 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'layout',
        component: {
          type: 'Mixed right',
          uId: 12,
          parentKey: 'Quick add layout',
          img:
            'https://preview.soundestlink.com/image/template/empty_section_small_big.jpg',
          component: ({ path }) => <QuickAddLayout6 path={path} />
        }
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Preheader',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Preheader Left',
        component: {
          uId: 13,
          parentKey: 'Preheader',
          img:
            'https://preview.soundestlink.com/image/template/preheader_left.jpg',
          component: ({ path }) => <Preheader align='left' parentPath={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Preheader Center',
        component: {
          uId: 14,
          parentKey: 'Preheader',
          img:
            'https://preview.soundestlink.com/image/template/preheader_center.jpg',
          component: ({ path }) => (
            <Preheader align='center' parentPath={path} />
          )
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Preheader Right',
        component: {
          uId: 15,
          parentKey: 'Preheader',
          img:
            'https://preview.soundestlink.com/image/template/preheader_right.jpg',
          component: ({ path }) => <Preheader align='right' parentPath={path} />
        }
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Header',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Logo',
        component: {
          uId: 16,
          parentKey: 'Header',
          img:
            'https://preview.soundestlink.com/image/template/header_01_logo.jpg',
          component: ({ path }) => <Header_01 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Logo & Menu',
        component: {
          uId: 17,
          parentKey: 'Header',
          img:
            'https://preview.soundestlink.com/image/template/header_02_logo_menu.jpg',
          component: ({ path }) => <Header_02 path={path} />
        }
      },
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Logo & Link',
        component: {
          uId: 18,
          parentKey: 'Header',
          img:
            'https://preview.soundestlink.com/image/template/header_03_logo_link.jpg',
          component: ({ path }) => <Header_03 path={path} />
        }
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Hero image',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Hero 1',
        component: {
          uId: 19,
          parentKey: 'Hero image',
          img: 'https://preview.soundestlink.com/image/template/hero_01.jpg',
          component: ({ path }) => <Hero1 path={path} />
        }
      }
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 2',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_02.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 3',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_03.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 4',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_04.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 5',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_05.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 6',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_06.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 7',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_07.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 8',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_08.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 9',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_09.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 10',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_10.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 11',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_11.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 12',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_12.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 13',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_13.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Hero 14',
      //   component: {
      //     img: 'https://preview.soundestlink.com/image/template/hero_14.jpg',
      //     content: ''
      //   }
      // }
    ]
  },
  {
    id: uuidv4(),
    title: 'Email body',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Title 1',
        component: {
          uId: 20,
          parentKey: 'Email body',
          img:
            'https://preview.soundestlink.com/image/template/email_body_07_section_title.jpg',
          component: ({ path }) => <EmailBody1 path={path} />
        }
      }
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_08_section_title.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_01_section_title.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title 4',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_06_section_title.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title 5',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_09_section_title.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title 6',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_10_section_title.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Button 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_04_section_title_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Button 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_11_section_title_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Button 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_03_section_title_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Button 4',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_12_section_title_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & 2 Buttons 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_13_section_title_2buttons.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title, Paragraph & Button 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_05_title_paragraph_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title, Paragraph & Button 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_14_title_paragraph_button.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Paragraph 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_02_title_paragraph.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Title & Paragraph 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_15_title_paragraph.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Columns 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_18_2columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Columns 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_20_2columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Columns 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_19_3columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Columns 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_21_3columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Columns 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_17_2columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Columns 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_16_3columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Columns 4',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_22_2columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Columns 4',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_23_3columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Buttons',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_35_2buttons.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Buttons',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_36_3buttons.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '4 Buttons 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_38_4buttons.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '4 Buttons 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_37_4buttons.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Text & Image 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_24_text_image.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Text & Image 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_25_text_image.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Text & Image 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_26_text_image.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Text & Image 4',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_28_text_Image.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Text & Image 5',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_27_text_image.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Columns 5',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_29_2columns.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Newsletter 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_31_newsletter.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Newsletter 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_31_newsletter.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Digest',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_32_digest.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '2 Column Digest',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_33_2column_digest.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '3 Column Digest',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/email_body_34_3column_digest.jpg',
      //     content: ''
      //   }
      // }
    ]
  },
  {
    id: uuidv4(),
    title: 'Product listing',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Product listing 1',
        component: {
          uId: 21,
          parentKey: 'Product listing',
          img:
            'https://preview.soundestlink.com/image/template/product_listing_01_2products.jpg',
          component: ({ path }) => <ProductListing_1 path={path} />
        }
      }
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Product Listing 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/product_listing_02_4products.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Product Listing 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/product_listing_03_6products.jpg',
      //     content: ''
      //   }
      // }
    ]
  },
  {
    id: uuidv4(),
    title: 'Image gallery',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: '3 Images',
        component: {
          uId: 22,
          parentKey: 'Image gallery',
          img:
            'https://preview.soundestlink.com/image/template/gallery_01_3images.jpg',
          component: ({ path }) => <ImageGallery1 path={path} />
        }
      }
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '5 Images 1',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/gallery_02_5images.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '5 Images 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/gallery_03_6images.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '6 Images',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/gallery_04_6images.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: '8 Images',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/gallery_05_8images.jpg',
      //     content: ''
      //   }
      // }
    ]
  },
  {
    id: uuidv4(),
    title: 'Footer',
    subMenus: [
      {
        id: uuidv4(),
        type: 'SIDEBAR_ITEM',
        category: 'Footer 1',
        component: {
          uId: 23,
          parentKey: 'Footer',
          img:
            'https://preview.soundestlink.com/image/template/footer_01_footer.jpg',
          component: ({ path }) => <Footer1 path={path} />
        }
      }
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Footer 2',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/footer_02_footer_menu.jpg',
      //     content: ''
      //   }
      // },
      // {
      //   id: uuidv4(),
      //   type: 'SIDEBAR_ITEM',
      //   category: 'Footer 3',
      //   component: {
      //     img:
      //       'https://preview.soundestlink.com/image/template/footer_03_footer.jpg',
      //     content: ''
      //   }
      // }
    ]
  }
]
