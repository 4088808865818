import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import useStyles from './styles'

import AccountChangeQueue from './AccountChangeQueue/index'
import EmailChangeQueue from './EmailChangeQueue/index'
import AccountAndEmailChangeQueue from './AccountAndEmailChangeQueue'
import Loader from '../../ui/Loader'

import {
  getQueueData,
  tagAsDuplicate,
  updatePrimaryAccount,
  addNewAffliatedAccount,
  addSecondaryEmail,
  createNewRecord
} from '../../../store/cdp/importQueueSlice'

const ImportQueue = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getQueueData())
  }, [dispatch])

  let { data, loading } = useSelector(
    ({ importQueue: { queueData, loading } }) => ({ data: queueData, loading })
  )

  const actionHandler = (matchId, actionTaken) => {
    switch (actionTaken) {
      case 'tagAsDuplicate': {
        dispatch(tagAsDuplicate(matchId, actionTaken))
        break
      }
      case 'updatePrimaryAccount': {
        dispatch(updatePrimaryAccount(matchId, actionTaken))
        break
      }
      case 'addNewAffliatedAccount': {
        dispatch(addNewAffliatedAccount(matchId, actionTaken))
        break
      }
      case 'addSecondaryEmail': {
        dispatch(addSecondaryEmail(matchId, actionTaken))
        break
      }
      case 'createNewRecord': {
        dispatch(createNewRecord(matchId, actionTaken))
        break
      }
      default: {
      }
    }
  }

  const getTotalNumberOfRecords = () => {
    let sum = 0
    Object.keys(data).forEach(key => {
      sum += data[key].length
    })
    return sum
  }

  if (loading) {
    return <Loader />
  }

  return (
    <main className={classes.content}>
      <Paper variant='outlined' className={classes.subHeaderPaper}>
        <div className={classes.subHeaderText}>
          <Typography variant='subtitle1'>
            Import Queue ({getTotalNumberOfRecords()} records)
          </Typography>
          <Typography variant='subtitle2'>
            Last Update: 10/28/19 3:36pm
          </Typography>
        </div>
        <div className={classes.subHeaderSearch}>
          <div className={classes.subHeaderSearchIcon}>
            <SearchIcon fontSize='small' />
          </div>
          <InputBase
            placeholder='Search…'
            classes={{
              input: classes.subHeaderSearchInput
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </Paper>
      {data.accountChangeTemplateData.map(content => (
        <AccountChangeQueue
          key={content.id}
          data={content}
          actionHandler={actionHandler}
        />
      ))}
      {data.emailChangeTemplateData.map(content => (
        <EmailChangeQueue
          key={content.id}
          data={content}
          actionHandler={actionHandler}
        />
      ))}
      {data.accountAndEmailChangeTemplateData.map(content => (
        <AccountAndEmailChangeQueue
          key={content.id}
          data={content}
          actionHandler={actionHandler}
        />
      ))}
    </main>
  )
}

export default ImportQueue
