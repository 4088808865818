/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
  bomboraDataFromApi,
  bomboraMockData,
  zoomInfoDataFromApi,
  mockedZoomInfoData
} from '../../../constant/audience'

import SideDrawer from './SideDrawer/index'
import Header from './Header/index'
import AudienceCard from './AudienceCard/index'
import AddAudienceForm from './Forms/AddAudienceForm/index'
import NewAudienceForm from './Forms/NewAudienceForm/index'
import BomboraAudienceCard from './BomboraAudienceCard/index'
import ZoomInfoAudienceCard from './ZoomInfoAudienceCard/index'
import NewBomboraForm from './Forms/NewBomboraForm/index'
import NewZoomInfoForm from './Forms/NewZoomInfoForm/index'

import {
  getAudienceData,
  updateAudiences,
  deleteAudience
} from '../../../store/dashboard/audienceSlice'

import {
  container,
  content,
  contentCard,
  dummyNewAudienceForm,
  dummyAudienceCard
} from './style'

const getSegmentLinkedMapper = segmentAudienceData => {
  const segmentList = Object.keys(segmentAudienceData)
  const segmentLinkedMapper = {}
  segmentList.forEach((key, index) => {
    segmentLinkedMapper[key] = {
      prev: segmentList[index - 1] || null,
      next: segmentList[index + 1] || null
    }
  })
  return segmentLinkedMapper
}

// function to create a map to store the information whether an audience
// is already selected or clickable(this flag responsible to show AddAudienceForm)
const getAddAudienceFlagStore = audienceDataFromApi => {
  let addNewAudienceFlagStore = {}
  let audienceKeys = Object.keys(audienceDataFromApi)
  for (let i = 0; i < audienceKeys.length; i++) {
    let obj = {}
    obj.id = audienceKeys[i]
    obj.prev = audienceKeys[i - 1]
    obj.next = audienceKeys[i + 1]
    obj.isClickable =
      audienceDataFromApi[audienceKeys[i]] === null ? false : true
    obj.status = audienceDataFromApi[audienceKeys[i]] === null ? false : true
    obj.isSelected = false
    addNewAudienceFlagStore[audienceKeys[i]] = obj
  }
  return addNewAudienceFlagStore
}

// function to get the height of audiences
// how many rows of audiences needed to render
const getMaxAudienceListLength = audienceData => {
  let max = 0
  Object.keys(audienceData).forEach(audience => {
    if (audienceData[audience] && audienceData[audience].length > max) {
      max = audienceData[audience].length
    }
  })
  let maxAudienceArr = []
  for (let i = 0; i < max; i++) {
    maxAudienceArr.push(i)
  }
  return maxAudienceArr
}

const Audience = ({
  productId,
  addAudienceFlag,
  addAudienceFormHandler = () => {}
}) => {
  const dispatch = useDispatch()
  const { audienceDataFromApi, expandAudience } = useSelector(state => ({
    audienceDataFromApi: state.audiences.audienceDataFromApi,
    expandAudience: state.topfold.expandAudience
  }))
  const segmentAudienceData = useSelector(
    state => state.audiences.segmentAudienceData
  )

  const [expandedView, setExpandedView] = useState(false)
  const [audiencesData, setAudiencesData] = useState(
    JSON.parse(JSON.stringify(audienceDataFromApi ? audienceDataFromApi : {}))
  )
  const [addAudienceFlagStore, setAddAudienceFlagStore] = useState(
    getAddAudienceFlagStore(audiencesData)
  )

  useEffect(() => {
    dispatch(getAudienceData(productId))
  }, [dispatch, productId])

  useEffect(() => {
    if (audienceDataFromApi) {
      setAudiencesData(audienceDataFromApi)
      setAddAudienceFlagStore(getAddAudienceFlagStore(audienceDataFromApi))
    }
  }, [dispatch, audienceDataFromApi])

  const [bomboraAudienceData, setBomboraAudienceData] = useState(
    JSON.parse(JSON.stringify(bomboraDataFromApi))
  )

  const [zoomInfoAudienceData, setZoomInfoAudienceData] = useState(
    JSON.parse(JSON.stringify(zoomInfoDataFromApi))
  )

  const [audienceSelectedList, setAudienceSelectedList] = useState([])

  const toggleHeightHandler = addAudienceFlag => {
    setExpandedView(expandedView => !expandedView)
  }

  useEffect(() => {
    setExpandedView(expandAudience)
  }, [expandAudience])

  useEffect(() => {
    if (addAudienceFlag) setExpandedView(true)
  }, [addAudienceFlag])

  // function handles the click on AddAudienceForm
  // after a click this function handles what all
  // audiences are clickable next
  const addNewAudienceFormHandler = audienceId => {
    const prevAddAudienceFlagStore = {
      ...JSON.parse(JSON.stringify(addAudienceFlagStore))
    }
    prevAddAudienceFlagStore[audienceId].isSelected = prevAddAudienceFlagStore[
      audienceId
    ]
      ? true
      : false
    prevAddAudienceFlagStore[audienceId].isClickable = prevAddAudienceFlagStore[
      audienceId
    ]
      ? false
      : true
    // setting which all audiences are clickable next to create an audiences
    // which span over more than one segments
    Object.keys(prevAddAudienceFlagStore).forEach(flagStoreId => {
      if (
        prevAddAudienceFlagStore[flagStoreId].status &&
        ((prevAddAudienceFlagStore[flagStoreId].prev &&
          prevAddAudienceFlagStore[prevAddAudienceFlagStore[flagStoreId].prev]
            .isSelected) ||
          (prevAddAudienceFlagStore[flagStoreId].next &&
            prevAddAudienceFlagStore[prevAddAudienceFlagStore[flagStoreId].next]
              .isSelected)) &&
        !prevAddAudienceFlagStore[flagStoreId].isSelected
      ) {
        prevAddAudienceFlagStore[flagStoreId].isClickable = true
      } else {
        prevAddAudienceFlagStore[flagStoreId].isClickable = false
      }
    })
    // stores the all the selectedAudiences
    // it will help for createdFromSegments field
    const selectedAudienceList = []
    Object.keys(prevAddAudienceFlagStore).forEach(flagStoreId => {
      if (prevAddAudienceFlagStore[flagStoreId].isSelected)
        selectedAudienceList.push(flagStoreId)
    })
    setAudienceSelectedList(selectedAudienceList)
    setAddAudienceFlagStore(prevAddAudienceFlagStore)
  }

  const minimizeAudienceHandler = actionEnd => {
    const selectedAudienceList = [
      ...JSON.parse(JSON.stringify(audienceSelectedList))
    ]
    const prevAddAudienceFlagStore = {
      ...JSON.parse(JSON.stringify(addAudienceFlagStore))
    }
    let removedId = null
    if (actionEnd === 'left') {
      removedId = selectedAudienceList.shift()
    }
    if (actionEnd === 'right') {
      removedId = selectedAudienceList.pop()
    }
    if (removedId) {
      prevAddAudienceFlagStore[removedId].isSelected = false
      prevAddAudienceFlagStore[removedId].isClickable = true
    }
    setAudienceSelectedList(selectedAudienceList)
    setAddAudienceFlagStore(prevAddAudienceFlagStore)
  }

  // function handles the creation of new audiences
  const addNewAudienceHandler = (audienceId, data, productId) => {
    const prevAudiences = JSON.parse(JSON.stringify(audiencesData))
    let elementIndex = null
    prevAudiences[audienceId] &&
      prevAudiences[audienceId].every((audience, index) => {
        if (audience === null) {
          const result = data.createdFromSegments.filter(
            item => prevAudiences[item][index] === null
          )
          if (result.length === data.createdFromSegments.length) {
            elementIndex = index
            return false
          }
          return true
        } else {
          return true
        }
      })
    data.createdFromSegments.forEach(id => {
      if (elementIndex === null) {
        prevAudiences[id].push(data)
      } else {
        prevAudiences[id][elementIndex] = data
      }
    })

    Object.keys(prevAudiences).forEach(prevAudienceId => {
      if (
        prevAudiences[prevAudienceId] &&
        !data.createdFromSegments.includes(prevAudienceId) &&
        !(elementIndex !== null)
      )
        prevAudiences[prevAudienceId].push(null)
    })
    setAudiencesData(prevAudiences)
    addAudienceFormHandler()
    setAudienceSelectedList([])
    setAddAudienceFlagStore(getAddAudienceFlagStore(audiencesData))
    dispatch(updateAudiences(data, productId))
  }

  const deleteNewAudienceHandler = () => {
    setAudienceSelectedList([])
    addAudienceFormHandler()
    setAddAudienceFlagStore(getAddAudienceFlagStore(audiencesData))
  }

  const createAudienceButtonHandler = (selectedSource, audienceName) => {
    if (selectedSource !== 'salesforce') {
      addAudienceFormHandler()
      setAudienceSelectedList([])
      setAddAudienceFlagStore(getAddAudienceFlagStore(audiencesData))
    }
    if (selectedSource === 'bombora') {
      const newBomboraAudience = JSON.parse(JSON.stringify(bomboraMockData))
      newBomboraAudience.audienceName = audienceName
      setBomboraAudienceData({
        ...JSON.parse(JSON.stringify(bomboraAudienceData)),
        [uuidv4()]: newBomboraAudience
      })
    }
    if (selectedSource === 'zoomInfo') {
      const newZoomInfoAudience = JSON.parse(JSON.stringify(mockedZoomInfoData))
      newZoomInfoAudience.audienceName = audienceName
      setZoomInfoAudienceData(prevZoomInfoAudiences => {
        return { ...prevZoomInfoAudiences, [uuidv4()]: newZoomInfoAudience }
      })
    }
  }

  const deleteAudienceHandler = (audienceId, id) => {
    const prevAudiences = JSON.parse(JSON.stringify(audiencesData))
    const index = prevAudiences[audienceId].findIndex(
      ({ id: dataId }) => dataId === id
    )
    const createdFromSegments =
      prevAudiences[audienceId][index].createdFromSegments
    let data = []
    createdFromSegments.forEach(item => {
      data.push(prevAudiences[item][index])
      prevAudiences[item][index] = null
    })
    let allNullElementsInRow = true
    Object.keys(prevAudiences).forEach(audience => {
      if (
        !(prevAudiences[audience] && prevAudiences[audience][index] === null)
      ) {
        allNullElementsInRow = false
      }
    })
    if (allNullElementsInRow) {
      Object.keys(prevAudiences).forEach(audience => {
        prevAudiences[audience].splice(index, 1)
      })
    }
    setAudiencesData(prevAudiences)
    dispatch(deleteAudience(data, productId))
  }

  const editAudienceHandler = (audienceId, id) => {
    const prevAudiences = JSON.parse(JSON.stringify(audiencesData))
    const index = prevAudiences[audienceId].findIndex(
      ({ id: dataId }) => dataId === id
    )
    prevAudiences[audienceId][index].isEditable = true
    setAudiencesData(prevAudiences)
  }

  const updateAudienceHandler = (audienceId, id, data) => {
    const prevAudiences = JSON.parse(JSON.stringify(audiencesData))
    const index = prevAudiences[audienceId].findIndex(
      ({ id: dataId }) => dataId === id
    )
    prevAudiences[audienceId][index] = data
    setAudiencesData(prevAudiences)
    dispatch(updateAudiences(data, productId))
  }

  const saveBomboraAudience = (bomboraAudienceId, optionsData) => {
    const prevAudiences = JSON.parse(JSON.stringify(bomboraAudienceData))
    prevAudiences[bomboraAudienceId].options = { ...optionsData }
    prevAudiences[bomboraAudienceId].mockedAudience = false
    setBomboraAudienceData(prevAudiences)
  }

  const deleteBomboraAudience = bomboraAudienceId => {
    const prevAudiences = JSON.parse(JSON.stringify(bomboraAudienceData))
    delete prevAudiences[bomboraAudienceId]
    setBomboraAudienceData(prevAudiences)
  }

  const saveZoomInfoAudience = (zoomInfoAudienceId, data) => {
    const prevAudiences = JSON.parse(JSON.stringify(zoomInfoAudienceData))
    prevAudiences[zoomInfoAudienceId] = data
    prevAudiences[zoomInfoAudienceId].mockedAudience = false
    setZoomInfoAudienceData(prevAudiences)
  }

  const deleteZoomInfoAudience = zoomInfoAudienceId => {
    const prevAudiences = JSON.parse(JSON.stringify(zoomInfoAudienceData))
    delete prevAudiences[zoomInfoAudienceId]
    setZoomInfoAudienceData(prevAudiences)
  }

  const handleAudienceExtensionMinimize = (
    type,
    side,
    audienceId,
    index,
    segmentLinkedMapper
  ) => {
    const prevAudiences = JSON.parse(JSON.stringify(audiencesData))
    if (type === 'extension') {
      if (side === 'right') {
        const audienceCopy = prevAudiences[audienceId][index]
        audienceCopy.createdFromSegments.push(
          segmentLinkedMapper[audienceId].next
        )
        audienceCopy.createdFromSegments.forEach(
          audienceId => (prevAudiences[audienceId][index] = audienceCopy)
        )
        setAudiencesData(prevAudiences)
        dispatch(updateAudiences(audienceCopy, productId))
      }
      if (side === 'left') {
        const audienceCopy = prevAudiences[audienceId][index]
        audienceCopy.createdFromSegments.unshift(
          segmentLinkedMapper[audienceId].prev
        )
        audienceCopy.createdFromSegments.forEach(
          audienceId => (prevAudiences[audienceId][index] = audienceCopy)
        )
        setAudiencesData(prevAudiences)
        dispatch(updateAudiences(audienceCopy, productId))
      }
    }
    if (type === 'minimize') {
      if (side === 'right') {
        const audienceCopy = prevAudiences[audienceId][index]
        const removedAudience = audienceCopy.createdFromSegments.pop()
        prevAudiences[removedAudience][index] = null
        audienceCopy.createdFromSegments.forEach(
          audienceId => (prevAudiences[audienceId][index] = audienceCopy)
        )
        setAudiencesData(prevAudiences)
        dispatch(updateAudiences(audienceCopy, productId))
      }
      if (side === 'left') {
        const audienceCopy = prevAudiences[audienceId][index]
        const removedAudience = audienceCopy.createdFromSegments.shift()
        prevAudiences[removedAudience][index] = null
        audienceCopy.createdFromSegments.forEach(
          audienceId => (prevAudiences[audienceId][index] = audienceCopy)
        )
        setAudiencesData(prevAudiences)
        dispatch(updateAudiences(audienceCopy, productId))
      }
    }
  }

  return (
    <div css={container}>
      <SideDrawer expandedView={expandedView} />
      <div css={content}>
        <Header
          expandedView={expandedView}
          toggleHeightHandler={toggleHeightHandler}
        />

        <div css={contentCard(expandedView)}>
          {/* This div renders the new Audience forms and it's creation process */}
          <div style={{ display: 'flex' }}>
            {addAudienceFlag &&
              Object.keys(addAudienceFlagStore).map((flagStoreId, index) => {
                if (audienceSelectedList[0] === flagStoreId) {
                  return (
                    <NewAudienceForm
                      key={flagStoreId}
                      productId={productId}
                      audienceId={flagStoreId}
                      audienceSelectedList={audienceSelectedList}
                      addAudienceFlagStore={addAudienceFlagStore}
                      addNewAudienceFormHandler={addNewAudienceFormHandler}
                      addNewAudienceHandler={addNewAudienceHandler}
                      minimizeAudienceHandler={minimizeAudienceHandler}
                      deleteNewAudienceHandler={deleteNewAudienceHandler}
                      createAudienceButtonHandler={createAudienceButtonHandler}
                    />
                  )
                } else if (
                  audienceSelectedList.length === 0 &&
                  addAudienceFlagStore[flagStoreId].isClickable
                ) {
                  return (
                    <AddAudienceForm
                      key={flagStoreId}
                      index={index}
                      audienceId={flagStoreId}
                      addNewAudienceFormHandler={addNewAudienceFormHandler}
                    />
                  )
                } else if (audienceSelectedList.includes(flagStoreId)) {
                  return null
                } else {
                  return (
                    <div style={{ flex: 1, flexShrink: 0 }}>
                      <div css={dummyNewAudienceForm} key={flagStoreId}></div>
                    </div>
                  )
                }
              })}
          </div>
          {/* This div renders the segment audiences only */}
          <div style={{ display: 'flex' }}>
            {Object.keys(segmentAudienceData)
              .sort()
              .map((segmentAudience, index) => {
                const data = segmentAudienceData[segmentAudience]
                if (data.status === 'inactive') {
                  return <div css={dummyAudienceCard} key={data.id}></div>
                }

                return (
                  <AudienceCard
                    key={segmentAudienceData[segmentAudience].id}
                    productId={productId}
                    audienceId={segmentAudience}
                    formValue={segmentAudienceData[segmentAudience]}
                  />
                )
              })}
          </div>
          {/* This part renders the already created audiences */}
          {getMaxAudienceListLength(audiencesData).map(id => {
            return (
              <div key={id} style={{ display: 'flex' }}>
                {Object.keys(audiencesData).map((audience, index) => {
                  let createFromSegmentClone = []
                  if (audiencesData[audience] && audiencesData[audience][id]) {
                    createFromSegmentClone = [
                      ...audiencesData[audience][id].createdFromSegments
                    ]
                  }
                  if (
                    audiencesData[audience] &&
                    audiencesData[audience][id] &&
                    createFromSegmentClone.sort((a, b) => a - b)[0] === audience
                  ) {
                    return (
                      <AudienceCard
                        key={audiencesData[audience][id].id}
                        productId={productId}
                        audienceId={audience}
                        index={id}
                        formValue={audiencesData[audience][id]}
                        audiencesData={audiencesData}
                        segmentLinkedMapper={getSegmentLinkedMapper(
                          segmentAudienceData
                        )}
                        handleAudienceExtensionMinimize={
                          handleAudienceExtensionMinimize
                        }
                        deleteAudienceHandler={deleteAudienceHandler}
                        editAudienceHandler={editAudienceHandler}
                        updateAudienceHandler={updateAudienceHandler}
                      />
                    )
                  } else if (
                    audiencesData[audience] === null ||
                    (audiencesData[audience] && !audiencesData[audience][id])
                  ) {
                    return <div css={dummyAudienceCard} key={uuidv4()}></div>
                  } else {
                    return null
                  }
                })}
              </div>
            )
          })}
          <div>
            {Object.keys(bomboraAudienceData).map(bomboraAudience => {
              return (
                <div key={bomboraAudience}>
                  {bomboraAudienceData[bomboraAudience].mockedAudience ? (
                    <NewBomboraForm
                      bomboraAudienceId={bomboraAudience}
                      bomboraData={bomboraAudienceData[bomboraAudience]}
                      saveBomboraAudience={saveBomboraAudience}
                      deleteBomboraAudience={deleteBomboraAudience}
                    />
                  ) : (
                    <BomboraAudienceCard
                      bomboraAudienceId={bomboraAudience}
                      bomboraOptions={bomboraAudienceData[bomboraAudience]}
                      deleteBomboraAudience={deleteBomboraAudience}
                      saveBomboraAudience={saveBomboraAudience}
                    />
                  )}
                </div>
              )
            })}
            {Object.keys(zoomInfoAudienceData).map(zoomInfoAudience => {
              return (
                <div key={zoomInfoAudience}>
                  {zoomInfoAudienceData[zoomInfoAudience].mockedAudience ? (
                    <NewZoomInfoForm
                      zoomInfoAudienceId={zoomInfoAudience}
                      zoomInfoAudienceData={
                        zoomInfoAudienceData[zoomInfoAudience]
                      }
                      deleteZoomInfoAudience={deleteZoomInfoAudience}
                      saveZoomInfoAudience={saveZoomInfoAudience}
                    />
                  ) : (
                    <ZoomInfoAudienceCard
                      zoomInfoAudienceId={zoomInfoAudience}
                      zoomInfoAudienceData={
                        zoomInfoAudienceData[zoomInfoAudience]
                      }
                      deleteZoomInfoAudience={deleteZoomInfoAudience}
                      saveZoomInfoAudience={saveZoomInfoAudience}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Audience
