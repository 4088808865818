/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import Portal from '../../../../../../../customHooks/portal'
import { updatebtnStyle } from '../../../../../../../store/dashboard/mailerSlice'
import SelectField from '../../../../../../ui/SelectFormField'
import {
  btnStylesField,
  btnTextStyle,
  panelTitleStyle,
  backBtnStyle,
  cardHolderStyle,
  noticeStyle,
  labelStyle,
  shapeToggleGrpContainer,
  decorationContainer,
  colorInputStyle,
  colorFieldStyle,
  colorBoxStyle,
  paddingText,
  paddingsContainer
} from './style'

const ButtonSettings = ({ toggleButtonSettings }) => {
  const [btnDetails, showBtnDetails] = useState(null)
  const dispatch = useDispatch()
  const { btnStyles } = useSelector(({ mailer: { btnStyles } }) => ({
    btnStyles
  }))

  const [colorType, setColorType] = useState(null)
  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })
  const colorPickerRef = useRef()
  const colorRef = useRef()
  const borderColorRef = useRef()
  const backgroundColorRef = useRef()

  useEffect(() => {
    const clickedOutside = event => {
      if (
        colorType &&
        colorPickerRef.current &&
        !colorRef.current.contains(event.target) &&
        !borderColorRef.current.contains(event.target) &&
        !backgroundColorRef.current.contains(event.target) &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setColorType(null)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [colorPickerRef, colorRef, colorType, backgroundColorRef, borderColorRef])

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  const handleChange = (key, value) => {
    const prevBtnStyles = { ...btnStyles[btnDetails.btnType] }
    const updateBtnStyles = {
      ...prevBtnStyles,
      [key]: value
    }
    const updatedBtns = {
      ...btnStyles,
      [btnDetails.btnType]: updateBtnStyles
    }
    dispatch(updatebtnStyle(updatedBtns))
  }

  return (
    <>
      {!btnDetails && (
        <>
          <div css={panelTitleStyle}>
            <button css={backBtnStyle} onClick={toggleButtonSettings}>
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  alignItems: 'center',
                  display: 'inline-flex',
                  justifyContent: 'center'
                }}
              >
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '16px', height: '16px' }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
                  ></path>
                </svg>
              </div>
            </button>
            Buttons
          </div>
          <div
            css={btnStylesField}
            onClick={() => showBtnDetails({ btnType: 'Primary button' })}
          >
            <div
              style={{
                ...btnStyles['Primary button'],
                height: '34px',
                fontSize: '13px',
                lineHeight: '22px',
                textAlign: 'center',
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 6,
                paddingRight: 6
              }}
            >
              Button
            </div>
            <div css={btnTextStyle}>Primary Buttons</div>
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: '16px',
                height: '16px',
                transform: 'rotate(-90deg)',
                marginLeft: 'auto',
                fill: 'rgb(78 175 226)'
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
          <div
            css={btnStylesField}
            onClick={() => showBtnDetails({ btnType: 'Secondary button' })}
          >
            <div
              style={{
                ...btnStyles['Secondary button'],
                height: '34px',
                fontSize: '13px',
                lineHeight: '22px',
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 6,
                paddingRight: 6,
                textAlign: 'center'
              }}
            >
              Button
            </div>
            <div css={btnTextStyle}>Secondary Buttons</div>
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: '16px',
                height: '16px',
                transform: 'rotate(-90deg)',
                marginLeft: 'auto',
                fill: 'rgb(78 175 226)'
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
          <div
            css={btnStylesField}
            onClick={() => showBtnDetails({ btnType: 'Tertiary button' })}
          >
            <div
              style={{
                ...btnStyles['Tertiary button'],
                height: '34px',
                fontSize: '13px',
                lineHeight: '22px',
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 6,
                paddingRight: 6,
                textAlign: 'center'
              }}
            >
              Button
            </div>
            <div css={btnTextStyle}>Tertiary Buttons</div>
            <svg
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: '16px',
                height: '16px',
                transform: 'rotate(-90deg)',
                marginLeft: 'auto',
                fill: 'rgb(78 175 226)'
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
              ></path>
            </svg>
          </div>
        </>
      )}
      {btnDetails && (
        <>
          <div css={panelTitleStyle}>
            <button css={backBtnStyle} onClick={() => showBtnDetails(null)}>
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  alignItems: 'center',
                  display: 'inline-flex',
                  justifyContent: 'center'
                }}
              >
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '16px', height: '16px' }}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.29285 6.70711L4.70706 5.29289L7.99995 8.58579L11.2928 5.29289L12.7071 6.70711L7.99995 11.4142L3.29285 6.70711Z'
                  ></path>
                </svg>
              </div>
            </button>
            {btnDetails && btnDetails.btnType}
          </div>
          <div css={cardHolderStyle}>
            <div
              style={{
                ...btnStyles[btnDetails && btnDetails.btnType],
                fontSize: '12px',
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 12,
                paddingRight: 12
              }}
            >
              Button
            </div>
          </div>
          <p css={noticeStyle}>
            Changes made to this button will be reflected throughout the whole
            email
          </p>
          <div
            style={{
              display: 'flex',
              paddingBottom: '24px',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ flex: 2 }}>
              <label css={labelStyle}>Font</label>
              <SelectField
                name='font'
                onChange={(item, name) =>
                  handleChange('fontFamily', item.value)
                }
                value={btnStyles[btnDetails.btnType]['fontFamily']}
                title='Font'
                width='90%'
                list={[
                  { label: 'Arial', value: 'Arial' },
                  { label: 'Helvetica', value: 'Helvetica' },
                  { label: 'Courier New', value: 'Courier New' },
                  { label: 'Georgia', value: 'Georgia' },
                  { label: 'Lucida', value: 'Lucida' }
                ]}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label css={labelStyle}>Size</label>
              <SelectField
                name='size'
                onChange={(item, name) => handleChange('fontSize', item.value)}
                value={btnStyles[btnDetails.btnType]['fontSize']}
                title='Font Size'
                width='100%'
                list={[
                  { label: '8px', value: '8px' },
                  { label: '9px', value: '9px' },
                  { label: '10px', value: '10px' },
                  { label: '11px', value: '11px' },
                  { label: '12px', value: '12px' },
                  { label: '14px', value: '14px' },
                  { label: '16px', value: '16px' },
                  { label: '18px', value: '18px' },
                  { label: '20px', value: '20px' },
                  { label: '22px', value: '22px' },
                  { label: '24px', value: '24px' },
                  { label: '26px', value: '26px' },
                  { label: '28px', value: '28px' },
                  { label: '30px', value: '30px' },
                  { label: '36px', value: '36px' },
                  { label: '48px', value: '48px' },
                  { label: '60px', value: '60px' },
                  { label: '72px', value: '72px' },
                  { label: '90px', value: '90px' }
                ]}
              />
            </div>
          </div>
          <div>
            <label css={labelStyle}>Shape</label>
            <div css={shapeToggleGrpContainer}>
              <div className='toggle-grp-content'>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderRadius'
                  ] === '0px' && 'active'}`}
                  onClick={() => handleChange('borderRadius', '0px')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='21'
                      height='14'
                      viewBox='0 0 21 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <rect
                        x='1.33334'
                        y='1'
                        width='18'
                        height='12'
                        stroke='#555C66'
                        strokeWidth='2'
                      ></rect>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderRadius'
                  ] == '8px' && 'active'}`}
                  onClick={() => handleChange('borderRadius', '8px')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='20'
                      height='14'
                      viewBox='0 0 20 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <rect
                        x='1.00003'
                        y='1'
                        width='18'
                        height='12'
                        rx='3'
                        stroke='#555C66'
                        strokeWidth='2'
                      ></rect>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderRadius'
                  ] == '24px' && 'active'}`}
                  onClick={() => handleChange('borderRadius', '24px')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='21'
                      height='14'
                      viewBox='0 0 21 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <rect
                        x='1.66669'
                        y='1'
                        width='18'
                        height='12'
                        rx='6'
                        stroke='#555C66'
                        strokeWidth='2'
                      ></rect>
                    </svg>
                  </div>
                </button>
              </div>
              <div className='toggle-grp-input'>
                <div className='text-field'>
                  <input
                    autoComplete='no'
                    className='textInput'
                    value={btnStyles[btnDetails.btnType]['borderRadius'].slice(
                      0,
                      -2
                    )}
                    onChange={({ target: { value } }) =>
                      handleChange('borderRadius', value + 'px')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label css={labelStyle}>Decoration</label>
            <div css={decorationContainer}>
              <div className='toggle-grp-content'>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'fontWeight'
                  ] == 'bold' && 'active'}`}
                  onClick={() =>
                    handleChange(
                      'fontWeight',
                      btnStyles[btnDetails.btnType]['fontWeight'] !== 'normal'
                        ? 'normal'
                        : 'bold'
                    )
                  }
                >
                  <div className='iconContainer'>
                    <svg
                      width='25'
                      height='24'
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <path
                        d='M7.36499 18.8003V5.19971H12.4675C13.5066 5.19971 14.3385 5.33887 14.9631 5.61719C15.594 5.88932 16.0857 6.31299 16.4382 6.88818C16.797 7.45719 16.9763 8.05404 16.9763 8.67871C16.9763 9.26009 16.8186 9.80745 16.5032 10.3208C16.1877 10.8341 15.7115 11.2485 15.0745 11.564C15.8971 11.8052 16.5279 12.2165 16.967 12.7979C17.4124 13.3792 17.635 14.0658 17.635 14.8574C17.635 15.4945 17.4989 16.0882 17.2268 16.6387C16.9609 17.1829 16.63 17.6035 16.2341 17.9004C15.8383 18.1973 15.3404 18.423 14.7405 18.5776C14.1467 18.7261 13.4169 18.8003 12.551 18.8003H7.36499ZM9.16479 10.9146H12.1057C12.9036 10.9146 13.4757 10.862 13.822 10.7568C14.2797 10.6208 14.623 10.395 14.8518 10.0796C15.0868 9.76416 15.2043 9.36833 15.2043 8.89209C15.2043 8.44059 15.0961 8.04476 14.8796 7.70459C14.6632 7.35824 14.3539 7.12321 13.9519 6.99951C13.5499 6.86963 12.8603 6.80469 11.8831 6.80469H9.16479V10.9146ZM9.16479 17.1953H12.551C13.1324 17.1953 13.5406 17.1737 13.7756 17.1304C14.19 17.0562 14.5364 16.9325 14.8147 16.7593C15.093 16.5861 15.3219 16.3356 15.5012 16.0078C15.6806 15.6738 15.7703 15.2904 15.7703 14.8574C15.7703 14.3503 15.6404 13.9111 15.3806 13.54C15.1208 13.1628 14.759 12.8999 14.2952 12.7515C13.8375 12.5968 13.1757 12.5195 12.3098 12.5195H9.16479V17.1953Z'
                        fill='#555C66'
                      ></path>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'fontStyle'
                  ] == 'italic' && 'active'}`}
                  onClick={() =>
                    handleChange(
                      'fontStyle',
                      btnStyles[btnDetails.btnType]['fontStyle'] != 'normal'
                        ? 'normal'
                        : 'italic'
                    )
                  }
                >
                  <div className='iconContainer'>
                    <svg
                      width='25'
                      height='24'
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M13.75 7C14.3023 7 14.75 6.55228 14.75 6C14.75 5.44772 14.3023 5 13.75 5C13.1977 5 12.75 5.44772 12.75 6C12.75 6.55228 13.1977 7 13.75 7ZM12.3096 9.14746L10.25 19H11.9292L13.9888 9.14746H12.3096Z'
                        fill='#555C66'
                      ></path>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'textDecoration'
                  ] == 'underline' && 'active'}`}
                  onClick={() =>
                    handleChange(
                      'textDecoration',
                      btnStyles[btnDetails.btnType]['textDecoration'] != 'unset'
                        ? 'unset'
                        : 'underline'
                    )
                  }
                >
                  <div className='iconContainer'>
                    <svg
                      width='25'
                      height='24'
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.5 4V12C6.5 15.3137 9.18629 18 12.5 18C15.8137 18 18.5 15.3137 18.5 12V4H16.5V12C16.5 14.2091 14.7091 16 12.5 16C10.2909 16 8.5 14.2091 8.5 12V4H6.5ZM5.5 19V21H19.5V19H5.5Z'
                        fill='#555C66'
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div>
            <label css={labelStyle}>Text color</label>
            <div css={colorFieldStyle} ref={colorRef}>
              <input
                css={colorInputStyle}
                type='text'
                value={btnStyles[btnDetails.btnType]['color']}
                onChange={({ target: { value } }) => {
                  handleChange('color', value)
                }}
              />
              <span
                css={colorBoxStyle}
                style={{
                  backgroundColor: btnStyles[btnDetails.btnType]['color']
                }}
                onClick={() => {
                  setColorType('color')
                  updateColorCoordinate(colorRef.current)
                }}
              />
            </div>
          </div>
          <div>
            <label css={labelStyle}>Background color</label>
            <div css={colorFieldStyle} ref={backgroundColorRef}>
              <input
                css={colorInputStyle}
                type='text'
                value={btnStyles[btnDetails.btnType]['backgroundColor']}
                onChange={({ target: { value } }) => {
                  handleChange('backgroundColor', value)
                }}
              />
              <span
                css={colorBoxStyle}
                style={{
                  backgroundColor:
                    btnStyles[btnDetails.btnType]['backgroundColor']
                }}
                onClick={() => {
                  setColorType('backgroundColor')
                  updateColorCoordinate(backgroundColorRef.current)
                }}
              />
            </div>
          </div>
          <div>
            <label css={labelStyle}>Border Style</label>
            <div css={decorationContainer}>
              <div className='toggle-grp-content'>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderStyle'
                  ] === 'solid' && 'active'}`}
                  onClick={() => handleChange('borderStyle', 'solid')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='24'
                      height='2'
                      viewBox='0 0 24 2'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <path d='M0 1H24' stroke='#555C66' strokeWidth='2'></path>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderStyle'
                  ] === 'dashed' && 'active'}`}
                  onClick={() => handleChange('borderStyle', 'dashed')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='22'
                      height='2'
                      viewBox='0 0 22 2'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <rect width='4' height='2' fill='#555C66'></rect>
                      <rect x='6' width='4' height='2' fill='#555C66'></rect>
                      <rect x='12' width='4' height='2' fill='#555C66'></rect>
                      <rect x='18' width='4' height='2' fill='#555C66'></rect>
                    </svg>
                  </div>
                </button>
                <button
                  className={`toggle-btn ${btnStyles[btnDetails.btnType][
                    'borderStyle'
                  ] === 'dotted' && 'active'}`}
                  onClick={() => handleChange('borderStyle', 'dotted')}
                >
                  <div className='iconContainer'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ width: '24px', height: '24px' }}
                    >
                      <circle cx='2' cy='12' r='1' fill='#555C66'></circle>
                      <circle cx='6' cy='12' r='1' fill='#555C66'></circle>
                      <circle cx='10' cy='12' r='1' fill='#555C66'></circle>
                      <circle cx='14' cy='12' r='1' fill='#555C66'></circle>
                      <circle cx='18' cy='12' r='1' fill='#555C66'></circle>
                      <circle cx='22' cy='12' r='1' fill='#555C66'></circle>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div>
            <label css={labelStyle}>Border color</label>
            <div css={colorFieldStyle} ref={borderColorRef}>
              <input
                css={colorInputStyle}
                type='text'
                value={btnStyles[btnDetails.btnType]['borderColor']}
                onChange={({ target: { value } }) => {
                  handleChange('borderColor', value)
                }}
              />
              <span
                css={colorBoxStyle}
                style={{
                  backgroundColor: btnStyles[btnDetails.btnType]['borderColor']
                }}
                onClick={() => {
                  setColorType('borderColor')
                  updateColorCoordinate(borderColorRef.current)
                }}
              />
            </div>
          </div>
          <div style={{ borderTop: '1px solid #e7e7e7', paddingTop: '24px' }}>
            <label css={labelStyle}>Padding</label>
            <span css={paddingText}>
              {' '}
              Use padding to adjust the size of the button.
            </span>
            <div css={paddingsContainer}>
              <div className='padding' style={{ gridColumn: '3/4' }}>
                <div className='padding-input'>
                  <input
                    value={btnStyles[btnDetails.btnType]['paddingTop'].slice(
                      0,
                      -2
                    )}
                    onChange={({ target: { value } }) =>
                      handleChange('paddingTop', value + 'px')
                    }
                  />
                </div>
              </div>
              <div className='padding' style={{ gridColumn: '1/3' }}>
                <div className='padding-input'>
                  <input
                    value={btnStyles[btnDetails.btnType]['paddingLeft'].slice(
                      0,
                      -2
                    )}
                    onChange={({ target: { value } }) =>
                      handleChange('paddingLeft', value + 'px')
                    }
                  />
                </div>
              </div>
              <div className='padding-icon'>
                <svg
                  width='106'
                  height='84'
                  viewBox='0 0 106 84'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '100px', height: '100px' }}
                >
                  <rect width='106' height='84' rx='4' fill='#F5F5F5'></rect>
                  <rect
                    x='20'
                    y='31'
                    width='66'
                    height='2'
                    fill='#C4C4C4'
                  ></rect>
                  <rect
                    x='20'
                    y='38'
                    width='56'
                    height='2'
                    fill='#C4C4C4'
                  ></rect>
                  <rect
                    x='20'
                    y='44'
                    width='61'
                    height='2'
                    fill='#C4C4C4'
                  ></rect>
                  <rect
                    x='20'
                    y='51'
                    width='47'
                    height='2'
                    fill='#C4C4C4'
                  ></rect>
                </svg>
              </div>
              <div className='padding' style={{ gridColumn: '4/4' }}>
                <div className='padding-input'>
                  <input
                    value={btnStyles[btnDetails.btnType]['paddingRight'].slice(
                      0,
                      -2
                    )}
                    onChange={({ target: { value } }) =>
                      handleChange('paddingRight', value + 'px')
                    }
                  />
                </div>
              </div>
              <div className='padding' style={{ gridColumn: '3/4' }}>
                <div className='padding-input'>
                  <input
                    value={btnStyles[btnDetails.btnType]['paddingBottom'].slice(
                      0,
                      -2
                    )}
                    onChange={({ target: { value } }) =>
                      handleChange('paddingBottom', value + 'px')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {colorType && (
        <Portal>
          <div
            style={{ ...colorCoords, position: 'absolute' }}
            ref={colorPickerRef}
          >
            <SketchPicker
              color={btnStyles[btnDetails.btnType][colorType]}
              onChangeComplete={({ hex }) => handleChange(colorType, hex)}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

export default ButtonSettings
