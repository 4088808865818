import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import useStyles from './styles'

import neyoIcon from '../../../../assets/images/NeyoIcon.png'

const Header = props => {
  const classes = useStyles()
  const { showButton = true } = props
  return (
    <React.Fragment>
      <AppBar color='secondary' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <span className={classes.title}>
            <img alt='neyo-icon' src={neyoIcon} />
          </span>
          {showButton ? (
            <>
              <Typography
                variant='body2'
                className={classes.actionDescriptionText}
              >
                {props.actionDescriptionText}
              </Typography>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                component={Link}
                to={`/${props.toPage}`}
              >
                {props.actionText}
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin}></div>
    </React.Fragment>
  )
}

export default Header
