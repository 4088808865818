/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'

import AudienceForm from '../Forms/AudienceForm/index'

import {
  audienceCard,
  audienceAccountData,
  header,
  headerCard,
  contentCard,
  extensionButton,
  minimizeFromLeft,
  minimizeFromRight,
  leftExtensionContainer,
  rightExtensionContainer,
  leftExtensionCard,
  rightExtensionCard
} from './style'

import salesforceIcon from '../../../../assets/images/dashboard/audience/salesforce.png'
import bomboraIcon from '../../../../assets/images/dashboard/audience/bombora.png'
import neyoColorIcon from '../../../../assets/images/neyoMarkColor.svg'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'

const AudienceCard = ({
  audienceId,
  index,
  formValue,
  audiencesData,
  segmentLinkedMapper,
  handleAudienceExtensionMinimize,
  deleteAudienceHandler,
  editAudienceHandler,
  updateAudienceHandler
}) => {
  const [fullViewFlag, setFullViewFLag] = useState(false)

  const [rightExtensionHoverFlag, setRightExtensionHoverFlag] = useState(false)
  const [rightMinimizeHoverFlag, setRightMinimizeHoverFlag] = useState(false)
  const [leftExtensionHoverFlag, setLeftExtensionHoverFlag] = useState(false)
  const [leftMinimizeHoverFlag, setLeftMinimizeHoverFlag] = useState(false)

  const [showExtensionMinimizeButtons, setShowExtensionMinimizeButtons] =
    useState(false)

  const formWidth = formValue.createdFromSegments.length || 1

  const audienceViewHandler = () => {
    setFullViewFLag(prev => !prev)
  }

  const getSourceIconBox = source => {
    let imageSource = neyoColorIcon
    if (source === 'salesforce') imageSource = salesforceIcon
    if (source === 'bombora') imageSource = bomboraIcon
    return <img className={source} alt={source} src={imageSource}></img>
  }

  const updateData = data => {
    const prevData = JSON.parse(JSON.stringify(formValue))
    const newData = { ...prevData, ...data }
    updateAudienceHandler(audienceId, formValue.id, newData)
  }

  const isSegmentAudience = () => {
    return formValue.source === 'neyo' ? true : false
  }

  const showLeftExtensionButton = showExtensionMinimizeButtons => {
    const leftMostSegmentId = formValue.createdFromSegments[0]
    if (
      showExtensionMinimizeButtons &&
      !isSegmentAudience() &&
      segmentLinkedMapper[leftMostSegmentId] &&
      segmentLinkedMapper[leftMostSegmentId].prev &&
      audiencesData[segmentLinkedMapper[leftMostSegmentId].prev] &&
      !audiencesData[segmentLinkedMapper[leftMostSegmentId].prev][index]
    )
      return true
    return false
  }

  const showRightExtensionButton = showExtensionMinimizeButtons => {
    const rightMostSegmentId =
      formValue.createdFromSegments[formValue.createdFromSegments.length - 1]
    if (
      showExtensionMinimizeButtons &&
      !isSegmentAudience() &&
      segmentLinkedMapper[rightMostSegmentId] &&
      segmentLinkedMapper[rightMostSegmentId].next &&
      audiencesData[segmentLinkedMapper[rightMostSegmentId].next] &&
      !audiencesData[segmentLinkedMapper[rightMostSegmentId].next][index]
    )
      return true
    return false
  }

  const isSingleRule = () => {
    let onlyOneRule = false
    ;(formValue.ruleGroups || []).forEach(({ rules }) => {
      rules.forEach(rule => {
        onlyOneRule = Object.keys(rule).some(
          key =>
            ['prospectTag', 'condition', 'value'].includes(key) && !rule[key]
        )
      })
    })
    return onlyOneRule
  }

  return (
    <div
      css={audienceCard(fullViewFlag, formWidth, formValue.isEditable)}
      onMouseEnter={() => {
        setShowExtensionMinimizeButtons(formValue.isEditable ? true : false)
      }}
      onMouseLeave={() => {
        setShowExtensionMinimizeButtons(false)
      }}
    >
      <div css={header(formValue.isEditable, fullViewFlag)}>
        {formWidth > 1 && (
          <div css={minimizeFromLeft(leftMinimizeHoverFlag)}>
            <Icon className='plusIcon'>remove</Icon>
          </div>
        )}
        <div css={headerCard(formValue.isEditable, formWidth, fullViewFlag)}>
          {!formValue.isEditable && (
            <span
              className='toggleAudienceHeight'
              onClick={audienceViewHandler}
            >
              <img alt='expandIcon' src={fullViewFlag ? minusIcon : plusIcon} />
            </span>
          )}
          {showExtensionMinimizeButtons &&
            formWidth > 1 &&
            !isSegmentAudience() && (
              <>
                <div
                  css={extensionButton(formWidth)}
                  className='leftMinimize'
                  onMouseEnter={() => {
                    setLeftMinimizeHoverFlag(true)
                  }}
                  onMouseLeave={() => {
                    setLeftMinimizeHoverFlag(false)
                  }}
                  onClick={() => {
                    handleAudienceExtensionMinimize(
                      'minimize',
                      'left',
                      audienceId,
                      index,
                      segmentLinkedMapper
                    )
                    setLeftMinimizeHoverFlag(false)
                    setLeftExtensionHoverFlag(false)
                    setRightExtensionHoverFlag(false)
                    setRightMinimizeHoverFlag(false)
                    setShowExtensionMinimizeButtons(false)
                  }}
                >
                  <label>{'>'}</label>
                </div>
                <div
                  css={extensionButton(formWidth)}
                  className='rightMinimize'
                  onMouseEnter={() => {
                    setRightMinimizeHoverFlag(true)
                  }}
                  onMouseLeave={() => {
                    setRightMinimizeHoverFlag(false)
                  }}
                  onClick={() => {
                    setLeftMinimizeHoverFlag(false)
                    setLeftExtensionHoverFlag(false)
                    setRightExtensionHoverFlag(false)
                    setRightMinimizeHoverFlag(false)
                    setShowExtensionMinimizeButtons(false)
                    handleAudienceExtensionMinimize(
                      'minimize',
                      'right',
                      audienceId,
                      index,
                      segmentLinkedMapper
                    )
                  }}
                >
                  <label>{'<'}</label>
                </div>
              </>
            )}
          {showLeftExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(formWidth)}
              className='leftExtension'
              onMouseEnter={() => {
                setLeftExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setLeftExtensionHoverFlag(false)
              }}
              onClick={() => {
                handleAudienceExtensionMinimize(
                  'extension',
                  'left',
                  audienceId,
                  index,
                  segmentLinkedMapper
                )
                setLeftExtensionHoverFlag(false)
                setRightExtensionHoverFlag(false)
                setShowExtensionMinimizeButtons(false)
              }}
            >
              <label>{'<'}</label>
            </div>
          )}
          {showRightExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(formWidth)}
              className='rightExtension'
              onMouseEnter={() => {
                setRightExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setRightExtensionHoverFlag(false)
              }}
              onClick={() => {
                handleAudienceExtensionMinimize(
                  'extension',
                  'right',
                  audienceId,
                  index,
                  segmentLinkedMapper
                )
                setRightExtensionHoverFlag(false)
                setShowExtensionMinimizeButtons(false)
              }}
            >
              <label>{'>'}</label>
            </div>
          )}
          <div css={leftExtensionContainer(formWidth)}>
            <div css={leftExtensionCard(leftExtensionHoverFlag, formWidth)}>
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
          <div css={rightExtensionContainer(formWidth)}>
            <div css={rightExtensionCard(rightExtensionHoverFlag, formWidth)}>
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
          <span className='iconBox'>{getSourceIconBox(formValue.source)}</span>
          <div css={audienceAccountData}>
            <label>{formValue.audienceName || 'Neyo Audience'}</label>
            <hr></hr>
            <span>{`${formValue.matchedRecordsTotal || 0} Targets`}</span>
          </div>
        </div>
        {formWidth > 1 && (
          <div css={minimizeFromRight(rightMinimizeHoverFlag)}>
            <Icon className='plusIcon'>remove</Icon>
          </div>
        )}
      </div>
      <div
        css={contentCard(fullViewFlag, formValue.isEditable, isSingleRule())}
      >
        <AudienceForm
          data={formValue}
          id={formValue.id}
          audienceId={audienceId}
          updateData={updateData}
          updateAudienceHandler={updateAudienceHandler}
          deleteAudienceHandler={deleteAudienceHandler}
          editAudienceHandler={editAudienceHandler}
          setShowExtensionMinimizeButtons={setShowExtensionMinimizeButtons}
        />
      </div>
    </div>
  )
}

export default AudienceCard
