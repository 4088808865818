/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react'

import salesforceIcon from '../../../../../assets/images/dashboard/audience/salesforce.png'
import neyoColorIcon from '../../../../../assets/images/neyoMarkColor.svg'

import { container, audienceAccountData } from './style'

const getSourceIconBox = source => {
  let imageSource = neyoColorIcon
  if (source === 'salesforce') imageSource = salesforceIcon
  return <img className={source} alt={source} src={imageSource}></img>
}

const AudienceCard = ({ index, data, onSelection = () => {} }) => {
  return (
    <div
      css={container(data.isSelected)}
      onClick={() => onSelection(index)}
      className='audienceCard'
    >
      <span className='iconBox'>{getSourceIconBox(data.source)}</span>
      <div css={audienceAccountData}>
        <label className='audienceName'>
          {data.audienceName || 'Neyo Audience'}
        </label>
        <hr></hr>
        <span>{data.matchedRecordsTotal || '0'}</span>
      </div>
    </div>
  )
}

export default AudienceCard
