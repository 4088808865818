import { useEffect, useState } from 'react'
const observerElementsMap = new Map()

function parseMargin (margin) {
  const marginString = margin ? margin.trim() : '0px'
  const [m0 = '0px', m1 = m0, m2 = m0, m3 = m1] = marginString.split(/\s+/)
  return `${m0} ${m1} ${m2} ${m3}`
}

function compareThreshold (prev, next) {
  const threshold = Array.isArray(next) ? next : [next || 0]
  return (
    prev &&
    prev.length === threshold.length &&
    !threshold.some((_, i) => prev[i] !== threshold[i])
  )
}
function getInstance ({ root = null, rootMargin, threshold } = {}) {
  rootMargin = parseMargin(rootMargin)
  const observers = observerElementsMap.keys()
  for (let observer of observers) {
    const matched =
      root === observer.root &&
      rootMargin === observer.rootMargin &&
      compareThreshold(observer.thresholds, threshold)

    if (matched) {
      return observer
    }
  }
  return null
}

function findObserverElement (observer, { target }) {
  const targetMap = observerElementsMap.get(observer)
  if (targetMap && targetMap.has(target)) {
    return {
      target,
      handleChange: targetMap.get(target)
    }
  }
  return null
}

function callback (entries, observer) {
  for (let entry of entries) {
    const element = findObserverElement(observer, entry)
    if (element) {
      element.handleChange(entry)
    }
  }
}

export function createObserver (options) {
  let observer = getInstance(options)
  if (!observer) {
    observer = new window.IntersectionObserver(callback, options)
    observerElementsMap.set(observer, new WeakMap())
  }
  return observer
}

/**
 * register the target to be observed by observer
 * @param {IntersectionObserver} observer
 * @param {{target: HTMLElement, handleChange: (entry: IntersectionObserverEntry) => any}} element
 * @returns {() => void} a function to be called to unobserve
 */
export function observeElement (observer, element) {
  if (!observerElementsMap.has(observer)) {
    observerElementsMap.set(observer, new WeakMap())
  }
  observerElementsMap.get(observer).set(element.target, element.handleChange)
  observer.observe(element.target)
  return () => unobserveElement(observer, { target: element.target })
}

export function unobserveElement (observer, element) {
  if (observerElementsMap.has(observer)) {
    const targetMap = observerElementsMap.get(observer)
    if (targetMap.delete(element.target)) {
      observer.unobserve(element.target)
    }
  }
}

export function useObserver (options) {
  const { root, rootMargin, threshold = '' } = options
  const [observer, setObserver] = useState(null)

  useEffect(() => {
    typeof root !== 'undefined' && setObserver(createObserver(options))
  }, [root, rootMargin, threshold.toString()])
  return observer
}
