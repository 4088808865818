import React from 'react'
import { useDispatch } from 'react-redux'

import SignInContent from './SignInContent/SignInContent'
import LandingPageHeader from '../ui/Header/LandingPageHeader/Header'
import Footer from '../ui/LandingPageFooter/Footer'
import { loginHandler } from '../../store/authSlice'

import useStyles from './styles'

const SignIn = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const signInHandler = userInfo => {
    dispatch(loginHandler(userInfo))
  }

  return (
    <div className={classes.root}>
      <LandingPageHeader
        title='Neyo'
        toPage='signup'
        showButton={false}
        actionText='Sign Up'
        actionDescriptionText="Don't have an account?"
      />
      <SignInContent signInHandler={signInHandler} />
      <Footer contentText='© 2023 Neyo' />
    </div>
  )
}

export default SignIn
