import { makeStyles } from '@material-ui/core/styles'
// import { deepBlue } from '@material-ui/core/colors';

const drawerWidth = 115

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    width: `calc(100% - ${drawerWidth}px)`,
    boxShadow: 'none',
    padding: '0.6em',
    backgroundColor: 'inherit'
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    margin: '0.6em'
  },
  title: {
    fontWeight: '400'
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 'medium'
  },
  avatarText: {
    marginLeft: 'auto'
  }
}))

export default useStyles
