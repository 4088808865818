import { v4 as uuidv4 } from 'uuid'

const getActions = flag => {
  const actions = {
    1: {
      key: 'Ignore update',
      isSelected: false
    },
    2: {
      key: 'Replace primary account',
      isSelected: false
    },
    3: {
      key: 'Add new affliated account',
      isSelected: false
    },
    4: {
      key: 'Create new record',
      isSelected: false
    }
  }
  if (flag === 2) actions['3'].key = 'Add secondary email'
  return actions
}

const getAccountData = (title, matchPercentage, contact = {}) => {
  return {
    id: `${contact?.id}_${uuidv4()}`,
    title: title,
    account: contact?.account?.Name,
    contact: contact?.Name,
    email: contact?.Email,
    link: true,
    match: matchPercentage ? `${matchPercentage}%` : '',
    isHighlighted: false
  }
}

const getCdpQueueData = sourceData => {
  return {
    id: `${sourceData.id}_${uuidv4()}`,
    title: '',
    account: sourceData.AccountName,
    contact: '',
    email: sourceData.Email,
    link: false,
    match: '',
    isHighlighted: false,
    isDuplicate: false
  }
}

const mapDataFromDataSource = payload => {
  const accountChangeTemplateData = []
  const emailChangeTemplateData = []
  const accountAndEmailChangeTemplateData = []
  payload.forEach(matchData => {
    if (matchData.status === 0) {
      const data = {
        id: matchData.id,
        matchingData: {
          first: [
            getAccountData('', matchData.match_percentage, matchData?.contact)
          ],
          viewOtherMatchesRows: [
            getAccountData(
              'Select',
              matchData.match_percentage,
              matchData?.contact
            ),
            getAccountData(
              'Select',
              matchData.match_percentage,
              matchData?.contact
            ),
            getAccountData(
              'Select',
              matchData.match_percentage,
              matchData?.contact
            ),
            getAccountData(
              'Select',
              matchData.match_percentage,
              matchData?.contact
            )
          ]
        },
        dataFromDataSource: getCdpQueueData(matchData.cdp_queue)
      }
      switch (matchData.match_type) {
        case 1: {
          data.actions = getActions(matchData.match_type)
          accountChangeTemplateData.push(data)
          break
        }
        case 2: {
          data.actions = getActions(matchData.match_type)
          emailChangeTemplateData.push(data)
          break
        }
        case 3: {
          data.actions = getActions(matchData.match_type)
          accountAndEmailChangeTemplateData.push(data)
          break
        }
        default: {
        }
      }
    }
  })
  return {
    accountChangeTemplateData,
    emailChangeTemplateData,
    accountAndEmailChangeTemplateData
  }
}

export default mapDataFromDataSource
