/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateModalState } from '../../../../store/dashboard/dashboardCommonSlice'
import { checkForEmptyCampaigns } from '../../../../utils/mailerUtils'

import {
  container,
  sidebar,
  content,
  actionButtonContainer,
  campaignActionButton,
  loaderStyle
} from './style'

const Loader = () => {
  return <div css={loaderStyle} />
}

const MailHeader = ({
  onCancelHandler = () => {},
  saveMailCampaign = () => {}
}) => {
  const [audienceCount, setAudienceCount] = useState(0)
  const [disableButton, setDisableButton] = useState(false)

  const { emails, savingMail } = useSelector(
    ({ mailer: { emails, savingMail } }) => ({ emails, savingMail })
  )

  useEffect(() => {
    let sum = 0
    Object.keys(emails).forEach(key => {
      const emailsArr = emails[key] || []
      emailsArr.forEach(email => {
        const { audienceData } = email || {}
        if (audienceData && audienceData.length) {
          const filteredAudience =
            audienceData.filter(({ isSelected }) => isSelected) || []
          sum += filteredAudience.reduce((prev, next) => {
            return (prev += next.matchedRecordsTotal || 0)
          }, 0)
        }
      })
      setAudienceCount(sum)
    })
    const isCampaignEmpty = checkForEmptyCampaigns(emails)
    setDisableButton(isCampaignEmpty)
  }, [emails])

  return (
    <div css={container}>
      <span css={sidebar}></span>
      <div css={content}>
        <span className='header'>Create Email Campaign</span>
        <div css={actionButtonContainer}>
          <button css={campaignActionButton} onClick={onCancelHandler}>
            Cancel
          </button>
          <button
            disabled={disableButton}
            css={campaignActionButton}
            onClick={() => saveMailCampaign('save_draft')}
          >
            Save Draft
          </button>
          <button
            className='saveButton'
            css={campaignActionButton}
            disabled={disableButton}
            onClick={() => {
              saveMailCampaign()
            }}
            style={{
              pointerEvents: savingMail ? 'none' : '',
              opacity: savingMail ? '0.6' : ''
            }}
          >
            {!savingMail ? 'Save' : <Loader />}
          </button>
        </div>
        <span className='estimatedAudience'>
          Estimated Audience: {audienceCount}
        </span>
      </div>
    </div>
  )
}

export default MailHeader
