import { css } from '@emotion/react/macro'

export const containerStyle = css`
  display: flex;
  flex-direction: column;
`

export const sidebarStyle = css`
  width: 115px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding-top: 55px;
`

export const contentStyle = css`
  width: 100%;
  display: flex;
`

export const modalInnerContainer = css`
  padding: 20px;
  width: 100%;
  p {
    margin: 0;
    font-family: 'BentonSans';
    text-align: center;
  }
  .heading {
    font-size: 17px;
    font-weight: 500;
    color: #292929;
  }
  .sub-heading {
    color: #2d2d2d;
    font-size: 12px;
    margin-top: 20px;
  }
  .buttonContainer {
    text-align: center;
    margin-top: 20px;
  }
  button {
    box-sizing: border-box;
    height: 35px;
    width: 105px;
    border: 0.8px solid #c5c5c5;
    background-color: #ffffff;
    border-radius: 4px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  .edit-button {
    border: 0.8px solid #c5c5c5;
    background-color: #e1e1e1;
  }

  .go-live {
    background: #3296dc;
    color: #fff;
  }
`

export const validationTextStyle = css`
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  margin: 5px 0;
  display: flex;
  .emailName {
    margin-right: 20px;
  }
  .fields {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    flex-basis: 60%;
    flex-shrink: 0;
    font-weight: 500;
  }
`
