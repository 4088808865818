/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect, useRef } from 'react'
import Sidebar from './Sidebar'
import Dropzone from './Droplist'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { handleMoveSidebarComponentIntoParent } from './helpers'
import { updateDragData } from '../../../../../store/dashboard/dndSlice'
import { LibraryItems } from '../../../../../constant/mailer/libraryItem'
import {
  updateEmailTemplateHandler,
  updateCurrentEditingComponent,
  updateTemplateTreeHandler,
  updateTemplateTreeComponents,
  handleElementDelete,
  updateMovedElement
} from '../../../../../store/dashboard/mailerSlice'
import RightPanel from './EditorRightPanel'

const Editor = () => {
  const [layout, setLayout] = useState([])
  const dispatch = useDispatch()

  const {
    isDragging,
    currentEditedSegmentandAudience,
    emailTemplates,
    emailSettings,
    templateTree,
    editingComponents,
    deletedElement,
    movedElement
  } = useSelector(state => ({
    isDragging: state.dragAndDrop.isDragging,
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    emailTemplates: state.mailer.emailTemplates,
    emailSettings: state.mailer.emailSettings,
    templateTree: state.mailer.templateTree,
    editingComponents: state.mailer.editingComponents,
    deletedElement: state.mailer.deletedElement,
    movedElement: state.mailer.movedElement
  }))

  const pageWrapperContainerRef = useRef()
  const pageWrapperRef = useRef()

  const handleDrop = (dropZone, item) => {
    const splitDropzonePath = dropZone.path.toString().split('-')
    if (item.type === 'SIDEBAR_ITEM') {
      const newComponent = {
        id: uuidv4(),
        type: 'COMPONENT',
        dropzonePath: splitDropzonePath,
        ...item.component
      }
      setLayout(
        handleMoveSidebarComponentIntoParent(
          layout,
          splitDropzonePath,
          newComponent
        )
      )
    }
  }

  useEffect(() => {
    dispatch(updateTemplateTreeComponents())
  }, [editingComponents])

  useEffect(() => {
    if (deletedElement) {
      const { componentId, index: deletedIndex } = deletedElement
      const newLayout = layout.filter(
        ({ uId }, index) => !(uId === componentId && index === deletedIndex)
      )
      setLayout(newLayout)
      setTimeout(() => {
        dispatch(handleElementDelete(null))
      }, 500)
    }
  }, [deletedElement])

  useEffect(() => {
    if (movedElement) {
      const { componentId, index, direction } = movedElement

      const clonedLayout = layout.filter(item => true)
      if (direction == 'up' && index > 0) {
        const temp = clonedLayout[index - 1]
        clonedLayout[index - 1] = clonedLayout[index]
        clonedLayout[index] = temp
      }

      if (direction == 'down' && index < layout.length - 1) {
        const temp = clonedLayout[index + 1]
        clonedLayout[index + 1] = clonedLayout[index]
        clonedLayout[index] = temp
      }
      setLayout(clonedLayout)
      setTimeout(() => {
        dispatch(updateMovedElement(null))
      }, 500)
    }
  }, [movedElement])

  useEffect(() => {
    if (layout.length) {
      setTimeout(() => {
        let components = []
        layout.forEach((element, index) => {
          const { uId, dropzonePath, parentKey, id } = element
          components.push({
            component: uId,
            index,
            dropzonePath,
            parentKey,
            id
          })
        })
        dispatch(updateTemplateTreeHandler(0, components))
      }, 300)
    }
  }, [layout])

  useEffect(() => {
    const clickedOutside = event => {
      if (
        pageWrapperContainerRef.current &&
        pageWrapperRef.current &&
        !pageWrapperRef.current.contains(event.target) &&
        pageWrapperContainerRef.current.contains(event.target)
      ) {
        dispatch(updateCurrentEditingComponent({}))
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [pageWrapperContainerRef, pageWrapperRef])

  useEffect(() => {
    if (currentEditedSegmentandAudience) {
      const { segmentId, audienceId } = currentEditedSegmentandAudience
      const savedLayout = (templateTree[segmentId] || {})[audienceId] || {}
      if ((savedLayout[0] || []).length) {
        const components = savedLayout[0]
        if (components.length) {
          let newLayout = []
          components.forEach(({ component, index, parentKey, id }) => {
            const parentComponent = LibraryItems.find(
              ({ title }) => title === parentKey
            )
            const renderingComponent = (
              (parentComponent || {}).subMenus || []
            ).find(({ component: { uId } }) => uId === component)
            const newComponent = {
              id,
              type: 'COMPONENT',
              dropzonePath: [index.toString()],
              ...renderingComponent.component
            }
            newLayout = handleMoveSidebarComponentIntoParent(
              newLayout,
              [index.toString()],
              newComponent
            )
          })
          setLayout(newLayout)
        }
      } else {
        setLayout([])
      }
    }
  }, [currentEditedSegmentandAudience])

  useEffect(() => {
    setTimeout(
      () =>
        dispatch(
          updateEmailTemplateHandler(
            emailTemplates,
            currentEditedSegmentandAudience
          )
        ),
      300
    )
  }, [layout])

  const renderComponent = (component, path) => {
    return <div data-path={path}>{component.component({ path })}</div>
  }

  const renderRow = (data, path) => {
    return (
      <React.Fragment>
        {renderComponent(data, path)}
        {isDragging && (
          <Dropzone
            data={{
              path: path
            }}
            onDrop={handleDrop}
          />
        )}
      </React.Fragment>
    )
  }
  const monitorDragging = useCallback(dragged => {
    setTimeout(() => dispatch(updateDragData(dragged)))
  }, [])

  return (
    <div
      style={{
        width: '100%',
        border: '0.8px solid #ccc',
        minHeight: '400px',
        marginTop: '15px',
        borderRadius: '4px',
        display: 'flex',
        flex: 1,
        background: emailSettings.backgroundColor
      }}
    >
      <Sidebar monitorDragging={monitorDragging} />
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}
      >
        <div
          id='page-wrapper'
          ref={pageWrapperContainerRef}
          style={{
            padding: '64px 48px 24px',
            marginLeft: 'auto',
            overflowY: 'auto'
          }}
        >
          <div
            ref={pageWrapperRef}
            style={{
              width: emailSettings.canvasWidth + 'px',
              minHeight: '40px',
              background: '#fff',
              height: 'auto'
            }}
          >
            {isDragging && (
              <Dropzone
                data={{
                  path: `0`
                }}
                onDrop={handleDrop}
              />
            )}
            {layout.map((row, index) => {
              const currentPath = `${index + 1}`
              return (
                <React.Fragment key={row.id}>
                  {renderRow(row, currentPath)}
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <RightPanel />
      </div>
    </div>
  )
}

export default Editor
