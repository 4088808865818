import { css } from '@emotion/react'

export const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0006;
  z-index: 9999;
  overflow: auto;
`

export const contentContainer = css`
  min-width: 200px;
  max-width: 600px;
  width: 498px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -2px rgba(51, 153, 255, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const crossIconStyle = css`
  text-align: right;
  padding: 5px;
  color: #808080;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  right: -36px;
  top: -8px;
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: #fff;
    &:hover {
      cursor: pointer;
    }
  }
`
