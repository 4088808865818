import { css } from '@emotion/react'

export const sidebarContainer = css`
  position: relative;
  flex: 0 0 208px;
`

export const libraryStyle = css`
  z-index: 3;
  width: 208px;
  position: absolute;
  background: #fff;
  height: 100%;
  border-right: 1px solid #e7e7e7;
`

export const libraryDropListStyle = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #e7e7e7;
  z-index: 2;
  overflow: auto;
  background-color: #fafafa;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  padding: 8px 24px;
  &.expanded {
    opacity: 1;
    left: 208px;
    width: 260px;
  }
`

export const headingStyle = css`
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  padding: 12px 24px;
`

export const libraryCategoryStyle = css`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #555c66;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 12px 24px;
  &:hover {
    background-color: #f5f5f5;
  }
`

export const categoryLabelStyle = css`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`

export const categoryCountStyle = css`
  height: 24px;
  width: 24px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  text-align: center;
  background: #fff;
  font-size: 13px;
`

export const quickAddWrapper = css`
  display: grid;
  justify-content: center;
  grid-template-columns: 90px 90px;
  grid-gap: 16px;
  padding-top: 24px;
`

export const quickaddTitlesStyle = css`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #555c66;
  padding-top: 24px;
  margin-bottom: 16px;
  text-transform: capitalize;
`

export const quickAddLayoutWrapper = css``
