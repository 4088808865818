import { useRef, useEffect } from 'react'

const useElementOnScreen = (
  selectedSection,
  options,
  setSelectedSection = () => {}
) => {
  const containerRef = useRef(null)
  const order = {
    welcome: 0,
    crm: 1,
    socialAndAnalytics: 2,
    businessType: 3,
    segment: 4,
    takeToDashboard: 5
  }
  useEffect(() => {
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    }
    const callbackFunction = entries => {
      const [entry] = entries
      if (entry.isIntersecting && entry.target.id !== selectedSection) {
        if (
          containerRef.current.getAttribute('data-clicked') === 'true' &&
          selectedSection === Object.keys(order)[order[entry.target.id] + 1]
        ) {
          containerRef.current.setAttribute('data-clicked', 'false')
        } else {
          containerRef.current.setAttribute('data-clicked', 'false')
          setSelectedSection(entry.target.id)
          containerRef.current.scrollIntoView(scrollIntoViewOptions)
        }
      }
    }
    const observer = new IntersectionObserver(callbackFunction, options)
    const node = containerRef.current
    if (node) observer.observe(node)

    return () => {
      if (node) observer.unobserve(node)
    }
  }, [containerRef, options])

  return [containerRef]
}

export default useElementOnScreen
