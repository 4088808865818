export const mapDataForDataSource = (payload, productId) => {
  return Object.keys(payload)
    .filter(segmentIndex => {
      if (payload[segmentIndex].segmentName) {
        return true
      }
      return false
    })
    .map(segmentIndex => {
      const segment = payload[segmentIndex]
      const { segmentNamePlaceholder } = segment || {}
      const response = {
        id: segment.id,
        segment_name: segment.segmentName,
        segment_type: segment.segmentType,
        product_id: productId || 2,
        status: segment.status,
        criteria: []
      }
      response['lost_customer'] = ['lost customers'].includes(
        segmentNamePlaceholder.toLowerCase()
      )
        ? true
        : false

      if (segment.ruleGroups[0].matchType === 'Match All')
        response.match_type = 'and'
      if (segment.ruleGroups[0].matchType === 'Match Any')
        response.match_type = 'or'
      response.criteria = segment.ruleGroups[0].rules
        .filter(rule => Object.values(rule).every(item => item))
        .map(rule => {
          return {
            id: rule.id,
            column_name: rule.prospectTag,
            condition: rule.condition,
            value_1: rule.value,
            status: 'active'
          }
        })
      return response
    })
}
