import { css } from '@emotion/react/macro'

export const viewContactWrapper = css`
  display: flex;
  margin-top: 18px;
`

export const container = css`
  padding: 0 20px 20px 20px;
  width: 100%;
  height: 100%;
`

export const recordLabelStyle = css`
  font-size: 15px;
  padding-left: 17px;
  color: #666666;
  font-family: BentonSans;
  font-weight: 300;
`

export const recordDropdown = css`
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
`

export const dropdownSearchStyle = css`
  border: none;
  width: 320px;
  background: none;
  .dropdown-menu {
    margin-top: 40px;
    margin-left: 148px;
    li {
      justify-content: space-between;
    }
  }
  .button {
    font-size: 19px;
    justify-content: unset;
    .chevron {
      margin-left: 16px;
    }
  }
`

export const conditionDataStyle = css`
  max-height: 489px;
  overflow-y: auto;
  position: relative;
`

export const downloadBtnGroup = css`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
`
export const buttonStyle = css`
  width: 135px;
  height: 38px;
  background: #ccc;
  border: 0.8px solid #cccccc;
  outline: none;
  border-radius: 2px;
  color: #fff;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 500;
  &:first-of-type {
    margin-right: 13px;
  }
`
export const primaryStyle = css`
  color: #fff;
  box-sizing: border-box;
  height: 38px;
  width: 131px;
  background-color: #18a3e2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  box-shadow: 0 2px 6px 2px rgb(102 153 204 / 40%);
  cursor: pointer;
`

export const grdStyle = () => css`
  &.ag-theme-alpine {
    font-family: BentonSans;
    .ag-header-group-cell {
      padding: 0;
    }
    .ag-header-group-cell-label {
      justify-content: center;
      align-items: center;
    }
    .ag-header {
      border: none;
      background-color: #fff;
      .ag-header-cell-text {
        font-size: 15px;
        font-family: BentonSans;
        color: rgb(51, 51, 51);
        font-weight: 600;
        line-height: 2;
      }
    }
    .ag-root-wrapper,
    .ag-header-row,
    .ag-header-row-column,
    .ag-header-cell {
      border: 0 !important;
    }
    .ag-pinned-left-header {
      border: 0;
    }
    .ag-pinned-left-cols-container {
      border: 0;
    }
    .ag-row {
      border: 0;
    }
    .ag-cell-focus {
      border: none !important;
    }
    .ag-cell {
      display: flex;
      align-items: center;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ag-center-cols-container,
    .ag-header-container {
      width: 100% !important;
    }
  }
`
