/** @jsxImportSource @emotion/react */
import React from 'react'
import ReactDOM from 'react-dom'
import {
  modalContainer,
  modalContent,
  tableHeaderStyle,
  tableCellStyle,
  tableRowStyle,
  labelStyle
} from './style'
import { TABLE_TYPE } from '../../constant/analytics'

const Modal = ({ tableData, tableType }) => {
  const modalRoot = document.getElementById('modal-root')

  const { value, column, data, clientX, clientY } = tableData || {}

  let content
  if (tableType === TABLE_TYPE.BY_CHANNEL) {
    const { segregatedData = [], channel } = data || {}
    content = (
      <div>
        <div>
          <p>
            <span css={labelStyle}>Digital Source</span>: {channel}
          </p>
          <p>
            <span css={labelStyle}>{column}</span>: {value}
          </p>
        </div>
        {segregatedData && segregatedData.length ? (
          <table>
            <thead>
              <tr>
                <th css={tableHeaderStyle}></th>
                <th css={tableHeaderStyle}>Impressions</th>
                <th css={tableHeaderStyle}>CTR</th>
                <th css={tableHeaderStyle}>Cost</th>
                <th css={tableHeaderStyle}>Leads</th>
                <th css={tableHeaderStyle}>CP Lead</th>
                <th css={tableHeaderStyle}>Primed Leads</th>
                <th css={tableHeaderStyle}>CP Primed Lead</th>
              </tr>
            </thead>
            <tbody>
              {segregatedData.map((row, index) => (
                <tr key={index} css={tableRowStyle}>
                  <td css={tableCellStyle}>{row.channel}</td>
                  <td css={tableCellStyle}>{row.impressions}</td>
                  <td css={tableCellStyle}>{row.ctr}</td>
                  <td css={tableCellStyle}>{row.cost}</td>
                  <td css={tableCellStyle}>{row.leads}</td>
                  <td css={tableCellStyle}>{row.cp_lead}</td>
                  <td css={tableCellStyle}>{row.primed_leads}</td>
                  <td css={tableCellStyle}>{row.cp_primed_lead}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    )
  }

  if (tableType === TABLE_TYPE.BY_TIMEFRAME) {
    const { timeframe } = data || {}
    content = (
      <div>
        <p>
          <span css={labelStyle}>Quarter of date</span>: {timeframe}
        </p>
        <p>
          <span css={labelStyle}>{column}</span>: {value}
        </p>
      </div>
    )
  }
  return (
    <>
      {modalRoot &&
        ReactDOM.createPortal(
          <div css={modalContainer(clientX, clientY)}>
            <div css={modalContent}>{content}</div>
          </div>,
          modalRoot
        )}
    </>
  )
}

export default Modal
