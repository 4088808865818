export const mapDataFromDataSource = payload => {
  return payload.map(segment => {
    const response = {
      id: segment.id,
      segmentName: segment.segment_name,
      isEditable: false,
      dataFromApi: true,
      segmentType: segment.segment_type,
      productId: segment.product_id,
      status: segment.status,
      lostCustomer: segment.lost_customer,
      ruleGroups: [],
      matchedRecordIds: segment.matched_record_ids
    }
    let rule = {}
    if (segment.match_type === 'and') rule.matchType = 'Match All'
    if (segment.match_type === 'or') rule.matchType = 'Match Any'
    rule.rules = segment.segment_queries.map(query => {
      return {
        id: query.id,
        prospectTag: query.column_name,
        condition: query.condition,
        value: query.value_1
      }
    })
    response.ruleGroups.push(rule)
    return response
  })
}

export const mapDataForDataSource = (payload, productId) => {
  return payload
    .filter(segment => {
      if (segment.status === 'inactive' && segment.newAddition) {
        return false
      }
      return true
    })
    .map(segment => {
      const response = {
        id: segment.id,
        segment_name: segment.segmentName,
        segment_type: segment.segmentType,
        product_id: segment.productId || productId,
        status: segment.status,
        criteria: []
      }
      if (segment.ruleGroups[0].matchType === 'Match All')
        response.match_type = 'and'
      if (segment.ruleGroups[0].matchType === 'Match Any')
        response.match_type = 'or'
      response.criteria = segment.ruleGroups[0].rules.map(rule => {
        return {
          id: rule.id,
          column_name: rule.prospectTag,
          condition: rule.condition,
          value_1: rule.value,
          status: 'active'
        }
      })
      return response
    })
}
