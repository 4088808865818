import { css } from '@emotion/react'

export const crmSection = css`
  display: flex;
`

export const crmContent = css`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  &[data-selected='false'] {
    opacity: 0.3;
    pointer-events: none;
  }
  span {
    margin: 6px 0px 16px 0px;
    color: #333333;
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 9px;
  }
  .solutionNotListed {
    background-color: #999999;
  }
`

export const crmOptions = css`
  display: flex;
  margin-top: 25px;
  margin-bottom: 46px;
`

export const crmOptionBox = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  margin-right: 26px;
  .optionDescription {
    position: absolute;
    text-align: center;
    top: 60px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease-out;
    box-sizing: border-box;
    height: 0px;
    width: 0px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    .textStyle {
      height: 8px;
      width: 72px;
      color: #333333;
      font-family: BentonSans;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 0;
      text-align: center;
    }
  }
  &.connected {
    box-shadow: 0 2px 6px 4px rgb(62 158 255 / 62%);
  }
  &:hover {
    .optionDescription {
      height: 23px;
      width: 86px;
    }
  }
`

export const leadIntelligenceOptions = css`
  display: flex;
  margin-bottom: 39px;
`

export const leadIntelligenceOptionBox = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  margin-right: 26px;
  .optionDescription {
    position: absolute;
    text-align: center;
    top: 60px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease-out;
    box-sizing: border-box;
    height: 0px;
    width: 0px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    .textStyle {
      height: 8px;
      width: 72px;
      color: #333333;
      font-family: BentonSans;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 0;
      text-align: center;
    }
  }
  &:hover {
    .optionDescription {
      height: 23px;
      width: 86px;
    }
  }
`

export const solutionNotExistingContainer = css`
  display: flex;
`

export const addSolutionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
`

export const addSolutionOptionContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .submissionStatus {
    color: #333333;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    margin: 0px;
    margin-left: 12px;
  }
`

export const addSolutionOption = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  width: 483px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  .submitButton {
    cursor: pointer;
    box-sizing: border-box;
    height: 33px;
    width: 64px;
    border-radius: 4px;
    border: 0.8px solid #cccccc;
    background-color: #18a3e2;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    color: #ffffff;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    padding-top: 4px;
    text-align: center;
    margin: 0px;
    margin-right: 12px;
  }
`

export const allSetButton = solutionExisting => css`
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  height: 50px;
  width: 341px;
  color: #ffffff;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  font-family: BentonSans;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0;
  text-align: center;
  ${!solutionExisting && 'width: 483px;'}
`

export const solutionNotListedButton = css`
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  height: 50px;
  width: 341px;
  color: #ffffff;
  border: 0.8px solid #cccccc;
  background-color: #999999;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  font-family: BentonSans;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0;
  text-align: center;
`

export const addSolutionButton = css`
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 12px;
  height: 50px;
  width: 483px;
  border-radius: 4px;
  border: 4px dashed #cccccc;
  text-align: center;
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  .plusIcon {
    height: 20px;
    width: 21px;
    color: #333333;
    font-family: Lato;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 0.9;
  }
`

export const inputStyle = css`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 0;
  background-color: inherit;
  outline: none;
  padding-left: 12px;
  font-family: BentonSans;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 0.9;
`

export const overlay = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
`
