/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'

import { switchButton } from './style'

const SwitchController = ({
  state,
  toggleSwitchState = () => {},
  style,
  showPause = true,
  containerStyle
}) => {
  const [switchState, setSwitchState] = useState('')

  useEffect(() => {
    setSwitchState(state || 'stop')
  }, [state])

  const switchClickHandler = action => {
    setSwitchState(action)
    toggleSwitchState(action)
  }

  return (
    <div css={[switchButton(switchState), containerStyle]} style={style}>
      <div className='stop' onClick={() => switchClickHandler('stop')}></div>
      {showPause ? (
        <div className='pause' onClick={() => switchClickHandler('pause')} />
      ) : null}
      <div
        className='active'
        onClick={() => switchClickHandler('active')}
      ></div>
    </div>
  )
}

export default SwitchController
