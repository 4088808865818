import { css } from '@emotion/react'

export const container = css`
  width: 115px;
  min-width: 115px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const welcomeSection = selectedSection => css`
  padding-top: 8px;
  img {
    height: 18px;
    width: 77px;
    ${selectedSection !== 'welcome' && 'opacity: 0.3;'}
  }
`

export const crm = selectedSection => css`
  padding-top: 120px;
  img {
    height: 37px;
    width: 36px;
    ${selectedSection !== 'crm' && 'opacity: 0.3;'}
  }
  span {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    margin-top: 8px;
    ${selectedSection !== 'crm' && 'opacity: 0.3;'}
  }
`

export const crmSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const leadIntelligenceSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
`

export const socialAndAnalytics = selectedSection => css`
  padding-top: 121px;
  img {
    ${selectedSection !== 'socialAndAnalytics' && 'opacity: 0.3;'}
  }
  span {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    margin-top: 8px;
    ${selectedSection !== 'socialAndAnalytics' && 'opacity: 0.3;'}
  }
`

export const adsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 33px;
    width: 37px;
  }
`

export const emailSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 38px 0px;
  img {
    height: 30px;
    width: 37px;
  }
`

export const analyticsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 37px;
    width: 36px;
  }
`

export const businessTypeSection = selectedSection => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    margin-top: 8px;
    ${selectedSection !== 'businessType' && 'opacity: 0.3;'}
  }
  img {
    height: 37px;
    width: 37px;
    ${selectedSection !== 'businessType' && 'opacity: 0.3;'}
  }
`

export const segmentSection = selectedSection => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    margin-top: 8px;
    ${selectedSection !== 'segment' && 'opacity: 0.3;'}
  }
  img {
    height: 37px;
    width: 37px;
    ${selectedSection !== 'segment' && 'opacity: 0.3;'}
  }
`

export const takeToDashboardSection = selectedSection => css`
  display: flex;
`
