import { css } from '@emotion/react/macro'

export const main = stickyHeader => css`
  display: flex;
  flex-direction: column;
  ${stickyHeader && 'position: sticky; z-index: 4;'}
  position: relative;
`

export const container = css`
  background-color: #e5e5e5;
  height: 66px;
  display: flex;
  align-items: center;
`

export const buttonStyle = id => css`
  box-sizing: border-box;
  height: 38px;
  width: 121px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 9px;
  ${id % 2 === 0 && 'margin-right: 13px;'}
  box-shadow: 0 2px 6px 2px rgb(208 208 209 / 40%);
  cursor: pointer;
  padding-top: 3px;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
  }
  .label {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 2px;
  }
`

export const secondaryButtonContainerStyle = css`
  display: flex;
  margin-left: auto;
  margin-right: 7px;
`

export const secondaryButtonStyle = css`
  box-sizing: border-box;
  height: 38px;
  width: 131px;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 12px;
    img {
      height: inherit;
      width: inherit;
    }
  }
  .label {
    color: #fff;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
  }
`

export const addIconStyle = css`
  display: flex;
  justify-content: center;
  width: 105px;
  height: 20px;
  margin-right: 12px;
  margin-left: 5px;
  img {
    width: 20px;
    height: 20px;
  }
`

export const modalInnerContainer = css`
  padding: 20px;
  width: 100%;
  p {
    margin: 0;
    font-family: 'BentonSans';
    text-align: center;
  }
  .heading {
    font-size: 17px;
    font-weight: 500;
    color: #292929;
  }
  .sub-heading {
    color: #2d2d2d;
    font-size: 12px;
    margin-top: 20px;
  }
  .buttonContainer {
    text-align: center;
    margin-top: 20px;
  }
  button {
    box-sizing: border-box;
    height: 35px;
    width: 105px;
    border: 0.8px solid #c5c5c5;
    background-color: #ffffff;
    border-radius: 4px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  .edit-button {
    border: 0.8px solid #c5c5c5;
    background-color: #e1e1e1;
  }
  .go-live {
    background: #3296dc;
    color: #fff;
  }
`

export const validationTextStyle = css`
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  margin: 5px 0;
  display: flex;
  .emailName {
    margin-right: 20px;
  }
  .fields {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    flex-basis: 60%;
    flex-shrink: 0;
    font-weight: 500;
  }
`
