import { createSlice } from '@reduxjs/toolkit'

const initialToastState = {
  isVisible: false,
  message: '',
  type: ''
}

const toastSlice = createSlice({
  name: 'toast',
  initialState: initialToastState,
  reducers: {
    showToast (state, action) {
      state.isVisible = true
      state.message = action.payload.message
      state.type = action.payload.type
    },
    hideToast (state) {
      state.isVisible = false
      state.message = ''
    }
  }
})

export const { showToast, hideToast } = toastSlice.actions

export default toastSlice.reducer
