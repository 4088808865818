import React from 'react'
import './style.css'

const Checkbox = React.forwardRef(
  ({ checked, onChange = () => {}, index }, ref) => {
    return (
      <>
        <input
          type='checkbox'
          className={`input-edit checkbox`}
          id={`checkbox-${index}`}
          checked={checked}
          onChange={onChange}
          ref={ref}
        />
        <label htmlFor={`checkbox-${index}`} />
      </>
    )
  }
)

export default Checkbox
