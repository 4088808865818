import { makeStyles } from '@material-ui/core/styles'
// import { deepBlue } from '@material-ui/core/colors';

const drawerWidth = 115

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    width: `calc(100% - ${drawerWidth}px)`,
    maxWidth: '1422px',
    marginLeft: drawerWidth,
    boxShadow: 'none',
    padding: '0.6em',
    paddingTop: '21px',
    right: 'unset',
    position: 'fixed',
    top: 0,
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800]
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    margin: '0.6em'
  },
  title: {
    fontWeight: '400'
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 'medium'
  },
  avatarText: {
    marginLeft: 'auto'
  }
}))

export default useStyles
