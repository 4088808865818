/** @jsxImportSource @emotion/react */
import React from 'react'

import { container } from './styles'

const Footer = () => {
  return (
    <div css={container}>
      <div
        style={{
          width: 1440,
          display: 'flex',
          justifyContent: 'flex-end',
          margin: 'auto'
        }}
      >
        <span className='textStyle'>All Rights Reserved. © 2023 Neyo, LLC</span>
      </div>
    </div>
  )
}

export default Footer
