import { css } from '@emotion/react'

export const containerStyle = css`
  display: flex;
`

export const contentContainer = css`
  box-sizing: content-box;
  overflow: auto;
  padding: 14px 12px;
  width: calc(100% - 117px);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-height: 600px;
  overflow: auto;
`

export const campaignSummaryStyle = css`
  box-sizing: content-box;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px -2px #0099ff;
  margin-bottom: 12px;
  flex-shrink: 0;
`

export const headerStyle = expandedView => css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 95px;
  border: 0.8px solid #cccccc;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -2px #0099ff;
  .toggleHeight {
    height: 0px;
    width: 0px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
  ${expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
  &:hover {
    ${!expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
  }
`

export const mailsContainer = expandedView => css`
  display: flex;
  flex-direction: column;
  height: 0px;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #e5e5e5;
  box-shadow: 0 2px 6px -2px #0099ff;
  overflow: hidden;
  transition: all 0.4s ease-out;
  ${expandedView &&
    'height: fit-content; padding-bottom: 20px;  border: 0.8px solid #cccccc;'}
`

export const previewStyle = css`
  display: inline-flex;
  position: relativel;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 68px;
  width: 68px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  margin-right: 70px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

export const campaignNameContainer = css`
  width: 239px;
  height: 68px;
  font-family: BentonSans;
  font-size: 14px;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgba(102, 153, 204, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding-top: 4px;
  .name,
  .target {
    width: 80%;
    text-align: center;
  }
  .name {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 1.2;
  }
  .target {
    padding-top: 5px;
    position: relative;
    margin-top: 5px;
    &::before {
      content: '';
      height: 0.5px;
      width: 120px;
      position: absolute;
      top: 0;
      left: calc(50% - 60px);
      background: #ccc;
    }
  }
`

export const setWidthOfForm = (cardLength = 1) => css`
  width: ${cardLength * 231.4 + (cardLength - 1) * 33}px;
  min-width: ${cardLength * 231.4 + (cardLength - 1) * 33}px;
  @media (min-width: 1441px) {
    width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
    min-width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
  }
`

export const mailCardStyle = (expandedView, cardLength) => css`
  width: 231.4px;
  height: 86px;
  min-width: 231.4px;
  position: relative;
  background-color: #ffffff;
  margin: 12px;
  box-shadow: 0 2px 6px -2px #0099ff;
  border-radius: 4px;
  display: flex;
  padding: 10px 0px 0px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .toggleHeight {
    height: 0px;
    width: 0px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
  padding-bottom: 10px;
  &:hover {
    ${!expandedView && '.toggleHeight { height: 24px; width: 24px; }'}
  }
  ${expandedView &&
    'height: auto; padding-bottom: 0; .toggleHeight { height: 24px; width: 24px;}'}
    ${cardLength > 1 && 'justify-content: center;'}
  ${setWidthOfForm(cardLength)}
  @media (min-width: 1441px) {
    margin: 12px 24px;
    margin-bottom: 0;
  }
`

export const emptyCardView = css`
  width: 231.4px;
  height: 86px;
  flex: 1;
  flex-shrink: 0;
`

export const mailCardPreview = css`
  margin-right: 10px;
`

export const mailShortInfo = css`
  display: flex;
  width: 100%;
  align-items: center;
  .emailDetail {
    flex: 1;
    width: calc(100% - 78px);
    text-align: center;
  }
  .emailName {
    padding-bottom: 5px;
    position: relative;
    text-transform: capitalize;
    &::after {
      content: '';
      height: 0.5px;
      width: 60px;
      position: absolute;
      bottom: 0;
      left: calc(50% - 30px);
      background: #ccc;
    }
  }
  .emailType {
    padding-top: 5px;
    text-transform: capitalize;
  }
`

export const emailFullDetails = expandedView => css`
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 231px;
  ${expandedView && `height: auto; overflow: unset;`}
  .row {
    margin-top: 10px;
  }
  .label {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  .value {
    background-color: #f5f5f5;
    padding: 10px;
    font-weight: 500;

    .inputBox {
      box-sizing: border-box;
      height: 40px;
      padding: 13px 8px;
      border: 0.8px solid #cccccc;
      background-color: #ffffff;
      line-height: 10px;
      font-weight: 300;
      margin: 8px 0;
      border-radius: 4px;
    }

    .matchType,
    .and {
      margin: 10px 0 15px 0;
      font-weight: 300;
    }
    .and {
      text-align: center;
    }
  }
`

export const controlRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px;
  .edit,
  .trash {
    height: 38px;
    width: 40px;
    border-radius: 5px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
    img {
      width: 20px;
      height: 20px;
    }
    cursor: pointer;
  }
  .trash {
    background-color: #ccc;
  }

  .view-contact {
    height: 38px;
    width: 116px;
    border: 0.8px solid #cccccc;
    background-color: #18a3e2;
    box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
`

export const mailRowStyle = css`
  display: flex;
  margin-bottom: 10px;
  min-width: 100%;
`

export const editDropdownStyle = css`
  position: absolute;
  z-index: 2;
  right: 6px;
  bottom: 6px;

  width: 15px;
  height: 15px;

  img {
    width: 15px;
    height: 15px;
    display: inline-block;
    object-fit: contain;
    transform: rotate(180deg);
    cursor: pointer;
  }
`

export const dropdownContainer = css`
  padding: 8px;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px #6699cc1f;
  border-radius: 5px;
`

export const drowndownBtnStyle = css`
  height: 38px;
  width: 72px;
  border: 0.8px solid #cccccc;
  box-shadow: 0 2px 6px 1px rgba(102, 153, 204, 0.4);
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    background-color: #18a3e2;
    color: #fff;
  }
`
