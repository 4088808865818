/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'

import SideDrawer from '../SideDrawer'
import CrmIntegrationModalForm from '../CrmIntegrationModalForm'

import {
  crmSection,
  crmContent,
  crmOptions,
  crmOptionBox,
  leadIntelligenceOptions,
  leadIntelligenceOptionBox,
  solutionNotExistingContainer,
  addSolutionContainer,
  addSolutionOptionContainer,
  addSolutionOption,
  allSetButton,
  solutionNotListedButton,
  addSolutionButton,
  inputStyle,
  overlay
} from './styles'

import salesforceIcon from '../../../assets/images/onboardingDashboard/crmIcons/salesforceIcon.png'
import sapIcon from '../../../assets/images/onboardingDashboard/crmIcons/sapIcon.png'
import oracleIcon from '../../../assets/images/onboardingDashboard/crmIcons/oracleIcon.png'
import microsoftIcon from '../../../assets/images/onboardingDashboard/crmIcons/microsoftIcon.png'
import adobeIcon from '../../../assets/images/onboardingDashboard/crmIcons/adobeIcon.png'
import zohoIcon from '../../../assets/images/onboardingDashboard/crmIcons/zohoIcon.png'
import hubspotIcon from '../../../assets/images/onboardingDashboard/crmIcons/hubspotIcon.png'
import freshdeskIcon from '../../../assets/images/onboardingDashboard/crmIcons/freshdeskIcon.png'
import zendeskIcon from '../../../assets/images/onboardingDashboard/crmIcons/zendeskIcon.png'
import keapIcon from '../../../assets/images/onboardingDashboard/crmIcons/keapIcon.png'
import sugarcrmIcon from '../../../assets/images/onboardingDashboard/crmIcons/sugarcrmIcon.png'
import pipedriveIcon from '../../../assets/images/onboardingDashboard/crmIcons/pipedriveIcon.png'

import zoomInfoIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/zoomInfo.png'
import apolloIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/apollo.png'
import seamlessIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/seamless.png'
import lushaIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/lusha.png'
import cognismIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/cognism.png'
import sixSenseIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/6Sense.png'
import leadIqIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/leadIq.png'
import clearbitIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/clearbit.png'
import adaptIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/adapt.png'
import demandbaseIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/demandbase.png'
import techTargetIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/techTarget.png'
import g2Icon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/g2.png'
import bomboraIcon from '../../../assets/images/onboardingDashboard/leadIntelligenceIcons/bombora.png'

const crmOptionsData = [
  {
    id: 1,
    name: 'Salesforce',
    source: salesforceIcon
  },
  {
    id: 2,
    name: 'SAP',
    source: sapIcon
  },
  {
    id: 3,
    name: 'Oracle',
    source: oracleIcon
  },
  {
    id: 4,
    name: 'Microsoft',
    source: microsoftIcon
  },
  {
    id: 5,
    name: 'Adobe',
    source: adobeIcon
  },
  {
    id: 6,
    name: 'Zoho',
    source: zohoIcon
  },
  {
    id: 7,
    name: 'HubSoft',
    source: hubspotIcon
  },
  {
    id: 8,
    name: 'FreshDesk',
    source: freshdeskIcon
  },
  {
    id: 9,
    name: 'Zendesk',
    source: zendeskIcon
  },
  {
    id: 10,
    name: 'Keap',
    source: keapIcon
  },
  {
    id: 11,
    name: 'SugarCRM',
    source: sugarcrmIcon
  },
  {
    id: 12,
    name: 'Pipedrive',
    source: pipedriveIcon
  }
]
const leadIntelligenceOptionsData = [
  {
    id: 1,
    name: 'ZoomInfo',
    source: zoomInfoIcon
  },
  {
    id: 2,
    name: 'Apollo.io',
    source: apolloIcon
  },
  {
    id: 3,
    name: 'Seamless.ai',
    source: seamlessIcon
  },
  {
    id: 4,
    name: 'Lusha',
    source: lushaIcon
  },
  {
    id: 5,
    name: 'Cognism',
    source: cognismIcon
  },
  {
    id: 6,
    name: '6sense',
    source: sixSenseIcon
  },
  {
    id: 7,
    name: 'LeadIQ',
    source: leadIqIcon
  },
  {
    id: 8,
    name: 'Clearbit',
    source: clearbitIcon
  },
  {
    id: 9,
    name: 'Adapt',
    source: adaptIcon
  },
  {
    id: 10,
    name: 'Demandbase',
    source: demandbaseIcon
  },
  {
    id: 11,
    name: 'TechTarget',
    source: techTargetIcon
  },
  {
    id: 12,
    name: 'G2',
    source: g2Icon
  },
  {
    id: 13,
    name: 'Bombora',
    source: bomboraIcon
  }
]

const newSolutionsOptions = [
  {
    id: 1,
    status: true,
    description: '',
    isEditable: true
  },
  {
    id: 2,
    status: false,
    isEditable: true,
    description: ''
  }
]

const CrmSection = React.forwardRef(
  (
    {
      selectedSection,
      scrollHandler = () => {},
      setSelectedSection = () => {}
    },
    ref
  ) => {
    const [solutionExisting, setSolutionExisting] = useState(true)
    const [currentSelectedCrm, setCurrentSelectedCrm] = useState(null)
    const [listOfConnectedCrm, updateListOfconnectedCrm] = useState([])
    const [newSolutionsData, setNewSolutionsData] = useState(
      newSolutionsOptions
    )
    const [modalViewFlag, setModalViewFlag] = useState(false)

    useEffect(() => {
      document.body.classList.toggle('audience-model-open', modalViewFlag)
    }, [modalViewFlag])

    useEffect(() => {
      if (!modalViewFlag) {
        setCurrentSelectedCrm(null)
      }
    }, [modalViewFlag])

    const clickHandler = () => {
      ref.current.setAttribute('data-clicked', 'true')
      setSelectedSection('socialAndAnalytics')
      scrollHandler('socialAndAnalytics')
    }
    const solutionNotListedHandler = () => {
      setSolutionExisting(false)
    }
    const handleOnChange = () => {}

    const addButtonHandler = (solutionData, index) => {
      const revSolutionData = JSON.parse(JSON.stringify(newSolutionsData))
      revSolutionData[index].status = true
      setNewSolutionsData(revSolutionData)
    }

    const submitNewSolutionHandler = index => {
      const revSolutionData = JSON.parse(JSON.stringify(newSolutionsData))
      revSolutionData[index].isEditable = !revSolutionData[index].isEditable
      setNewSolutionsData(revSolutionData)
    }

    const onIntegrationSuccess = () => {
      const updatedList = [...listOfConnectedCrm, currentSelectedCrm]
      updateListOfconnectedCrm(updatedList)
    }

    return (
      <section id='crm' ref={ref} data-clicked={false} css={crmSection}>
        <SideDrawer section={'crm'} selectedSection={selectedSection} />
        <div css={crmContent} data-selected={selectedSection === 'crm'}>
          <span>Let's start with audiences</span>
          <span>Select your CRM and Lead Intelligence solutions</span>
          <span>
            (We are SOC2 certified which means your data is completely secure in
            a privacy compliant data warehouse.)
          </span>
          <div css={crmOptions}>
            {crmOptionsData.map(option => {
              return (
                <div
                  key={option.id}
                  css={crmOptionBox}
                  className={
                    listOfConnectedCrm.includes(option.name) ? 'connected' : ''
                  }
                  onClick={() => {
                    setCurrentSelectedCrm(option.name)
                    setModalViewFlag(prev => !prev)
                  }}
                >
                  <img alt={option.name} src={option.source} />
                  <div className='optionDescription'>
                    <span className='textStyle'>{option.name}</span>
                  </div>
                </div>
              )
            })}
          </div>
          {modalViewFlag && (
            <div css={overlay}>
              <CrmIntegrationModalForm
                currentSelectedCrm={currentSelectedCrm}
                setModalViewFlag={setModalViewFlag}
                onCrmIntegrationSuccess={onIntegrationSuccess}
              />
            </div>
          )}
          <div css={leadIntelligenceOptions}>
            {leadIntelligenceOptionsData.map(option => {
              return (
                <div key={option.id} css={leadIntelligenceOptionBox}>
                  <img alt={option.name} src={option.source} />
                  <div className='optionDescription'>
                    <span className='textStyle'>{option.name}</span>
                  </div>
                </div>
              )
            })}
          </div>
          {solutionExisting && (
            <>
              <button
                css={solutionNotListedButton}
                onClick={solutionNotListedHandler}
              >
                My solution is not listed
              </button>
              <button
                onClick={clickHandler}
                css={allSetButton(solutionExisting)}
              >
                I'm all set. Let's keep going...
              </button>
            </>
          )}
          {!solutionExisting && (
            <div css={solutionNotExistingContainer}>
              <button css={solutionNotListedButton}>
                We'll add it and be in touch shortly...
              </button>
              <div css={addSolutionContainer}>
                {newSolutionsData.map((solutionData, index) => {
                  return solutionData.status ? (
                    <div key={solutionData.id} css={addSolutionOptionContainer}>
                      <div css={addSolutionOption}>
                        <input
                          css={inputStyle}
                          onChange={({ target: { value } = {} }) =>
                            handleOnChange(value)
                          }
                          disabled={!solutionData.isEditable}
                          placeholder={'Company & Product Name'}
                        />
                        <button
                          className='submitButton'
                          onClick={() => submitNewSolutionHandler(index)}
                        >
                          {solutionData.isEditable ? 'Submit' : 'Edit'}
                        </button>
                      </div>
                      {!solutionData.isEditable && (
                        <span className='submissionStatus'>Submitted!!</span>
                      )}
                    </div>
                  ) : (
                    <div
                      key={solutionData.id}
                      css={addSolutionButton}
                      onClick={() => addButtonHandler(solutionData, index)}
                    >
                      <span className='plusIcon'>+</span>
                    </div>
                  )
                })}
                <button
                  onClick={clickHandler}
                  css={allSetButton(solutionExisting)}
                >
                  I'm all set. Let's keep going...
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
)

export default CrmSection
