import { css } from '@emotion/react'

export const container = showAnalyticsGraphs => css`
  display: flex;
  height: 95px;
  ${showAnalyticsGraphs && 'height: 1000px;'}
`
export const sideDrawer = css`
  width: 115px;
  background: #fff;
  position: relative;
  flex-shrink: 0;
`
export const companyTitleToolbar = css`
  margin: 0.6em;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`
export const companyTitle = css`
  font-weight: bold;
  text-decoration: none;
  padding-top: 36px;
  img {
    width: 80%;
  }
`

export const graph = css`
  img {
    width: 100%;
    height: 100%;
  }
`

export const dropdownContainer = css`
  width: 270px;
  padding: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: #fff;
  height: 44px;
  border: 0.8px solid #ccc;
  border-radius: 4px;
  .dropdownMenu {
    opacity: 0;
    position: absolute;
    top: 44px; // same as your nav height
    left: 0;
    background: #fff;
    border: 0.8px solid #ccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    padding: 10px;
    text-align: center;
    transition: all 1000ms ease;
    width: 270px;
    display: none;
    z-index: 2;
    &.open {
      opacity: 1;
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
`

export const displayValueStyle = css`
  display: flex;
  align-items: center;
  span {
    flex: 1;
    text-align: left;
    display: inline-block;
    padding-left: 10px;
  }

  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    display: inline-block;
    margin-right: 8px;
  }
`

export const caretIconStyle = showAnalyticsGraphs => css`
  cursor: pointer;
  ${showAnalyticsGraphs && 'transform: rotate(180deg);'}
`

export const graphContainer = showAnalyticsGraphs => css`
  margin-top: 25px;
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  height: 0;
  overflow: hidden;
  ${showAnalyticsGraphs && 'height: unset; overflow: unset; '}
`

export const graphCardStyle = css`
  background: #fff;
  flex-shrink: 0;
  padding: 20px;
  border: 0.8px solid #ccc;
  border-radius: 4px;
`

export const labelStyle = css`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
`

export const tabsContainer = css`
  display: flex;
  .tab-item {
    display: inline-block;
    flex-shrink: 0;
    padding: 5px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 12px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    &.active {
      background: #ccc;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
`

export const kpiPacingGraph = css`
  width: 100%;
  flex: 1;
  background: #fff;
  margin: 10px;
  margin-bottom: 0;
  border: 0.8px solid #ccc;
  border-radius: 4px;
  padding: 5px;
`

export const kpiHeaderStyle = css`
  background: #f5f5f5;
  padding: 5px;
  p {
    margin: 0;
  }
  .heading {
    font-size: 14px;
    font-weight: 500;
  }
  .subheading {
    font-size: 12px;
    color: #333;
  }
`
