import { css } from '@emotion/react/macro'

export const formContainer = (modalForm, isEditable) => css`
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  margin-top: 5px;
  width: 231px;
  ${isEditable && 'margin: auto; padding: 0px 6px; width: 223px;'}
  ${modalForm && 'width: 100%;'}
  &.auto {
    height: auto;
  }
  &:hover {
    .toggleHeight {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const audienceTypeSection = (modalForm, isEditable) => css`
  display: flex;
  margin-top: 10px;
  margin-bottom: 23px;
  align-items: center;
  width: 208px;
  ${modalForm && 'width: 224px;'}
  .audienceTypeKey {
    width: 50%;
    color: #333333;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 7px;
  }
  .audienceTypeValueSection {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .audienceTypeValue {
    color: #333333;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 7px;
    text-transform: capitalize;
  }
  ${!isEditable && 'width: 100%; padding: 0px 13px;'}
`

export const overlay = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
`

export const expandModalIconStyle = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  margin-right: 3px;
  cursor: pointer;
  img {
    height: 16px;
    width: 16px;
  }
`

export const inputStyle = css`
  box-sizing: border-box;
  height: 40px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  margin-bottom: 8px;
`

export const segmentNameStyle = ruleGroupIndex => css`
  width: 100%;
  ${ruleGroupIndex > 0 && 'width: 100%;'}
`

export const matchTypeStyle = ruleGroupIndex => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  width: 208px;
  ${ruleGroupIndex > 0 && 'width: 204px;'}
  label {
    white-space: normal;
    color: #333333;
    font-family: BentonSans;
    font-weight: 300;
    font-size: 13px;
    width: 50%;
  }
  &.nonEditable {
    margin-bottom: 26px;
    width: 100%;
    padding: 0px 13px;
  }
`

export const conditionContainer = css``

export const addDeleteIconContainer = css`
  margin-bottom: 9px;
  span {
    cursor: pointer;
    display: inline-block;
    width: 23px;
    height: 23px;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const unionStyle = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 300;
  line-height: 7px;
  width: 100%;
  margin: 22px auto 13px auto;
  text-align: center;
  &.nonEditable {
    margin: 22px auto 22px auto;
  }
`

export const extraRuleContainer = css`
  display: flex;
  width: 206px;
  justify-content: space-between;
  margin-top: 8px;
`

export const extraRuleStyle = css`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
  }
  span {
    margin-right: 3px;
    font-size: 16px;
    font-weight: 700;
  }
`

export const actionButtonContainer = modalForm => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  span {
    box-sizing: border-box;
    height: 38px;
    width: 43px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
  }
  &.nonEditable {
    margin-top: 4px;
    padding: 0px 13px;
  }
  &.segmentAudience {
    margin-top: 4px;
    justify-content: center;
  }
  &.notNewAudience {
    justify-content: center;
  }
  ${
    modalForm &&
    'justify-content: center; span { margin-left: 10px; } button { margin-left: 10px; }'
  }
`

export const buttonStyle = css`
  box-sizing: border-box;
  height: 38px;
  width: 100px;
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  border-radius: 5px;
  cursor: pointer;
  &.saveButton {
    background-color: #18a3e2;
    color: #ffffff;
    margin-left: 6px;
  }
`

export const iconStyle = css`
  height: 23px;
  width: 23px;
  img {
    width: inherit;
    height: inherit;
  }
`

export const nonEditableInputStyle = css`
  display: flex;
  align-items: center;
  div {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
`

export const conditionWrapper = modalForm => {
  return css`
    overflow-y: auto;
    height: 220px;
    ${!modalForm && 'width: 208px;height: 406px;'}
    &.nonEditable {
      height: 200px;
      width: 100%;
      ${modalForm && 'height: 252px;'}
    }
  `
}

export const ruleGroupWrapper = (modalForm, isSingleRule) => css`
  height: 276px;
  ${!modalForm && 'width: 208px; height: 458px;'}

  &.nonEditable {
    height: 268px;
    width: 100%;
    ${modalForm && 'height: 320px;'}
    ${isSingleRule && 'height: 186px;'}
  }
`

const initialIndexStyle = css`
  background: transparent;
  padding: unset;
  border: 0;
`

export const ruleGroupContainer = ruleGroupIndex => css`
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  ${ruleGroupIndex === 0 && initialIndexStyle}
`

export const ruleOption = modalForm => css`
  ${
    modalForm &&
    'display: flex; justify-content: space-around; input { width: 208px; margin-left: 10px;}'
  }
`

export const nonEditableRuleOption = modalForm => css`
  padding: 13px;
  background-color: #F5F5F5;
  color: #333333;
  font-family: "BentonSans";
  font-size: 13px;
  font-weight: 600;
  ${modalForm && 'display: flex; div { margin-left: 10px;}'}
`

export const dropdownStyle = modalForm => css`
  max-width: unset;
  margin-bottom: 8px;
  height: 40px;
  color: #333;
  .dropdown-menu {
    top: 34px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 214px;
  }
  ${modalForm && 'width:230px'}
`
