import { css } from '@emotion/react'

export const productListingContainer = css`
  position: relative;
  &:hover {
    border: 1px solid #18a3e2;
  }
`

export const controlPanelStyle = css`
  position: absolute;
  width: 40px;
  height: 120px;
  background: #fff;
  top: calc(50% - 60px);
  left: -41px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const controlItemStyle = css`
  padding-bottom: 5px;
  padding-top: 9px;
  border-bottom: 0.8px solid #b9b6b6;
  cursor: pointer;
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &:first-of-type {
    padding-top: 0;
  }
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    &.arrowDown {
      transform: rotate(180deg);
    }
  }
`
