import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: '2',
    background: '#fff'
  },
  inputLabel: {
    fontSize: '14px',
    color: '#333333',
    fontWeight: '500'
    // fontfamily: "Benton Sans"
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    padding: '13px 10px',
    margin: theme.spacing(1.5, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5)
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    '& .MuiOutlinedInput-input': {
      height: '38px',
      padding: '0px 14px'
    }
  }
}))

export default useStyles
