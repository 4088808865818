import React, { useMemo } from 'react'

const GridToolTip = props => {
  const data = useMemo(() => {
    return props.api.getDisplayedRowAtIndex(props.rowIndex).data
  }, [])
  return (
    <div className='custom-tooltip'>
      <p>{data[props.column.colId]}</p>
    </div>
  )
}

export default GridToolTip
