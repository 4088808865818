import { css } from '@emotion/react/macro'

export const extraRuleStyle = css`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    padding-left: 10px;
  }
  span {
    margin-right: 3px;
    font-size: 16px;
    font-weight: 700;
  }
`

const initialRuleStyle = css`
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 17px;
  font-size: 14px;
  color: #18a3e2;
  font-weight: 600;
  margin-bottom: 25px;
`
export const extraRuleContainer = ruleGroupIndex => css`
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 14px;
  color: #18a3e2;
  font-weight: 600;
  ${ruleGroupIndex === 0 && initialRuleStyle}
`

export const ruleGroupWrapper = css`
  overflow-y: auto;
  margin-left: 17px;
`
export const conditionWrapper = css`
  overflow: auto;
  padding-bottom: 5px;
`
export const matchTypeStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  font-family: BentonSans;
  justify-content: space-between;
  &.nonEditable {
    margin-bottom: 47px;
    label {
      color: #fff;
      font-size: 16px;
    }
  }
`

const initialIndexStyle = css`
  background: transparent;
  padding: unset;
  border: 0;
`
export const ruleGroupContainer = ruleGroupIndex => css`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-right: 35px;
  flex: 1;
  ${ruleGroupIndex === 0 && initialIndexStyle}
`

export const iconStyle = css`
  height: 20px;
  width: 15px;
  img {
    width: inherit;
    height: inherit;
  }
`

export const conditionRowStyle = index => css`
  display: flex;
  align-items: center;
  ${index > 0 && 'margin-top: 17px;'}
`

export const optionalStyle = css`
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  font-family: BentonSans;
  text-transform: lowercase;
  margin-right: 19px;
`

export const ruleGroupsStyle = css`
  display: flex;
  margin-bottom: 17px;
`

export const deleteGroupStyle = css`
  color: #999999;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`

export const userMatchingContainerStyle = css`
  flex: 1;
  display: flex;
  align-items: center;
`

export const userMatchingDropdownStyle = css`
  width: 70px;
  height: 34px;
  font-family: BentonSans;
  font-size: 14px;
  margin: 0 15px;
  line-height: 1.5;
  display: inline-block;
  min-width: unset;
  .button {
    margin-top: 3px;
    .chevron {
      margin-top: -3px;
    }
  }
  .dropdown-menu {
    position: fixed;
    margin-left: -18px;
    margin-top: 10px;
    top: unset;
  }
`
