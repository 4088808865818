import { css } from '@emotion/react/macro'

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 115px;
  flex-shrink: 0;
  background: #ffffff;
  padding-top: 55px;
`

export const iconStyle = css`
  display: block;
  width: 33px;
  height: 30px;
  margin-bottom: 13px;
  img {
    width: 100%;
    object-fit: contain;
  }
`

export const textStyle = css`
  height: 11px;
  width: 65px;
  color: #333333;
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 27px;
  display: block;
`
