/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'
import pasteIcon from '../../../../../assets/images/dashboard/ads/pasteIcon.png'
import { useSelector } from 'react-redux'

import { addAudienceContainer, addAudienceDummyContainer } from './style'

const AddMail = ({
  index,
  segmentId,
  copiedMailData,
  mail,
  addNewMailFormHandler = () => {}
}) => {
  const { segmentsData } = useSelector(({ segments: { segmentsData } }) => ({
    segmentsData
  }))
  const [displayForm, setDisplayForm] = useState(false)

  const onMouseEnterHandler = () => {
    const currentSegment = segmentsData.find(({ id }) => id == segmentId) || {}

    if (currentSegment && currentSegment.segmentName) {
      setDisplayForm(true)
    }
  }

  const onMouseLeaveHandler = () => {
    setDisplayForm(false)
  }

  const getAddMailForm = mailData => {
    if (mailData)
      return (
        <div
          css={addAudienceContainer(displayForm)}
          onClick={() => {
            const currentSegment =
              segmentsData.find(({ id }) => id == segmentId) || {}

            if (currentSegment && currentSegment.segmentName) {
              addNewMailFormHandler(segmentId, index, copiedMailData)
            }
          }}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {!copiedMailData ? (
            <Icon className='plusIcon'>add</Icon>
          ) : (
            <img src={pasteIcon} alt='paste-icon' width={50} height={45} />
          )}
        </div>
      )
    else {
      return <div css={addAudienceDummyContainer}></div>
    }
  }

  return <div style={{ flex: 1, flexShrink: 0 }}>{getAddMailForm(mail)}</div>
}

export default AddMail
