import { createSlice } from '@reduxjs/toolkit'

import {
  mapDataFromDataSource,
  mapDataForDataSource
} from '../../mapper/dashboard/segments'

import { getAudienceData } from './audienceSlice'

import axiosInstance from '../../axiosConfig'

const initialSegments = {
  segmentsData: [],
  segmentColumnNames: [],
  segmentSupportedConditions: []
}

const segmentsSlice = createSlice({
  name: 'segments',
  initialState: initialSegments,
  reducers: {
    getInitialData(state, action) {
      state.segmentsData = action.payload.segmentsData
      state.segmentColumnNames = action.payload.segmentColumnNames
      state.segmentSupportedConditions =
        action.payload.segmentSupportedConditions
    }
  }
})

export const getSegmentsData = productId => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get(
      `/segments?product_id=${productId}`
    )
    if (status === 200) {
      const mappedData = mapDataFromDataSource(data)
      const [columnNames, suportedConditions] = await Promise.all([
        axiosInstance.get('/onboarding/column_names'),
        axiosInstance.get('/onboarding/supported_conditions')
      ])
      if (columnNames.status === 200 && suportedConditions.status === 200) {
        dispatch(
          segmentsSlice.actions.getInitialData({
            segmentsData: mappedData,
            segmentColumnNames: columnNames.data.column_names,
            segmentSupportedConditions:
              suportedConditions.data.supported_conditions
          })
        )
      } else {
        dispatch(
          segmentsSlice.actions.getInitialData({
            segmentsData: mappedData,
            segmentColumnNames: [],
            segmentSupportedConditions: []
          })
        )
      }
    } else {
      throw new Error('Error obtaining data from source')
    }
  }
}

export const updateSegments = (payload, productId) => {
  const dataToSend = { segments: mapDataForDataSource(payload, productId) }
  return async dispatch => {
    const { data, status } = await axiosInstance.post(
      `/segments/universal?product_id=${productId}`,
      dataToSend
    )
    if (status === 200) {
      dispatch(getSegmentsData(productId))
      dispatch(getAudienceData(productId))
    } else {
      throw new Error('Error obtaining data from source')
    }
  }
}

export const queueActions = segmentsSlice.actions

export default segmentsSlice.reducer
