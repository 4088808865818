import { css } from '@emotion/react'

export const panelTitleStyle = css`
  font-family: Roboto;
  line-height: 32px;
  font-weight: 400;
  color: #555c66;
  font-size: 15px;
  padding-bottom: 18px;
  display: inline-flex;
  align-items: center;
  margin-right: auto;
`

export const backBtnStyle = css`
  transform: rotate(90deg);
  margin-left: auto;
  fill: #555c66;
  margin-right: 16px;
  cursor: pointer;
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  border-radius: 5px;
  &:before {
    border-radius: 4px;
    bottom: -4px;
    content: ' ';
    left: -4px;
    position: absolute;
    right: -4px;
    top: -4px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }

  &:hover {
    background-color: #f5f5f5;
    border: 1px solid transparent;
    outline: none;
  }
`

export const btnStylesField = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-bottom: 10px;
  max-width: 392px;
  height: 54px;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  width: 100%;
`

export const btnTextStyle = css`
  font-family: Roboto;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  font-size: 13px;
  line-height: 22px;
  padding-left: 24px;
`

export const cardHolderStyle = css`
  background-color: #f5f5f5;
  font-size: 12px;
  max-width: 372px;
  min-height: 72px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  width: 100%;
`

export const noticeStyle = css`
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  font-size: 13px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 1rem;
`

export const labelStyle = css`
  font-size: 13px;
  line-height: 22px;
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 4px;
`

export const shapeToggleGrpContainer = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  .toggle-grp-content {
    outline: 1px solid #d8d8d8;
    border-radius: 4px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
  .toggle-btn {
    font-size: 13px;
    line-height: 22px;
    height: 36px;
    padding: 6px;
    border: none;
    fill: #555c66;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #555c66;
    background-color: #fff;
    -webkit-appearance: none;
    cursor: pointer;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      border-radius: 4px;
      font-size: 13px;
      line-height: 22px;
      padding: 0 6px;
      height: auto;
    }
  }
  .toggle-btn.active {
    z-index: 2;
    outline: 1px solid #4eafe2;
    fill: #f5f5f5;
    background-color: #f5f5f5;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #555c66;
    border: none;
    border-radius: 4px;
    transition: background-color 0.1s ease-out, border 0.1s ease-out;
    position: relative;
  }
  .toggle-grp-input {
    font-size: 13px;
    line-height: 22px;
    max-width: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 16px;
    .text-field {
      border: 1px solid #b6b6b6;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      height: 36px;
      padding: 6px;
      min-width: 72px;
      position: relative;
      transition: border-color 0.1s linear;
      &::after {
        position: absolute;
        right: 12px;
        font-family: Roboto;
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #979797;
        content: 'px';
      }
      .textInput {
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: #555c66;
        font-family: Roboto;
        font-size: 13px;
        line-height: 22px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        outline: none;
        width: 100%;
        padding: 0 6px;
      }
    }
  }
`

export const decorationContainer = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  .toggle-grp-content {
    outline: 1px solid #d8d8d8;
    border-radius: 4px;
    display: inline-flex;
    width: 100%;
  }
  .toggle-btn {
    font-size: 13px;
    line-height: 22px;
    height: 36px;
    padding: 6px;
    border: none;
    fill: #555c66;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #555c66;
    background-color: #fff;
    -webkit-appearance: none;
    cursor: pointer;
    flex: 1;
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      border-radius: 4px;
      font-size: 13px;
      line-height: 22px;
      padding: 0 6px;
      height: auto;
    }
  }
  .toggle-btn.active {
    z-index: 2;
    fill: #f5f5f5;
    background-color: #f5f5f5;
    outline: 0px;
    border: 1px solid #4eafe2;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #555c66;
    font-size: 13px;
    line-height: 22px;
    height: 36px;
    padding: 6px;
  }
`

export const colorFieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  padding: 6px;
  position: relative;
  margin-bottom: 24px;
  transition: border-color 0.1s linear;
`

export const colorInputStyle = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
`

export const colorBoxStyle = css`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgb(218, 148, 110);
  border: 1px solid rgb(158, 88, 50);
  box-shadow: none;
  cursor: pointer;
`

export const paddingText = css`
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  margin: 0 0 24px;
  font-size: 13px;
  line-height: 22px;
  display: block;
`

export const paddingsContainer = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 11px;
  align-items: center;
  padding-bottom: 24px;
  .padding {
    grid-column: 1/4;
    max-width: 65px;
    width: 100%;
    margin: 0 auto;
    .padding-input {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 13px;
      line-height: 22px;
      &::after {
        position: absolute;
        right: 8px;
        font-family: Roboto;
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #979797;
        font-size: 13px;
        line-height: 22px;
        content: 'px';
      }
      input {
        position: relative;
        width: 100%;
        border: 1px solid #b6b6b6;
        border-radius: 4px;
        outline: none;
        padding: 7px 8px;
        margin: 0;
        font-family: Roboto;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #555c66;
        font-size: 13px;
        line-height: 22px;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
  .padding-icon {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
`
