/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react'
import Icon from '@material-ui/core/Icon'
import WithMockCellRenderer from '../../../../HOC/WithMockCellRenderer'
import EditIcon from '../../../../assets/images/editIcon.png'
import { MockEditingContext } from '../../../../contexts/mockEditingContext'

import { iconImage, icon } from '../styles'

const ActionRenderer = props => {
  const context = useContext(MockEditingContext)
  const onEditRowClick = () => {
    if (context.mockEditingId !== null) {
      alert('You can only edit one row at a time')
      return
    }
    const nodeId = props.node.id
    context.setMockEditingId(nodeId)
  }

  const onSaveChanges = () => {
    props.commit(props.node.id)
    setTimeout(() => {
      context.setMockEditingId(null)
    }, 0)
  }
  return (
    <>
      {props.isMockEditing ? (
        <Icon css={icon} onClick={() => onSaveChanges()}>
          save
        </Icon>
      ) : (
        <Icon css={icon}>
          <img
            css={iconImage}
            alt='Edit button'
            src={EditIcon}
            onClick={() => onEditRowClick()}
          />
        </Icon>
      )}
    </>
  )
}

export default WithMockCellRenderer(ActionRenderer)
