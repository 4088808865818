import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modalState: null,
  refCampaignType: null,
  adsValidationModalResponse: null
}

const dashboardCommonSlice = createSlice({
  name: 'dashboardCommon',
  initialState,
  reducers: {
    updateModalState (state, action) {
      state.modalState = action.payload
    },
    updateRefCampaignType (state, action) {
      state.refCampaignType = action.payload
    },
    updateAdsValidationModalResponse (state, action) {
      state.adsValidationModalResponse = action.payload
    }
  }
})

export const {
  updateModalState,
  updateRefCampaignType,
  updateAdsValidationModalResponse
} = dashboardCommonSlice.actions

export default dashboardCommonSlice.reducer
