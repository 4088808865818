import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import NavigationButton from '../../ui/NavigationButton'

import cdpNavigationIconList from '../../../constant/cdpNavingationIconList'

import neyoIcon from '../../../assets/images/NeyoIcon.png'

import useStyles from './styles'

const NavigationDrawer = props => {
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <div>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
        anchor='left'
      >
        <div className={classes.companyTitleToolbar}>
          <div>
            <span className={classes.companyTitle}>
              <img alt='neyo-icon' src={neyoIcon} />
            </span>
          </div>
        </div>
        <NavigationButton style={{ marginTop: '6px', marginBottom: '20px' }} />
        <List className={classes.navigationList}>
          {cdpNavigationIconList.map((navigationItem, index) => (
            <ListItem
              button
              component={Link}
              to={navigationItem.href}
              disableRipple
              key={navigationItem.text}
              className={`${classes.navigationButton} ${
                pathname.includes(navigationItem.href)
                  ? classes.navigationButtonActive
                  : undefined
              }`}
            >
              <navigationItem.svgIcon
                className={classes.navigationButtonIcon}
                height={35}
                width={35}
              />
              <ListItemText
                disableTypography={true}
                primary={navigationItem.text}
                className={classes.navigationButtonText}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  )
}

export default NavigationDrawer
