import { css } from '@emotion/react'

export const sideDrawer = expandedView => css`
  width: 115px;
  flex-shrink: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .audiencesIcon {
    img {
      height: 32px;
      width: 32px;
    }
    margin-bottom: 8px;
  }
  .audienceText {
    height: 11px;
    width: 65px;
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 0;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 27px;
  }
  .audiencesPullIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 36px;
    width: 36px;
    border: 0.8px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    border-radius: 4px;
    transition: all 0.5s ease-out;
    img {
      height: 22px;
      width: 20px;
    }
  }
`
