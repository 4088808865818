// import integrationsIcon from '../assets/images/cdpNavigationBar/integrationsIcon.svg';
// import unifiedIdsIcon from '../assets/images/cdpNavigationBar/unifiedIdsIcon.svg';
// import importQueueIcon from '../assets/images/cdpNavigationBar/importQueueIcon.svg';
// import queueArchiveIcon from '../assets/images/cdpNavigationBar/queueArchiveIcon.svg';

import { ReactComponent as integrationsIcon } from '../assets/images/cdpNavigationBar/integrationsIcon.svg'
import { ReactComponent as unifiedIdsIcon } from '../assets/images/cdpNavigationBar/unifiedIdsIcon.svg'
import { ReactComponent as importQueueIcon } from '../assets/images/cdpNavigationBar/importQueueIcon.svg'
import { ReactComponent as queueArchiveIcon } from '../assets/images/cdpNavigationBar/queueArchiveIcon.svg'
import { ReactComponent as RecordsIcon } from '../assets/images/cdpNavigationBar/records.svg'

const navigationIconList = Object.freeze([
  {
    text: 'Records',
    svgIcon: RecordsIcon,
    altText: 'records-icon',
    tabValue: 0,
    href: '/cdp/view-contacts'
  },
  {
    text: 'Unified IDs',
    svgIcon: unifiedIdsIcon,
    altText: 'unified-icon-icon',
    tabValue: 1,
    href: '/cdp/unifiedIds'
  },
  {
    text: 'Import Queue',
    svgIcon: importQueueIcon,
    altText: 'import-queue-icon',
    tabValue: 2,
    href: '/cdp/importQueue'
  },
  {
    text: 'Queue Archive',
    svgIcon: queueArchiveIcon,
    altText: 'queue-archive-icon',
    tabValue: 3,
    href: '/cdp/queueArchive'
  },
  {
    text: 'Connectors',
    svgIcon: integrationsIcon,
    altText: 'integration-icon',
    tabValue: 4,
    href: '/cdp/connectors'
  }
])

export default navigationIconList
