import { css } from '@emotion/react'

export const buttonEditorContainer = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 24px;
`

export const titleStyle = css`
  color: #555c66;
  font-size: 15px;
  padding-bottom: 24px;
`

export const fieldBlockStyle = css`
  display: inline-flex;
  flex-direction: column;
  min-width: 214px;
  padding-bottom: 24px;
  width: 100%;
`

export const textFieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  transition: border-color 0.1s linear;
  height: 36px;
  padding: 6px;
`

export const inputStyle = css`
  padding: 0 6px;
  font-size: 13px;
  line-height: 22px;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  outline: none;
  width: 100%;
`

export const labelStyle = css`
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0 0 4px;
  font-size: 13px;
  line-height: 22px;
`

export const noticeTextStyle = css`
  display: flex;
  padding: 16px 24px;
  margin-bottom: 24px;
  background-color: #f5f5f5;
  .icon {
    width: 24px;
    height: 24px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  .notice-text {
    padding-left: 16px;
    font-family: Roboto;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #555c66;
    font-size: 13px;
    line-height: 22px;
  }
  .notice-link {
    ont-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #4eafe2;
    font-size: 13px;
    line-height: 22px;
  }
`

export const btnGroupStyle = css`
  display: inline-flex;
`

export const alignmentBtnStyle = isActive => css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  width: 100%;
  border-radius: 4px;
  outline: none;
  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
  &:before {
    border-radius: 6px;
    bottom: -6px;
    content: ' ';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }
  ${isActive && 'background-color: #4eafe2; color: #fff;'}
`

export const alignmentBtnTextStyle = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  line-height: 25px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
  height: auto;
  &:after {
    border-radius: 4px;
    bottom: 0;
    content: ' ';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    line-height: 22px;
  }
`
