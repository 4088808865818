import { css } from '@emotion/react'

export const preHeaderContainer = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 24px;
`

export const titleStyle = css`
  color: #555c66;
  cursor: default;
  fill: #555c66;
  font-size: 15px;
  margin-bottom: 24px;
`

export const labelStyle = css`
  font-size: 13px;
  line-height: 22px;
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 4px;
  span:not(.title) {
    display: block;
    font-family: Roboto;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #979797;
    font-size: 13px;
    line-height: 22px;
  }
`

export const inputStyle = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
`

export const fieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  padding: 6px;
  position: relative;
  transition: border-color 0.1s linear;
`

export const textEditorStyle = css`
  padding: unset;
  overflow-y: unset;
`

export const colorBoxStyle = css`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgb(218, 148, 110);
  border: 1px solid rgb(158, 88, 50);
  box-shadow: none;
  cursor: pointer;
`

export const btnGroupStyle = css`
  display: inline-flex;
  width: 100%;
`

export const typeBtnStyle = isActive => css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  width: 100%;
  border-radius: 4px;
  outline: none;
  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
  &:before {
    border-radius: 6px;
    bottom: -6px;
    content: ' ';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }
  ${isActive && 'background-color: #4eafe2; color: #fff;'}
`

export const typeBtnTextStyle = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  line-height: 25px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
  height: auto;
  &:after {
    border-radius: 4px;
    bottom: 0;
    content: ' ';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    line-height: 22px;
  }
`

export const toggleContent = css`
  max-width: 100%;
  &:not(.color-container) {
    border-bottom: 1px solid #e7e7e7;
  }
  .toggle-arrow-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    cursor: pointer;
    .toggle-title {
      font-family: Roboto;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #555c66;
      font-size: 13px;
      line-height: 22px;
    }
    .arrow {
      width: 10px;
      height: 10px;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      transition: transform 0.1s ease-out;
      &.is-open {
        transform: rotate(90deg);
      }
    }
  }
`

export const paddingText = css`
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #555c66;
  margin: 0 0 24px;
  font-size: 13px;
  line-height: 22px;
  display: block;
`

export const paddingsContainer = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 11px;
  align-items: center;
  padding-bottom: 24px;
  .padding {
    grid-column: 1/4;
    max-width: 65px;
    width: 100%;
    margin: 0 auto;
    .padding-input {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 13px;
      line-height: 22px;
      &::after {
        position: absolute;
        right: 8px;
        font-family: Roboto;
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #979797;
        font-size: 13px;
        line-height: 22px;
        content: 'px';
      }
      input {
        position: relative;
        width: 100%;
        border: 1px solid #b6b6b6;
        border-radius: 4px;
        outline: none;
        padding: 7px 8px;
        margin: 0;
        font-family: Roboto;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #555c66;
        font-size: 13px;
        line-height: 22px;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
  .padding-icon {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
`

export const imagePlaceholderStyle = css`
  display: flex;
  align-items: center;
  width: 60px;
  height: 40px;
  overflow: hidden;
  margin-right: 16px;
  img {
    max-width: 60px;
    max-height: 40px;
  }
`

export const selectFileBtnStyle = css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  color: #555c66;
  font-family: Roboto;
  position: relative;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  fill: #555c66;
  border-radius: 4px;
  cursor: pointer;
  &:before {
    border-radius: 6px;
    bottom: -6px;
    content: ' ';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
    transition: background-color 0.1s ease-out;
    z-index: -1;
  }
  .text {
    padding: 0 6px;
    height: auto;
  }
  &:hover {
    background-color: #fafafa;
    border: 1px solid #d8d8d8;
    outline: none;
  }
`

export const imageUploadBlockStyle = css`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  [hidden] {
    display: none !important;
  }
`
