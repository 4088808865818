/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import SideDrawer from '../SideDrawer'
import SegmentForm from './SegmentForm'

import {
  segmentSection,
  segmentContent,
  segmentForms,
  actionButton
} from './styles'

import { updateSegments } from '../../../store/onboardingDashboard'

import segmentMocks from '../../../constant/onboardingDashboardSegmentsList'

const SegmentSection = React.forwardRef(
  (
    {
      businessType,
      selectedSection,
      scrollHandler = () => {},
      setSelectedSection = () => {}
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const segments = businessType
      ? segmentMocks[businessType]
      : segmentMocks['B2B']
    const [segmentData, setSegmentData] = useState({
      ...JSON.parse(JSON.stringify(segments))
    })

    const updateData = (index, formValue) => {
      setSegmentData(prevSegments => {
        const updatedSegments = { ...prevSegments } // Create a shallow copy of the object
        updatedSegments[index] = { ...formValue, isEditable: false }
        return updatedSegments
      })
    }

    const clickHandler = () => {
      ref.current.setAttribute('data-clicked', 'true')
      setSelectedSection('takeToDashboard')
      scrollHandler('takeToDashboard')
      dispatch(updateSegments(segmentData))
    }

    return (
      <section id='segment' ref={ref} data-clicked={false} css={segmentSection}>
        <SideDrawer section={'segment'} selectedSection={selectedSection} />
        <div css={segmentContent} data-selected={selectedSection === 'segment'}>
          {/* <span className='stageDescription'>
            The final step! Now it's time to build each segment using the data
            you've imported.
          </span> */}
          <span className='stageDescription'>
            The final step! Edit and save your segment criteria to customize
            each phase of your adoption funnel...
          </span>
          <div css={segmentForms}>
            {businessType &&
              Object.keys(segmentData).map((segmentIndex, index) => {
                return (
                  <SegmentForm
                    data={segmentData[segmentIndex]}
                    key={segmentData[segmentIndex].id}
                    index={segmentIndex}
                    fullHeight={true}
                    updateData={updateData}
                  />
                )
              })}
          </div>
          <button css={actionButton} onClick={clickHandler}>
            I'm all set. Let's keep going.
          </button>
        </div>
      </section>
    )
  }
)

export default SegmentSection
