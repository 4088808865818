/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { channelSourceList } from '../../../../constant/ads'
import SwitchController from '../../../ui/SwitchController'

import AddAd from '../Forms/AddAd'
import BuildAdd from '../Forms/BuildAdd'
import AdDetails from '../Forms/AdDetails'

import AdIcon from '../../../../assets/images/dashboard/ad.png'
import minusIcon from '../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../assets/images/dashboard/audience/plusIcon.png'
import { useSelector, useDispatch } from 'react-redux'
import { updateAdCounter } from '../../../../store/dashboard/adsSlice'
import {
  container,
  sideDrawer,
  textStyle,
  iconStyle,
  campaignContainerStyle,
  campaignHeaderStyle,
  previewChannelContainer,
  previewChannelStyle,
  campaignSummaryStyle,
  campaignTitleStyle,
  campaignTargetStyle,
  saveCampaignNameButton,
  inputStyle,
  createAddContainer,
  adRowStyle,
  dummyAdsForm
} from './style'

const getAdDefaultDetails = ({
  audienceCollection,
  segmentId,
  adDetails = {},
  campaignDetails = {},
  copyAction = false,
  adCounter = 1,
  rowIndex
}) => {
  let adMockData = {
    id: uuidv4(),
    source: null,
    url: '',
    newAd: true,
    isEditable: false,
    createdFromSegments: [segmentId],
    status: 'Under Review',
    adName: `${campaignDetails.campaignName}-ad-${adCounter}`,
    objective: '',
    text: campaignDetails.text,
    headline: campaignDetails.headline,
    newsFeedLinkDescription: campaignDetails.newsFeedLinkDescription,
    displayLink: campaignDetails.displayLink,
    callToAction: campaignDetails.callToAction,
    rowIndex
  }

  if (copyAction) {
    adMockData = {
      ...adDetails,
      id: uuidv4(),
      rowIndex,
      createdFromSegments: [segmentId]
    }
  }
  adMockData.audienceData =
    audienceCollection[segmentId] &&
    audienceCollection[segmentId]
      .filter(audience => {
        return audience ? true : false
      })
      .map(audience => {
        return { ...audience, isSelected: false }
      })
  return adMockData
}

const getSelectedChannels = campaignData => {
  const uniqueChannels = {}
  channelSourceList.forEach(channelObj => {
    uniqueChannels[channelObj.key] = false
  })
  Object.keys(campaignData).forEach(segmentId => {
    campaignData[segmentId].forEach(adData => {
      if (adData && adData.source) {
        uniqueChannels[adData.source] = true
      }
    })
  })
  const selectedChannels = Object.keys(uniqueChannels).filter(
    channel => uniqueChannels[channel]
  )
  const dataForCampaignHeader = []
  for (let counter = 0; counter < 4; counter++) {
    if (selectedChannels[counter])
      dataForCampaignHeader.push(selectedChannels[counter])
    else dataForCampaignHeader.push(null)
  }
  return dataForCampaignHeader
}

const getChannelSource = channel => {
  let channelSource = channelSourceList.find(
    channelSource => channelSource.key === channel
  )
  return (
    <img
      className={channelSource.className}
      alt={channelSource.alt}
      src={channelSource.src}
    ></img>
  )
}

const AdCampaign = ({
  audienceCollection,
  adCampaignData,
  segmentLinkedMapper,
  campaignDetails,
  editingAdsCampaignData,
  setCampaignDetails = () => {},
  setAdCampaignData = () => {},
  adsCampaignValidity
}) => {
  const [saveCampaignName, setSaveCampaignName] = useState(
    editingAdsCampaignData ? true : false
  )
  const [showAdCampaignDetails, setShowAdCampaignDetails] = useState(
    editingAdsCampaignData ? true : false
  )
  const [expandedView, setExpandedView] = useState(
    editingAdsCampaignData ? true : false
  )
  const [copiedAdData, setCopiedAdData] = useState(null)
  const [copyIconClicked, setCopyIconClicked] = useState(false)
  const dispatch = useDispatch()
  const { adCounter, audiences } = useSelector(
    ({ ads: { adCounter }, audiences }) => ({ adCounter, audiences })
  )

  const getMaxAdListLength = adCampaignData => {
    let max = 0
    Object.keys(adCampaignData).forEach(segmentId => {
      if (adCampaignData[segmentId] && adCampaignData[segmentId].length > max) {
        max = adCampaignData[segmentId].length
      }
    })
    let maxAdsArr = []
    for (let i = 0; i < max; i++) {
      maxAdsArr.push(i)
    }
    return maxAdsArr
  }

  const toggleHeightHandler = () => {
    setExpandedView(expandedView => !expandedView)
  }

  const addNewAdFormHandler = (segmentId, index, copiedAdData) => {
    const newAdCampaignData = JSON.parse(JSON.stringify(adCampaignData))
    newAdCampaignData[segmentId][index] = getAdDefaultDetails({
      audienceCollection,
      segmentId,
      adDetails: copiedAdData,
      campaignDetails,
      copyAction: copyIconClicked,
      adCounter,
      rowIndex: index
    })
    const increasedCounter = adCounter + 1
    dispatch(updateAdCounter(increasedCounter))
    let count = 0

    for (let i = index + 1; i < newAdCampaignData[segmentId].length; i++) {
      if (!newAdCampaignData[segmentId][i]) {
        newAdCampaignData[segmentId][i] = {}
        count += 1
        break
      } else if (
        newAdCampaignData[segmentId][i] &&
        Object.keys(newAdCampaignData[segmentId][i]).length === 0
      ) {
        count += 1
        break
      }
    }
    if (!count) newAdCampaignData[segmentId].push({})
    if (!showAdCampaignDetails) setShowAdCampaignDetails(true)
    setAdCampaignData(newAdCampaignData)
    setCopiedAdData(null)
    setCopyIconClicked(false)
  }

  const updateAdDetails = (segmentId, adIndex, updatedDataSet) => {
    const newAdCampaignData = JSON.parse(JSON.stringify(adCampaignData))
    const adPosition = newAdCampaignData[segmentId].findIndex(
      adData => (adData || {}).rowIndex === adIndex
    )

    if (
      newAdCampaignData[segmentId][adPosition].createdFromSegments &&
      newAdCampaignData[segmentId][adPosition].createdFromSegments.length > 1
    ) {
      ;(
        newAdCampaignData[segmentId][adPosition].createdFromSegments || []
      ).forEach(id => {
        newAdCampaignData[id][adPosition] = {
          ...newAdCampaignData[id][adPosition],
          ...updatedDataSet
        }
      })
    } else {
      newAdCampaignData[segmentId][adPosition] = {
        ...newAdCampaignData[segmentId][adPosition],
        ...updatedDataSet
      }
    }
    if (updatedDataSet.source && campaignDetails.spreadBudgetAcrossChannels) {
      const selectedChannels = getSelectedChannels(newAdCampaignData)
      let channelBudgetBreakdown = []
      const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
      channelBudgetBreakdown = selectedChannels.filter(channel => channel)
      const channelBudget = (
        campaignDetails.budget / channelBudgetBreakdown.length
      ).toFixed(2)
      channelBudgetBreakdown.forEach(channel => {
        prevCampaignDetails[`${channel}Budget`] = channelBudget
      })
      setCampaignDetails(prevCampaignDetails)
    }

    setAdCampaignData(newAdCampaignData)
  }

  const handleAdExtensionMinimize = (
    adCampaignData,
    segmentId,
    segmentIndex,
    adIndex,
    type
  ) => {
    const prevAdCampaignData = JSON.parse(JSON.stringify(adCampaignData))
    const adPosition = prevAdCampaignData[segmentId].findIndex(
      adData => (adData || {}).rowIndex === adIndex
    )
    const { segmentAudienceData, audienceDataFromApi } = audiences || {}

    switch (type) {
      case 'rightExtension': {
        const createdFromSegmentsArr =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments
        const lastSegmentId =
          createdFromSegmentsArr[createdFromSegmentsArr.length - 1]
        const nextSegmentId = segmentLinkedMapper[lastSegmentId].next
        const newCreatedFromSegments = [
          ...prevAdCampaignData[segmentId][adPosition].createdFromSegments,
          nextSegmentId
        ]
        let updatedAudience = []
        newCreatedFromSegments.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        createdFromSegmentsArr.forEach(id => {
          const adPosIndex = prevAdCampaignData[id].findIndex(
            adData => (adData || {}).rowIndex === adIndex
          )
          prevAdCampaignData[id][adPosIndex].createdFromSegments =
            newCreatedFromSegments
          prevAdCampaignData[id][adPosIndex].audienceData = updatedAudience
        })
        if (!prevAdCampaignData[nextSegmentId][adPosition]) {
          prevAdCampaignData[nextSegmentId][adPosition] = JSON.parse(
            JSON.stringify(prevAdCampaignData[segmentId][adPosition])
          )
        } else {
          prevAdCampaignData[nextSegmentId].splice(
            adPosition,
            0,
            JSON.parse(
              JSON.stringify(prevAdCampaignData[segmentId][adPosition])
            )
          )
        }
        setAdCampaignData(JSON.parse(JSON.stringify(prevAdCampaignData)))
        break
      }
      case 'rightMinimize': {
        const lastSegmentId =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments.pop()
        const newCreatedFromSegmentsArr =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments
        if (
          prevAdCampaignData[lastSegmentId][adPosition + 1] &&
          typeof prevAdCampaignData[lastSegmentId][adPosition + 1] === 'object'
        ) {
          if (
            Object.keys(prevAdCampaignData[lastSegmentId][adPosition + 1])
              .length === 0
          ) {
            prevAdCampaignData[lastSegmentId][adPosition + 1] = null
          }
          prevAdCampaignData[lastSegmentId][adIndex] = {}
        } else {
          prevAdCampaignData[lastSegmentId][adIndex] = null
        }
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        newCreatedFromSegmentsArr.forEach(id => {
          const adPosIndex = prevAdCampaignData[id].findIndex(
            adData => (adData || {}).rowIndex === adIndex
          )
          prevAdCampaignData[id][adPosIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevAdCampaignData[id][adPosIndex].audienceData = updatedAudience
        })

        setAdCampaignData(JSON.parse(JSON.stringify(prevAdCampaignData)))
        break
      }
      case 'leftExtension': {
        const createdFromSegmentsArr =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments
        const firstSegmentId = createdFromSegmentsArr[0]
        const prevSegmentId = segmentLinkedMapper[firstSegmentId].prev
        const newCreatedFromSegmentsArr = [
          prevSegmentId,
          ...prevAdCampaignData[segmentId][adPosition].createdFromSegments
        ]
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        createdFromSegmentsArr.forEach(id => {
          const adPosIndex = prevAdCampaignData[id].findIndex(
            adData => (adData || {}).rowIndex === adIndex
          )
          prevAdCampaignData[id][adPosIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevAdCampaignData[id][adPosIndex].audienceData = updatedAudience
        })
        if (!prevAdCampaignData[prevSegmentId][adPosition]) {
          prevAdCampaignData[prevSegmentId][adPosition] = JSON.parse(
            JSON.stringify(prevAdCampaignData[segmentId][adPosition])
          )
        } else {
          prevAdCampaignData[prevSegmentId].splice(
            adPosition,
            0,
            JSON.parse(
              JSON.stringify(prevAdCampaignData[segmentId][adPosition])
            )
          )
        }
        setAdCampaignData(prevAdCampaignData)
        break
      }
      case 'leftMinimize': {
        const firstSegmentId =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments.shift()
        const newCreatedFromSegmentsArr =
          prevAdCampaignData[segmentId][adPosition].createdFromSegments
        let updatedAudience = []
        newCreatedFromSegmentsArr.forEach(id => {
          const segmentAudience = segmentAudienceData[id]
          const apiAudience = audienceDataFromApi[id]

          updatedAudience.push(segmentAudience, ...apiAudience)
        })
        if (
          prevAdCampaignData[firstSegmentId][adPosition + 1] &&
          typeof prevAdCampaignData[firstSegmentId][adPosition + 1] === 'object'
        ) {
          if (
            Object.keys(prevAdCampaignData[firstSegmentId][adPosition + 1])
              .length === 0
          ) {
            prevAdCampaignData[firstSegmentId][adPosition + 1] = {}
          }
          prevAdCampaignData[firstSegmentId][adPosition] = {}
        } else {
          prevAdCampaignData[firstSegmentId][adPosition] = {}
        }

        newCreatedFromSegmentsArr.forEach(id => {
          const adPosIndex = prevAdCampaignData[id].findIndex(
            adData => (adData || {}).rowIndex === adIndex
          )
          prevAdCampaignData[id][adPosIndex].createdFromSegments =
            newCreatedFromSegmentsArr
          prevAdCampaignData[id][adPosIndex].audienceData = updatedAudience
        })

        setAdCampaignData(prevAdCampaignData)
        break
      }
      default:
        break
    }
  }
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  const handleAdDelete = (segmentId, adIndex) => {
    const newAdCampaignData = JSON.parse(JSON.stringify(adCampaignData))
    const adPosition = newAdCampaignData[segmentId].findIndex(
      adData => (adData || {}).rowIndex === adIndex
    )
    newAdCampaignData[segmentId][adPosition].createdFromSegments.forEach(
      segmentIdDetail => {
        const adPositionIndex = newAdCampaignData[segmentIdDetail].findIndex(
          adData => (adData || {}).rowIndex === adIndex
        )
        newAdCampaignData[segmentIdDetail][adPositionIndex] = {}
      }
    )
    setAdCampaignData(newAdCampaignData)
  }

  const checkIsAdValid = (adId, segmentId) => {
    const fields =
      (adsCampaignValidity && (adsCampaignValidity[segmentId] || {})[adId]) ||
      {}
    return !Object.keys(fields).filter(key => !fields[key]).length
  }

  const getAdValidityFields = () => {
    if (!adsCampaignValidity) return []
    let fields = []
    Object.keys(adsCampaignValidity).forEach(segmentId => {
      const allAdsInsideSegment = adsCampaignValidity[segmentId] || {}
      Object.keys(allAdsInsideSegment).forEach(adName => {
        const fieldsObj = allAdsInsideSegment[adName] || {}
        fields = [
          ...fields,
          ...(Object.keys(fieldsObj).filter(key => !fieldsObj[key]) || [])
        ]
      })
    })
    return fields.filter(onlyUnique)
  }

  return (
    <div css={container}>
      <div css={sideDrawer}></div>
      <div css={campaignContainerStyle}>
        <div css={campaignHeaderStyle(expandedView, saveCampaignName)}>
          <span className='toggleHeight' onClick={toggleHeightHandler}>
            <img alt='expandIcon' src={expandedView ? minusIcon : plusIcon} />
          </span>
          {getSelectedChannels(adCampaignData).map((channel, index) => (
            <div css={previewChannelContainer(channel)} key={index}>
              <div css={previewChannelStyle}>
                {channel && getChannelSource(channel)}
              </div>
              {channel && <SwitchController style={{ top: '57px' }} />}
            </div>
          ))}
          <div css={campaignSummaryStyle}>
            <div css={campaignTitleStyle}>Campaign Name</div>
            <div css={campaignTargetStyle}>
              <input
                type='text'
                css={inputStyle}
                disabled={saveCampaignName}
                className='campaignName'
                value={campaignDetails.campaignName}
                onChange={({ target: { value } = {} }) =>
                  setCampaignDetails(prev => {
                    return { ...prev, campaignName: value }
                  })
                }
              />
              <button
                css={saveCampaignNameButton(campaignDetails.campaignName)}
                disabled={!campaignDetails.campaignName}
                onClick={() => {
                  setSaveCampaignName(prev => !prev)
                  toggleHeightHandler()
                }}
              >
                {saveCampaignName ? 'Edit' : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <div css={createAddContainer(saveCampaignName, expandedView)}>
          {getMaxAdListLength(adCampaignData).map(index => {
            return (
              <div key={index} css={adRowStyle}>
                {Object.keys(adCampaignData).map((segmentId, segmentIndex) => {
                  const adObj =
                    (adCampaignData[segmentId] || []).find(
                      adData => (adData || {}).rowIndex === index
                    ) || {}
                  if (Object.keys(adObj).length) {
                    if (adObj.createdFromSegments[0] === segmentId) {
                      const { adName } = adCampaignData[segmentId][index] || {}
                      return (
                        <BuildAdd
                          key={`${segmentId}-${index}`}
                          segmentId={segmentId}
                          adIndex={index}
                          isAdDataValid={checkIsAdValid(adName, segmentId)}
                          segmentIndex={segmentIndex}
                          copyIconClicked={copyIconClicked}
                          campaignDetails={campaignDetails}
                          setCopyIconClicked={setCopyIconClicked}
                          setCopiedAdData={setCopiedAdData}
                          adData={adObj}
                          segmentLinkedMapper={segmentLinkedMapper}
                          adCampaignData={adCampaignData}
                          updateAdDetails={updateAdDetails}
                          handleAdDelete={handleAdDelete}
                          handleAdExtensionMinimize={handleAdExtensionMinimize}
                        />
                      )
                    } else {
                      return null
                    }
                  } else if (adCampaignData[segmentId][index]) {
                    return (
                      <AddAd
                        key={`${segmentId}-${index}`}
                        index={index}
                        segmentIndex={segmentIndex}
                        segmentId={segmentId}
                        copiedAdData={copiedAdData}
                        ad={adCampaignData[segmentId][index]}
                        addNewAdFormHandler={addNewAdFormHandler}
                      />
                    )
                  } else {
                    return (
                      <div style={{ flex: '1 0 0%' }}>
                        <div
                          css={dummyAdsForm}
                          key={`${segmentId}-${index}`}
                        ></div>
                      </div>
                    )
                  }
                })}
              </div>
            )
          })}
        </div>
        <AdDetails
          validityFields={getAdValidityFields()}
          showAdCampaignDetails={showAdCampaignDetails}
          selectedChannels={getSelectedChannels(adCampaignData)}
          campaignDetails={campaignDetails}
          setCampaignDetails={setCampaignDetails}
        />
      </div>
    </div>
  )
}

export default AdCampaign
