import React from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body // Render the dropdown menu in the body
  )
}

export default Portal
