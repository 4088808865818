/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'

import pasteIcon from '../../../../../assets/images/dashboard/ads/pasteIcon.png'

import { addAudienceContainer, addAudienceDummyContainer } from './style'

const AddAd = ({
  index,
  segmentIndex,
  segmentId,
  copiedAdData,
  ad,
  addNewAdFormHandler = () => {}
}) => {
  const [displayForm, setDisplayForm] = useState(
    segmentIndex === 0 && index === 0 ? true : false
  )

  const onMouseEnterHandler = () => {
    setDisplayForm(true)
  }

  const onMouseLeaveHandler = () => {
    setDisplayForm(false)
  }

  const getAddAdForm = adData => {
    if (adData)
      return (
        <div
          css={addAudienceContainer(displayForm)}
          onClick={() => addNewAdFormHandler(segmentId, index, copiedAdData)}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {!copiedAdData ? (
            <Icon className='plusIcon'>add</Icon>
          ) : (
            <img src={pasteIcon} alt='paste-icon' width={50} height={45} />
          )}
        </div>
      )
    else {
      return <div css={addAudienceDummyContainer}></div>
    }
  }

  return <div style={{ flex: 1, flexShrink: 0 }}>{getAddAdForm(ad)}</div>
}

export default AddAd
