import { css } from '@emotion/react'

export const menuEditorContainer = css`
  padding: 24px;
`

export const titleStyle = css`
  font-size: 15px;
  color: #555c66;
  cursor: default;
  fill: #555c66;
  display: block;
  margin-bottom: 24px;
`

export const buttonContainer = css`
  display: flex;
  margin-bottom: 24px;
`

export const btnStyle = css`
  font-size: 13px;
  line-height: 22px;
  height: 36px;
  padding: 6px;
  width: 50%;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  fill: #555c66;
  font-weight: 400;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.1s ease-out, border 0.1s ease-out;
  &:hover {
    background-color: #fafafa;
    border: 1px solid #d8d8d8;
    outline: none;
  }
  &:first-of-type {
    margin-right: 8px;
  }
`

export const labelStyle = css`
  font-size: 13px;
  line-height: 22px;
  color: #555c66;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 4px;
`

export const fieldStyle = css`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 36px;
  padding: 6px;
  position: relative;
  transition: border-color 0.1s linear;
`

export const inputStyle = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #555c66;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  padding: 0 6px;
`

export const colorBoxStyle = css`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgb(218, 148, 110);
  border: 1px solid rgb(158, 88, 50);
  box-shadow: none;
  cursor: pointer;
`
