/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import Modal from '../../Modal'
import { TABLE_TYPE } from '../../../constant/analytics'

import { grdStyle } from './summaryTableStyle'

const SummaryTable = ({ data, columns, type, setHoveredCell }) => {
  const [modalData, setModalData] = useState(null)
  const containerStyle = useMemo(
    () => ({
      width: '100%',
      height: '121px'
    }),
    []
  )
  const [gridApi, setGridApi] = useState()

  const gridRef = useRef()
  const [gridData, setGridData] = useState([])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const onGridReady = tableEvent => {
    setGridApi(tableEvent.api)
  }

  useEffect(() => {
    setGridData(data)
  }, [data])

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      cellStyle: {
        outline: 'none',
        fontWeight: '300',
        fontSize: '12px',
        color: 'rgb(0 0 0 / 60%)',
        lineHeight: '18px'
      }
    }
  }, [])

  const getRowStyle = tableEvent => {
    let rowStyle = {}
    if (tableEvent.node.rowIndex % 2 === 0) {
      rowStyle = { ...rowStyle, background: '#f5f5f5' }
    }
    return rowStyle
  }

  const handleCellMouseOver = tableEvent => {
    const { event } = tableEvent || {}

    if (
      tableEvent?.colDef?.field === 'timeframe' ||
      tableEvent.colDef?.field === 'channel'
    )
      return

    setHoveredCell(tableEvent.rowIndex + '-' + tableEvent.colDef.field)

    if (type === TABLE_TYPE.BY_CHANNEL || type === TABLE_TYPE.BY_TIMEFRAME) {
      let dataToShow = {}
      const { value, data, colDef: { headerName } = {} } = tableEvent || {}
      dataToShow = {
        column: headerName,
        value: value,
        clientX: event.clientX,
        clientY: event.clientY,
        data
      }
      setModalData(dataToShow)
    }
  }

  const handleCellMouseOut = event => {
    setModalData(null)
  }

  const gridOptions = {
    onCellMouseOver: handleCellMouseOver,
    onCellMouseOut: handleCellMouseOut
  }

  return (
    <div style={containerStyle}>
      <div
        className='ag-theme-alpine'
        css={grdStyle()}
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          rowHeight={25}
          headerHeight={20}
          style={{ height: '100%;' }}
          rowData={gridData}
          gridOptions={gridOptions}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          getRowStyle={getRowStyle}
          suppressRowClickSelection='true'
          rowMultiSelectWithClick='false'
        ></AgGridReact>
      </div>
      {modalData && <Modal tableData={modalData} tableType={type} />}
    </div>
  )
}

export default SummaryTable
