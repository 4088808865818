/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'

import SideDrawer from '../MailerEditor/SideDrawer'
import { useDispatch, useSelector } from 'react-redux'
import {
  containerStyle,
  campaignSummaryStyle,
  headerStyle,
  mailsContainer,
  previewStyle,
  campaignNameContainer,
  contentContainer,
  mailCardStyle,
  emptyCardView,
  mailShortInfo,
  mailCardPreview,
  emailFullDetails,
  controlRow,
  mailRowStyle,
  editDropdownStyle,
  dropdownContainer,
  drowndownBtnStyle
} from './style'
import minusIcon from '../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../assets/images/dashboard/audience/plusIcon.png'
import trashIcon from '../../../assets/images/trash-icon.png'
import editIcon from '../../../assets/images/editIcon.png'
import DropdownIcon from '../../../assets/images/dropdown-caret.png'
import Portal from '../../../customHooks/portal'
import { processEmails } from '../../../utils/mailerUtils'
import SwitchController from '../../ui/SwitchController'

import {
  getCampaigns,
  postEmailCampaignHandler,
  deleteCampaignHandler,
  updateCurrentEditedSegmentAndAudience
} from '../../../store/dashboard/mailerSlice'
import Loader from '../../ui/Loader'

const MailCard = props => {
  let {
    data,
    data: {
      emailName,
      templateString,
      emailType,
      subjectLine,
      sender,
      backupSender,
      additionalAudienceFilter = [],
      createdFromSegments,
      sendDate
    },
    campaignId,
    editMailHandler,
    isDraft
  } = props
  const [expandedView, setExpandedView] = useState(false)
  return (
    <div css={mailCardStyle(expandedView, createdFromSegments.length)}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: '0 10px'
        }}
      >
        <span
          className='toggleHeight'
          onClick={() => setExpandedView(expandedView => !expandedView)}
        >
          <img
            alt='expandIcon'
            src={expandedView ? minusIcon : plusIcon}
            height={24}
            width={24}
          />
        </span>
        <div css={mailShortInfo}>
          <div css={[previewStyle, mailCardPreview]}>
            <div
              style={{ transform: 'scale(0.1, 0.05' }}
              dangerouslySetInnerHTML={{
                __html: decodeURIComponent(window.atob(templateString))
              }}
            />
            <SwitchController state={isDraft ? 'stop' : 'active'} />
          </div>
          <div className='emailDetail'>
            <div className='emailName'>{emailName}</div>
            <div className='emailType'>{emailType}</div>
          </div>
        </div>
      </div>
      <div css={emailFullDetails(expandedView)}>
        <div css={controlRow}>
          <button
            className='edit'
            onClick={() => editMailHandler(data, campaignId)}
          >
            <img src={editIcon} />
          </button>
          <Link
            to='/cdp/view-contacts'
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <button className='view-contact'>View Contacts</button>
          </Link>
        </div>
        <div className='row'>
          <div className='label'>
            <span>
              Email Send Date<sup>*</sup>
            </span>
            <span>
              Required<sup>*</sup>
            </span>
          </div>
          <div className='value'>{sendDate}</div>
        </div>
        <div className='row'>
          <div className='label'>
            <span>
              Email Send Time<sup>*</sup>
            </span>
          </div>
          <div className='value'>11:00 AM PST</div>
        </div>
        <div className='row'>
          <div className='label'>
            <span>
              Subject Line<sup>*</sup>
            </span>
          </div>
          <div className='value'>{subjectLine}</div>
        </div>
        <div className='row'>
          <div className='label'>
            <span>Sender</span>
          </div>
          <div className='value'>{sender}</div>
        </div>
        <div className='row'>
          <div className='label'>
            <span>Backup Sender</span>
          </div>
          <div className='value'>{backupSender}</div>
        </div>
        {additionalAudienceFilter && additionalAudienceFilter.length ? (
          <div className='row'>
            <div className='label'>
              <span>Additional Audience Filters</span>
            </div>
            <div className='value'>
              <div className='matchType'>Match Type: Match All</div>
              {additionalAudienceFilter.map(
                ({ prospectTag, condition, value }, index) => {
                  return (
                    <div key={index}>
                      <div className='inputBox'>{prospectTag}</div>
                      <div className='inputBox'>{condition}</div>
                      <div className='inputBox'>{value}</div>
                      {index !== additionalAudienceFilter.length - 1 && (
                        <div className='and'>and</div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const MailModule = ({ editCampaign }) => {
  const dispatch = useDispatch()
  const [expandedView, setexpandedView] = useState(null)
  const [openEditDropdown, setOpenEditDropdown] = useState(false)
  const editRefContainer = useRef()
  const editorDropdownRef = useRef()
  const [selectedIndex, setCurrentSelectedIndex] = useState(null)
  const [editorDropdownCoords, setEditorDropdownCoords] = useState({
    top: '',
    left: '',
    width: ''
  })

  useEffect(() => {
    if (openEditDropdown) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
      setCurrentSelectedIndex(null)
    }
  }, [openEditDropdown])

  const {
    createdCampaigns,
    segmentAudienceData = {},
    closeCampaign,
    audienceDataFromApi = {},
    showLoader
  } = useSelector(
    ({
      mailer: { createdCampaigns, closeCampaign, showLoader } = {},
      audiences: { segmentAudienceData, audienceDataFromApi = {} } = {}
    }) => ({
      createdCampaigns,
      segmentAudienceData,
      closeCampaign,
      audienceDataFromApi,
      showLoader
    })
  )
  useEffect(() => {
    dispatch(getCampaigns())
  }, [])

  useEffect(() => {
    if (closeCampaign) {
      setCurrentSelectedIndex(null)
      setOpenEditDropdown(false)
    }
  }, [closeCampaign])

  const getPreviewForCampaign = emails => {
    const preview = (emails || []).map(({ templateString }) =>
      decodeURIComponent(window.atob(templateString))
    )
    return preview.length > 5 ? preview.slice(0, 5) : preview
  }

  const getMaxMailListLength = campaignData => {
    let max = 0
    Object.keys(campaignData).forEach(segmentId => {
      if (campaignData[segmentId] && campaignData[segmentId].length > max) {
        max = campaignData[segmentId].length
      }
    })
    let maxMailsArr = []
    for (let i = 0; i < max; i++) {
      maxMailsArr.push(i)
    }
    return maxMailsArr
  }

  const updateEditorCoordinate = ({ currentTarget }) => {
    const rect = currentTarget.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 148
    }
    setEditorDropdownCoords({
      left, // add half the width of the button for centering
      top: top + 15, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: 87
    })
  }

  const deleteCampaign = campaign => {
    const { id } = campaign || {}
    if (id) {
      dispatch(deleteCampaignHandler(id))
    }
  }

  const copyCampaign = campaign => {
    const newCampaign = {
      ...campaign,
      campaign_name: `Copy of ${campaign.campaignName}`
    }
    delete newCampaign.campaignName
    delete newCampaign.id
    newCampaign['emails'] = (newCampaign.emails || []).map(email => {
      const {
        emailName,
        emailType,
        subjectLine,
        additionalAudienceFilter,
        audienceId,
        sender,
        backupSender,
        testEmail,
        recurring,
        recurringRule,
        createdFromSegments,
        backupSenderEmail,
        templateString,
        emailLayoutTree
      } = email
      return {
        email_name: emailName,
        email_type: emailType,
        subject_line: subjectLine,
        additional_audience_filter: additionalAudienceFilter,
        audience_ids: audienceId,
        sender,
        backup_sender: backupSender,
        test_email: testEmail,
        backup_sender_email: backupSenderEmail,
        recurring,
        recurring_rule: recurringRule,
        template_string: templateString,
        segment_ids: createdFromSegments,
        email_layout_tree: emailLayoutTree
      }
    })
    dispatch(postEmailCampaignHandler(newCampaign))
  }

  const editMailHandler = (emailData, campaignId) => {
    const { id, createdFromSegments } = emailData || {}
    const campaignDetail =
      createdCampaigns.find(({ id }) => id === campaignId) || {}
    editCampaign(campaignDetail)

    dispatch(
      updateCurrentEditedSegmentAndAudience({
        segmentId: createdFromSegments[0],
        audienceId: id
      })
    )
  }

  const allAudiences = useMemo(() => {
    let audiences = []
    if (segmentAudienceData) {
      Object.keys(segmentAudienceData).forEach(key => {
        audiences.push(segmentAudienceData[key])
      })
    }
    if (audienceDataFromApi) {
      Object.keys(audienceDataFromApi).forEach(key => {
        audiences = [
          ...audiences,
          ...(((audienceDataFromApi || {})[key] || [])[0]
            ? audienceDataFromApi[key]
            : [])
        ]
      })
    }
    return audiences.filter(audience => audience)
  }, [segmentAudienceData, audienceDataFromApi])

  const getTotalTarget = emails => {
    let sum = 0
    emails.forEach(mail => {
      const { audienceId = [] } = mail || {}
      if (audienceId.length) {
        const selectedAudiences = allAudiences.filter(({ id }) =>
          audienceId.includes(id)
        )
        sum += selectedAudiences.reduce((prev, next) => {
          return (prev += next.matchedRecordsTotal || 0)
        }, 0)
      }
    })
    return sum
  }

  return (
    <div css={containerStyle}>
      <div style={{ background: '#fff' }}>
        <SideDrawer />
      </div>
      <div css={contentContainer}>
        {showLoader && (
          <div css={campaignSummaryStyle} style={{ height: '95px' }}>
            <Loader />
          </div>
        )}
        {createdCampaigns.map((campaign, index) => {
          const { campaignName, emails, id: campaignId, isDraft } =
            campaign || {}
          const campaignData = processEmails(emails, segmentAudienceData)
          const totalTarget = getTotalTarget(emails)
          return (
            <div css={campaignSummaryStyle} key={index}>
              <div css={headerStyle(expandedView === index)}>
                <span
                  className='toggleHeight'
                  onClick={() =>
                    setexpandedView(prev => (index !== prev ? index : null))
                  }
                >
                  <img
                    alt='expandIcon'
                    src={expandedView === index ? minusIcon : plusIcon}
                  />
                </span>
                {getPreviewForCampaign(emails).map((email, index) => (
                  <div css={previewStyle} key={index}>
                    <div
                      style={{ transform: 'scale(0.1, 0.05' }}
                      dangerouslySetInnerHTML={{ __html: email }}
                    />
                  </div>
                ))}
                <div css={campaignNameContainer}>
                  <div className='name'>{campaignName}</div>
                  <div className='target'>{totalTarget} Targets</div>
                  <div
                    css={editDropdownStyle}
                    ref={editRefContainer}
                    key={index}
                  >
                    <img
                      css={editDropdownStyle}
                      src={DropdownIcon}
                      onClick={event => {
                        setOpenEditDropdown(prev => !prev)
                        updateEditorCoordinate(event)
                        setCurrentSelectedIndex(index)
                      }}
                    />
                  </div>
                  <SwitchController
                    style={{ top: '58px' }}
                    state={isDraft ? 'stop' : 'active'}
                  />
                </div>
                {openEditDropdown && (
                  <Portal>
                    <div
                      style={{ ...editorDropdownCoords, position: 'absolute' }}
                      ref={editorDropdownRef}
                    >
                      <div css={dropdownContainer}>
                        <button
                          css={drowndownBtnStyle}
                          className='edit-btn'
                          onClick={() => {
                            editCampaign(createdCampaigns[selectedIndex])
                            setOpenEditDropdown(false)
                            setCurrentSelectedIndex(null)
                          }}
                        >
                          Edit
                        </button>
                        <button
                          css={drowndownBtnStyle}
                          onClick={() => {
                            copyCampaign(createdCampaigns[selectedIndex])
                            setOpenEditDropdown(false)
                            setCurrentSelectedIndex(null)
                          }}
                        >
                          Copy
                        </button>

                        <button
                          css={drowndownBtnStyle}
                          onClick={() => {
                            deleteCampaign(createdCampaigns[selectedIndex])
                            setOpenEditDropdown(false)
                            setCurrentSelectedIndex(null)
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </Portal>
                )}
              </div>
              <div css={mailsContainer(expandedView === index)}>
                {getMaxMailListLength(campaignData).map(index => {
                  return (
                    <div key={index} css={mailRowStyle}>
                      {Object.keys(campaignData).map(
                        (segmentId, segmentIndex) => {
                          if (
                            campaignData[segmentId][index] &&
                            Object.keys(campaignData[segmentId][index]).length
                          ) {
                            if (
                              campaignData[segmentId][index]
                                .createdFromSegments[0] == segmentId
                            ) {
                              const { id: mailId } =
                                campaignData[segmentId][index] || {}
                              return (
                                <MailCard
                                  editMailHandler={editMailHandler}
                                  key={mailId}
                                  isDraft={isDraft}
                                  campaignId={campaignId}
                                  data={campaignData[segmentId][index]}
                                />
                              )
                            } else {
                              return null
                            }
                          } else {
                            return <div css={emptyCardView} />
                          }
                        }
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MailModule
