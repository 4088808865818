/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import Dropzone from '../../Droplist'
//import Image from '../Image'
import Image from '../QuickAddItem/image'
import {
  imageGalleryContainer,
  controlPanelStyle,
  controlItemStyle
} from './style'
import { handleMoveSidebarComponentIntoParent } from '../../helpers'
import { LibraryItems } from '../../../../../../../constant/mailer/libraryItem'
import TrashIcon from '../../../../../../../assets/images/trash-icon.png'
import ArrowUp from '../../../../../../../assets/images/arrow-up.png'
import {
  updateEmailTemplateHandler,
  updateTemplateTreeHandler,
  handleElementDelete,
  updateMovedElement
} from '../../../../../../../store/dashboard/mailerSlice'

const ImageGallery1 = ({ path: parentPath }) => {
  const dispatch = useDispatch()
  const [showControlPanel, setShowControlPanel] = useState(false)
  const {
    isDragging,
    currentEditedSegmentandAudience,
    emailTemplates,
    templateTree
  } = useSelector(state => ({
    isDragging: state.dragAndDrop.isDragging,
    currentEditedSegmentandAudience:
      state.mailer.currentEditedSegmentandAudience,
    emailTemplates: state.mailer.emailTemplates,
    templateTree: state.mailer.templateTree
  }))
  const [layout, setLayout] = useState([
    {
      type: 'ROW',
      id: uuidv4(),
      children: [
        {
          type: 'COLUMN',
          id: uuidv4(),
          children: [
            {
              type: 'COMPONENT',
              id: uuidv4(),
              uId: 3,
              dropzonePath: ['0', '0'],
              parentKey: 'Quick add item',
              component: props => (
                <Image
                  {...props}
                  filePath='https://app.omnisend.com/image/newsletter/504/61a753867c36a9001352d702'
                />
              )
            }
          ]
        },
        {
          type: 'COLUMN',
          id: uuidv4(),
          children: [
            {
              type: 'COMPONENT',
              id: uuidv4(),
              uId: 3,
              dropzonePath: ['0', '1'],
              parentKey: 'Quick add item',
              component: props => (
                <Image
                  {...props}
                  filePath='https://app.omnisend.com/image/newsletter/504/61a75389a2aca20013434997'
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              uId: 3,
              dropzonePath: ['0', '1'],
              parentKey: 'Quick add item',
              component: props => (
                <Image
                  {...props}
                  filePath='https://app.omnisend.com/image/newsletter/504/61a7538da2aca20013434999'
                />
              )
            }
          ]
        }
      ]
    }
  ])

  useEffect(() => {
    setTimeout(
      () =>
        dispatch(
          updateEmailTemplateHandler(
            emailTemplates,
            currentEditedSegmentandAudience
          )
        ),
      300
    )
    if (layout[0].children.some(({ children }) => children.length > 0)) {
      setTimeout(() => {
        let components = []
        layout[0].children.forEach(({ children }) => {
          children.forEach((element, index) => {
            const { uId, dropzonePath, parentKey, id } = element
            components.push({
              component: uId,
              index,
              dropzonePath,
              parentKey,
              id
            })
          })
        })
        dispatch(updateTemplateTreeHandler(22, components))
      }, 300)
    }
  }, [layout])

  useEffect(() => {
    if (currentEditedSegmentandAudience) {
      const { segmentId, audienceId } = currentEditedSegmentandAudience
      const savedLayout = (templateTree[segmentId] || {})[audienceId] || {}
      if ((savedLayout[22] || []).length) {
        const components = savedLayout[22]
        if (components.length) {
          let col1 = []
          let col2 = []
          components.forEach(
            ({ component, index, parentKey, id, dropzonePath }) => {
              const parentComponent = LibraryItems.find(
                ({ title }) => title === parentKey
              )
              const renderingComponent = (
                (parentComponent || {}).subMenus || []
              ).find(({ component: { uId } }) => uId === component)
              const newComponent = {
                id,
                type: 'COMPONENT',
                dropzonePath,
                ...renderingComponent.component
              }
              if (dropzonePath[1] == 0) {
                col1 = handleMoveSidebarComponentIntoParent(
                  col1,
                  [index.toString()],
                  newComponent
                )
              } else if (dropzonePath[1] == 1) {
                col2 = handleMoveSidebarComponentIntoParent(
                  col2,
                  [index.toString()],
                  newComponent
                )
              }
            }
          )
          setLayout([
            {
              ...layout[0],
              children: [
                { ...layout[0].children[0], children: col1 },
                { ...layout[0].children[1], children: col2 }
              ]
            }
          ])
        }
      } else {
        setLayout([{ ...layout[0] }])
      }
    }
  }, [currentEditedSegmentandAudience])

  const handleDrop = (dropZone, item) => {
    const splitDropzonePath = dropZone.path.toString().split('-')
    if (
      item.type === 'SIDEBAR_ITEM' &&
      (item.category === 'item' || item.category === 'layout')
    ) {
      const newComponent = {
        id: uuidv4(),
        type: 'COMPONENT',
        dropzonePath: splitDropzonePath,
        ...item.component
      }
      setLayout(
        handleMoveSidebarComponentIntoParent(
          layout,
          splitDropzonePath,
          newComponent
        )
      )
    }
  }

  const renderComponent = (component, path) => {
    return (
      <div data-path={path}>
        {component.component({
          parentPath,
          componentPath: path,
          parentUid: 22
        })}
      </div>
    )
  }

  const renderColumn = (data, path) => {
    return (
      <React.Fragment>
        {data.children.map((component, index) => {
          const currentPath = `${path}-${index}`
          return (
            <React.Fragment key={component.id}>
              {isDragging && (
                <Dropzone
                  data={{
                    path: currentPath,
                    childrenCount: data.children.length
                  }}
                  onDrop={handleDrop}
                />
              )}
              {renderComponent(component, currentPath)}
            </React.Fragment>
          )
        })}
        {isDragging && (
          <Dropzone
            data={{
              path: `${path}-${data.children.length}`,
              childrenCount: data.children.length
            }}
            onDrop={handleDrop}
          />
        )}
      </React.Fragment>
    )
  }

  const renderRow = (data, path) => {
    return (
      <div style={{ display: 'flex' }}>
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`
          return (
            <div key={column.id} style={{ flexShrink: 0, maxWidth: '50%' }}>
              {renderColumn(column, currentPath)}
            </div>
          )
        })}
      </div>
    )
  }

  const handleContainerDelete = () => {
    dispatch(
      handleElementDelete({
        componentId: 22,
        index: parentPath - 1
      })
    )
  }

  const handleContainerMove = direction => {
    dispatch(
      updateMovedElement({
        componentId: 22,
        index: parentPath - 1,
        direction
      })
    )
  }
  return (
    <div
      onMouseEnter={() => setShowControlPanel(true)}
      onMouseLeave={() => setShowControlPanel(false)}
      css={imageGalleryContainer}
      style={{
        backgroundColor: 'rgb(255, 255,255)',
        padding: '24px',
        backgroundPosition: 'unset',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      {layout.map((row, index) => {
        const currentPath = `${index}`
        return (
          <React.Fragment key={row.id}>
            {renderRow(row, currentPath)}
          </React.Fragment>
        )
      })}
      {showControlPanel && (
        <div css={controlPanelStyle}>
          <div css={controlItemStyle} onClick={handleContainerDelete}>
            <img src={TrashIcon} />
          </div>
          <div css={controlItemStyle} onClick={() => handleContainerMove('up')}>
            <img src={ArrowUp} />
          </div>
          <div
            css={controlItemStyle}
            onClick={() => handleContainerMove('down')}
          >
            <img src={ArrowUp} className='arrowDown' />
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageGallery1
