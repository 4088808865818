import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';

import useStyles from './styles';

const QuickSearchToolbar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
         <InputLabel className={classes.inputLabel}>
          Search
        </InputLabel>
        <TextField
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            className={classes.textField}
            InputProps={{
            endAdornment: (
                <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
                >
                <ClearIcon fontSize="small" />
                </IconButton>
            ),
            }}
        />
    </div>
  );
};

export default QuickSearchToolbar;