/** @jsxImportSource @emotion/react */
import React from 'react'

import SideDrawer from '../SideDrawer'

import helloSmiley from '../../../assets/images/onboardingDashboard/helloSmiley.png'

import { welcomeSection, welcomeContent } from './styles'

const WelcomeSection = React.forwardRef(
  (
    {
      selectedSection,
      scrollHandler = () => {},
      setSelectedSection = () => {}
    },
    ref
  ) => {
    const clickHandler = event => {
      ref.current.setAttribute('data-clicked', 'true')
      setSelectedSection('crm')
      scrollHandler('crm')
    }

    return (
      <section id='welcome' ref={ref} data-clicked={false} css={welcomeSection}>
        <SideDrawer section={'welcome'} selectedSection={selectedSection} />
        <div css={welcomeContent} data-selected={selectedSection === 'welcome'}>
          <span>
            Welcome back, Jimmy Butler!
            <img alt='Hi' src={helloSmiley} />
          </span>
          <span>
            In the next 5 minutes, we're going to unify your data in Neyo.
          </span>
          <span>
            (Spoiler alert: we'll help you clean, segment, activate and measure
            it too!)
          </span>
          <button onClick={clickHandler}>Let's get started</button>
        </div>
      </section>
    )
  }
)

export default WelcomeSection
