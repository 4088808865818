/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import {
  productListingContainer,
  controlPanelStyle,
  controlItemStyle
} from './style'
import { handleMoveSidebarComponentIntoParent } from '../../helpers'
import Image from '../QuickAddItem/image'
import EditableTextComponent from '../EditableTextComponent'
import { LibraryItems } from '../../../../../../../constant/mailer/libraryItem'
import {
  updateTemplateTreeHandler,
  handleElementDelete,
  updateMovedElement
} from '../../../../../../../store/dashboard/mailerSlice'
import Button from '../Button'
import TrashIcon from '../../../../../../../assets/images/trash-icon.png'
import ArrowUp from '../../../../../../../assets/images/arrow-up.png'

const textMap = {
  1: 'Bright Sky Case',
  2: 'iPhone 12 Pro Case — Tough'
}

const ProductListing1 = ({ path: parentPath }) => {
  const dispatch = useDispatch()
  const [showControlPanel, setShowControlPanel] = useState(false)
  const { currentEditedSegmentandAudience, templateTree } = useSelector(
    state => ({
      currentEditedSegmentandAudience:
        state.mailer.currentEditedSegmentandAudience,
      templateTree: state.mailer.templateTree
    })
  )
  const [layout, setLayout] = useState([
    {
      type: 'Row',
      id: uuidv4(),
      children: [
        {
          type: 'COLUMN',
          id: uuidv4(),
          children: [
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '0'],
              uId: 3,
              parentKey: 'Quick add item',
              component: props => (
                <Image
                  {...props}
                  filePath={
                    'https://app.omnisend.com/image/newsletter/1755/61a8ce33a2aca200134349d7'
                  }
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '0'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <EditableTextComponent
                  text={textMap[1]}
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    color: 'rgb(0, 0, 0)',
                    lineHeight: '150%',
                    fontWeight: 700
                  }}
                  {...props}
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '0'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <EditableTextComponent
                  text={textMap[2]}
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    color: 'rgb(0, 0, 0)',
                    lineHeight: '150%'
                  }}
                  {...props}
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '0'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <div style={{ display: 'flex' }}>
                  <EditableTextComponent
                    {...props}
                    text={'$49.99'}
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '14px',
                      color: 'rgb(0, 0, 0)',
                      lineHeight: '150%',
                      fontWeight: 700
                    }}
                    containerStyle={{ paddingRight: '6px' }}
                  />
                  <EditableTextComponent
                    {...props}
                    text={'$0.00'}
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '14px',
                      color: 'rgb(0, 0, 0)',
                      lineHeight: '150%',
                      textDecoration: 'line-through'
                    }}
                    containerStyle={{ paddingLeft: '6px' }}
                  />
                </div>
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '0'],
              uId: 2,
              parentKey: 'Quick add item',
              component: props => (
                <Button {...props} style={{ justifyContent: 'flex-start' }} />
              )
            }
          ]
        },
        {
          type: 'COLUMN',
          id: uuidv4(),
          children: [
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '1'],
              uId: 3,
              parentKey: 'Quick add item',
              component: props => (
                <Image
                  filePath={
                    'https://app.omnisend.com/image/newsletter/1755/61a8cf06a2aca200134349d9'
                  }
                  {...props}
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '1'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <EditableTextComponent
                  text={textMap[1]}
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    color: 'rgb(0, 0, 0)',
                    lineHeight: '150%',
                    fontWeight: 700
                  }}
                  {...props}
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '1'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <EditableTextComponent
                  text={textMap[2]}
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    color: 'rgb(0, 0, 0)',
                    lineHeight: '150%'
                  }}
                  {...props}
                />
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '1'],
              uId: 1,
              parentKey: 'Quick add item',
              component: props => (
                <div style={{ display: 'flex' }}>
                  <EditableTextComponent
                    {...props}
                    text={'$49.99'}
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '14px',
                      color: 'rgb(0, 0, 0)',
                      lineHeight: '150%',
                      fontWeight: 700
                    }}
                    containerStyle={{ paddingRight: '6px' }}
                  />
                  <EditableTextComponent
                    {...props}
                    text={'$0.00'}
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '14px',
                      color: 'rgb(0, 0, 0)',
                      lineHeight: '150%',
                      textDecoration: 'line-through'
                    }}
                    containerStyle={{ paddingLeft: '6px' }}
                  />
                </div>
              )
            },
            {
              type: 'COMPONENT',
              id: uuidv4(),
              dropzonePath: ['0', '1'],
              uId: 2,
              parentKey: 'Quick add item',
              component: props => (
                <Button {...props} style={{ justifyContent: 'flex-start' }} />
              )
            }
          ]
        }
      ]
    }
  ])

  useEffect(() => {
    if (layout[0].children.some(({ children }) => children.length > 0)) {
      setTimeout(() => {
        let components = []
        layout[0].children.forEach(({ children }) => {
          children.forEach((element, index) => {
            const { uId, dropzonePath, parentKey, id } = element
            components.push({
              component: uId,
              index,
              dropzonePath,
              parentKey,
              id
            })
          })
        })
        dispatch(updateTemplateTreeHandler(21, components))
      }, 300)
    }
  }, [layout])

  useEffect(() => {
    if (currentEditedSegmentandAudience) {
      const { segmentId, audienceId } = currentEditedSegmentandAudience
      const savedLayout = (templateTree[segmentId] || {})[audienceId] || {}
      if ((savedLayout[21] || []).length) {
        const components = savedLayout[21]
        if (components.length) {
          let col1 = []
          let col2 = []
          components.forEach(
            ({ component, index, parentKey, id, dropzonePath }) => {
              const parentComponent = LibraryItems.find(
                ({ title }) => title === parentKey
              )
              const renderingComponent = (
                (parentComponent || {}).subMenus || []
              ).find(({ component: { uId } }) => uId === component)
              const newComponent = {
                id,
                type: 'COMPONENT',
                dropzonePath,
                ...renderingComponent.component
              }
              if (dropzonePath[1] == 0) {
                col1 = handleMoveSidebarComponentIntoParent(
                  col1,
                  [index.toString()],
                  newComponent
                )
              } else if (dropzonePath[1] == 1) {
                col2 = handleMoveSidebarComponentIntoParent(
                  col2,
                  [index.toString()],
                  newComponent
                )
              }
            }
          )
          setLayout([
            {
              ...layout[0],
              children: [
                { ...layout[0].children[0], children: col1 },
                { ...layout[0].children[1], children: col2 }
              ]
            }
          ])
        }
      } else {
        setLayout([{ ...layout[0] }])
      }
    }
  }, [currentEditedSegmentandAudience])

  const renderComponent = (component, path) => {
    return (
      <div data-path={path}>
        {component.component({
          parentPath,
          componentPath: path,
          parentUid: 21
        })}
      </div>
    )
  }

  const renderColumn = (data, path) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
          maxWidth: '50%'
        }}
      >
        {data.children.map((component, index) => {
          const currentPath = `${path}-${index}`
          return (
            <React.Fragment key={component.id}>
              {renderComponent(component, currentPath)}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  const renderRow = (data, path) => {
    return (
      <div style={{ display: 'flex' }}>
        {data.children.map((component, index) => {
          const currentPath = `${path}-${index}`
          return (
            <React.Fragment key={component.id}>
              {renderColumn(component, currentPath)}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  const handleContainerDelete = () => {
    dispatch(handleElementDelete({ componentId: 21, index: parentPath - 1 }))
  }

  const handleContainerMove = direction => {
    dispatch(
      updateMovedElement({ componentId: 21, index: parentPath - 1, direction })
    )
  }

  return (
    <div
      onMouseEnter={() => setShowControlPanel(true)}
      onMouseLeave={() => setShowControlPanel(false)}
      css={productListingContainer}
      style={{
        backgroundColor: '#fff',
        padding: '24px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'unset',
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      {layout.map((row, index) => {
        const currentPath = `${index}`
        return (
          <React.Fragment key={row.id}>
            {renderRow(row, currentPath)}
          </React.Fragment>
        )
      })}
      {showControlPanel && (
        <div css={controlPanelStyle}>
          <div css={controlItemStyle} onClick={handleContainerDelete}>
            <img src={TrashIcon} />
          </div>
          <div css={controlItemStyle} onClick={() => handleContainerMove('up')}>
            <img src={ArrowUp} />
          </div>
          <div
            css={controlItemStyle}
            onClick={() => handleContainerMove('down')}
          >
            <img src={ArrowUp} className='arrowDown' />
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductListing1
