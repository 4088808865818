export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]

const addChildToChildren = (children, splitDropzonePath, item) => {
  if (splitDropzonePath.length === 1) {
    const dropZoneIndex = Number(splitDropzonePath[0])
    return insert(children, dropZoneIndex, item)
  }
  const updatedChildren = [...children]

  const curIndex = Number(splitDropzonePath.slice(0, 1))

  // Update the specific node's children
  const splitItemChildrenPath = splitDropzonePath.slice(1)
  const nodeChildren = updatedChildren[curIndex]
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(
      nodeChildren.children,
      splitItemChildrenPath,
      item
    )
  }

  return updatedChildren
}

export const handleMoveSidebarComponentIntoParent = (
  layout,
  splitDropzonePath,
  item
) => {
  let newLayoutStructure = item
  return addChildToChildren(layout, splitDropzonePath, newLayoutStructure)
}
