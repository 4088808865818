import { css } from '@emotion/react'

export const content = showAdCampaignDetails => css`
  display: flex;
  margin: 32px 0px;
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  height: 0px;
  overflow: hidden;
  transition: all 0.4s ease-out;
  ${showAdCampaignDetails && 'height: 540px;'}
`

export const campaignContainer = css`
  box-sizing: border-box;
  height: 529px;
  width: 252px;
  margin-right: 13px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 13px 24px 20px 21px;
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
  .campaignNameContainer {
    margin-bottom: 16px;
  }
  .budgetContainer {
    margin-bottom: 16px;
  }
`

export const adContainer = css`
  box-sizing: border-box;
  height: 529px;
  width: 517px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 13px 24px 20px 21px;
  margin-right: 13px;
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  .requiredLabel {
    position: absolute;
    right: 24px;
  }
`

export const previewContainer = css`
  box-sizing: border-box;
  height: 529px;
  width: 516px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  padding: 13px 24px 20px 21px;
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  padding-left: 5px;
  border-radius: 4px;
  margin-top: 2px;
  font-family: BentonSans;
  cursor: pointer;
  &.campaignName {
    margin-right: 4px;
  }
  type {
    cursor: pointer;
  }
`

export const inputWithCurrencyStyle = css`
  position: relative;
  .currency {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    margin-top: 2px;
  }
  .inputText {
    padding-left: 24px;
    line-height: 3;
  }
`

export const dropdownStyle = css`
  max-width: unset;
  height: 34px;
  margin-top: 2px;
  color: #333;
  padding: 4px 14px;
`

export const channelContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 28px;
`

export const channel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  .iconStyle {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
`

export const channelLabel = css`
  min-width: 63px;
  margin: 4px 16px 0px 0px;
`

export const spreadAcrossChannelOption = css`
  display: flex;
  justify-content: center;
  align-items: center;
  .labelText {
    font-size: 12px;
    margin-top: 2px;
  }
`

export const dateContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .startDate {
    margin-right: 5px;
    margin-bottom: 10px;
  }
  input[type='date'] {
    padding: 0px 10px;
    font-family: BentonSans;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }
`

export const check = css`
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  border: 0.8px solid #999999;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(102,153,204,0.4);
  margin: 7px 10px 20px 0px;
}`

export const label = css`
  margin: 10px 10px 0px 0px;
  width: 100px;
`

export const adNameContainer = css`
  margin-bottom: 24px;
  .textboxStyle {
    border-radius: 5px;
    height: 34px;
    width: 300px;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
  }
`

export const textContainer = css`
  margin-bottom: 24px;
  .textboxStyle {
    border-radius: 5px;
    height: 34px;
    width: 100%;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
    padding: 0px 8px;
    &[data-isInvalid='true'] {
      border: 1px solid red !important;
    }
    &[data-isinvalid='true'] {
      border: 1px solid red !important;
    }
  }
`

export const headlineContainer = css`
  margin-bottom: 24px;
  .textboxStyle {
    border-radius: 5px;
    height: 34px;
    width: 100%;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
    padding: 0px 8px;
    &[data-isInvalid='true'] {
      border: 1px solid red !important;
    }
    &[data-isinvalid='true'] {
      border: 1px solid red !important;
    }
  }
`

export const newsfeedDescriptionContainer = css`
  margin-bottom: 24px;
  .textboxStyle {
    border-radius: 5px;
    height: 64px;
    width: 100%;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
    padding: 8px 8px;
    resize: none;
    font-family: BentonSans;
    font-size: 13px;
    font-weight: 300;
    &[data-isInvalid='true'] {
      border: 1px solid red !important;
    }
    &[data-isinvalid='true'] {
      border: 1px solid red !important;
    }
  }
`

export const displayLinkContainer = css`
  margin-bottom: 24px;
  .textboxStyle {
    border-radius: 5px;
    height: 34px;
    width: 227px;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
    padding: 0px 8px;
  }
`

export const callToActionDropdownStyle = css`
  max-width: unset;
  width: 185px !important;
  height: 34px;
  margin-top: 2px;
  color: #333;
  padding: 4px 14px;
  line-height: 2;
`

export const previewDropdownContainer = css`
  display: flex;
  margin-top: 2px;
  margin-bottom: 25px;
`

export const previewDropdownStyle = css`
  height: 34px;
  min-width: 100px;
  color: #333333;
  line-height: 2;
  margin-right: 14px;
`

export const previewDesktopDropdownStyle = css`
  height: 34px;
  min-width: 100px;
  color: #333333;
  line-height: 2;
`

export const blank = css`
  height: 410px;
  background-color: #eeeeee;
  border-radius: 5px;
`

export const charCountError = css`
  color: red;
  font-size: 12px;
  margin-top: 8px;
  text-align: right;
`

export const charCountStyle = css`
  color: green;
  font-size: 12px;
  margin-top: 8px;
  text-align: right;
`

export const errorBorderStyle = css`
  border: 0.8px solid red !important;
`

export const borderStyle = css`
  border: 0.8px solid #ccc !important;
`

export const errorMessageStyle = css`
  ${charCountError}
  text-align: unset;
`
