import { css, keyframes } from '@emotion/react'

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`

export const container = css`
  box-sizing: border-box;
  height: 66px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  padding-left: 36px;
  padding-right: 44px;
`

export const sidebar = css`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 62px;
`

export const content = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .header {
    font-size: 17px;
    color: #333333;
    font-family: BentonSans;
    font-weight: 600;
  }
  .estimatedAudience {
    font-size: 15px;
    margin-top: 4px;
    color: #333333;
    font-family: BentonSans;
    font-weight: 600;
  }
`

export const actionButtonContainer = css`
  display: flex;
`

export const campaignActionButton = css`
  box-sizing: border-box;
  height: 34px;
  width: 102px;
  color: #333333;
  padding-top: 2px;
  margin-right: 20px;
  font-family: BentonSans;
  border-radius: 4px;
  cursor: pointer;
  border: 1.6px solid #cccccc;
  background-color: #ffffff;
  &.saveButton {
    color: #ffffff;
    background-color: #18a3e2;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const loaderStyle = css`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 16px;
  height: 16px;
  animation: ${spinAnimation} 1s linear infinite;
`
