import React from 'react'
import WithMockCellEditor from '../../../../HOC/WithMockCellEditor'
import { MockEditingContext } from '../../../../contexts/mockEditingContext'

class QueueRendere extends React.Component {
  state
  inputRef
  static contextType = MockEditingContext

  constructor (props) {
    super(props)
    this.state = {
      value: false
    }
    this.inputRef = React.createRef()
  }

  componentDidMount () {
    this.setState({
      value: this.props.getValue()
    })
  }

  getValue () {
    const field = this.props.column.getColId()
    return [field, this.state.value]
  }

  onChangeHandler = e => {
    this.setState({ value: e.target.value })
  }

  render () {
    return (
      <>
        {this.props.isMockEditing ? (
          <input
            className={`input-edit inputField`}
            style={{ width: '100%' }}
            value={this.state.value}
            onChange={this.onChangeHandler}
            onBlur={this.onChangeHandler}
            ref={this.inputRef}
          />
        ) : this.state.value ? (
          <div>{this.state.value}</div>
        ) : null}
      </>
    )
  }
}

export default WithMockCellEditor(QueueRendere)
