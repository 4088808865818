import { css } from '@emotion/react'

const setDisplayAddAudienceForm = () => css`
  background-color: rgba(204, 204, 204, 0.4); 
  border: 4px dashed #cccccc; 
  border-radius: 4px; 
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  .plusIcon { 
    color: black 
  }
`

export const addAudienceContainer = displayAddAudienceForm => css`
  box-sizing: border-box;
  height: 82px;
  width: 231.4px;
  min-width: 231.4px;
  padding: 13px 24px 20px 21px;
  display: flex;
  margin: 10px auto;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  .plusIcon {
    color: #E5E5E5;
    transition: color 0.5s ease-out;
  }
  ${displayAddAudienceForm && setDisplayAddAudienceForm()}
`
