/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'

import { useDrag } from 'react-dnd'

import { sectionTitle, imgStyle } from './style'

const ImageGallery = ({ data, monitorDragging = () => {} }) => {
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'IMAGE_GALLERY',
    item: data,
    collect: monitor => {
      return {
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging()
      }
    }
  })
  useEffect(() => {
    monitorDragging(isDragging)
  }, [isDragging])
  return (
    <section>
      <div css={sectionTitle}>{data.category}</div>
      <img
        style={{ opacity }}
        src={data.component.img}
        alt='preview image'
        ref={drag}
        css={imgStyle}
      />
    </section>
  )
}

export default ImageGallery
