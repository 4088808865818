import { css, keyframes } from '@emotion/react'

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`

export const container = (showSuccessOption, error) => css`
  box-sizing: border-box;
  height: 498px;
  width: 517px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 32px 24px 32px 21px;
  margin-right: 13px;
  border-radius: 5px;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  .requiredLabel {
    position: absolute;
    right: 24px;
  }
  ${(showSuccessOption || error) && 'height: 160px;'}
  ${showSuccessOption &&
    'background-color: #18A3E2; height: 439px; width: 406px;'}
`

export const textContainer = css`
  margin-bottom: 22px;
  .textboxStyle {
    border-radius: 5px;
    height: 34px;
    width: 100%;
    border: 0.8px solid #cccccc;
    box-sizing: border-box;
    margin-top: 2px;
  }
`

export const actionButtonContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 11px 0px 11px;
`

export const actionButton = loading => css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  padding-top: 2px;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  width: 99px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  position: relative;
  &.logInButton {
    color: #ffffff;
    background-color: #18a3e2;
    margin-left: 12px;
  }
  ${loading &&
    `opacity: 0.6;
    pointer-events: none;
    &.connect-button {
      text-align: left;
    }
    `}
  .loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    animation: ${spinAnimation} 1s linear infinite;
  }
`

export const messageContainer = (showSuccessOption, error) => css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  ${error && 'color: red;'}
  ${showSuccessOption && 'font-size: 20px; color: #fff; text-align: center;'}
`

export const loginMessageStyle = css`
  color: #ffffff;
  font-family: BentonSans;
  font-size: 16px;
`

export const imageWrapper = css`
  height: 60px;
  width: 60px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 2px rgba(102, 153, 204, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 20px;
  img {
    width: 33px;
    object-fit: contain;
  }
`
