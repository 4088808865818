import { css } from '@emotion/react'

export const container = css`
  display: flex;
  pointer-events: none;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 43px;
  border: 0.8px solid #cccccc;
  background-color: #cccccc;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  .textStyle {
    height: 17px;
    width: 299px;
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    margin-right: 43px;
  }
`
