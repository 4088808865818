/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Icon from '@material-ui/core/Icon'

import Dropdown from '../../../ui/Dropdown/Dropdown'
import SegmentConditionForm from './SegmentConditionForm'
import SegmentModalForm from '../SegmentModalForm/index'

import MinusIcon from '../../../../assets/images/dashboard/segments/minusIcon.png'
import PlusIcon from '../../../../assets/images/dashboard/segments/plusIcon.png'
import WhiteTrashIcon from '../../../../assets/images/dashboard/white-trash.png'
import expandModalIcon from '../../../../assets/images/dashboard/audience/expandModal.png'

import {
  formContainer,
  segmentNameStyle,
  inputStyle,
  matchTypeStyle,
  unionStyle,
  extraRuleContainer,
  extraRuleStyle,
  buttonStyle,
  saveButtonContainer,
  nonEditableInputStyle,
  nonEditableSegmentName,
  conditionWrapper,
  ruleGroupWrapper,
  ruleGroupContainer,
  matchTypeDropdownStyle,
  overlay,
  expandModalIconStyle,
  nonEditableInputOption
} from './style'

const SegmentForm = ({
  data,
  id,
  updateData = () => {},
  deleteSegment = () => {},
  segmentDropdown = false,
  fullHeight = false,
  toggleHeight = () => {},
  modalForm,
  index,
  isSingleCriteriaSegment = false
}) => {
  const {
    segmentName,
    matchType,
    ruleGroups,
    isEditable,
    lostCustomer,
    matchedRecordIds = []
  } = data

  const [formValue, setFormValue] = useState({})
  const [modalViewFlag, setModalViewFlag] = useState(false)
  useEffect(() => {
    setFormValue({
      segmentName,
      matchType,
      ruleGroups,
      isEditable,
      lostCustomer
    })
  }, [segmentName, matchType, ruleGroups])

  const getQueryParams = () => {
    let qParams = ''
    if (matchedRecordIds && matchedRecordIds.length > 0) {
      qParams = `matched_record_ids=${matchedRecordIds.join(',')}`
    }
    return qParams
  }

  const handleOnChange = (key, value) => {
    setFormValue(prev => {
      return { ...JSON.parse(JSON.stringify(prev)), [key]: value }
    })
    updateData(id, key, value)
  }

  const updateRuleGroupData = (ruleGroupIndex, key, value) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    const newRuleGroupData = { ...ruleGroupData, [key]: value }
    formValue.ruleGroups.splice(ruleGroupIndex, 1, newRuleGroupData)
    handleOnChange('ruleGroups', formValue.ruleGroups)
  }

  const updateConditionData = ({ ruleGroupIndex, ruleIndex, key, value }) => {
    const ruleGroupData = JSON.parse(JSON.stringify(ruleGroups[ruleGroupIndex]))
    const rule = JSON.parse(
      JSON.stringify((ruleGroupData.rules || [])[ruleIndex])
    )
    const newRule = { ...rule, [key]: value }
    ruleGroupData.rules.splice(ruleIndex, 1, newRule)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const onEditOrQueueUpdate = value => {
    handleOnChange('isEditable', value)
  }

  const addCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    if (!ruleIndex && ruleIndex !== 0) {
      ruleGroupData.rules.push({
        prospectTag: '',
        condition: '',
        value: ''
      })
    } else {
      ruleGroupData.rules.splice(ruleIndex, 0, {
        prospectTag: '',
        condition: '',
        value: ''
      })
    }
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const addRuleGroup = () => {
    formValue.ruleGroups.push({
      matchType: 'Match All',
      rules: [
        {
          prospectTag: '',
          condition: '',
          value: ''
        },
        {
          prospectTag: '',
          condition: '',
          value: ''
        }
      ]
    })
    handleOnChange('ruleGroups', formValue.ruleGroups)
  }

  const deleteCondition = (ruleGroupIndex, ruleIndex) => {
    const ruleGroupData = JSON.parse(
      JSON.stringify(formValue.ruleGroups[ruleGroupIndex])
    )
    ruleGroupData.rules.splice(ruleIndex, 1)
    updateRuleGroupData(ruleGroupIndex, 'rules', ruleGroupData.rules)
  }

  const modalClickHandler = () => {
    setModalViewFlag(prev => !prev)
  }

  useEffect(() => {
    document.body.classList.toggle('audience-model-open', modalViewFlag)
  }, [modalViewFlag])

  return (
    <>
      {formValue.isEditable ? (
        <div
          css={formContainer(
            formValue.isEditable,
            segmentDropdown,
            fullHeight,
            modalForm,
            index,
            lostCustomer
          )}
          className={
            formValue.ruleGroups && formValue.ruleGroups.length > 1
              ? 'auto'
              : ''
          }
        >
          <input
            css={[inputStyle, segmentNameStyle(modalForm)]}
            placeholder={'Segment Name'}
            value={formValue.segmentName}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('segmentName', value)
            }
          />
          {modalViewFlag && (
            <div css={overlay}>
              <SegmentModalForm
                formValue={formValue}
                id={id}
                updateData={updateData}
                deleteSegment={deleteSegment}
                segmentDropdown={segmentDropdown}
                fullHeight={fullHeight}
                toggleHeight={toggleHeight}
                modalClickHandler={modalClickHandler}
              />
            </div>
          )}
          <div css={ruleGroupWrapper(segmentDropdown, modalForm)}>
            {(formValue.ruleGroups || []).map(({ rules, matchType }, index) => {
              const ruleGroupIndex = index
              return (
                <React.Fragment key={ruleGroupIndex}>
                  <div css={ruleGroupContainer(ruleGroupIndex)}>
                    <div css={matchTypeStyle(ruleGroupIndex, modalForm)}>
                      <label>Match Type:</label>
                      <Dropdown
                        options={['Match All', 'Match Any']}
                        style={matchTypeDropdownStyle}
                        value={matchType}
                        onOptionSelected={value =>
                          updateRuleGroupData(
                            ruleGroupIndex,
                            'matchType',
                            value
                          )
                        }
                      />
                    </div>
                    <div css={conditionWrapper(modalForm)}>
                      {(rules || []).map((conditionData, index) => {
                        const ruleIndex = index
                        return (
                          <React.Fragment key={index}>
                            <SegmentConditionForm
                              data={conditionData}
                              ruleIndex={ruleIndex}
                              ruleGroupIndex={ruleGroupIndex}
                              modalForm={modalForm}
                              deleteCondition={(ruleGroupIndex, ruleIndex) =>
                                deleteCondition(ruleGroupIndex, ruleIndex)
                              }
                              updateConditionsData={updateConditionData}
                              addCondition={(ruleGroupIndex, ruleIndex) =>
                                addCondition(ruleGroupIndex, ruleIndex)
                              }
                            />
                            {index !== rules.length - 1 && (
                              <div css={unionStyle}>
                                {matchType === 'Match All' ? 'And' : 'or'}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      })}
                      {ruleGroupIndex > 0 && (
                        <div css={extraRuleContainer}>
                          <div
                            css={extraRuleStyle}
                            onClick={() => addCondition(ruleGroupIndex)}
                          >
                            <Icon>add</Icon>
                            new rule
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {ruleGroupIndex !== formValue.ruleGroups.length - 1 && (
                    <div css={unionStyle}>And</div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          <div css={extraRuleContainer(modalForm)}>
            <div css={extraRuleStyle} onClick={() => addCondition(0)}>
              <Icon>add</Icon>
              new rule
            </div>
            <div css={extraRuleStyle} onClick={() => addRuleGroup()}>
              <Icon>add</Icon>
              New rule group
            </div>
          </div>
          <div css={saveButtonContainer}>
            <button
              css={buttonStyle}
              onClick={() => onEditOrQueueUpdate(false)}
            >
              Queue Update
            </button>
            {!modalForm && (
              <button css={expandModalIconStyle} onClick={modalClickHandler}>
                <img alt='expand-modal-icon' src={expandModalIcon} />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          className={isSingleCriteriaSegment ? 'single' : 'auto'}
          css={formContainer(
            formValue.isEditable,
            segmentDropdown,
            fullHeight,
            modalForm,
            index,
            lostCustomer
          )}
        >
          {segmentDropdown && !modalForm && (
            <span className='toggleHeight' onClick={toggleHeight}>
              <img
                alt='minimize-icon'
                style={{ borderRadius: '4px' }}
                src={fullHeight ? MinusIcon : PlusIcon}
              />
            </span>
          )}
          <div css={nonEditableSegmentName(fullHeight, lostCustomer)}>
            {!segmentDropdown && !lostCustomer && (
              <span className='deleteSegment' onClick={() => deleteSegment(id)}>
                <img src={WhiteTrashIcon} alt='white-trash-icon' />
              </span>
            )}
            <span className='segmentName'>{formValue.segmentName}</span>
          </div>
          {modalViewFlag && (
            <div css={overlay}>
              <SegmentModalForm
                formValue={formValue}
                id={id}
                updateData={updateData}
                deleteSegment={deleteSegment}
                segmentDropdown={segmentDropdown}
                fullHeight={fullHeight}
                toggleHeight={toggleHeight}
                modalClickHandler={modalClickHandler}
              />
            </div>
          )}
          <div
            css={ruleGroupWrapper(segmentDropdown, modalForm)}
            className={`nonEditable ${isSingleCriteriaSegment && 'single'}`}
          >
            {(formValue.ruleGroups || []).map(({ matchType, rules }, index) => {
              const ruleGroupIndex = index
              return (
                <React.Fragment key={ruleGroupIndex}>
                  {!segmentDropdown && (
                    <div
                      css={matchTypeStyle(ruleGroupIndex, modalForm)}
                      className={`nonEditable ${isSingleCriteriaSegment &&
                        'single'}`}
                    >
                      <label>Match Type:</label>
                      <div
                        css={[
                          inputStyle,
                          segmentNameStyle(),
                          nonEditableInputStyle
                        ]}
                        style={{
                          width: '104px',
                          minWidth: 'unset',
                          height: '40px'
                        }}
                      >
                        {matchType}
                      </div>
                    </div>
                  )}
                  <div
                    css={conditionWrapper(modalForm, segmentDropdown)}
                    className={`nonEditable ${isSingleCriteriaSegment &&
                      'single'}`}
                  >
                    {(rules || []).map((conditionData, index) => {
                      const ruleIndex = index
                      return (
                        <React.Fragment key={ruleIndex}>
                          <div css={nonEditableInputOption(modalForm)}>
                            <div
                              css={[
                                inputStyle,
                                segmentNameStyle(modalForm, true),
                                nonEditableInputStyle
                              ]}
                            >
                              <div>{conditionData.prospectTag}</div>
                            </div>
                            <div
                              css={[
                                inputStyle,
                                segmentNameStyle(modalForm, true),
                                nonEditableInputStyle
                              ]}
                            >
                              <div>{conditionData.condition}</div>
                            </div>
                            <div
                              css={[
                                inputStyle,
                                segmentNameStyle(modalForm, true),
                                nonEditableInputStyle
                              ]}
                            >
                              <div>{conditionData.value}</div>
                            </div>
                          </div>
                          {index !== rules.length - 1 && (
                            <div css={unionStyle} className='nonEditable'>
                              {matchType === 'Match All' ? 'And' : 'or'}
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {ruleGroupIndex !== formValue.ruleGroups.length - 1 && (
                    <div css={unionStyle} className='nonEditable'>
                      And
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          <div css={saveButtonContainer} className='nonEditable'>
            {!segmentDropdown && (
              <button
                css={buttonStyle}
                onClick={() => onEditOrQueueUpdate(true)}
                className='nonEditable'
              >
                Edit
              </button>
            )}
            {segmentDropdown && (
              <Link
                to={`/cdp/view-contacts?${getQueryParams()}&segment=${
                  formValue.segmentName
                }`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <button css={buttonStyle}>View Contacts</button>
              </Link>
            )}
            {!modalForm && (
              <button css={expandModalIconStyle} onClick={modalClickHandler}>
                <img alt='expand-modal-icon' src={expandModalIcon} />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default SegmentForm
