import React from 'react';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const Footer = (props) => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Typography variant="body2">{props.contentText}</Typography>
        </footer>
    );
};

export default Footer;