/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import Portal from '../../../../../../../customHooks/portal'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  lineSpaceEditorContainer,
  labelStyle,
  btnGroupStyle,
  typeBtnStyle,
  typeBtnTextStyle,
  fieldStyle,
  inputStyle,
  colorBoxStyle,
  titleStyle
} from './style'

const LineSpaceEditor = () => {
  const borderColorRef = useRef()
  const borderColorPickerRef = useRef()
  const dispatch = useDispatch()

  const {
    editingPath,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent: { path: editingPath },
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      editingPath,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )

  const [lineEditorObj, updateLineEditorObj] = useState({
    type: 'line',
    style: 'solid',
    alignment: 'center',
    borderColor: '#000'
  })
  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })

  const [borderColorPicker, setBorderColorPicker] = useState(false)

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  useEffect(() => {
    let editorObj = {
      type: 'line',
      style: 'solid',
      alignment: 'center',
      borderColor: '#000'
    }
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        editorObj = { ...editorObj, ...(editingAudience[editingPath] || {}) }
      }
    }
    updateLineEditorObj(editorObj)
  }, [editingComponents])

  useEffect(() => {
    const clickedOutside = event => {
      if (
        borderColorPicker &&
        borderColorPickerRef.current &&
        !borderColorRef.current.contains(event.target) &&
        !borderColorPickerRef.current.contains(event.target)
      ) {
        setBorderColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [borderColorPickerRef, borderColorRef, borderColorPicker])

  const handleChange = (key, value) => {
    const newEditorObj = {
      ...lineEditorObj,
      [key]: value
    }
    updateLineEditorObj(newEditorObj)
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: newEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  return (
    <div css={lineSpaceEditorContainer}>
      <div css={titleStyle}>Line/Space</div>
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Type</label>
        <div css={btnGroupStyle}>
          <button
            css={typeBtnStyle(lineEditorObj.type === 'line')}
            onClick={() => handleChange('type', 'line')}
          >
            <div css={typeBtnTextStyle}>Line</div>
          </button>
          <button
            css={typeBtnStyle(lineEditorObj.type === 'space')}
            onClick={() => handleChange('type', 'space')}
          >
            <div css={typeBtnTextStyle}>Space</div>
          </button>
        </div>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Style</label>
        <div css={btnGroupStyle}>
          <button
            css={typeBtnStyle(lineEditorObj.style === 'solid')}
            onClick={() => handleChange('style', 'solid')}
          >
            <div css={typeBtnTextStyle}>Solid</div>
          </button>
          <button
            css={typeBtnStyle(lineEditorObj.style === 'dashed')}
            onClick={() => handleChange('style', 'dashed')}
          >
            <div css={typeBtnTextStyle}>Dashed</div>
          </button>
          <button
            css={typeBtnStyle(lineEditorObj.style === 'dotted')}
            onClick={() => handleChange('style', 'dotted')}
          >
            <div css={typeBtnTextStyle}>Dotted</div>
          </button>
        </div>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <label css={labelStyle}>Alignment</label>
        <div css={btnGroupStyle}>
          <button
            css={typeBtnStyle(lineEditorObj.alignment === 'flex-start')}
            onClick={() => handleChange('alignment', 'flex-start')}
          >
            <div css={typeBtnTextStyle}>Left</div>
          </button>
          <button
            css={typeBtnStyle(lineEditorObj.alignment === 'center')}
            onClick={() => handleChange('alignment', 'center')}
          >
            <div css={typeBtnTextStyle}>Center</div>
          </button>
          <button
            css={typeBtnStyle(lineEditorObj.alignment === 'flex-end')}
            onClick={() => handleChange('alignment', 'flex-end')}
          >
            <div css={typeBtnTextStyle}>Right</div>
          </button>
        </div>
      </div>
      <div>
        <label css={labelStyle}>Border Color</label>
        <div css={fieldStyle} ref={borderColorRef}>
          <input
            css={inputStyle}
            type='text'
            value={lineEditorObj.borderColor}
            onChange={({ target: { value } }) => {
              handleChange('borderColor', value)
            }}
          />
          <span
            css={colorBoxStyle}
            style={{ backgroundColor: lineEditorObj.borderColor }}
            onClick={() => {
              setBorderColorPicker(prev => !prev)
              updateColorCoordinate(borderColorRef.current)
            }}
          />
        </div>
      </div>
      {borderColorPicker && (
        <Portal>
          <div
            style={{ ...colorCoords, position: 'absolute' }}
            ref={borderColorPickerRef}
          >
            <SketchPicker
              color={lineEditorObj.borderColor}
              onChangeComplete={color => {
                handleChange('borderColor', color.hex)
              }}
            />
          </div>
        </Portal>
      )}
    </div>
  )
}

export default LineSpaceEditor
