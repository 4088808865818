/** @jsxImportSource @emotion/react */
import React from 'react'
import MailIcon from '../../../../assets/images/dashboard/email.png'

import { containerStyle, iconStyle, textStyle } from './style'

const SideDrawer = ({ blankSideNav = false }) => {
  return (
    <div css={containerStyle}>
      {!blankSideNav ? (
        <>
          <span css={iconStyle}>
            <img src={MailIcon} alt='mail-icon' />
          </span>
          <span css={textStyle}>Emails</span>
        </>
      ) : null}
    </div>
  )
}

export default SideDrawer
