import { css } from '@emotion/react/macro'

export const dropdownMenu = css`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  top: 40px;
  z-index: 2;
  width: inherit;
`

export const blanket = css`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: 'fixed';
  z-index: 1;
`

export const dropdownSearch = css`
  position: relative;
  max-width: 200px;
  background: #fff;
  min-width: 150px;
  border: 1px solid #ccc;
  height: 38px;
  border-radius: 5px;
  padding: 4px 17px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const optionsPanel = css`
  width: 100%;
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      font-size: 0.875rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 300;
      &:hover {
        background: #f5f5f5;
      }
      line-height: 1.2;
      padding: 8px 4px;
    }
  }
`

export const chevron = css`
  display: inline-block;
  transform: rotate(180deg);
  width: 12px;
  height: 12px;
  margin-left: auto;
  img {
    width: inherit;
    height: inherit;
    position: absolute;
  }
`

export const button = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
`

export const searchInput = css`
  width: 90%;
  outline: none;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  padding: 4px 10px;
  margin: 0 10px 0px 10px;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
`
