/** @jsxImportSource @emotion/react */
import React from 'react'
import AlertIcon from '../../../assets/images/dashboard/alert.png'
import {
  container,
  header,
  subHeader,
  content,
  buttonContainer,
  buttonStyle,
  containerStyle,
  sidebarStyle,
  iconStyle,
  textStyle
} from './styles'
const Alert = () => {
  return (
    <div css={containerStyle}>
      <div css={sidebarStyle}>
        <span css={iconStyle}>
          <img src={AlertIcon} alt='alert-icon' />
        </span>
        <span css={textStyle}>Alerts</span>
      </div>
    </div>
    // <div css={container}>
    //   <div css={header}>Alert</div>
    //   <div css={subHeader}>This change will impact 12987 records</div>
    //   <div css={content}>
    //     <span className='top'>
    //       This dictionary change will affect audiences, reporting and triggers.
    //       Once this change is complete, it cannot be undone.
    //     </span>
    //     <span>
    //       If changes to segments are necessary, it is suggested to edit them at
    //       the beginning of a month or quarter for reporting purposes.
    //     </span>
    //   </div>
    //   <div css={buttonContainer}>
    //     <button css={buttonStyle} className='keepEditing'>
    //       Keep Editing
    //     </button>
    //     <button css={buttonStyle} className='cancel'>
    //       Cancel
    //     </button>
    //     <button css={buttonStyle} className='fileChanges'>
    //       File Changes
    //     </button>
    //   </div>
    // </div>
  )
}

export default Alert
