/** @jsxImportSource @emotion/react */
import { sideDrawer } from './style'

import audiencesIcon from '../../../../assets/images/dashboard/audience/audiences.png'
import audiencesPullIcon from '../../../../assets/images/dashboard/audience/audiencesPull.png'

const SideDrawer = props => {
  return (
    <div css={sideDrawer()}>
      <span className='audiencesIcon'>
        <img alt='audiencesIcon' src={audiencesIcon} />
      </span>
      <span className='audienceText'>Audiences</span>
      {props.expandedView && (
        <span className='audiencesPullIcon'>
          <img alt='audiencesPullIcon' src={audiencesPullIcon} />
        </span>
      )}
    </div>
  )
}

export default SideDrawer
