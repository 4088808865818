/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFileToS3 } from '../../../../../../../external/uploadImageToS3WithNativeSdk'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  imageEditorContainer,
  labelStyle,
  imageUploadBlockStyle,
  imagePlaceholderStyle,
  selectFileBtnStyle,
  fieldBlockStyle,
  textFieldStyle,
  inputStyle,
  altLabelStyle,
  btnGroupStyle,
  alignmentBtnStyle,
  alignmentBtnTextStyle,
  loaderWrapperStyle
} from './style'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'

const ImageEditor = ({ componentType, uploadFileToS3 }) => {
  const fileRef = useRef()
  const dispatch = useDispatch()
  const [imageEditorObj, setImageEditorObj] = useState({
    file: '',
    altText: '',
    link: '',
    alignment: 'center'
  })
  const {
    currentEditingComponent,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent,
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      currentEditingComponent,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )

  const handleFileChange = async event => {
    const {
      target: { files }
    } = event
    const file = files[0]
    const { name } = file
    const updateLoadingState = { ...imageEditorObj, isUploading: true }
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: updateLoadingState,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setImageEditorObj(updateLoadingState)
    const { url } = await uploadFileToS3(files[0], name)
    const newImageEditorObj = {
      ...imageEditorObj,
      file: url,
      isUploading: false
    }
    setImageEditorObj(newImageEditorObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newImageEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  const handleImageSettingChange = (key, value) => {
    const newImageEditorObj = {
      ...imageEditorObj,
      [key]: value
    }
    setImageEditorObj(newImageEditorObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newImageEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  useEffect(() => {
    let imgObj = {
      file: '',
      altText: '',
      link: '',
      alignment: 'center'
    }
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        imgObj = {
          ...imgObj,
          ...(editingAudience[currentEditingComponent.path] || {})
        }
      }
    }
    setImageEditorObj(imgObj)
  }, [currentEditingComponent, editingComponents])

  const handleFileRemove = async path => {
    // const splittedPath = path.split('/')
    // const filename = splittedPath[splittedPath.length - 1]
    // const response = await deleteFileFromS3(filename)
    // console.log(response)
    const newImageEditorObj = {
      ...imageEditorObj,
      file: ''
    }
    setImageEditorObj(newImageEditorObj)
    dispatch(
      updateEditingComponent({
        path: currentEditingComponent.path,
        value: newImageEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
  }

  return (
    <div css={imageEditorContainer}>
      <div>
        <label css={labelStyle}>
          <span className='title'>{componentType}</span>
          <span>
            You can use JPG, PNG, and GIF formats, each under 2000px in
            dimension
          </span>
        </label>
        <div css={imageUploadBlockStyle}>
          <input
            ref={fileRef}
            type='file'
            hidden
            onChange={handleFileChange}
            accept='image/png,image/jpeg,image/jpg,image/gif'
          />
          <div css={imagePlaceholderStyle}>
            {imageEditorObj.isUploading && (
              <div css={loaderWrapperStyle} className='spinner'>
                <div class='bounce1'></div>
                <div class='bounce2'></div>
                <div class='bounce3'></div>
              </div>
            )}
            {!imageEditorObj.isUploading && (
              <img
                src={
                  imageEditorObj.file ||
                  'https://app.omnisend.com/image/newsletter/2084/63e2b2bbcbd80f001c215cec'
                }
              />
            )}
          </div>
          <button
            css={selectFileBtnStyle}
            onClick={() => {
              fileRef.current.click()
            }}
          >
            <div className='text'>
              {imageEditorObj.file ? 'Replace' : 'Select file'}
            </div>
          </button>

          {imageEditorObj.file && (
            <>
              <div
                style={{
                  margin: '0 10px',
                  height: '15px',
                  backgroundColor: '#333',
                  width: 1
                }}
              ></div>
              <button
                css={selectFileBtnStyle}
                onClick={() => handleFileRemove(imageEditorObj.file)}
              >
                <div className='text'>Remove</div>
              </button>
            </>
          )}
        </div>
        <div css={fieldBlockStyle}>
          <label css={altLabelStyle}>Alt text</label>
          <div css={textFieldStyle}>
            <input
              css={inputStyle}
              onChange={({ target: { value } }) => {
                handleImageSettingChange('altText', value)
              }}
            />
          </div>
        </div>
        {/* <div css={fieldBlockStyle}>
          <label css={altLabelStyle}>Link to</label>
          <div css={textFieldStyle}>
            <input
              css={inputStyle}
              placeholder='https://'
              onChange={({ target: { value } }) => {
                handleImageSettingChange('link', value)
              }}
            />
          </div>
        </div> */}
        <div css={fieldBlockStyle}>
          <label css={altLabelStyle}>Alignment</label>
          <div css={btnGroupStyle}>
            <button
              css={alignmentBtnStyle(imageEditorObj.alignment === 'flex-start')}
              onClick={() => {
                handleImageSettingChange('alignment', 'flex-start')
              }}
            >
              <div css={alignmentBtnTextStyle}>Left</div>
            </button>
            <button
              css={alignmentBtnStyle(imageEditorObj.alignment === 'center')}
              onClick={() => {
                handleImageSettingChange('alignment', 'center')
              }}
            >
              <div css={alignmentBtnTextStyle}>Center</div>
            </button>
            <button
              css={alignmentBtnStyle(imageEditorObj.alignment === 'flex-end')}
              onClick={() => {
                handleImageSettingChange('alignment', 'flex-end')
              }}
            >
              <div css={alignmentBtnTextStyle}>Right</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  uploadFileToS3: bindActionCreators(uploadFileToS3, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditor)
