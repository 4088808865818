import { css } from '@emotion/react'

export const segmentCard = isEditable => css`
    box-sizing: border-box;
    position: relative;
    height: 532px;
    width: 824px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px rgba(0,153,204,0.4);
    ${isEditable && 'border: 4px dashed #CCCCCC;background-color: #E5E5E5;'}
    .iconBox {
        height: 61px;
        width: 61px;
        border: 0.8px solid #cccccc;
        box-shadow: 0 2px 6px rgba(102,153,204,0.4);
        display: inherit;
        justify-content: center;
        align-items: inherit;
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-left: 9px;
        .neyo {
            height: 33px;
            width: 47px;
        }
        .salesforce {
            height: 33px;
            width: 47px;
        }
        .bombora {
            height: 33px;
            width: 28px;
        }
    }
`

export const contractModalIconStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border: 0.8px solid #CCCCCC;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px rgba(102,153,204,0.4);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
    img {
      height: 16px;
      width: 16px;
    }
`

export const contentCard = isEditable => css`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    overflow: hidden;
    background-color: #FFFFFF;
    border-top: 0px #ffffff;
    border-radius: 0px 0px 4px 4px;
    ${isEditable && 'background-color: inherit;'}
`
