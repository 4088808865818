/** @jsxImportSource @emotion/react */
import React from 'react'

import { useDrop } from 'react-dnd'
const ACCEPTS = [
  'QUICK_ADD',
  'QUICK_ADD_LAYOUT',
  'PREHEADER',
  'HEADER',
  'HEROIMAGE',
  'PRODUCT_LISTING',
  'IMAGE_GALLERY',
  'FOOTER',
  'EMAIL_BODY'
]

const DropZone = ({ onDrop, data, dropZoneStyle, dropzoneText }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item)
    },
    canDrop: (item, monitor) => {
      const { path = '' } = data
      const splitDropzonePath = path.toString().split('-')
      //don't render quickadd item in main layout
      if (splitDropzonePath.length === 1 && item.category === 'item') {
        return false
      }

      return true
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })
  const active = isOver && canDrop
  return (
    <div
      style={{
        flex: '0 0 auto',
        height: '40px',
        opacity: active ? 1 : 0,
        background: '#00a2ff5e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #16a3e1',
        ...dropZoneStyle
      }}
      className='dropzone'
      ref={drop}
    >
      {dropzoneText || 'Drop Here'}
    </div>
  )
}

export default DropZone
