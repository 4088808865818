/** @jsxImportSource @emotion/react */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateFilter } from '../../../store/dashboard/topfoldSlice'

import {
  filterContainer,
  label,
  filterItemContainer,
  segmentContainerStyle,
  segmentStyle,
  blankSegmentStyle,
  campaignContainerStyle,
  campaignIcon
} from './segmentAudienceFilterStyle'

const Segments = () => {
  const dispatch = useDispatch()
  const {
    segmentsData,
    filters: { segments: filterSegments = [] }
  } = useSelector(({ segments: { segmentsData }, topfold: { filters } }) => ({
    segmentsData,
    filters
  }))

  const handleFilterUpdate = id => {
    const filterClone = [...filterSegments]
    const filteredIndex = filterSegments.findIndex(item => item === id)
    if (filteredIndex !== -1) {
      filterClone.splice(filteredIndex, 1)
    } else {
      filterClone.push(id)
    }
    dispatch(updateFilter({ segments: filterClone }))
  }
  return (
    <div css={filterItemContainer} className='segment-filter'>
      <div css={label}>
        <p className='heading'>Segments Live</p>
        <p className='subHeading'>(click to filter results)</p>
      </div>
      <div css={segmentContainerStyle}>
        {segmentsData.map((segment, index) => {
          const { segmentName, lostCustomer, id } = segment || {}
          return segmentName ? (
            <div
              key={index}
              css={segmentStyle}
              className={`${lostCustomer ? 'lost-customer' : ''} ${
                !filterSegments.includes(id) && filterSegments.length
                  ? 'fade'
                  : ''
              }`}
              onClick={() => handleFilterUpdate(id)}
            >
              {segmentName}
            </div>
          ) : (
            <div css={blankSegmentStyle} />
          )
        })}
      </div>
    </div>
  )
}

const Campaigns = () => {
  const dispatch = useDispatch()
  let {
    mailCampaigns,
    filters: { campaigns: filterCampaigns },
    adCampaigns
  } = useSelector(
    ({
      mailer: { createdCampaigns: mailCampaigns },
      topfold: { filters },
      ads: { campaignCollection: adCampaigns }
    }) => ({
      mailCampaigns,
      filters,
      adCampaigns
    })
  )

  mailCampaigns = mailCampaigns.map(campaign => ({ ...campaign, type: 'mail' }))
  adCampaigns = adCampaigns.map(({ campaignData }) => ({
    ...campaignData,
    type: 'ad'
  }))

  const allCampaigns = [...mailCampaigns, ...adCampaigns]

  const handleFilterUpdate = id => {
    const filterClone = [...filterCampaigns]
    const filteredIndex = filterCampaigns.findIndex(item => item === id)
    if (filteredIndex !== -1) {
      filterClone.splice(filteredIndex, 1)
    } else {
      filterClone.push(id)
    }
    dispatch(updateFilter({ campaigns: filterClone }))
  }
  return (
    <div css={filterItemContainer}>
      <div css={label}>
        <p className='heading'>Campaigns Live</p>
        <p className='subHeading'>(click to filter results)</p>
      </div>
      <div css={campaignContainerStyle}>
        {allCampaigns.map((campaign, index) => {
          const { id, campaignName, type } = campaign
          return (
            <div
              css={campaignIcon(type)}
              className={`${
                !filterCampaigns.includes(id) && filterCampaigns.length
                  ? 'fade'
                  : ''
              }`}
              key={id}
              onClick={() => handleFilterUpdate(id)}
            >
              <span>{campaignName}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SegmentAudienceFilter = ({ showAnalyticsGraphs }) => {
  return (
    <div css={filterContainer(showAnalyticsGraphs)}>
      <Segments />
      <Campaigns />
    </div>
  )
}

export default SegmentAudienceFilter
