import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 115

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    marginTop: '27px'
  },
  subHeaderPaper: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    margin: '0rem 0.8rem 0.8rem 0.8rem',
    backgroundColor: '#e5e5e5',
    height: '5rem'
  },
  subHeaderText: {
    marginLeft: '1.3rem',
    '& .MuiTypography-subtitle1': {
      fontWeight: 'bold'
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'normal'
    }
  },
  subHeaderSearch: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: '1.3rem',
      width: 'auto'
    }
  },
  subHeaderSearchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subHeaderSearchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontWeight: '400',
    fontSize: '0.8rem',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20chch'
    }
  }
}))

export default useStyles
