import { css } from '@emotion/react'

export const switchButton = switchState => css`
  position: absolute;
  top: 65px;
  box-sizing: border-box;
  height: 18px;
  width: 48px;
  border: 2px solid #999999;
  background-color: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  div {
    flex: 0 0 33.333333%;
    border-radius: 14px;
  }
  .active {
    background-color: #ffffff;
    transition: background-color 0.5s ease-out;
    ${switchState === 'active' && 'background-color: #66CC66;'}
  }
  .pause {
    background-color: #ffffff;
    transition: background-color 0.5s ease-out;
    ${switchState === 'pause' && 'background-color: #ffcc33;'}
  }
  .stop {
    background-color: #ffffff;
    transition: background-color 0.5s ease-out;
    ${switchState === 'stop' && 'background-color: grey;'}
  }
`
