import { createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../axiosConfig'

const initialState = {
  unifiedIdData: [],
  connector: [],
  loading: false
}

const unifiedIdSlice = createSlice({
  name: 'unifiedId',
  initialState: initialState,
  reducers: {
    updateUnifiedIdData: (state, action) => {
      state.unifiedIdData = action.payload
    },
    updateConnector: (state, action) => {
      state.connector = action.payload
    },
    updateLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const fetchUnifiedIdData = () => {
  return async dispatch => {
    dispatch(unifiedIdSlice.actions.updateLoading(true))
    let { data, status } = await axiosInstance.get('/unified_id_fields')
    let {
      data: connectorData,
      status: connectorStatus
    } = await axiosInstance.get('/connectors')
    if (status === 200 && connectorStatus === 200) {
      dispatch(unifiedIdSlice.actions.updateLoading(false))
      dispatch(unifiedIdSlice.actions.updateUnifiedIdData(data))
      dispatch(unifiedIdSlice.actions.updateConnector(connectorData))
    } else {
      dispatch(unifiedIdSlice.actions.updateLoading(false))
    }
  }
}

export const updateUnifiedData = modifiedData => {
  return async dispatch => {
    const data = {
      unified_id_field: { update_fields: modifiedData, delete_fields: [] }
    }
    const res = await axiosInstance.post(
      'unified_id_fields/update_or_delete',
      data
    )
  }
}

export const { updateUnifiedIdData, updateContacts } = unifiedIdSlice.actions

export default unifiedIdSlice.reducer
