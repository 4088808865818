/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react'
import Checkbox from '../../../ui/Checkbox/Checkbox'
import Editor from './Editor'
import ConditionForm from './ConditionForm'
import { useSelector, useDispatch } from 'react-redux'
import DropdownIcon from '../../../../assets/images/dropdown-caret.png'
import { sendTestMail } from '../../../../store/dashboard/mailerSlice'

import {
  campaignBuilderContainer,
  builderSideBarStyle,
  boxStyle,
  inputStyle,
  emailTypeStyle,
  builderContentStyle,
  builderHeaderStyle,
  testEmailContainer,
  senderStyle,
  backupSenderStyle,
  headerlabelStyle,
  labelStyle,
  triggeredBoxStyle,
  emailTypeBoxStyle,
  emailTypeContianerStyle,
  triggerTitleContainerStyle,
  audienceBox,
  inputWithErrorStyle,
  additionAudienceDropDownPopupStyle,
  recurringDropdownStyle,
  sendDateStyle,
  dropdownContainer,
  displayValueStyle,
  dropdownMenuStyle
} from './style'

const isValidEmail = email => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(String(email).toLowerCase())
}

const Dropdown = ({
  selectedValue,
  onSelect = () => {},
  label = '',
  options,
  style,
  isInvalid = false
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      css={dropdownContainer(isInvalid)}
      style={{ padding: 0, ...(style || {}) }}
    >
      <div
        onClick={() => setIsOpen(prev => !prev)}
        css={displayValueStyle}
        style={{ justifyContent: 'space-between' }}
      >
        <div
          style={{ flex: 1, lineHeight: '1.2', fontSize: 12, paddingTop: 5 }}
        >
          {selectedValue || label}
        </div>
        <img src={DropdownIcon} />
      </div>
      <div css={dropdownMenuStyle(isOpen)}>
        <ul>
          {options.map(({ label, value }) => (
            <li
              onClick={() => {
                setIsOpen(false)
                onSelect(value)
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const CampaignBuilder = ({
  updateEmailData = () => {},
  menuView,
  menuHandler = () => {}
}) => {
  const [showAdditionalAudiencePopup, toggleAdditionAudiencePopup] =
    useState(false)
  const [audiencePopupCoords, setAudiencePopupCoords] = useState({
    top: '',
    left: '',
    width: ''
  })
  const [recurringCriteriaCoords, setRecurringCriteriaCoords] = useState({
    width: ''
  })
  const [showRecurringCriteriaForm, setShowRecurringCriteriaForm] =
    useState(false)
  const additionalAudienceRef = useRef(null)
  const audiencePopupRef = useRef(null)
  const dispatch = useDispatch()

  const {
    emails = {},
    currentEditedSegmentandAudience,
    mailValidityObject,
    segmentMails = []
  } = useSelector(
    ({
      mailer: {
        emails,
        currentEditedSegmentandAudience,
        mailValidityObject,
        currentEditedSegmentandAudience: { segmentId } = {},
        emailTemplates: { [segmentId]: segmentMails }
      } = {}
    }) => ({
      emails,
      currentEditedSegmentandAudience,
      mailValidityObject,
      segmentMails
    })
  )
  const { segmentId, audienceId: audience } = currentEditedSegmentandAudience
  const { emailString } =
    segmentMails.find(({ audienceId }) => audienceId === audience) || {}
  const {
    id: audienceId,
    emailName = '',
    emailType,
    subjectLine = '',
    recurring,
    recurringRule = [{ prospectTag: '', condition: '', value: '' }],
    additionalAudienceFilter = [{ prospectTag: '', condition: '', value: '' }],
    sender = '',
    backupSender = '',
    testEmail = '',
    backupSenderEmail = '',
    customSenderEmail = '',
    sendDate = ''
  } = (emails[segmentId] || []).find(item => item && item.id === audience) || {}

  const [isTestEmailValid, setTestEmailValid] = useState(true)
  const [isBackupSenderEmailValid, setBackupEmailValid] = useState(true)
  const [isCustomSenderEmailValid, setCustomSenderEmailValid] = useState(true)

  const updateTriggerData = (ruleIndex, key, data) => {
    const prevData = JSON.parse(JSON.stringify(recurringRule))
    const prevRule = prevData[ruleIndex]
    const newRule = { ...prevRule, [key]: data }
    prevData.splice(ruleIndex, 1, newRule)
    updateEmailData(audienceId, 'recurringRule', prevData)
  }

  const deleteTriggerRule = ruleIndex => {
    const prevData = JSON.parse(JSON.stringify(recurringRule))
    prevData.splice(ruleIndex, 1)
    updateEmailData(audienceId, 'recurringRule', prevData)
  }

  const addTriggerRule = () => {
    const prevData = JSON.parse(JSON.stringify(recurringRule))
    prevData.push({ prospectTag: '', condition: '', value: '' })
    updateEmailData(audienceId, 'recurringRule', prevData)
  }

  const addAudienceRule = () => {
    const prevFilters = [...additionalAudienceFilter]
    prevFilters.push({ prospectTag: '', condition: '', value: '' })
    updateEmailData(audienceId, 'additionalAudienceFilter', prevFilters)
  }
  const deleteAudienceRule = ruleIndex => {
    if (ruleIndex >= 1) {
      const prevFilters = [...additionalAudienceFilter]
      prevFilters.splice(ruleIndex, 1)
      updateEmailData(audienceId, 'additionalAudienceFilter', prevFilters)
    }
  }
  const updateAudienceData = (ruleIndex, key, value) => {
    const prevFilters = [...additionalAudienceFilter]
    const prevRuleValue = prevFilters[ruleIndex]
    const updatedRule = { ...prevRuleValue, [key]: value }
    prevFilters.splice(ruleIndex, 1, updatedRule)
    updateEmailData(audienceId, 'additionalAudienceFilter', prevFilters)
  }

  const validateEmail = email => {
    const isValid = isValidEmail(email)
    setTestEmailValid(isValid)
  }

  const validateBackupEmail = email => {
    const isValid = isValidEmail(email)
    setBackupEmailValid(isValid)
  }

  const validateCustomSenderEmail = email => {
    const isValid = isValidEmail(email)
    setCustomSenderEmailValid(isValid)
  }

  const updateAudienncePopCoordinate = ({ currentTarget }) => {
    setAudiencePopupCoords({
      width: currentTarget.offsetWidth
    })
  }

  const updateRecurringCriteriaCoords = ({ currentTarget }) => {
    setRecurringCriteriaCoords({
      width: currentTarget.offsetWidth
    })
  }

  const getMailValidityFields = () => {
    const { segmentId } = currentEditedSegmentandAudience || {}
    const fields = (mailValidityObject[segmentId] || {})[emailName] || {}

    return Object.keys(fields).filter(key => !fields[key])
  }

  const sendTestEmail = () => {
    if (!testEmail || !subjectLine || !emailString) {
      return
    }
    const payload = {
      test_email: testEmail,
      subject: subjectLine,
      template_string: window.btoa(encodeURIComponent(emailString) || '')
    }
    dispatch(sendTestMail(payload))
  }

  return (
    <div css={campaignBuilderContainer}>
      <div css={builderSideBarStyle(!menuView)}>
        {/* <span css={collapseButton} onClick={menuHandler}>
          <img alt='collapseIcon' src={collapseIcon} />
        </span> */}
        <div className='column'>
          <div css={boxStyle}>
            <label css={labelStyle}>Email Name</label>
            <input
              data-isInvalid={getMailValidityFields().includes('emailName')}
              css={inputStyle}
              value={emailName}
              onChange={({ target: { value } }) =>
                updateEmailData(audienceId, 'emailName', value)
              }
            />
          </div>
          <div css={boxStyle}>
            <label css={labelStyle}>Subject Line</label>
            <input
              data-isInvalid={getMailValidityFields().includes('subjectLine')}
              css={inputStyle}
              value={subjectLine}
              onChange={({ target: { value } }) =>
                updateEmailData(audienceId, 'subjectLine', value)
              }
            />
          </div>
        </div>
        <div className='column' style={{ paddingTop: 2 }}>
          <div css={senderStyle}>
            <label css={labelStyle}>Sender</label>
            <div style={{ display: 'flex' }}>
              <Dropdown
                isInvalid={getMailValidityFields().includes('sender')}
                style={{ width: '50%' }}
                options={[
                  { label: 'Assigned User', value: 'Assigned User' },
                  { label: 'Custom', value: 'Custom' }
                ]}
                selectedValue={sender}
                onSelect={value => updateEmailData(audienceId, 'sender', value)}
              />

              {sender === 'Custom' && (
                <div css={inputWithErrorStyle}>
                  <input
                    css={inputStyle}
                    value={customSenderEmail}
                    className={!isCustomSenderEmailValid ? 'not-valid' : ''}
                    onBlur={() => validateCustomSenderEmail(customSenderEmail)}
                    onChange={({ target: { value } }) =>
                      updateEmailData(audienceId, 'customSenderEmail', value)
                    }
                  />
                  <p
                    className={`error-message ${
                      !isCustomSenderEmailValid ? 'show' : ''
                    }`}
                  >
                    Please enter a valid email
                  </p>
                </div>
              )}
            </div>
          </div>
          <div css={backupSenderStyle}>
            <label css={labelStyle}>Backup Sender</label>
            <div style={{ display: 'flex' }}>
              <Dropdown
                isInvalid={getMailValidityFields().includes('backupSender')}
                options={[{ label: 'Custom', value: 'Custom' }]}
                selectedValue={backupSender}
                style={{ width: '50%' }}
                onSelect={value =>
                  updateEmailData(audienceId, 'backupSender', value)
                }
              />

              <div css={inputWithErrorStyle}>
                <input
                  css={inputStyle}
                  value={backupSenderEmail}
                  className={!isBackupSenderEmailValid ? 'not-valid' : ''}
                  onBlur={() => validateBackupEmail(backupSenderEmail)}
                  onChange={({ target: { value } }) =>
                    updateEmailData(audienceId, 'backupSenderEmail', value)
                  }
                />
                <p
                  className={`error-message ${
                    !isBackupSenderEmailValid ? 'show' : ''
                  }`}
                >
                  Please enter a valid email
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          css={emailTypeContianerStyle}
          className='column'
          style={{ paddingTop: 2 }}
        >
          <div css={emailTypeBoxStyle(emailType && emailType === 'triggered')}>
            <label
              css={labelStyle}
              style={{ marginBottom: '8px', display: 'inline-block' }}
            >
              Email Type
            </label>
            <div
              data-isInvalid={getMailValidityFields().includes('emailType')}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              className='emailTypeWrapper'
            >
              <div
                css={emailTypeStyle}
                data-selected={emailType === 'one-time'}
                onClick={() =>
                  updateEmailData(audienceId, 'emailType', 'one-time')
                }
              >
                One-Time
              </div>
              <div
                css={emailTypeStyle}
                data-selected={emailType === 'triggered'}
                onClick={() =>
                  updateEmailData(audienceId, 'emailType', 'triggered')
                }
              >
                Triggered
              </div>
            </div>
          </div>
          {emailType && emailType === 'one-time' && (
            <div css={sendDateStyle}>
              <div css={labelStyle}>Start Date</div>
              <input
                data-isInvalid={getMailValidityFields().includes('sendDate')}
                css={inputStyle}
                type='date'
                value={sendDate}
                onChange={({ target: { value } = {} }) =>
                  updateEmailData(audienceId, 'sendDate', value)
                }
              />
            </div>
          )}

          {(!emailType || emailType === 'triggered') && (
            <div style={{ marginTop: 10 }}>
              <label css={labelStyle}>Trigger Criteria</label>
              <div
                data-isInvalid={getMailValidityFields().includes(
                  'triggerCriteria'
                )}
                style={emailType === 'one-time' ? { opacity: 0.2 } : {}}
                css={recurringDropdownStyle(showRecurringCriteriaForm)}
                onClick={event => {
                  event.stopPropagation()
                  if (emailType === 'triggered') {
                    setShowRecurringCriteriaForm(prev => !prev)
                    updateRecurringCriteriaCoords(event)
                  }
                }}
              >
                <div style={{ fontSize: 12 }}>
                  {recurringRule.length &&
                  Object.values(recurringRule[0]).some(ele => ele != '')
                    ? recurringRule.length
                    : 0}{' '}
                  Filters Applied
                  <img
                    src={DropdownIcon}
                    alt='dropdown-caret'
                    className={`dropdown-caret ${
                      showRecurringCriteriaForm ? 'rotate-up' : ''
                    }`}
                  />
                </div>
              </div>
              <div
                style={{ position: 'absolute', ...recurringCriteriaCoords }}
                css={triggeredBoxStyle(showRecurringCriteriaForm)}
              >
                <div css={triggerTitleContainerStyle}>
                  <label>Trigger Criteria</label>
                  <div>
                    <span className='recurring-text'>Recurring ?</span>
                    <Checkbox
                      onChange={({ target: { checked } }) =>
                        updateEmailData(audienceId, 'recurring', checked)
                      }
                      checked={recurring || false}
                    />
                  </div>
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '210px' }}>
                  {recurringRule.map((rule, index) => (
                    <ConditionForm
                      data={rule}
                      key={`recurring - ${index}`}
                      ruleIndex={index}
                      addRule={addTriggerRule}
                      deleteRule={deleteTriggerRule}
                      updateData={updateTriggerData}
                    />
                  ))}
                </div>
                <span className='add-trigger-rule' onClick={addTriggerRule}>
                  +
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='column'>
          <div css={testEmailContainer}>
            <label css={labelStyle}>Send Test Email</label>
            <div style={{ display: 'flex' }}>
              <div css={inputWithErrorStyle}>
                <input
                  css={inputStyle}
                  value={testEmail}
                  className={!isTestEmailValid ? 'not-valid' : ''}
                  onBlur={() => validateEmail(testEmail)}
                  onChange={({ target: { value } }) =>
                    updateEmailData(audienceId, 'testEmail', value)
                  }
                />
                <p
                  className={`error-message ${!isTestEmailValid ? 'show' : ''}`}
                >
                  Please enter a valid email
                </p>
              </div>
              <button onClick={sendTestEmail}>Send</button>
            </div>
          </div>
          <div css={[boxStyle, audienceBox]}>
            <label css={labelStyle}>Additional Audience Filters</label>
            <div
              ref={additionalAudienceRef}
              onClick={event => {
                toggleAdditionAudiencePopup(prev => !prev)
                updateAudienncePopCoordinate(event)
              }}
              css={recurringDropdownStyle(showAdditionalAudiencePopup)}
            >
              <div style={{ fontSize: 12 }}>
                {additionalAudienceFilter.length &&
                Object.values(additionalAudienceFilter[0]).some(
                  ele => ele != ''
                )
                  ? additionalAudienceFilter.length
                  : 0}{' '}
                Filters Applied
                <img
                  src={DropdownIcon}
                  alt='dropdown-caret'
                  className={`dropdown-caret ${
                    showAdditionalAudiencePopup ? 'rotate-up' : ''
                  }`}
                />
              </div>
            </div>
            <div
              ref={audiencePopupRef}
              css={additionAudienceDropDownPopupStyle(
                showAdditionalAudiencePopup
              )}
              style={{
                ...audiencePopupCoords,
                position: 'absolute',
                maxHeight: 300,
                background: '#fff',
                overflow: 'auto'
              }}
            >
              {additionalAudienceFilter.map((audience, index) => {
                return (
                  <ConditionForm
                    key={`audience-${index}`}
                    data={audience}
                    ruleIndex={index}
                    addRule={addAudienceRule}
                    deleteRule={deleteAudienceRule}
                    updateData={updateAudienceData}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div css={builderContentStyle(menuView)}>
        <div css={builderHeaderStyle}>
          <label css={[labelStyle, headerlabelStyle]}>Email Builder</label>
        </div>
        <Editor />
      </div>
    </div>
  )
}

export default CampaignBuilder
