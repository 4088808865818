import { css } from '@emotion/react/macro'

export const modalContainer = (clientX, clientY) => css`
padding: 10px 20px;
width: fit-content;
height: fit-content;
position: fixed;
left: ${clientX + 10}px;
top: ${clientY + 10}px;
background: #fff;
border: 0.8px solid #ccc;
`

export const modalContent = css``

export const labelStyle = css`
font-weight: 600;
`

export const tableRowStyle = css`
&:nth-child(odd) {
    background: rgb(245, 245, 245);
}
`

export const tableHeaderStyle = css`
padding: 0 10px;
font-size: 14px;
color: rgba(0, 0, 0, 0.6);
`

export const tableCellStyle = css`
padding: 0 10px;
font-size: 12px;
color: rgba(0, 0, 0, 0.6);
`
