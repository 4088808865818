/** @jsxImportSource @emotion/react */
import React from 'react'
import Icon from '@material-ui/core/Icon'
import { useLocation, Link } from 'react-router-dom'
import DashboardIcon from '../../../assets/images/dashboard/dashboardIcon.png'
import WhiteDashboardIcon from '../../../assets/images/dashboard/white-dashboard.png'
import UnifiedIcon from '../../../assets/images/cdpNavigationBar/unifiedIdsIcon.svg'
import {
  iconContainer,
  icon,
  iconText,
  navigationButtonContainer
} from './style'

export default ({ style }) => {
  const { pathname } = useLocation()
  return (
    <div css={navigationButtonContainer} style={{ ...style }}>
      <Link to='/cdp/view-contacts' style={{ textDecoration: 'none' }}>
        <div
          css={iconContainer}
          className={pathname.includes('/cdp') ? 'active' : ''}
        >
          <Icon css={icon}>
            <img src={UnifiedIcon} alt='unify-icon' className='iconImage' />
          </Icon>
          <span css={iconText} className='icon-text'>
            Unify
          </span>
        </div>
      </Link>
      <Link to='/dashboard' style={{ textDecoration: 'none' }}>
        <div
          css={iconContainer}
          className={
            pathname === '/dashboard' || pathname === '/' ? 'active' : ''
          }
        >
          <Icon css={icon}>
            <img
              src={
                pathname === '/dashboard' || pathname === '/'
                  ? WhiteDashboardIcon
                  : DashboardIcon
              }
              alt='dashboard-icon'
              className='iconImage'
            />
          </Icon>
          <span css={iconText} className='icon-text'>
            Activate
          </span>
        </div>
      </Link>
    </div>
  )
}
