import { css } from '@emotion/react'

export const iconText = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 10px;
  font-weight: 300;
`

export const iconContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 52px;
  height: 52px;
  border: 0.8px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  &.active {
    background: #18a3e2;
    .icon-text {
      color: #fff;
    }
  }
`

export const icon = css`
  width: 22px;
  height: 22px;
  overflow: unset;
  margin-bottom: 5px;
  .iconImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const navigationButtonContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
`
