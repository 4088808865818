import { css } from '@emotion/react/macro'

export const setWidthOfForm = (cardLength = 1) => css`
  width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
  min-width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
`

export const cardContainerStyle = (
  expandedView,
  audienceIconClicked,
  rightMinimizeHoverFlag,
  leftMinimizeHoverFlag,
  cardLength,
  cardEditableState,
  isValid
) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    height: 86px;
    margin-bottom: 0px;
    border: 0.8px solid #CCCCCC;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    position: relative;
    box-shadow: 0 2px 6px -2px #0099FF;
    transition: all 0.4s ease-out;
    cursor: pointer;
    margin: 0 auto;
    ${cardEditableState && 'opacity: 0.6;'}
    &.active {
      opacity: 1;
    }
    .toggleHeight {
        height: 0px;
        width: 0px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    @media (min-width: 1441px) {
      margin: 12px 24px;
      margin-bottom: 0;
    }
    ${cardLength > 1 && !rightMinimizeHoverFlag && 'justify-content: center;'}
    ${
      expandedView &&
      'border-bottom: 0.8px solid #FFFFFF; border-radius: 4px 4px 0px 0px;'
    }
    ${expandedView && '.toggleHeight { height: 24px; width: 24px; }'}
    &:hover {
        ${!expandedView && '.toggleHeight { height: 24px; width: 24px; }'}
    }
    ${
      audienceIconClicked &&
      'border-bottom: 0.8px solid #FFFFFF; border-radius: 4px 4px 0px 0px; box-shadow: 0 2px 6px -2px #FFFFFF;'
    }
    .channelMediaContainer {
        display: flex;
        justify-content: center;
        ${rightMinimizeHoverFlag && cardLength > 2 && 'flex: 2;'}
    }
    ${setWidthOfForm(cardLength)}
    ${
      rightMinimizeHoverFlag &&
      cardLength > 1 &&
      'justify-content: space-between; padding-right: 0px;'
    }
    ${
      leftMinimizeHoverFlag &&
      cardLength > 1 &&
      'justify-content: space-between; padding-left: 0px;'
    }
  ${!isValid && 'border: 2px solid #ff00008c; box-shadow: 0 2px 6px -2px red;'}
      
    
`

export const emailNameStyle = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  display: block;
  padding-bottom: 2px;
  border-bottom: 0.8px solid #ccc;
`

export const emailTypeStyle = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  display: block;
  text-transform: capitalize;
  padding-top: 5px;
`

export const addAudienceIconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -15px;
  left: 0;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

export const addedAudienceIconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  .additionalSelectedAudiences {
    font-family: BentonSans;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
  }
`

export const selectedAudienceIconList = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -15px;
  left: 0;
`

export const seletedAudienceIconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

const getStyleForAudienceListSection = (count, type) => css`
  height: auto;
  overflow: ${type === 'container' ? 'hidden' : 'auto'};
  padding-bottom: 8px;
  border: 0.8px solid #cccccc;
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
`

export const audienceListActionButtonContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 11px 0px 11px;
`

export const audienceListActionButton = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  padding-top: 2px;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  width: 99px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  &.saveButton {
    color: #ffffff;
    background-color: #18a3e2;
  }
`

export const mailIcon = css`
  width: 61px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.8px solid #cccccc;
  margin-right: 10px;
  box-shadow: 0 2px 6px -2px rgb(102 153 204 / 40%);
  border-radius: 4px;
  img {
    width: 38px;
    height: 38px;
    object-fit: contain;
  }
`

export const audienceListContainer = (
  cardLength,
  audienceIconClicked,
  audienceCount
) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 6px -4px #0099ff;
  height: 0px;
  overflow: hidden;
  margin-left: 12px;
  transition: all 0.4s ease-out;
  @media (min-width: 1441px) {
    margin-left: 24px;
  }
  ${setWidthOfForm(cardLength)}
  ${
    audienceIconClicked &&
    getStyleForAudienceListSection(audienceCount, 'container')
  }
`

export const audienceListSection = (audienceIconClicked, audienceCount) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0px;
  width: 231.4px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -4px #0099ff;
  transition: all 0.4s ease-out;
  ${audienceIconClicked && getStyleForAudienceListSection(audienceCount)}
  border: 0px;
  box-shadow: 0 2px 6px -4px #ffffff;

  .audienceCard {
    width: 200px;
  }
`

export const minimizeFromLeft = leftMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${
    leftMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-left: 0px; background-color: rgba(229, 229, 229, 1);'
  }
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const minimizeFromRight = rightMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${
    rightMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-right: 0px; background-color: rgba(229, 229, 229, 1);'
  }
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const extensionButton = (cardLength = 1) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  height: 88px;
  width: 14px;
  overflow: hidden;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  label {
    color: #ffffff;
    font-family: BentonSans;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
  }
  &.leftExtension {
    position: absolute;
    left: -14px;
    ${cardLength > 1 && 'left: -28px;'}
  }
  &.leftMinimize {
    position: absolute;
    left: -14px;
  }
  &.rightExtension {
    position: absolute;
    right: -14px;
    ${cardLength > 1 && 'right: -28px;'}
  }
  &.rightMinimize {
    position: absolute;
    right: -14px;
  }
`

export const rightExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(100%, 0);
  right: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'right: -28px;'}
`

export const leftExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(-100%, 0);
  left: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'left: -28px;'}
`

export const rightExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${
    extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
  }
  ${
    extensionHoverFlag &&
    cardLength === 1 &&
    'width: 250.4px; min-width: 250.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
  }
`

export const leftExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${
    extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
  }
  ${
    extensionHoverFlag &&
    cardLength === 1 &&
    'width: 251.4px; min-width: 251.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
  }
`

export const mailContentContainer = (cardLength, expandedView) => css`
  ${setWidthOfForm(cardLength)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  box-shadow: 0 2px 6px -4px #0099ff;
  height: 0px;
  overflow: hidden;
  margin-left: 12px;
  transition: all 0.4s ease-out;
  @media (min-width: 1441px) {
    margin-left: 24px;
    ${expandedView && 'margin-top: -3px; padding-top: 20px;'}
  }
  ${
    expandedView &&
    'height: auto; border: 0.8px solid #CCCCCC; border-top: 0px;'
  }
`

export const mailActionButton = isMailEditable => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
    width: 47px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
  .edit {
    ${isMailEditable && 'background-color: #EBEBEB;'}
  }
`

export const copyActionIconContainer = copyButtonClicked => css`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  right: 0px;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.88);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${copyButtonClicked && 'display: flex;'}
`

export const emailDetails = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 228px;
  overflow: auto;
  .row {
    margin-top: 10px;
  }
  .label {
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  .value {
    background-color: #f5f5f5;
    padding: 10px;
    font-weight: 500;
    min-height: 40px;
    line-height: 1.5;

    .inputBox {
      box-sizing: border-box;
      height: 40px;
      padding: 13px 8px;
      border: 0.8px solid #cccccc;
      background-color: #ffffff;
      line-height: 10px;
      font-weight: 300;
      margin: 8px 0;
      border-radius: 4px;
    }

    .matchType,
    .and {
      margin: 10px 0 15px 0;
      font-weight: 300;
    }
    .and {
      text-align: center;
    }
  }
`

export const previewStyle = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 68px;
  width: 68px;
  border-radius: 4px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  margin-right: 10px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`
