/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import SideDrawer from '../SideDrawer'
import CountUp from 'react-countup'
import { useSelector } from 'react-redux'

import onboardingRocket from '../../../assets/images/onboardingDashboard/onboardingRocket.png'

import {
  takeToDashboardSection,
  content,
  contactsImported,
  accountsImported,
  audienceCreated,
  duplicateRecords,
  loader
} from './styles'
import { actionButton } from '../CrmIntegrationModalForm/style'

const TakeToDashboard = React.forwardRef(
  (
    {
      selectedSection,
      scrollHandler = () => {},
      setSelectedSection = () => {},
      onboardHandler = () => {}
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true)
    const syncReportData = useSelector(state => state.onboarding.syncReportData)

    const takeMeToDashboardHandler = () => {
      onboardHandler()
    }

    useEffect(() => {
      if (Object.keys(syncReportData).length > 0) {
        setLoading(false)
      }
    }, [syncReportData])

    return (
      <section id='takeToDashboard' ref={ref} css={takeToDashboardSection}>
        <SideDrawer
          section={'takeToDashboard'}
          selectedSection={selectedSection}
        />
        <div
          css={content}
          data-selected={selectedSection === 'takeToDashboard'}
        >
          <div style={{ height: '65px' }}></div>
          <img src={onboardingRocket} alt='onboardingRocket'></img>
          <div css={contactsImported}>
            {loading ? (
              <div css={loader} />
            ) : (
              <CountUp
                decimal=','
                className='dataCount'
                start={0}
                end={syncReportData.contacts_synced}
              />
            )}
            <span>Contacts Imported</span>
          </div>
          <div css={accountsImported}>
            {loading ? (
              <div css={loader} />
            ) : (
              <CountUp
                decimal=','
                className='dataCount'
                start={0}
                end={syncReportData.accounts_synced}
              />
            )}
            <span>Accounts Imported</span>
          </div>
          <div css={audienceCreated}>
            {loading ? (
              <div css={loader} />
            ) : (
              <CountUp
                decimal=','
                className='dataCount'
                start={0}
                end={syncReportData.audience_created}
              />
            )}
            <span>Audiences Created</span>
          </div>
          <div css={duplicateRecords}>
            {loading ? (
              <div css={loader} />
            ) : (
              <CountUp
                decimal=','
                className='dataCount'
                start={0}
                end={syncReportData.duplicate_records}
              />
            )}
            <span>Duplicate Records Flagged</span>
          </div>
          <Link to='/dashboard' style={{ textDecoration: 'none' }}>
            <button
              css={actionButton(loading)}
              onClick={takeMeToDashboardHandler}
            >
              Take me to my dashboard!
            </button>
          </Link>
          <div style={{ height: '65px' }}></div>
        </div>
      </section>
    )
  }
)

export default TakeToDashboard
