import { css } from '@emotion/react'

export const headerCard = expandedView => css`
    display: flex;
    z-index: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 86px;
    border: 0.8px solid #CCCCCC;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px #0099FF;
    .toggleHeight {
        height: 0px;
        width: 0px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    ${expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
    &:hover {
        ${!expandedView && '.toggleHeight { height: 28px; width: 28px; }'}
    }
`

export const expandIcon = css`

`

export const iconSection = css`
    display: inherit;
    justify-content: center;
    align-items: center;
    span {
        display: inherit;
        justify-content: inherit;
        align-items: center;
        height: 68px;
        width: 68px;
        border: 0.8px solid #CCCCCC;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 6px -2px rgba(102,153,204,0.4);
        .image1 {
            height: 33px;
            width: 47px;
        }
        .image2 {
            height: 33px;
            width: 28px;
        }
        .image3 {
            height: 34px;
            width: 34px;
        }
    }
    .iconBox2 {
        margin-left: 73px;
    }
    .iconBox3 {
        margin-left: 74px;
    }
`

export const customAudiences = css`
    height: 44px;
    width: 133px;
    color: #333333;
    font-family: BentonSans;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 7px;
    text-align: center;
    margin-left: 198px;
    margin-top: 12px;
    .audienceCount {
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        position: relative;
        line-height: 1.2;
    }
    .targets::before {
        content: '';
        height: 0.5px;
        width: 120px;
        position: absolute;
        top: 0;
        left: calc(50% - 60px);
        background: #ccc;
    }
    .targets {
        display: inline-block;
        padding-top: 5px;
        position: relative;
        margin-top: 5px;
        line-height: 1.5;
    }
    
`
