/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import SideDrawer from './SideDrawer'
import CampaignContainer from './CampaignContainer'
import CampaignBuilder from './CampaignBuilder'
import SideMenu from './SideMenu'
import { useDispatch, useSelector } from 'react-redux'
import { updateEmails } from '../../../store/dashboard/mailerSlice'

import { mailerContainerStyle, contentStyle, mainContentStyle } from './style'

const getAllAudiences = (audienceDataFromApi, segmentAudienceData) => {
  const audienceCollection = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    if (audienceDataFromApi[segmentId]) {
      audienceCollection[segmentId] = [
        segmentAudienceData[segmentId],
        ...audienceDataFromApi[segmentId]
      ]
    } else {
      audienceCollection[segmentId] = [segmentAudienceData[segmentId]]
    }
  })
  return audienceCollection
}

const getInitialMailCampaignData = segmentAudienceData => {
  const data = {}
  Object.keys(segmentAudienceData).forEach(segmentId => {
    data[segmentId] = [{}]
  })
  return data
}

const createSegmentIdLinkedList = campaignData => {
  const segmentList = Object.keys(campaignData)
  const segmentLinkedMapper = {}
  segmentList.forEach((key, index) => {
    segmentLinkedMapper[key] = {
      prev: segmentList[index - 1] || null,
      next: segmentList[index + 1] || null
    }
  })
  return segmentLinkedMapper
}

const Mailer = ({ campaignName, setCampaignName, editingCampaignData }) => {
  const dispatch = useDispatch()
  const [menuView, setMenuView] = useState(false)
  const [isEditingCampaign, setIsEditingCampaign] = useState(false)

  const {
    emails,
    audienceDataFromApi,
    segmentAudienceData,
    currentEditedSegmentandAudience
  } = useSelector(
    ({
      mailer: {
        emails,
        templateTree,
        emailTemplates,
        currentEditedSegmentandAudience
      } = {},
      audiences: { audienceDataFromApi, segmentAudienceData } = {}
    }) => ({
      emails,
      templateTree,
      emailTemplates,
      audienceDataFromApi,
      segmentAudienceData,
      currentEditedSegmentandAudience
    })
  )

  useEffect(() => {
    if (editingCampaignData) {
      setIsEditingCampaign(true)
    }
  }, [editingCampaignData])

  const [mailCampaignData, setMailCampaignData] = useState(
    getInitialMailCampaignData(segmentAudienceData)
  )

  const [segmentLinkedMapper, setSegmentLinkedMapper] = useState(
    createSegmentIdLinkedList(mailCampaignData)
  )

  const audienceCollection = getAllAudiences(
    audienceDataFromApi,
    segmentAudienceData
  )

  useEffect(() => {
    if (segmentAudienceData && Object.keys(segmentAudienceData).length > 0) {
      const initialMailCampaignData =
        editingCampaignData || getInitialMailCampaignData(segmentAudienceData)
      setMailCampaignData(initialMailCampaignData)
      setSegmentLinkedMapper(createSegmentIdLinkedList(initialMailCampaignData))
    }
  }, [segmentAudienceData])

  useEffect(() => {
    dispatch(updateEmails(mailCampaignData))
  }, [mailCampaignData])

  const updateEmailData = (_, field, data) => {
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    let currentEditingMail = JSON.parse(
      JSON.stringify(
        (emails[segmentId] || []).find(item => item.id === audienceId) || {}
      )
    )
    let emailClone = JSON.parse(JSON.stringify(emails))
    currentEditingMail[field] = data
    const { createdFromSegments, id } = currentEditingMail
    createdFromSegments.forEach(segment => {
      const mailIndex = emails[segment].findIndex(item => item.id === id)
      if (mailIndex > -1) {
        emailClone[segment].splice(mailIndex, 1, currentEditingMail)
      }
    })
    setMailCampaignData(emailClone)
  }

  const menuHandler = () => {
    setMenuView(prev => !prev)
  }

  const isEditMode = Object.values(currentEditedSegmentandAudience).length > 0

  return (
    <div css={mailerContainerStyle}>
      <>
        <div css={contentStyle}>
          <SideDrawer blankSideNav />
          <CampaignContainer
            audienceCollection={audienceCollection}
            mailCampaignData={mailCampaignData}
            segmentLinkedMapper={segmentLinkedMapper}
            setMailCampaignData={setMailCampaignData}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            isEditingCampaign={isEditingCampaign}
          />
        </div>
        {isEditMode && (
          <div>
            <div css={contentStyle}>
              <SideMenu menuView={menuView} menuHandler={menuHandler} />
              <div css={mainContentStyle} style={{ paddingTop: 0 }}>
                <CampaignBuilder
                  menuView={menuView}
                  menuHandler={menuHandler}
                  updateEmailData={updateEmailData}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default Mailer
