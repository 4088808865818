/** @jsxImportSource @emotion/react */
import React from 'react'

import SideDrawer from '../SideDrawer'

import { emptySlotSection, emptySlotContent } from './styles'

const BlankSlotSection = ({ sectionTopTo }) => {
  return (
    <div id='emptySlot' css={emptySlotSection}>
      <SideDrawer section={'emptySlot'} />
      <div css={emptySlotContent(sectionTopTo)}></div>
    </div>
  )
}

export default BlankSlotSection
