import { css } from '@emotion/react'

export const container = isSelected => css`
  display: flex;
  height: 70px;
  min-height: 70px;
  width: 215.4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  cursor: pointer;
  ${isSelected && 'box-shadow: 0 2px 6px 0px #0099FF;'}
  .iconBox {
    height: 55px;
    width: 55px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
    display: inherit;
    justify-content: center;
    align-items: inherit;
    border-radius: 4px;
    background-color: #ffffff;
    .neyo {
      height: 33px;
      width: 47px;
    }
    .salesforce {
      height: 33px;
      width: 47px;
    }
    .bombora {
      height: 33px;
      width: 28px;
    }
  }
`

export const audienceAccountData = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 6px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  .audienceName {
    cursor: pointer;
  }
  hr {
    box-sizing: border-box;
    height: 0.8px;
    width: 105px;
    border: none;
    border-bottom: 0.8px solid #cccccc;
    margin-top: 7px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`
