import { css } from '@emotion/react'

const activeStyle = css`
  border: 2px solid #18a3e2;
`

export const editableButtonContainer = isActive => css`
  &:hover {
    border: 1px solid #18a3e2;
  }
  ${isActive && activeStyle}
`
