import { css } from '@emotion/react'

export const container = css`
  display: flex;
`

export const content = css`
  box-sizing: content-box;
  overflow: auto;
  padding: 14px 12px;
  width: 1407px;
`

const selectAudienceStyle = css`
  border: unset;
  border-radius: unset;
  background-color: unset;
  box-shadow: unset;
`

export const contentCard = expandedView => {
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 0px;
    border-radius: 4px;
    background-color: #e5e5e5;
    box-shadow: 0 2px 6px -2px #0099ff;
    margin-bottom: 17px;
    transition: all 0.5s ease-out;
    overflow: hidden;
    ${expandedView && 'max-height: 5000px;'}
  `
}

export const addAudienceContainer = css`
  box-sizing: border-box;
  height: 132px;
  width: 241px;
  min-width: 241px;
  border: 4px dashed #cccccc;
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  padding: 13px 24px 20px 21px;
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const dummyNewAudienceForm = css`
  box-sizing: border-box;
  height: 82px;
  width: 231.4px;
  min-width: 231.4px;
  padding: 13px 24px 20px 21px;
  margin: 10px auto;
  margin-bottom: 0px;
`

export const dummyAudienceCard = css`
  box-sizing: border-box;
  height: 82px;
  min-height: 82px;
  width: 231.4px;
  min-width: 231.4px;
  padding: 13px 24px 20px 21px;
  margin: 10px auto;
  margin-bottom: 0px;
`

export const audienceWithCheckboxStyle = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .checkbox + label:before {
    width: 16px;
    height: 16px;
  }
  .checkbox:checked + label:after {
    left: 2px;
  }
`
