import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: {
    segments: [],
    campaigns: []
  },
  expandAudience: false
}

const topfoldSlice = createSlice({
  name: 'topfold',
  initialState,
  reducers: {
    updateFilter (state, action) {
      const updatedFilter = { ...state.filters, ...action.payload }
      state.filters = updatedFilter
    },
    updateExpandAudience (state, action) {
      state.expandAudience = action.payload
    }
  }
})

export const { updateFilter, updateExpandAudience } = topfoldSlice.actions

export default topfoldSlice.reducer
