/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  updateCurrentEditingComponent,
  handleElementDelete,
  updateMovedElement
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  preHeaderContainer,
  controlPanelStyle,
  controlItemStyle
} from './style'
import TrashIcon from '../../../../../../../assets/images/trash-icon.png'
import ArrowUp from '../../../../../../../assets/images/arrow-up.png'

const PreHeader = ({ align, parentPath }) => {
  const dispatch = useDispatch()
  const [showControlPanel, setShowControlPanel] = useState(false)

  const [preHeaderEditorObj, updatePreheaderObj] = useState({})

  const { editingPath, editingComponents, currentEditedSegmentandAudience } =
    useSelector(
      ({
        mailer: {
          currentEditingComponent: { path: editingPath },
          editingComponents,
          currentEditedSegmentandAudience
        }
      }) => ({
        editingPath,
        editingComponents,
        currentEditedSegmentandAudience
      })
    )

  useEffect(() => {
    let editorObj = {
      preHeaderText: 'If you cannot see images',
      linkToWeb: '',
      size: '12px',
      lineHeight: '1.5',
      color: '#000',
      linkColor: '#17a3e1',
      alignment: align,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '12px',
      paddingRight: '12px',
      backgroundColor: '#fff',
      file: '',
      imageStyle: 'contain'
    }
    let path = `${parentPath}`
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        editorObj = { ...editorObj, ...(editingAudience[path] || {}) }
      }
    }
    updatePreheaderObj(editorObj)
  }, [editingComponents, editingPath])

  const onPreHeaderSelect = () => {
    let parentUid
    switch (align) {
      case 'left':
        parentUid = 0
        break
      case 'center':
        parentUid = 0
        break
      case 'right':
        parentUid = 0
        break
      default:
        break
    }
    dispatch(
      updateCurrentEditingComponent({
        path: `${parentPath}`,
        type: 'preheader',
        parentUid
      })
    )
  }

  const getComponentId = () => {
    let parentUid
    switch (align) {
      case 'left':
        parentUid = 13
        break
      case 'center':
        parentUid = 14
        break
      case 'right':
        parentUid = 15
        break
      default:
        break
    }
    return parentUid
  }

  const handleContainerDelete = () => {
    dispatch(
      handleElementDelete({
        componentId: getComponentId(align),
        index: parentPath - 1
      })
    )
  }

  const handleContainerMove = direction => {
    dispatch(
      updateMovedElement({
        componentId: getComponentId(align),
        index: parentPath - 1,
        direction
      })
    )
  }
  return (
    <div
      onMouseEnter={() => setShowControlPanel(true)}
      onMouseLeave={() => setShowControlPanel(false)}
      style={{
        backgroundColor: preHeaderEditorObj.backgroundColor,
        backgroundImage: `url(${preHeaderEditorObj.file})`,
        padding: `${preHeaderEditorObj.paddingTop} ${preHeaderEditorObj.paddingRight} ${preHeaderEditorObj.paddingBottom} ${preHeaderEditorObj.paddingLeft}`,
        backgroundPosition:
          preHeaderEditorObj.imageStyle !== 'cover' ? 'center center' : 'unset',
        backgroundRepeat:
          preHeaderEditorObj.imageStyle === 'repeat' ? 'repeat' : 'no-repeat',
        backgroundSize:
          preHeaderEditorObj.imageStyle !== 'repeat'
            ? preHeaderEditorObj.imageStyle
            : 'cover'
      }}
      onClick={event => {
        event.stopPropagation()
        onPreHeaderSelect()
      }}
      css={preHeaderContainer(`${parentPath}` === editingPath)}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start'
        }}
      >
        <div
          style={{
            textAlign: preHeaderEditorObj.alignment,
            fontFamily: preHeaderEditorObj.font,
            fontSize: preHeaderEditorObj.size,
            color: preHeaderEditorObj.color,
            lineHeight: preHeaderEditorObj.lineHeight,
            width: '100%'
          }}
        >
          {preHeaderEditorObj.preHeaderText},
          <a
            style={{
              color: preHeaderEditorObj.linkColor,
              textDecoration: 'none'
            }}
            target='_blank'
            href={preHeaderEditorObj.linkToWeb}
          >
            {' '}
            {preHeaderEditorObj.linkToWeb || 'click here'}
          </a>
        </div>
      </div>
      {showControlPanel && (
        <div css={controlPanelStyle}>
          <div css={controlItemStyle} onClick={handleContainerDelete}>
            <img src={TrashIcon} />
          </div>
          <div css={controlItemStyle} onClick={() => handleContainerMove('up')}>
            <img src={ArrowUp} />
          </div>
          <div
            css={controlItemStyle}
            onClick={() => handleContainerMove('down')}
          >
            <img src={ArrowUp} className='arrowDown' />
          </div>
        </div>
      )}
    </div>
  )
}

export default PreHeader
