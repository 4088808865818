/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import ArrowDown from '../../../assets/images/arrowDown.svg'
import ArrowUp from '../../../assets/images/arrowUp.svg'
import Styles from './style.module.css'
import Portal from '../../../customHooks/portal'

class Dropdown extends Component {
  constructor (props) {
    super(props)
    const { title, list } = this.props

    this.state = {
      isListOpen: false,
      title,
      selectedItem: null,
      keyword: '',
      list,
      coords: {
        left: '',
        top: '',
        width: ''
      }
    }

    this.searchField = React.createRef()
    this.buttonRef = React.createRef()
    this.panelRef = React.createRef()
  }

  updateDropdownCoordinate (button) {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    this.setState({
      coords: {
        left, // add half the width of the button for centering
        top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
        width: button.offsetWidth
      }
    })
  }

  componentDidMount () {
    const { select } = this.props

    if (select) {
      this.selectSingleItem(select)
    }
  }

  componentDidUpdate () {
    const { isListOpen } = this.state

    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener('click', this.close)
      } else {
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.close)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { list } = nextProps

    if (JSON.stringify(list) !== JSON.stringify(prevState.list)) {
      return { list }
    }

    return null
  }

  close = () => {
    this.setState({
      isListOpen: false
    })
  }

  clearSelection = () => {
    const { name, title, onChange } = this.props

    this.setState(
      {
        selectedItem: null,
        title
      },
      () => {
        onChange(null, name)
      }
    )
  }

  selectSingleItem = item => {
    const { list } = this.props

    const selectedItem = list.find(i => i.value === item.value)
    this.selectItem(selectedItem)
  }

  selectItem = item => {
    const { label, value } = item || {}
    const { list, selectedItem } = this.state
    const { name, onChange } = this.props

    let foundItem

    if (!label) {
      foundItem = list.find(i => i.value === item.value)
    }

    this.setState(
      {
        title: label || foundItem.label,
        isListOpen: false,
        selectedItem: item
      },
      () => selectedItem?.value !== value && onChange(item, name)
    )
  }

  toggleList = () => {
    this.setState(
      prevState => ({
        isListOpen: !prevState.isListOpen,
        keyword: ''
      }),
      () => {
        if (this.state.isListOpen && this.searchField.current) {
          this.searchField.current.focus()
          this.setState({
            keyword: ''
          })
        }
      }
    )
  }

  filterList = e => {
    this.setState({
      keyword: e.target.value.toLowerCase()
    })
  }

  listItems = () => {
    const { id, searchable } = this.props
    const { keyword, list } = this.state
    let tempList = [...list]

    if (keyword.length) {
      tempList = list.filter(item =>
        item.label.toLowerCase().includes(keyword.toLowerCase())
      )
    }

    if (tempList.length) {
      return tempList.map(item => (
        <button
          type='button'
          className={`${Styles.ddListItem} ${id}`}
          key={item.value}
          onClick={() => this.selectItem(item)}
        >
          {item.label}{' '}
        </button>
      ))
    }

    return (
      <div className={`${Styles.noResult} ${id}`} ref={this.panelRef}>
        {searchable[1]}
      </div>
    )
  }

  render () {
    const {
      id,
      searchable,
      width = '222px',
      wrapperStyle,
      value,
      overrideStyle
    } = this.props
    const { isListOpen, title, coords, keyword } = this.state
    return (
      <div
        className={`${Styles.ddWrapper} ${wrapperStyle} ${id}`}
        style={{ width, ...overrideStyle }}
        ref={this.buttonRef}
      >
        <button
          type='button'
          className={`${Styles.ddHeader} ${id}`}
          onClick={e => {
            this.toggleList()
            this.updateDropdownCoordinate(this.buttonRef.current)
          }}
        >
          <div className={`${Styles.ddHeaderTitle} ${id}`}>
            {value || title}
          </div>
          {isListOpen ? (
            <span>
              <img src={ArrowUp} />
            </span>
          ) : (
            <span>
              <img src={ArrowDown} />
            </span>
          )}
        </button>
        {isListOpen && (
          <Portal>
            <div
              className={`${Styles.ddList}${
                searchable ? ' searchable' : ''
              } ${id}`}
              style={{ ...coords, position: 'absolute' }}
            >
              {searchable && (
                <input
                  ref={this.searchField}
                  value={keyword}
                  className={`${Styles.ddListSearchBar} ${id}`}
                  placeholder={searchable[0]}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.filterList(e)}
                />
              )}
              <div className={`${Styles.ddScrollList} ${id}`}>
                {this.listItems()}
              </div>
            </div>
          </Portal>
        )}
      </div>
    )
  }
}

export default Dropdown
