import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hideToast } from '../../../store/toast/toastSlice'

const Toast = () => {
  const dispatch = useDispatch()
  const { isVisible, message, type } = useSelector(
    ({ toast: { isVisible, message, type } }) => ({ isVisible, message, type })
  )
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        dispatch(hideToast())
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [isVisible, hideToast])

  const getToastStyle = () => {
    let backgroundColor = '#333'
    switch (type) {
      case 'error':
        backgroundColor = '#FF6347'
        break
      case 'success':
        backgroundColor = '#32CD32'
        break
      default:
        break
    }

    return {
      position: 'fixed',
      bottom: '16px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '16px',
      backgroundColor,
      color: '#fff',
      borderRadius: '8px',
      display: isVisible ? 'block' : 'none'
    }
  }

  const portalRoot = document.getElementById('toast-portal')

  return portalRoot
    ? ReactDOM.createPortal(
        <div style={getToastStyle()}>{message}</div>,
        portalRoot
      )
    : null
}
export default Toast
