import React from 'react'
import { MockEditingContext } from '../contexts/mockEditingContext'

/**
 * wraps a mock-editor with the logic to identify whether it is in mock-edit mode or not whenever the cell is refreshed
 * @param WrappedComponent - The cell renderer component which should enter a mock edit mode
 */
const WithMockCellEditor = WrappedComponent =>
  class extends React.Component {
    state
    wrappedComponentRef
    static contextType = MockEditingContext

    constructor (props) {
      super(props)
      this.state = {
        isMockEditing: false
      }
      this.wrappedComponentRef = React.createRef()
    }

    refresh () {
      this.setState({
        isMockEditing: this.context.mockEditingId === this.props.node.id
      })
      return true
    }

    /** @returns the colId and value of the updated mock editor */
    getValue () {
      return this.wrappedComponentRef.current.getValue()
    }

    /** calls reset on the mock editor */
    reset () {
      this.wrappedComponentRef.current.reset()
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          isMockEditing={this.state.isMockEditing}
          ref={this.wrappedComponentRef}
        />
      )
    }
  }

export default WithMockCellEditor
