/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react'
import Portal from '../../../../../../../customHooks/portal'
import { useSelector, useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import Checkbox from '../../../../../../ui/Checkbox/Checkbox'
import {
  updateEditingComponent,
  updateEmailTemplateHandler
} from '../../../../../../../store/dashboard/mailerSlice'
import {
  container,
  labelStyle,
  titleStyle,
  toggleContent,
  btnGroupStyle,
  typeBtnStyle,
  typeBtnTextStyle,
  fieldStyle,
  inputStyle,
  colorBoxStyle,
  checkboxContainer
} from './style'

const ICONS = [
  {
    name: 'facebook',
    linkTo: 'https://facebook.com'
  },
  {
    name: 'instagram',
    linkTo: 'https://facebook.com'
  },
  {
    name: 'twitter',
    linkTo: 'https://facebook.com'
  },

  {
    name: 'linkedin',
    linkTo: 'https://facebook.com'
  }
]

const SocialMediaEditor = () => {
  const colorRef = useRef()
  const colorPickerRef = useRef()
  const dispatch = useDispatch()
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [colorCoords, setColorCoords] = useState({
    top: '',
    left: '',
    width: ''
  })
  const [socialEditorObj, updateSocialEditorObj] = useState({})
  const [showDesignContainer, toggleDesignContainer] = useState(false)
  const [showColorContainer, toggleColorContainer] = useState(false)

  const {
    editingPath,
    currentEditedSegmentandAudience,
    editingComponents,
    emailTemplates
  } = useSelector(
    ({
      mailer: {
        currentEditingComponent: { path: editingPath },
        currentEditedSegmentandAudience,
        editingComponents,
        emailTemplates
      }
    }) => ({
      editingPath,
      currentEditedSegmentandAudience,
      editingComponents,
      emailTemplates
    })
  )

  useEffect(() => {
    let editorObj = {
      socialMediaItems: [
        {
          name: 'facebook',
          linkTo: 'https://facebook.com'
        },
        {
          name: 'instagram',
          linkTo: 'https://facebook.com'
        },
        {
          name: 'twitter',
          linkTo: 'https://facebook.com'
        },

        {
          name: 'linkedin',
          linkTo: 'https://facebook.com'
        }
      ],
      size: 'small',
      alignment: 'center',
      shape: 'circle',
      colorType: 'original',
      iconColor: '#000'
    }
    const { segmentId, audienceId } = currentEditedSegmentandAudience
    const editingSegment = editingComponents[segmentId]
    if (editingSegment) {
      const editingAudience = editingSegment[audienceId]
      if (editingAudience) {
        editorObj = editingAudience[editingPath] || {}
      }
    }
    updateSocialEditorObj(editorObj)
  }, [editingComponents])

  useEffect(() => {
    const clickedOutside = event => {
      if (
        showColorPicker &&
        colorPickerRef.current &&
        !colorRef.current.contains(event.target) &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false)
      }
    }
    document.addEventListener('click', clickedOutside)
    return () => {
      document.removeEventListener('click', clickedOutside)
    }
  }, [colorRef, colorPickerRef, showColorPicker])

  const updateColorCoordinate = button => {
    const rect = button.getBoundingClientRect()
    let left = rect.x
    let top = rect.y + rect.height
    const windowHeight = window.innerHeight
    if (windowHeight - top - rect.height < 150) {
      top = rect.y - 200
    }
    setColorCoords({
      left, // add half the width of the button for centering
      top, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      width: button.offsetWidth
    })
  }

  const handleChange = (key, value) => {
    const updatedEditorObj = {
      ...socialEditorObj,
      [key]: value
    }
    updateSocialEditorObj(updatedEditorObj)
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: updatedEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  const handleIconToggle = (event, name) => {
    const {
      target: { checked }
    } = event || {}
    const newEditorObj = { ...socialEditorObj }
    if (checked) {
      const iconObj = ICONS.find(({ name: iconName }) => name === iconName)
      const newArr = [...newEditorObj['socialMediaItems'], iconObj]
      newEditorObj['socialMediaItems'] = newArr
    } else {
      const updatedArr = socialEditorObj['socialMediaItems'].filter(
        ({ name: iconName }) => name !== iconName
      )
      newEditorObj['socialMediaItems'] = updatedArr
    }
    updateSocialEditorObj(newEditorObj)
    dispatch(
      updateEditingComponent({
        path: editingPath,
        value: newEditorObj,
        currentEditedSegmentandAudience,
        state: editingComponents
      })
    )
    setTimeout(() => {
      dispatch(
        updateEmailTemplateHandler(
          emailTemplates,
          currentEditedSegmentandAudience
        )
      )
    }, 300)
  }

  return (
    <div css={container}>
      <div css={titleStyle}>Social</div>
      <div
        style={{
          paddingBottom: '24px',
          borderBottom: '1px solid #e7e7e7'
        }}
      >
        <label css={labelStyle}>Icons</label>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {ICONS.map(({ name }, index) => {
            const isChecked =
              (socialEditorObj['socialMediaItems'] || []).findIndex(
                ({ name: iconName }) => iconName === name
              ) > -1
            return (
              <div key={name} css={checkboxContainer}>
                <Checkbox
                  checked={isChecked}
                  onChange={event => handleIconToggle(event, name)}
                  index={index}
                />
                {name}
              </div>
            )
          })}
        </div>
      </div>
      <div css={toggleContent}>
        <div
          className='toggle-arrow-wrap'
          onClick={() => {
            toggleDesignContainer(prev => !prev)
          }}
        >
          <div className='toggle-title'>Design</div>
          <div className={`arrow ${showDesignContainer && 'is-open'}`}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '10px', height: '10px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0634553 10L0 0L6 4.91624L0.0634553 10Z'
                fill='#17a3e1'
              ></path>
            </svg>
          </div>
        </div>
        <div
          className={'content'}
          style={{ display: showDesignContainer ? 'block' : 'none' }}
        >
          <div style={{ marginBottom: '24px' }}>
            <label css={labelStyle}>Size</label>
            <div css={btnGroupStyle}>
              <button
                css={typeBtnStyle(socialEditorObj.size === 'small')}
                onClick={() => handleChange('size', 'small')}
              >
                <div css={typeBtnTextStyle}>Small</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.size === 'medium')}
                onClick={() => handleChange('size', 'medium')}
              >
                <div css={typeBtnTextStyle}>Medium</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.size === 'large')}
                onClick={() => handleChange('size', 'large')}
              >
                <div css={typeBtnTextStyle}>Large</div>
              </button>
            </div>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <label css={labelStyle}>Alignment</label>
            <div css={btnGroupStyle}>
              <button
                css={typeBtnStyle(socialEditorObj.alignment === 'flex-start')}
                onClick={() => handleChange('alignment', 'flex-start')}
              >
                <div css={typeBtnTextStyle}>Left</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.alignment === 'center')}
                onClick={() => handleChange('alignment', 'center')}
              >
                <div css={typeBtnTextStyle}>Center</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.alignment === 'flex-end')}
                onClick={() => handleChange('alignment', 'flex-end')}
              >
                <div css={typeBtnTextStyle}>Right</div>
              </button>
            </div>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <label css={labelStyle}>Shape</label>
            <div css={btnGroupStyle}>
              <button
                css={typeBtnStyle(socialEditorObj.shape === 'circle')}
                onClick={() => handleChange('shape', 'circle')}
              >
                <div css={typeBtnTextStyle}>Circle</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.shape === 'rounded')}
                onClick={() => handleChange('shape', 'rounded')}
              >
                <div css={typeBtnTextStyle}>Rounded</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.shape === 'squared')}
                onClick={() => handleChange('shape', 'squared')}
              >
                <div css={typeBtnTextStyle}>Square</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div css={toggleContent} className='color-container'>
        <div
          className='toggle-arrow-wrap'
          onClick={() => {
            toggleColorContainer(prev => !prev)
          }}
        >
          <div className='toggle-title'>Colors</div>
          <div className={`arrow ${showColorContainer && 'is-open'}`}>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ width: '10px', height: '10px' }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0634553 10L0 0L6 4.91624L0.0634553 10Z'
                fill='#17a3e1'
              ></path>
            </svg>
          </div>
        </div>
        <div
          className={'content'}
          style={{ display: showColorContainer ? 'block' : 'none' }}
        >
          <div style={{ marginBottom: '24px' }}>
            <div css={btnGroupStyle}>
              <button
                css={typeBtnStyle(socialEditorObj.colorType === 'original')}
                onClick={() => handleChange('colorType', 'original')}
              >
                <div css={typeBtnTextStyle}>Original</div>
              </button>
              <button
                css={typeBtnStyle(socialEditorObj.colorType === 'custom')}
                onClick={() => handleChange('colorType', 'custom')}
              >
                <div css={typeBtnTextStyle}>Custom</div>
              </button>
            </div>
          </div>
          {socialEditorObj.colorType === 'custom' && (
            <div>
              <label css={labelStyle}>Color</label>
              <div css={fieldStyle} ref={colorRef}>
                <input
                  css={inputStyle}
                  type='text'
                  value={socialEditorObj.iconColor}
                  onChange={({ target: { value } }) => {
                    handleChange('iconColor', value)
                  }}
                />
                <span
                  css={colorBoxStyle}
                  style={{ backgroundColor: socialEditorObj.iconColor }}
                  onClick={() => {
                    setShowColorPicker(prev => !prev)
                    updateColorCoordinate(colorRef.current)
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {showColorPicker && socialEditorObj.colorType === 'custom' && (
        <Portal>
          <div
            style={{ ...colorCoords, position: 'absolute' }}
            ref={colorPickerRef}
          >
            <SketchPicker
              color={socialEditorObj.iconColor}
              onChangeComplete={color => {
                handleChange('iconColor', color.hex)
              }}
            />
          </div>
        </Portal>
      )}
    </div>
  )
}

export default SocialMediaEditor
