/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'

import {
  container,
  header,
  audienceAccountData,
  content,
  optionsHeader,
  optionsToCheck,
  optionStyle,
  actionButtonSection,
  buttonStyle
} from './style'

import bomboraIcon from '../../../../../assets/images/dashboard/audience/bombora.png'

const BomboraAudienceCard = ({
  bomboraAudienceId,
  bomboraData,
  saveBomboraAudience = () => {},
  deleteBomboraAudience = () => {}
}) => {
  const [options, setOptions] = useState({
    ...JSON.parse(JSON.stringify(bomboraData.options))
  })

  const optionOnChangeHandler = (event, optionKey) => {
    const prevData = JSON.parse(JSON.stringify(options))
    prevData[optionKey].value = event.target.checked
    setOptions(prevData)
  }

  return (
    <div css={container}>
      <div css={header}>
        <span className='iconBox'>
          <img className='image' alt='bomboraIcon' src={bomboraIcon}></img>
        </span>
        <div css={audienceAccountData}>
          <label>{bomboraData.audienceName}</label>
          <hr></hr>
          <span>8 Topics</span>
        </div>
      </div>
      <div css={content}>
        <div css={optionsHeader}>
          <label className='plus'>+</label>
          <label>12 Topics Available</label>
        </div>
        <div css={optionsToCheck}>
          {Object.keys(options).map((option, index) => {
            return (
              <div css={optionStyle} key={option}>
                <div>
                  <input
                    type='checkbox'
                    className={`input-edit checkbox`}
                    id={`checkbox-${bomboraAudienceId}-${index}`}
                    checked={option.value}
                    onChange={event => optionOnChangeHandler(event, option)}
                  />
                  <label htmlFor={`checkbox-${bomboraAudienceId}-${index}`} />
                </div>
                <span className='optionKeyName'>{options[option].key}</span>
              </div>
            )
          })}
        </div>
        <div css={actionButtonSection}>
          <button
            css={buttonStyle}
            onClick={() => deleteBomboraAudience(bomboraAudienceId)}
          >
            Delete
          </button>
          <button
            css={buttonStyle}
            className='saveContacts'
            onClick={() => saveBomboraAudience(bomboraAudienceId, options)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default BomboraAudienceCard
