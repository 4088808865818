import { css } from '@emotion/react/macro'

export const container = css`
  position: relative;
`

export const inputStyle = css`
  height: 39px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  background: transparent;
`

export const chipStyle = css`
  height: 34px;
  border: 1px solid #ccc;
  padding: 9px 16px 8px 15px;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  color: #333;
  font-family: BentonSans;
  display: inline-flex;
  justify-content: space-between;
  min-width: 152px;
  line-height: 1.2;
  margin-right: 5px;
  .crossIcon {
    cursor: pointer;
    &:hover {
      transform: scale(1.01);
    }
  }
`

export const dropdownMenu = css`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: 400px;
`

export const dropdownSearch = css`
  position: relative;
  max-width: 200px;
  background: #fff;
  min-width: 150px;
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 5px;
  padding: 4px 17px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const optionsPanel = css`
  width: 100%;
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
`
